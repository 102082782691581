import { faInfoCircle, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { setAffilliatedPopup } from "../../store";
import { useDispatch } from "react-redux";

interface PartPropertyServiceProvidersListProps {
    propertyVendors: Array<any>;
}

const PartPropertyServiceProvidersList = ({
    propertyVendors
}: PartPropertyServiceProvidersListProps) => {
    const dispatch = useDispatch();

    return (
        <>
            <div className="property-card__listing-top service-providers">
                <h3>
                    <FontAwesomeIcon icon={faUser} />
                    <span>Other Key Players</span>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="property-card__listing-tooltip-icon"
                    />
                    <span className="property-card__listing-tooltip">
                        <span>
                            Real estate professionals that claim to have
                            provided services to this property.
                        </span>
                        <span>
                            * GPARENCY does not validate their affiliation.
                        </span>
                    </span>
                </h3>
            </div>
            {propertyVendors !== undefined && (
                <div className="property-card__service-providers-wrap">
                    {propertyVendors &&
                        propertyVendors.map((vendor: any) => {
                            const role = vendor.vendorRole;
                            return vendor.vendorContacts.map(
                                (vendorContact: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className="property-card__service-provider"
                                        >
                                            <h6>
                                                {vendorContact.firstName}{" "}
                                                {vendorContact.lastName}
                                            </h6>
                                            <span>{role}</span>
                                            <a
                                                href={`mailto:${vendorContact.email}}`}
                                                className="property-card__list-content property-card__list-link"
                                            >
                                                {vendorContact.email}
                                            </a>
                                            <a
                                                className="property-card__list-content property-card__list-link"
                                                href={`tel:${vendorContact.phone}`}
                                            >
                                                {vendorContact.phone}
                                            </a>
                                        </div>
                                    );
                                }
                            );
                        })}
                </div>
            )}
            <button
                onClick={() => {
                    dispatch(setAffilliatedPopup(true));
                }}
                className="property-card__service-providers-popup-trigger"
            >
                Claim your professional association to this property. Get
                marketed to owners.
            </button>
        </>
    );
};

export default PartPropertyServiceProvidersList;
