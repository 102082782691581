import { USER_DATA_API } from "../../apiList";
import { API_CLIENT_CONTENT_TYPE_JSON } from "../../utils/http/ApiClient";
import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
export default class FavoritePropertyGateway {
    addToFavorite(data: Collection) {
        return USER_DATA_API.post("/property-stars", data, {
            contentType: API_CLIENT_CONTENT_TYPE_JSON
        });
    }

    removeFromFavorite(propertyId: TypeString) {
        return USER_DATA_API.delete(`/property-stars/${propertyId.value}`);
    }
    getPropertyStar(propertyId: TypeString) {
        return USER_DATA_API.get(`/property-stars/${propertyId.value}`);
    }
    getFavoriteProperties(queryData?: Collection) {
        return USER_DATA_API.get(`/property-stars`, queryData);
    }

    toggleAlerts(data: Collection) {
        return USER_DATA_API.post("/property-stars/alert", data, {
            contentType: API_CLIENT_CONTENT_TYPE_JSON
        });
    }
}
