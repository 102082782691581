import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import LPInvestmentFactory from "./LPInvestmentFactory";
import LPInvestmentGateway from "./LPInvestmentGateway";
import LPInvestmentRepository from "./LPInvestmentRepository";

export default class LPInvestmentService {
    static getAll(propertyId: TypeString) {
        return new LPInvestmentRepository(
            new LPInvestmentGateway(),
            new LPInvestmentFactory()
        ).getAll(propertyId);
    }

    static addTransaction(propertyId: TypeString, data: Collection) {
        return new LPInvestmentGateway().addTransaction(propertyId, data);
    }

    static updateTransaction(sortKey: TypeString, data: Collection) {
        return new LPInvestmentGateway().updateTransaction(sortKey, data);
    }

    static removeTransaction(sortKey: TypeString) {
        return new LPInvestmentGateway().removeTransaction(sortKey);
    }

    static createPropertyPortfolio(data: Collection) {
        return new LPInvestmentGateway().createPropertyPortfolio(data);
    }
    static updatePropertyPortfolio(data: Collection) {
        return new LPInvestmentGateway().updatePropertyPortfolio(data);
    }
}
