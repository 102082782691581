import TypeString from "../valueObject/TypeString";

export default class VendorRoleEntity {
    _description: TypeString;
    _roleName: TypeString;
    _code: TypeString;

    get description(): TypeString {
        return this._description;
    }

    setDescription(value: TypeString) {
        this._description = value;
        return this;
    }

    hasDescription(): boolean {
        return this._description instanceof TypeString;
    }

    get roleName(): TypeString {
        return this._roleName;
    }

    setRoleName(value: TypeString) {
        this._roleName = value;
        return this;
    }

    hasRoleName(): boolean {
        return this._roleName instanceof TypeString;
    }

    get code(): TypeString {
        return this._code;
    }

    setCode(value: TypeString) {
        this._code = value;
        return this;
    }

    hasCode(): boolean {
        return this._code instanceof TypeString;
    }
}
