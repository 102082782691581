import Exception from "../../utils/exceptions/Exception";
import TypeInteger from "./TypeInteger";

export default class TypePositiveInteger extends TypeInteger {
    constructor(value: number | string) {
        super(value);
        this._validateValue(value);
    }

    _validateValue(value: any) {
        super._validateValue(value);
        if (this._value < 0)
            throw new Exception(
                `Expected positive integer got ${this._value}`,
                406
            );
    }

    addLeadingZeros(numberOfZeros: number): string {
        let value = this._value.toString();
        const zeros = [Math.pow(10, numberOfZeros - 1)].toString();

        while (value.length < zeros.length) {
            value = "0" + value;
        }

        return value;
    }
}
