import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import BaseValueObject from "../../domain/valueObject/BaseValueObject";
import Collection from "../../domain/valueObject/Collection";
import {
    AxiosRequestIntarface,
    ErrorResponse
} from "../../interfaces/interfacesIndex";
import { checkTokenExpiration } from "./../utils";
import store from "../../store/storeIndex";

export const API_CLIENT_CONTENT_TYPE_JSON = 1;
export const API_CLIENT_CONTENT_TYPE_FORM_DATA = 2;

export default class APIClient {
    _axiosClient: any;
    _baseUrl: any;
    _loginPromise: Promise<any> | undefined;
    _includeToken: boolean | undefined = false;
    private _accessTokenSilentlyReference: Function;

    constructor(baseUrl: any, includeToken?: boolean) {
        this._baseUrl = baseUrl;
        this._includeToken = includeToken;
        this._axiosClient = axios.create({
            baseURL: baseUrl,
            withCredentials: false,
            headers: {
                Accept: "*/*",
                "Access-Control-Allow-Origin": "*"
            }
        });
        const onRequest = async (
            config: AxiosRequestIntarface
        ): Promise<AxiosRequestConfig> => {
            if (config.data instanceof Collection)
                switch (config.method) {
                    case "post":
                        if (
                            config?.contentType === API_CLIENT_CONTENT_TYPE_JSON
                        )
                            config.data = this._dataToJson(config.data);

                        if (
                            config?.contentType ===
                            API_CLIENT_CONTENT_TYPE_FORM_DATA
                        )
                            config.data = this._dataToFormData(config.data);
                        break;
                    case "put":
                    case "delete":
                    case "get":
                        config.url += this._dataToQueryString(config.data);
                        break;
                }

            let token = store.getState().index?.token;
            if (this._includeToken && !token) {
                throw new Error("Token is not available");
            }
            const expired = checkTokenExpiration(token);

            if (this._accessTokenSilentlyReference)
                token = expired
                    ? await this._accessTokenSilentlyReference()
                    : token;

            if (config.headers && this._includeToken && token)
                config.headers["Authorization"] = `Bearer ${token}`;

            return config;
        };

        const onRequestError = (error: AxiosError): Promise<AxiosError> => {
            return Promise.reject(error);
        };

        const onResponse = (response: AxiosResponse): any => {
            return response.data ? response.data : undefined;
        };

        const onResponseError = async (
            error: ErrorResponse
        ): Promise<ErrorResponse> => {
            return Promise.reject(error);
        };

        this._axiosClient.interceptors.request.use(onRequest, onRequestError);
        this._axiosClient.interceptors.response.use(
            onResponse,
            onResponseError
        );
    }

    // Reference for getA
    public setAccessTokenSilentlyReference(value: Function) {
        this._accessTokenSilentlyReference = value;
        return this;
    }
    get(endpoint: string, data?: Collection): any {
        return this._axiosClient.get(endpoint, {
            data: data
        });
    }

    post(
        endpoint: string,
        data?: Collection,
        config?: AxiosRequestIntarface
    ): any {
        const axiosConfig: AxiosRequestConfig = { ...config };
        return config
            ? this._axiosClient.post(endpoint, data, axiosConfig)
            : this._axiosClient.post(endpoint, data);
    }

    put(
        endpoint: string,
        data?: Collection,
        config?: AxiosRequestIntarface
    ): any {
        const axiosConfig: AxiosRequestConfig = { ...config };
        return config
            ? this._axiosClient.put(endpoint, data, axiosConfig)
            : this._axiosClient.put(endpoint, data);
    }

    delete(endpoint: string, data?: Collection): any {
        return this._axiosClient.delete(endpoint, { data: data });
    }

    _dataToJson(data: Collection) {
        return data.map((item: any) => {
            switch (true) {
                case item instanceof Collection:
                    return this._dataToJson(item);
                case item instanceof BaseValueObject:
                    return item.value;
                default:
                    return item;
            }
        }).items;
    }

    _dataToFormData(data: Collection) {
        const formData = new FormData();
        data.forEach((value: any, key: any) => {
            if (value instanceof Collection) {
                // multilevel support
                value.forEach((subValue: any, subKey: any) => {
                    const parsedValue =
                        subValue instanceof BaseValueObject
                            ? subValue.value
                            : subValue;

                    formData.append(`${key}[${subKey}]`, parsedValue);
                });
            } else {
                // single level support
                const parsedValue =
                    value instanceof BaseValueObject ? value.value : value;

                formData.append(key, parsedValue);
            }
        });

        return formData;
    }

    _arrayToQueryString(
        array: Array<any>,
        resultingItems: Array<any>,
        key: any
    ) {
        array.forEach((value) => {
            this._valueToQueryString(value, resultingItems, `${key}[]`);
        });
    }

    _associativeCollectionToQueryString(
        collection: Collection,
        resultingItems: Array<any>,
        key: any
    ) {
        collection.forEach((value: any, parameterKey: any) => {
            this._valueToQueryString(
                value,
                resultingItems,
                `${key}[${parameterKey}]`
            );
        });
    }

    _collectionToQueryString(
        collection: Collection,
        resultingItems: Array<any>,
        key: any
    ) {
        collection.isAssociative()
            ? this._associativeCollectionToQueryString(
                  collection,
                  resultingItems,
                  key
              )
            : this._arrayToQueryString(collection.items, resultingItems, key);
    }

    _valueToQueryString(value: any, resultingItems: Array<any>, key: any) {
        if (Array.isArray(value))
            this._arrayToQueryString(value, resultingItems, key);
        else if (value instanceof Collection)
            this._collectionToQueryString(value, resultingItems, key);
        else {
            resultingItems.push(
                `${encodeURIComponent(key)}=${encodeURIComponent(
                    value instanceof BaseValueObject ? value.value : value
                )}`
            );
        }
    }

    _dataToQueryString(data: Collection) {
        const queryString: Array<any> = [];
        data.forEach((value: any, key: any) => {
            this._valueToQueryString(value, queryString, key);
        });
        return "?" + queryString.join("&");
    }
}
