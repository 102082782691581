import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import PropertyDocumentFactory from "./PropertyDocumentFactory";
import PropertyDocumentGateway from "./PropertyDocumentGateway";
import PropertyDocumentRepository from "./PropertyDocumentRepository";

export default class PropertyDocumentService {
    static getAll(propertyId: TypeString) {
        return new PropertyDocumentRepository(
            new PropertyDocumentGateway(),
            new PropertyDocumentFactory()
        ).getAll(propertyId);
    }

    static deleteDocument(propertyID: TypeString, documentId: TypeString) {
        return new PropertyDocumentGateway().deleteDocument(
            propertyID.value,
            documentId.value
        );
    }

    static uploadDocumentStep1(data: Collection) {
        return new PropertyDocumentGateway().uploadDocumentStep1(data);
    }

    static uploadDocumentStep2(
        url: string,
        data: any,
        contentType: string,
        handleOnUpload: any
    ) {
        return new PropertyDocumentGateway().uploadDocumentStep2(
            url,
            data,
            contentType,
            handleOnUpload
        );
    }
}
