const PartMyPropertiesSkeleton = () => {
    return (
        <div className="my-properties__skeleton">
            <div className="my-properties__skeleton-row">
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
            </div>
            <div className="my-properties__skeleton-row">
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
            </div>
            <div className="my-properties__skeleton-row">
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
            </div>
            <div className="my-properties__skeleton-row">
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
            </div>
            <div className="my-properties__skeleton-row">
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
            </div>
            <div className="my-properties__skeleton-row">
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
                <span className="my-properties__skeleton-item"></span>
            </div>
        </div>
    );
};

export default PartMyPropertiesSkeleton;
