//Check the crm-cta-app readMe for the constantly updated list to add new ENUMs

export const WHERE_FROM_CTA = {
    LEFT_NAV_FINANCE_COMPS_CHECKBOX: "LEFT_NAV_FINANCE_COMPS_CHECKBOX",
    LEFT_NAV_SALES_COMPS_CHECKBOX: "LEFT_NAV_SALES_COMPS_CHECKBOX",
    LEFT_NAV_SALES_FINANCE_COMP_FILTERS: "LEFT_NAV_SALES_FINANCE_COMP_FILTERS",
    PROPERTY_CARD_SALES_COMP_TAB: "PROPERTY_CARD_SALES_COMP_TAB",
    PROPERTY_CARD_FINANCE_BUTTON: "PROPERTY_CARD_FINANCE_BUTTON",
    PROPERTY_CARD_FINANCE_COMP_TAB: "PROPERTY_CARD_FINANCE_COMP_TAB",
    PROPERTY_CARD_UNLOCK_LISTING_PRICE: "PROPERTY_CARD_UNLOCK_LISTING_PRICE",
    PROPERTY_CARD_UNLOCK_BROKERAGE_INFO: "PROPERTY_CARD_UNLOCK_BROKERAGE_INFO",
    TRIAL_MEMBERSHIP_UPGRADE: "TRIAL_MEMBERSHIP_UPGRADE",
    LEFT_NAV_LENDER_FLYOUT: "LEFT_NAV_LENDER_FLYOUT",
    LEFT_NAV_LENDER_UNLOCK_AMOUNT_AND_LOAN_TYPE:
        "LEFT_NAV_LENDER_UNLOCK_AMOUNT_AND_LOAN_TYPE",
    LEFT_NAV_LENDER_RESULTS: "LEFT_NAV_LENDER_RESULTS",
    LEFT_NAV_LENDER_LOCKS: "LEFT_NAV_LENDER_LOCKS",
    PROPERTY_CARD_UNLOCK_BROKER_INFO: "PROPERTY_CARD_UNLOCK_BROKER_INFO",
    LEFT_NAV_INTEREST_RATES: "LEFT_NAV_INTEREST_RATES",
    LEFT_NAV_LENDER_FLYOUT_INFO_SHARE: "LEFT_NAV_LENDER_FLYOUT_INFO_SHARE",
    LEFT_NAV_LENDER_DEAL_MATCH: "LEFT_NAV_LENDER_DEAL_MATCH",
    LEFT_NAV_LENDER_NAME_SEARCH: "LEFT_NAV_LENDER_NAME_SEARCH",
    NON_PAID_USER_WELCOME_MESSAGE_MEMBERSHIP_UPGRADE:
        "NON_PAID_USER_WELCOME_MESSAGE_MEMBERSHIP_UPGRADE",
    NON_PAID_USER_PROFILE_ICON_MEMBERSHIP_UPGRADE:
        "NON_PAID_USER_PROFILE_ICON_MEMBERSHIP_UPGRADE",
    NON_PAID_USER_NAV_BAR_MEMBERSHIP_UPGRADE:
        "NON_PAID_USER_NAV_BAR_MEMBERSHIP_UPGRADE",
    NON_PAID_USER_MORE_DETAILS_MEMBERSHIP_UPGRADE:
        "NON_PAID_USER_MORE_DETAILS_MEMBERSHIP_UPGRADE",
    NON_PAID_USER_NAV_BAR_DEMO: "NON_PAID_USER_NAV_BAR_DEMO"
};
