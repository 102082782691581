import VendorFactory from "../representer/vendor/VendorFactory";
import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";

export default class PropertyVendorRepository {
    _gateway: any;
    _factory: any;

    constructor(gateway: any, factory: any) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async get(id: TypeString) {
        const data = new Collection(await this._gateway.getAll(id));
        let items = new Collection([]);

        items = data.slice("responseData").map((itemData: any) => {
            const item = new Collection(itemData);
            const vendorContactsCollection = new Collection([]);

            const vendorContacts = new Collection(item.get("vendorContacts"));
            vendorContacts.forEach((vendorContact: any) => {
                vendorContactsCollection.insert(
                    new VendorFactory().reconstitute(
                        new Collection(vendorContact)
                    )
                );
            });
            const vendorServiceProviders = this._factory.reconstitute(item);
            vendorServiceProviders.setVendorContacts(vendorContactsCollection);
            return vendorServiceProviders;
        });

        return items;
    }
}
