import Collection from "../valueObject/Collection";
import TypeBoolean from "../valueObject/TypeBoolean";
import TypeFloat from "../valueObject/TypeFloat";
import TypeString from "../valueObject/TypeString";

export default class LPInvestmentEntity {
    _finalDistributionEstimateAmount: TypeFloat;
    _distributionTargetAmount: TypeFloat;
    _distributionScheduleType: TypeString;
    _distributionTrackAlert: TypeBoolean;
    _opportunitiesSubscription: TypeBoolean;
    _sortKey: TypeString;
    _propertyTransactions: Collection;
    _nextDistributionDate: Date;

    get finalDistributionEstimateAmount() {
        return this._finalDistributionEstimateAmount;
    }

    setFinalDistributionEstimateAmount(value: TypeFloat) {
        this._finalDistributionEstimateAmount = value;
        return this;
    }

    hasFinalDistributionEstimateAmount() {
        return this._finalDistributionEstimateAmount instanceof TypeFloat;
    }

    get distributionTargetAmount() {
        return this._distributionTargetAmount;
    }

    setDistributionTargetAmount(value: TypeFloat) {
        this._distributionTargetAmount = value;
        return this;
    }

    hasDistributionTargetAmount() {
        return this._distributionTargetAmount instanceof TypeFloat;
    }

    get distributionScheduleType() {
        return this._distributionScheduleType;
    }

    setDistributionScheduleType(value: TypeString) {
        this._distributionScheduleType = value;
        return this;
    }

    hasDistributionScheduleType() {
        return this._distributionScheduleType instanceof TypeString;
    }

    get distributionTrackAlert() {
        return this._distributionTrackAlert;
    }

    setDistributionTrackAlert(value: TypeBoolean) {
        this._distributionTrackAlert = value;
        return this;
    }

    hasDistributionTrackAlert() {
        return this._distributionTrackAlert instanceof TypeBoolean;
    }

    get opportunitiesSubscription() {
        return this._opportunitiesSubscription;
    }

    setOpportunitiesSubscription(value: TypeBoolean) {
        this._opportunitiesSubscription = value;
        return this;
    }

    hasOpportunitiesSubscription() {
        return this._opportunitiesSubscription instanceof TypeBoolean;
    }

    get sortKey() {
        return this._sortKey;
    }

    setSortKey(value: TypeString) {
        this._sortKey = value;
        return this;
    }

    hasSortKey() {
        return this._sortKey instanceof TypeString;
    }

    get propertyTransactions() {
        return this._propertyTransactions;
    }

    setPropertyTransactions(value: Collection) {
        this._propertyTransactions = value;
        return this;
    }

    hasPropertyTransactions() {
        return this._propertyTransactions instanceof Collection;
    }

    get nextDistributionDate() {
        return this._nextDistributionDate;
    }

    setNextDistributionDate(value: Date) {
        this._nextDistributionDate = value;
        return this;
    }

    hasNextDistributionDate() {
        return this._nextDistributionDate instanceof Date;
    }
}
