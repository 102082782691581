import { IPropertyType, IState } from "../store";

export const getStateIdFromAbb = (
    states: IState[],
    abb: string
): number | undefined => {
    return states.find((state: IState) => state.abbreviation === abb)?.id;
};

export const getStateNameById = (id: number, states: IState[]) => {
    return states.find((state: any) => state.id === id)?.state;
};

export const getStateAbbById = (id: number, states: IState[]) => {
    return states.find((state: any) => state.id === id)?.abbreviation;
};

export const getPropertyTypeLabelById = (
    id: number,
    propertyTypes: IPropertyType[]
) => {
    return propertyTypes.find((propertyType: any) => propertyType.value === id)
        ?.label;
};
