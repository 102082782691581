import Collection from "../../valueObject/Collection";
import TypePositiveInteger from "../../valueObject/TypePositiveInteger";
import TypeString from "../../valueObject/TypeString";
import BrokerEntity from "./BrokerEntity";

export default class BrokerFactory {
    reconstitute(item: Collection): BrokerEntity {
        const broker = new BrokerEntity();
        if (item.has("id"))
            broker.setId(new TypePositiveInteger(item.get("id")));
        if (item.has("name")) broker.setName(new TypeString(item.get("name")));
        if (item.has("email"))
            broker.setEmail(new TypeString(item.get("email")));
        if (item.has("mobile"))
            broker.setPhone(new TypeString(item.get("mobile")));

        return broker;
    }
}
