import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./storeIndex";

interface IMapTriggers {
    listingsChecked: boolean;
    listingsDateRange: number | undefined;
    triggerZoomToPolygon: any;
    showOnlyFavoriteProperties: boolean;
    propertyFavoriteActionTriggered: boolean;
    triggerNotesTab: boolean;
    triggerPropretyServiceProviders: boolean;
    setPropertyCardMarkAsFavorite: boolean;
    setPropertyCardHasAlerts: boolean;
    hidePropertyCard: boolean;
    showFavoritePopup: boolean;
    triggerDocTab: boolean;
    triggerAddressFocus: boolean;
    triggerTenantLeadsAcc: string | null;
    triggerTenantLeadFetch: boolean;
}

export const initialState: IMapTriggers = {
    listingsChecked: false,
    listingsDateRange: undefined,
    triggerZoomToPolygon: undefined,
    showOnlyFavoriteProperties: false,
    propertyFavoriteActionTriggered: false,
    triggerNotesTab: false,
    setPropertyCardMarkAsFavorite: false,
    setPropertyCardHasAlerts: false,
    triggerPropretyServiceProviders: false,
    hidePropertyCard: false,
    showFavoritePopup: false,
    triggerDocTab: false,
    triggerAddressFocus: false,
    triggerTenantLeadsAcc: null,
    triggerTenantLeadFetch: false
};

export const mapTriggers = createSlice({
    name: "mapTriggers",
    initialState,
    reducers: {
        setListingsChecked: (state, action: PayloadAction<any>) => {
            state.listingsChecked = action.payload;
        },
        setListingsDateRange: (state, action: PayloadAction<any>) => {
            state.listingsDateRange = action.payload;
        },
        setTriggerZoomToPolygon: (state, action: PayloadAction<any>) => {
            state.triggerZoomToPolygon = action.payload;
        },
        setShowOnlyFavoriteProperties: (state, action: PayloadAction<any>) => {
            state.showOnlyFavoriteProperties = action.payload;
        },
        setPropertyFavoriteActionTriggered: (
            state,
            action: PayloadAction<any>
        ) => {
            state.propertyFavoriteActionTriggered = action.payload;
        },
        setTriggerNotesTab: (state, action: PayloadAction<any>) => {
            state.triggerNotesTab = action.payload;
        },
        setPropertyCardMarkAsFavorite: (state, action: PayloadAction<any>) => {
            state.setPropertyCardMarkAsFavorite = action.payload;
        },
        setPropertyCardHasAlerts: (state, action: PayloadAction<any>) => {
            state.setPropertyCardHasAlerts = action.payload;
        },
        setTriggerPropretyServiceProviders: (
            state,
            action: PayloadAction<any>
        ) => {
            state.triggerPropretyServiceProviders = action.payload;
        },
        setHidePropertyCard: (state, action: PayloadAction<boolean>) => {
            state.hidePropertyCard = action.payload;
        },
        setShowFavoritePopup: (state, action: PayloadAction<boolean>) => {
            state.showFavoritePopup = action.payload;
        },
        setTriggerDocTab: (state, action: PayloadAction<boolean>) => {
            state.triggerDocTab = action.payload;
        },
        setTriggerAddressFocus: (state, action: PayloadAction<boolean>) => {
            state.triggerAddressFocus = action.payload;
        },
        setTriggerTenantLeadsAcc: (
            state,
            action: PayloadAction<string | null>
        ) => {
            state.triggerTenantLeadsAcc = action.payload;
        },
        setTriggerTenantLeadFetch: (state, action: PayloadAction<boolean>) => {
            state.triggerTenantLeadFetch = action.payload;
        }
    }
});

export const {
    setListingsChecked,
    setListingsDateRange,
    setTriggerZoomToPolygon,
    setShowOnlyFavoriteProperties,
    setPropertyFavoriteActionTriggered,
    setTriggerNotesTab,
    setPropertyCardMarkAsFavorite,
    setPropertyCardHasAlerts,
    setTriggerPropretyServiceProviders,
    setHidePropertyCard,
    setShowFavoritePopup,
    setTriggerDocTab,
    setTriggerAddressFocus,
    setTriggerTenantLeadsAcc,
    setTriggerTenantLeadFetch
} = mapTriggers.actions;

const mapTriggersSliceState = (state: RootState) => state.mapTriggers;

export default mapTriggersSliceState;
