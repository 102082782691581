import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./storeIndex";

interface IResponsive {
    isMobile: boolean;
    currentView: "dashboard" | "map";
    overlays: {
        userMenu: boolean;
        hamburgerMenu: boolean;
        propertySearch: boolean;
        lenderDefault: boolean;
        lenderOverlay: boolean;
        indexesOverlay: boolean;
        ratesOverlay: boolean;
        myPropertiesOverlay: boolean;
        lenderPortfoliosOverlay: boolean;
        videoWalkthroughOverlay: boolean;
        fannyFreddyLendersOverlay: boolean;
        tenantLeadsOverlay: boolean;
    };
    showMapBtn: boolean;
}

export const initialState: IResponsive = {
    isMobile: false,
    currentView: "dashboard",
    overlays: {
        userMenu: false,
        hamburgerMenu: false,
        propertySearch: false,
        lenderDefault: false,
        lenderOverlay: false,
        indexesOverlay: false,
        ratesOverlay: false,
        myPropertiesOverlay: false,
        lenderPortfoliosOverlay: false,
        videoWalkthroughOverlay: false,
        fannyFreddyLendersOverlay: false,
        tenantLeadsOverlay: false
    },
    showMapBtn: true
};

export const responsiveSlice = createSlice({
    name: "responsive",
    initialState,
    reducers: {
        setIsMobile: (state, action: PayloadAction<any>) => {
            state.isMobile = action.payload;
        },
        setCurrentView: (state, action: PayloadAction<any>) => {
            state.currentView = action.payload;
        },
        setOverlays: (state, action: PayloadAction<any>) => {
            state.overlays = action.payload;
        },
        setShowMapBtn: (state, action: PayloadAction<any>) => {
            state.showMapBtn = action.payload;
        }
    }
});

export const { setIsMobile, setCurrentView, setOverlays, setShowMapBtn } =
    responsiveSlice.actions;

const responsiveSliceState = (state: RootState) => state.responsive;

export default responsiveSliceState;
