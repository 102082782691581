import PropertyType from "../property/valueObject/PropertyType";
import Collection from "../valueObject/Collection";
import TypeBoolean from "../valueObject/TypeBoolean";
import TypeFloat from "../valueObject/TypeFloat";
import TypeString from "../valueObject/TypeString";
import FavoritePropertyEntity from "./FavoritePropertyEntity";
import FavoriteType from "./valueObject/FavoriteType";

export default class FavoritePropertyFactory {
    reconstitute(data: Collection) {
        const address = data.get("address");
        const geoPoint = data.get("geoPoint");
        const instance = new FavoritePropertyEntity();
        if (address?.street)
            instance.setAddress(new TypeString(address.street));
        if (address?.city) instance.setCity(new TypeString(address.city));
        if (address?.state) instance.setState(new TypeString(address.state));
        if (geoPoint?.lat) instance.setLatitude(new TypeFloat(geoPoint.lat));
        if (geoPoint?.lon) instance.setLongitude(new TypeFloat(geoPoint.lon));
        if (data.has("propertyTypeIdLT"))
            instance.setPropertyType(
                new PropertyType(data.get("propertyTypeIdLT"))
            );
        if (data.has("starType"))
            instance.setStarType(new FavoriteType(data.get("starType")));

        if (data.has("hasNotes"))
            instance.setContainNotes(new TypeBoolean(data.get("hasNotes")));

        if (data.has("hasCalculation"))
            instance.setHasCalculation(
                new TypeBoolean(data.get("hasCalculation"))
            );

        if (data.has("hasDocuments"))
            instance.setHasDocuments(new TypeBoolean(data.get("hasDocuments")));

        if (data.has("propertyID"))
            instance.setPropertyId(new TypeString(data.get("propertyID")));

        if (data.has("documentCreated"))
            instance.setCreatedAt(new Date(data.get("documentCreated")));

        if (data.has("hasAlert"))
            instance.setHasAlerts(new TypeBoolean(data.get("hasAlert")));
        if (data.has("hasPortfolio"))
            instance.setHasPortfolio(new TypeBoolean(data.get("hasPortfolio")));

        if (data.has("starReasons"))
            instance.setStarReasons(new Collection(data.get("starReasons")));

        if (data.has("vendorRoles"))
            instance.setVendorRoles(new Collection(data.get("vendorRoles")));

        return instance;
    }
}
