import Collection from "../valueObject/Collection";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import PropertyTypeEntity from "./PropertyTypeEntity";

export default class Factory {
    reconstitute(item: Collection) {
        const propertyType = new PropertyTypeEntity();
        if (item.has("id"))
            propertyType.setId(new TypePositiveInteger(item.get("id")));
        if (item.has("value"))
            propertyType.setPropertyType(new TypeString(item.get("value")));

        return propertyType;
    }
}
