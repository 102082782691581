import { faCopy, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconFaceBook from "../icons/IconFacebook";
import IconTwitter from "../icons/IconTwitter";
import IconLinkedin from "../icons/IconLinkedin";
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, { setShareCalculatorPopup } from "../../store";
import IconGmail from "../icons/IconGmail";
import IconWhatsapp from "../icons/IconWhatsapp";
import { hotjar } from "react-hotjar";
const BlockShareCalculatorPopup = () => {
    const calcLinkRef = useRef(null);
    const [copiedSuccess, setCopiedSuccess] = useState({});
    const indexState = useSelector(indexSliceState);
    const [generatedUrl, setGeneratedUrl] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            indexState.shareCalculatorPopup.hash &&
            indexState.shareCalculatorPopup.isOpen
        ) {
            setGeneratedUrl(compileUrl(indexState.shareCalculatorPopup.hash));
        }
    }, [indexState.shareCalculatorPopup.hash]);

    const compileUrl = (hash: string) => {
        // ToDo replace this with the appropriate environment based url
        return process.env.REACT_APP_SUMMARY_PAGE_URL + "/calculator/" + hash;
    };

    const handleCopyClick = async (ref: any, key: any) => {
        const writeUpText = Array.from(
            ref.current.querySelectorAll(
                ".calculator-mini-popup__box-copy-field-link-holder"
            )
        )
            .map((pElement: any) => pElement.innerText.replace(/[$]/g, ""))
            .join("\n");
        try {
            await navigator.clipboard.writeText(writeUpText);
            setCopiedSuccess((prevState) => ({
                ...prevState,
                [key]: true
            }));
            setTimeout(() => {
                setCopiedSuccess((prevState) => ({
                    ...prevState,
                    [key]: false
                }));
            }, 2000);
        } catch (error) {
            console.error("Failed to copy text: ", error);
        }
    };

    const clickOutsideOfContainer = () => {
        return (e: any) => {
            if (e.target.classList.contains("calculator-mini-popup")) {
                dispatch(
                    setShareCalculatorPopup({
                        isOpen: false,
                        hash: undefined,
                        propertyId: undefined
                    })
                );
            }
        };
    };

    return (
        <div
            className="calculator-mini-popup"
            onClick={clickOutsideOfContainer()}
        >
            <div className="calculator-mini-popup__box">
                <span
                    className="calculator-mini-popup__close"
                    onClick={() => {
                        dispatch(
                            setShareCalculatorPopup({
                                isOpen: false,
                                hash: undefined,
                                propertyId: undefined
                            })
                        );
                    }}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </span>
                <h2 className="calculator-mini-popup__box-title">
                    Share Your Calculations
                </h2>
                <p className="calculator-mini-popup__box-text">
                    You’ve created a new calculator for{" "}
                    <b>{indexState.calculatorSlideout.address}</b>. Invite
                    others to review your calculations, or make their own
                    adjustments.
                </p>

                <div
                    className="calculator-mini-popup__box-copy-wrapper"
                    ref={calcLinkRef}
                >
                    <span className="calculator-mini-popup__box-copy-title">
                        Calculator Link
                    </span>
                    <div className="calculator-mini-popup__box-copy-field">
                        <span className="calculator-mini-popup__box-copy-field-link-holder">
                            {generatedUrl ? generatedUrl : "Loading..."}
                        </span>
                        <button
                            className="calculator-mini-popup__box-copy-field-link-copy-btn"
                            onClick={() => {
                                hotjar.event(
                                    `Underwriting Calculator - Share Calculator Popup Copy Button - ${indexState.shareCalculatorPopup.propertyId}`
                                );
                                handleCopyClick(calcLinkRef, "calcLink");
                            }}
                        >
                            {copiedSuccess["calcLink"] ? (
                                "Copied"
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faCopy} />
                                    Copy
                                </>
                            )}
                            {/* {copiedSuccess["calcLink"] ? "Copied" : "Copy"} */}
                        </button>
                    </div>
                </div>

                <div className="calculator-mini-popup__box-socials-wrapper">
                    <span className="calculator-mini-popup__box-socials-title">
                        Social Sharing
                    </span>
                    <div className="calculator-mini-popup__box-socials-container">
                        <a
                            className="calculator-mini-popup__box-socials-icon"
                            href={`https://www.facebook.com/share.php?u=${generatedUrl}`}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                hotjar.event(
                                    `Underwriting Calculator - Share Calculator Popup Facebook Button - ${indexState.shareCalculatorPopup.propertyId}`
                                );
                            }}
                        >
                            <IconFaceBook />
                        </a>
                        <a
                            className="calculator-mini-popup__box-socials-icon"
                            href={`https://twitter.com/intent/tweet?text=${generatedUrl}`}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                hotjar.event(
                                    `Underwriting Calculator - Share Calculator Popup Twitter Button - ${indexState.shareCalculatorPopup.propertyId}`
                                );
                            }}
                        >
                            <IconTwitter />
                        </a>
                        <a
                            className="calculator-mini-popup__box-socials-icon"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                hotjar.event(
                                    `Underwriting Calculator - Share Calculator Popup Gmail Button - ${indexState.shareCalculatorPopup.propertyId}`
                                );

                                const url =
                                    "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Your+Subject+here&body=" +
                                    generatedUrl +
                                    "&ui=2&tf=1&pli=1";

                                window.open(
                                    url,
                                    "sharer",
                                    "toolbar=0,status=0,width=648,height=395"
                                );
                            }}
                        >
                            <IconGmail />
                        </a>
                        <a
                            className="calculator-mini-popup__box-socials-icon"
                            href={`https://www.linkedin.com/shareArticle?mini=true&url=${generatedUrl}`}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                hotjar.event(
                                    `Underwriting Calculator - Share Calculator Popup Linkedin Button - ${indexState.shareCalculatorPopup.propertyId}`
                                );
                            }}
                        >
                            <IconLinkedin />
                        </a>
                        <a
                            className="calculator-mini-popup__box-socials-icon"
                            href={`whatsapp://send?text=${generatedUrl}`}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                hotjar.event(
                                    `Underwriting Calculator - Share Calculator Popup Whatsapp Button - ${indexState.shareCalculatorPopup.propertyId}`
                                );
                            }}
                        >
                            <IconWhatsapp />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlockShareCalculatorPopup;
