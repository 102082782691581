import { RAM_PERSISTENCE } from "../../..";
import LenderFilterQuery from "./LenderFilterQuery";

export default class LenderFilterQueryRAMGateway {
    _entityKey = "LenderFilterQueryEntity";

    persist(persistenceKey: any, lenderFilterQuery: LenderFilterQuery) {
        RAM_PERSISTENCE.persist(
            persistenceKey,
            lenderFilterQuery,
            this._entityKey
        );
    }

    retrieve(persistenceKey: any) {
        return RAM_PERSISTENCE.retrieve(persistenceKey, this._entityKey);
    }
}
