import { PROPERTY_DATA_API } from "../../apiList";
import { API_CLIENT_CONTENT_TYPE_JSON } from "../../utils/http/ApiClient";
import Collection from "../valueObject/Collection";

export default class ListingBrokerGateway {
    get(brokerageNames?: Collection) {
        let parameter = undefined;
        if (brokerageNames) parameter = new Collection({ b: brokerageNames });

        return PROPERTY_DATA_API.post("/brokers", parameter, {
            contentType: API_CLIENT_CONTENT_TYPE_JSON
        });
    }
}
