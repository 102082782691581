import TypeString from "../../valueObject/TypeString";

export default class OwnershipEntity {
    _name: TypeString;
    _mailingAddress: TypeString;

    get name(): TypeString {
        return this._name;
    }
    setName(name: TypeString) {
        this._name = name;
        return this;
    }

    hasName(): boolean {
        return this._name instanceof TypeString;
    }

    get mailingAddress(): TypeString {
        return this._mailingAddress;
    }

    setMailingAddress(mailingAddress: TypeString) {
        this._mailingAddress = mailingAddress;
        return this;
    }

    hasMailingAddress(): boolean {
        return this._mailingAddress instanceof TypeString;
    }
}
