import React, { useEffect, useRef, useState } from "react";
import { Input, Space } from "antd";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface InputFieldProps {
    name: string;
    classWrapper?: string;
    label?: string;
    control: any;
    errors: Object;
    isRequired?: boolean; //If true, adds * next to the input field
    capitalizeFirstLetter?: boolean;
    rows?: number; // how many rows to span (default 4)
    validationMessage?: string;
    showCount?: boolean;
    maxLength?: number;
    placeholder?: string;
}

const PartTextAreaInput = ({
    name,
    classWrapper,
    label,
    control,
    errors,
    isRequired,
    rows = 4,
    validationMessage,
    showCount = false,
    maxLength,
    placeholder
}: InputFieldProps) => {
    const inputRef = useRef<any>(null);
    const [inputValue, setInputValue] = useState("");
    const [inputValueLimitReached, setInputValueLimitReached] = useState(false);

    useEffect(() => {
        if (inputRef) inputRef.current.blur();
    }, []);

    useEffect(() => {
        if (inputValue.length === maxLength) {
            setInputValueLimitReached(true);
        } else {
            setInputValueLimitReached(false);
        }
    }, [inputValue]);

    return (
        <div
            className={`form-element form-element-textarea ${
                name in errors ? "form-element--error" : ""
            }`}
        >
            {label && (
                <label>
                    {label}
                    {isRequired && <span>{" *"}</span>}
                </label>
            )}
            <Controller
                name={name}
                control={control}
                defaultValue={""}
                render={({ field }) => (
                    <Input.TextArea
                        {...field}
                        className={classWrapper}
                        rows={rows}
                        allowClear
                        ref={inputRef}
                        showCount={showCount}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        onChange={(e) => {
                            field.onChange(e);
                            setInputValue(e.target.value);
                        }}
                    />
                )}
            />
            <p
                className={
                    inputValueLimitReached
                        ? "text-area-char-limit limit-reached"
                        : "text-area-char-limit"
                }
            >
                Characters Remaining: {2000 - inputValue.length}/2000
            </p>
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                    <p className="form-element__error-msg">
                        {validationMessage ? validationMessage : message}
                    </p>
                )}
            />
            <Space />
        </div>
    );
};

export default PartTextAreaInput;
