import Collection from "../valueObject/Collection";
import BrokerageFactory from "./BrokerageFactory";
import BrokerageGateway from "./BrokerageGateway";

export default class BrokerageRepository {
    _gateway: BrokerageGateway;
    _factory: BrokerageFactory;

    constructor(gateway: BrokerageGateway, factory: BrokerageFactory) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async getAll() {
        const data = new Collection(await this._gateway.getAll());
        let items = new Collection([]);
        items = data.map((itemData: any) => {
            return new BrokerageFactory().reconstitute(
                new Collection(itemData)
            );
        });
        return items;
    }
}
