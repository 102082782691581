import Collection from "../valueObject/Collection";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import StateEntity from "./StateEntity";

export default class StateFactory {
    reconstitute(item: Collection) {
        const state = new StateEntity();
        if (item.has("id"))
            state.setId(new TypePositiveInteger(item.get("id")));
        if (item.has("state"))
            state.setState(new TypeString(item.get("state")));
        if (item.has("abb"))
            state.setAbbreviation(new TypeString(item.get("abb")));
        return state;
    }
}
