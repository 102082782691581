import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const LoginRedirect = () => {
    const { loginWithRedirect, user } = useAuth0();
    const navigate = useNavigate();
    const modifiedUrl = window.location.href.replace(/authorize/g, "");

    useEffect(() => {
        if (user) {
            navigate(modifiedUrl);
        } else {
            // Trigger Auth0 login if the user is not authenticated
            loginWithRedirect().catch((error) => {
                console.error("Error during login:", error);
            });
        }
    }, [user, loginWithRedirect, history]);

    return (
        <div className="row">
            <div className="col col-light">
                <div className="login-box">
                    <div className="login-box-inner"></div>
                </div>
            </div>
        </div>
    );
};

export default LoginRedirect;
