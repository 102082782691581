import Collection from "../../valueObject/Collection";
import TypeBoolean from "../../valueObject/TypeBoolean";
import IFrameMessage from "../IFrameMessage";

export default class ClosePopupsMessage extends IFrameMessage {
    public static ACTION_NAME = "close-popups";

    constructor() {
        super(ClosePopupsMessage.ACTION_NAME);
    }

    public send(iframe: HTMLIFrameElement, close: TypeBoolean) {
        super._send(
            new Collection({
                close
            }),
            iframe
        );
    }

    public recieve(callback: Function, data: Collection) {
        callback(data.get("close"));
    }
}
