import Collection from "../valueObject/Collection";
import BrokerageEntity from "./BrokerageEntity";

export type BrokerageType = {
    value?: number;
    label?: string;
};

export default class BrokerageViewMapper {
    static map(brokerages: Collection) {
        return brokerages.map((brokerage: BrokerageEntity) => {
            return {
                value: brokerage.name.value,
                label: brokerage.name.value
            };
        }).items;
    }
}
