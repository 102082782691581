import { faMap } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hotjar } from "react-hotjar";
import { useDispatch } from "react-redux";
import {
    setCurrentView,
    setOverlays,
    setShowMapBtn
} from "../../store/responsive";

const PartMapButton = () => {
    const dispatch = useDispatch();

    return (
        <button
            className="mobile-map-btn"
            onClick={() => {
                hotjar.event("Mobile Floating Map CTA");
                dispatch(setCurrentView("map"));
                dispatch(setShowMapBtn(false));
                dispatch(
                    setOverlays({
                        userMenu: false,
                        hamburgerMenu: false,
                        propertySearch: false,
                        lenderDefault: false,
                        lenderOverlay: false,
                        indexesOverlay: false,
                        ratesOverlay: false,
                        myPropertiesOverlay: false
                    })
                );
            }}
        >
            <FontAwesomeIcon icon={faMap} />
            Map
        </button>
    );
};

export default PartMapButton;
