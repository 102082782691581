import Collection from "../../valueObject/Collection";
import TypeBoolean from "../../valueObject/TypeBoolean";
import TypeFloat from "../../valueObject/TypeFloat";
import TypePositiveInteger from "../../valueObject/TypePositiveInteger";
import TypeString from "../../valueObject/TypeString";
import EntityType from "../valueObject/EntityType";
import PropertyMarkerEntity from "./PropertyMarkerEntity";

export default class PropertyMarkerFactory {
    reconstitute(item: Collection) {
        const price = item.has("price")
            ? new TypePositiveInteger(item.get("price"))
            : new TypePositiveInteger(0);

        const isFavorite = item.has("isFavorite")
            ? new TypeBoolean(item.get("isFavorite"))
            : new TypeBoolean(false);

        const lenderName = item.has("lenderName")
            ? new TypeString(item.get("lenderName"))
            : undefined;

        const address = item.has("address")
            ? new TypeString(item.get("address"))
            : undefined;

        const propertyType = item.has("propertyType")
            ? new TypeString(item.get("propertyType"))
            : undefined;

        const boundaryPoints = new Array<any>();
        if (item.has("boundaryPoints")) {
            item.get("boundaryPoints").forEach((point: Collection) => {
                boundaryPoints.push({
                    lat: point["latitude"],
                    lng: point["longitude"]
                });
            });
        }

        return new PropertyMarkerEntity(
            new TypeString(item.get("propertyId").toString()),
            new EntityType(item.get("markerType")),
            new TypeFloat(item.get("latitude")),
            new TypeFloat(item.get("longitude"))
        )
            .setPrice(price)
            .setIsFavorite(isFavorite)
            .setLenderName(lenderName)
            .setAddress(address)
            .setPropertyType(propertyType)
            .setBoundaryPoints(boundaryPoints);
    }
}
