import IFrameMessage from "../IFrameMessage";

export default class SendMeATokenMessage extends IFrameMessage {
    public static ACTION_NAME = "send-me-a-token";

    constructor() {
        super(SendMeATokenMessage.ACTION_NAME);
    }

    //public send() {}

    public recieve(callback: Function) {
        callback();
    }
}
