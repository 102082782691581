import React, { useEffect, useRef, useState } from "react";
import { hotjar } from "react-hotjar";
import { useSelector } from "react-redux";
import BubbleEntity from "../../domain/map/bubble/BubbleEntity";
import { formatCommaNums, displayNum } from "../../utils/uiUitls";
import lenderPortfolioSliceState from "../../store/lenderPortfolio";
import PropertyService from "../../domain/property/PropertyService";
import PropertySearchQuery from "../../domain/property/queries/PropertySearchQuery";

interface BlockBubbleProps {
    data: BubbleEntity;
    setDisableDoubleClickZoom: any;
    handleOnClick: any;
    key: number;
    lat: number;
    lng: number;
}
const BlockBubble = ({
    data,
    setDisableDoubleClickZoom,
    handleOnClick
}: BlockBubbleProps) => {
    const [cluster, setCluster] = useState(false);
    const [clusterPosition, setClusterPosition] = useState("");
    const lenderPortfolioState = useSelector(lenderPortfolioSliceState);

    const query: PropertySearchQuery = PropertyService.getSearchQuery();

    const getLenderCountByName = (lenderName: string) => {
        const lenderValue = data.lenders._data[lenderName];
        return lenderValue !== undefined ? lenderValue : 0;
    };

    const bubbleRef = useRef<any>(null);

    useEffect(() => {
        setPropertyCardPosition();
    }, [data, cluster]);

    const setPropertyCardPosition = () => {
        if (cluster) {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const cardWidth = 210;
            const bubbleTopOffset =
                bubbleRef.current?.getBoundingClientRect().top || 0;
            const verticalPosition =
                bubbleTopOffset < 97
                    ? "full-bottom"
                    : bubbleTopOffset < 200
                    ? "bottom"
                    : bubbleTopOffset > windowHeight - 110
                    ? "full-top"
                    : "top";
            const bubbleLeftOffset =
                bubbleRef.current?.getBoundingClientRect().left || 0;
            const horizontalPosition =
                windowWidth > bubbleLeftOffset + cardWidth + 160
                    ? "right"
                    : "left";
            setClusterPosition(`${horizontalPosition} ${verticalPosition}`);
        }
    };

    return (
        <div
            ref={bubbleRef}
            className={`map-cluster ${
                cluster ? "map-cluster-active" : ""
            } ${clusterPosition}`}
            onMouseEnter={() => {
                setDisableDoubleClickZoom(true);
                setCluster(true);
            }}
            onMouseLeave={() => {
                setDisableDoubleClickZoom(false);
                setCluster(false);
            }}
            onClick={() => {
                handleOnClick();
                hotjar.event("Bubble Aggregated Properties Click");
            }}
        >
            <div className="map-cluster__bubble">
                <div className="map-cluster__bubble-holder">
                    <span>{displayNum(data.totalCount)}</span>
                </div>
            </div>
            <div className="bubble-card">
                <h5 className="bubble-card__title">View More Properties</h5>
                <ul className="bubble-card__list">
                    {!query.hasLenderPortfolioItems() ? (
                        <>
                            <li className="bubble-card__item top-item">
                                <span className="bubble-card__item-square top-square bubble-card__item-square--dark-green"></span>
                                <p className="bubble-card__item-name top-name">
                                    Listings
                                </p>
                                <p className="bubble-card__item-value top-value">
                                    {formatCommaNums(
                                        data.listingCount?.value ?? 0
                                    )}
                                </p>
                            </li>
                            <li className="bubble-card__item">
                                <span className="bubble-card__item-square bubble-card__item-square--dark-gray"></span>
                                <p className="bubble-card__item-name">
                                    Sale Comps
                                </p>
                                <p className="bubble-card__item-value">
                                    {formatCommaNums(
                                        data.saleCount?.value ?? 0
                                    )}
                                </p>
                            </li>
                            <li className="bubble-card__item">
                                <span className="bubble-card__item-square bubble-card__item-square--gray"></span>
                                <p className="bubble-card__item-name">
                                    Finance Comps
                                </p>
                                <p className="bubble-card__item-value">
                                    {formatCommaNums(
                                        data.financeCount?.value ?? 0
                                    )}
                                </p>
                            </li>
                        </>
                    ) : (
                        <>
                            {lenderPortfolioState.firstLenderValue ? (
                                <li className="bubble-card__item">
                                    <span className="bubble-card__item-square bubble-card__item-square--navy"></span>
                                    <p className="bubble-card__item-name">
                                        {lenderPortfolioState.firstLenderValue}
                                    </p>
                                    <p className="bubble-card__item-value">
                                        {getLenderCountByName(
                                            lenderPortfolioState.firstLenderValue
                                        )}
                                    </p>
                                </li>
                            ) : null}
                            {lenderPortfolioState.secondLenderValue ? (
                                <li className="bubble-card__item">
                                    <span className="bubble-card__item-square bubble-card__item-square--red"></span>
                                    <p className="bubble-card__item-name">
                                        {lenderPortfolioState.secondLenderValue}
                                    </p>
                                    <p className="bubble-card__item-value">
                                        {getLenderCountByName(
                                            lenderPortfolioState.secondLenderValue
                                        )}
                                    </p>
                                </li>
                            ) : null}
                            {lenderPortfolioState.thirdLenderValue ? (
                                <li className="bubble-card__item">
                                    <span className="bubble-card__item-square bubble-card__item-square--yellow"></span>
                                    <p className="bubble-card__item-name">
                                        {lenderPortfolioState.thirdLenderValue}
                                    </p>
                                    <p className="bubble-card__item-value">
                                        {getLenderCountByName(
                                            lenderPortfolioState.thirdLenderValue
                                        )}
                                    </p>
                                </li>
                            ) : null}
                        </>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default React.memo(BlockBubble);
