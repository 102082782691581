import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import NoteFactory from "./NoteFactory";

export default class NoteRepository {
    _gateway: any;
    _factory: any;

    constructor(gateway: any, factory: any) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async getNotes(propertyId: TypeString) {
        const data = new Collection(await this._gateway.getNotes(propertyId));

        let items = new Collection([]);

        const responseData = data.slice("responseData");
        const firstItem = responseData.first();

        if (firstItem) {
            items = firstItem.notes.map((itemData: any) => {
                return new NoteFactory().reconstitute(new Collection(itemData));
            });
        }

        return items;
    }
}
