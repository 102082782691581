import TypeString from "../valueObject/TypeString";

export default class NoteEntity {
    _id: TypeString;
    _content: TypeString;
    _createdAt: Date;

    get id(): TypeString {
        return this._id;
    }
    setId(value: TypeString) {
        this._id = value;
        return this;
    }

    get content(): TypeString {
        return this._content;
    }
    setContent(value: TypeString) {
        this._content = value;
        return this;
    }
    hasContent(): boolean {
        return this._content instanceof TypeString;
    }

    get createdAt(): Date {
        return this._createdAt;
    }
    setCreatedAt(value: Date) {
        this._createdAt = value;
        return this;
    }
    hasCreatedAt(): boolean {
        return this._createdAt instanceof Date;
    }
}
