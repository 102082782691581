import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import FavoriteFilterEntity from "./FavoriteFilterEntity";

export default class FavoriteFilterFactory {
    reconstitute(data: Collection) {
        const instance = new FavoriteFilterEntity();

        if (data.has("propertyTypes"))
            instance.setPropertyTypes(
                new Collection(data.get("propertyTypes")).map(
                    (propertyType: any) => {
                        if (propertyType !== null)
                            return new TypeString(propertyType);
                    }
                )
            );

        if (data.has("cities"))
            instance.setCities(
                new Collection(data.get("cities")).map((city: any) => {
                    return new TypeString(city);
                })
            );

        if (data.has("starReasons"))
            instance.setStarReasons(
                new Collection(data.get("starReasons")).map(
                    (starReason: any) => {
                        return new Collection(starReason);
                    }
                )
            );

        if (data.has("starTypes"))
            instance.setStarTypes(
                new Collection(data.get("starTypes")).map((starType: any) => {
                    return new TypeString(starType);
                })
            );

        if (data.has("states")) {
            instance.setStates(
                new Collection(data.get("states")).map((state: any) => {
                    return new TypeString(state);
                })
            );
        }

        return instance;
    }
}
