import Collection from "../valueObject/Collection";
import PropertyTypeFactory from "./PropertyTypeFactory";
import PropertyTypeGateway from "./PropertyTypeGateway";

export default class PropertyTypeRepository {
    _gateway: PropertyTypeGateway;
    _factory: PropertyTypeFactory;

    constructor(gateway: PropertyTypeGateway, factory: PropertyTypeFactory) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async get() {
        const data = new Collection(await this._gateway.get());
        let items = new Collection([]);

        items = data.slice("data").map((itemData: any) => {
            const item = new Collection(itemData);
            return new PropertyTypeFactory().reconstitute(item);
        });
        return items;
    }
}
