const IconGmail = () => {
    return (
        <svg
            width="32"
            height="24"
            viewBox="0 0 32 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.6507 2.14999L15.9952 8.74635L7.14185 2.14999V2.15178L7.15254 2.16069V11.3974L15.8954 18.1364L24.6507 11.6577V2.14999Z"
                fill="#EA4335"
            />
            <path
                d="M26.9238 0.545485L24.6508 2.15001V11.6577L31.8033 6.29501V3.06458C31.8033 3.06458 30.9351 -1.5493 26.9238 0.545485Z"
                fill="#FBBC05"
            />
            <path
                d="M24.6508 11.6577V23.9893H30.1329C30.1329 23.9893 31.6928 23.8324 31.8051 22.096V6.29501L24.6508 11.6577Z"
                fill="#34A853"
            />
            <path
                d="M7.15254 24V11.3974L7.14185 11.3885L7.15254 24Z"
                fill="#C5221F"
            />
            <path
                d="M7.1419 2.15178L4.88131 0.556174C0.870006 -1.53862 0 3.07349 0 3.07349V6.30392L7.1419 11.3885V2.15178Z"
                fill="#C5221F"
            />
            <path
                d="M7.14185 2.15179V11.3885L7.15254 11.3974V2.16071L7.14185 2.15179Z"
                fill="#C5221F"
            />
            <path
                d="M0 6.30569V22.1066C0.110534 23.8449 1.67227 24 1.67227 24H7.15438L7.1419 11.3885L0 6.30569Z"
                fill="#4285F4"
            />
        </svg>
    );
};

export default IconGmail;
