import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./storeIndex";

export interface FavoriteProperty {
    vendorRoles: any;
    favoritesPopup: any;
    favoritesProperties: any;
    favoriteCities: string[];
    favoritePropTypes: string[];
    favoriteStarReasons: string[];
    favoriteStarTypes: string[];
    favoriteStates: string[];
    userPropertyStar: any;
    pipelineCapacity: any;
}

export const initialState: FavoriteProperty = {
    vendorRoles: [],
    favoritesPopup: null,
    favoritesProperties: [],
    favoriteCities: [],
    favoritePropTypes: [],
    favoriteStarReasons: [],
    favoriteStarTypes: [],
    favoriteStates: [],
    userPropertyStar: undefined,
    pipelineCapacity: {}
};

export const favoritePropertySlice = createSlice({
    name: "favoriteProperty",
    initialState,
    reducers: {
        setFavoritesPopup: (state, action: PayloadAction<any>) => {
            state.favoritesPopup = action.payload;
        },
        setVendorRoles: (state, action: PayloadAction<any>) => {
            state.vendorRoles = action.payload;
        },
        setFavoritesProperties: (state, action: PayloadAction<any>) => {
            state.favoritesProperties = action.payload;
        },
        setFavoriteCities: (state, action: PayloadAction<any>) => {
            state.favoriteCities = action.payload;
        },
        setFavoritePropTypes: (state, action: PayloadAction<any>) => {
            state.favoritePropTypes = action.payload;
        },
        setFavoriteStarReasons: (state, action: PayloadAction<any>) => {
            state.favoriteStarReasons = action.payload;
        },
        setFavoriteStarTypes: (state, action: PayloadAction<any>) => {
            state.favoriteStarTypes = action.payload;
        },
        setFavoriteStates: (state, action: PayloadAction<any>) => {
            state.favoriteStates = action.payload;
        },
        setUserPropertyStar: (state, action: PayloadAction<any>) => {
            state.userPropertyStar = action.payload;
        },
        setFavoritePipelineCapacity: (state, action: PayloadAction<any>) => {
            state.pipelineCapacity = action.payload;
        }
    }
});

export const {
    setFavoritesPopup,
    setVendorRoles,
    setFavoritesProperties,
    setFavoriteCities,
    setFavoritePropTypes,
    setFavoriteStarReasons,
    setFavoriteStarTypes,
    setFavoriteStates,
    setUserPropertyStar,
    setFavoritePipelineCapacity
} = favoritePropertySlice.actions;

const favoritePropertySliceState = (state: RootState) => state.favoriteProperty;
export default favoritePropertySliceState;
