const IconWhatsapp = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_14361_22686)">
                <path
                    d="M0.743391 32C0.563704 32 0.3889 31.929 0.259261 31.7979C0.0883629 31.625 0.022445 31.374 0.0866539 31.1394L2.16697 23.5427C0.871809 21.2007 0.188949 18.5513 0.189925 15.8618C0.193587 7.11548 7.31125 0 16.0568 0C20.2988 0.00170899 24.2846 1.65405 27.2805 4.6521C30.2758 7.65039 31.9247 11.636 31.9235 15.8745C31.9196 24.6211 24.8019 31.7371 16.0568 31.7371C16.0568 31.7371 16.0503 31.7371 16.05 31.7371C13.4956 31.7361 10.9631 31.1135 8.70822 29.9343L0.915998 31.9778C0.858869 31.9927 0.800764 32 0.743391 32Z"
                    fill="#E5E5E5"
                />
                <path
                    d="M0.743408 31.319L2.89771 23.4521C1.56885 21.1496 0.869873 18.5378 0.87085 15.862C0.874268 7.49119 7.68629 0.680908 16.0569 0.680908C20.1189 0.682617 23.9317 2.26367 26.7989 5.13329C29.6658 8.00291 31.2442 11.8174 31.2427 15.8742C31.2391 24.2448 24.4261 31.0561 16.0569 31.0561C16.0564 31.0561 16.0572 31.0561 16.0569 31.0561H16.0503C13.5088 31.0551 11.0117 30.4177 8.79371 29.2079L0.743408 31.319ZM9.16652 26.4597L9.6277 26.7328C11.5652 27.8827 13.7866 28.4909 16.052 28.4921H16.0569C23.0135 28.4921 28.6756 22.831 28.6785 15.8733C28.6797 12.5014 27.3682 9.33079 24.9851 6.94579C22.6018 4.56078 19.4331 3.24658 16.0615 3.24511C9.09938 3.24511 3.4375 8.9055 3.43482 15.863C3.43384 18.2473 4.10083 20.569 5.36451 22.5783L5.6648 23.0559L4.38941 27.7121L9.16652 26.4597Z"
                    fill="white"
                />
                <path
                    d="M1.27148 30.7891L3.35132 23.1941C2.06811 20.9714 1.39355 18.4497 1.39429 15.8669C1.39746 7.78589 7.97387 1.21143 16.0542 1.21143C19.9761 1.21313 23.657 2.7395 26.4243 5.50977C29.1924 8.28003 30.7158 11.9624 30.7143 15.8784C30.7109 23.9597 24.1343 30.5349 16.0549 30.5349C16.0542 30.5349 16.0552 30.5349 16.0549 30.5349H16.0483C13.595 30.5339 11.1841 29.9182 9.04296 28.7512L1.27148 30.7891Z"
                    fill="#64B161"
                />
                <path
                    d="M0.743408 31.319L2.89771 23.4521C1.56885 21.1496 0.869873 18.5378 0.87085 15.862C0.874268 7.49119 7.68629 0.680908 16.0569 0.680908C20.1189 0.682617 23.9317 2.26367 26.7989 5.13329C29.6658 8.00291 31.2442 11.8174 31.2427 15.8742C31.2391 24.2448 24.4261 31.0561 16.0569 31.0561C16.0564 31.0561 16.0572 31.0561 16.0569 31.0561H16.0503C13.5088 31.0551 11.0117 30.4177 8.79371 29.2079L0.743408 31.319ZM9.16652 26.4597L9.6277 26.7328C11.5652 27.8827 13.7866 28.4909 16.052 28.4921H16.0569C23.0135 28.4921 28.6756 22.831 28.6785 15.8733C28.6797 12.5014 27.3682 9.33079 24.9851 6.94579C22.6018 4.56078 19.4331 3.24658 16.0615 3.24511C9.09938 3.24511 3.4375 8.9055 3.43482 15.863C3.43384 18.2473 4.10083 20.569 5.36451 22.5783L5.6648 23.0559L4.38941 27.7121L9.16652 26.4597Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.2615 9.51539C11.9773 8.88355 11.678 8.87085 11.4075 8.85987C11.1863 8.85035 10.9331 8.85083 10.6804 8.85083C10.4275 8.85083 10.0164 8.94605 9.6687 9.32569C9.3208 9.70533 8.34033 10.6233 8.34033 12.4903C8.34033 14.3575 9.70019 16.1614 9.88989 16.4148C10.0796 16.668 12.5149 20.6216 16.3716 22.1426C19.5769 23.4068 20.229 23.1553 20.9248 23.0921C21.6206 23.0289 23.1696 22.1741 23.4858 21.2882C23.8022 20.4022 23.8022 19.6429 23.7072 19.4839C23.6123 19.326 23.3593 19.231 22.9799 19.0413C22.6006 18.8516 20.7351 17.9334 20.3872 17.8069C20.0393 17.6802 19.7864 17.6172 19.5334 17.9971C19.2802 18.3765 18.5537 19.231 18.3323 19.4839C18.1108 19.7376 17.8894 19.7693 17.51 19.5794C17.1306 19.3892 15.9084 18.9888 14.4587 17.6961C13.3308 16.6905 12.5691 15.4483 12.3476 15.0684C12.1265 14.689 12.344 14.5015 12.5144 14.2942C12.8213 13.9209 13.3362 13.2498 13.4626 12.9969C13.5891 12.7434 13.5259 12.522 13.4311 12.3321C13.3362 12.1424 12.5986 10.2659 12.2615 9.51539Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_14361_22686">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconWhatsapp;
