/* eslint-disable no-unused-vars */
import { API_CLIENT_CONTENT_TYPE_JSON } from "./../../utils/http/ApiClient";
import Collection from "../valueObject/Collection";
import PropertySearchQuery from "./queries/PropertySearchQuery";
import PropertySearchQueryMap from "./queries/PropertySearchQueryMap";
import TypeFloat from "../valueObject/TypeFloat";
import TypeString from "../valueObject/TypeString";
import {
    PROPERTY_DATA_API,
    USER_DATA_API,
    PUBLIC_PROPERTY_DATA_API
} from "../../apiList";
import SearchByLendersQueryMap from "./queries/SearchByLendersQueryMap";
import store from "../../store/storeIndex";

export default class PropertyGateway {
    isLoggedIn = store.getState().index.token;
    PROPERTY_API = this.isLoggedIn
        ? PROPERTY_DATA_API
        : PUBLIC_PROPERTY_DATA_API;
    search(searchQuery: PropertySearchQuery) {
        const usePublic = this.isLoggedIn ? "" : "/public";
        return this.PROPERTY_API.post(
            `/property/search${usePublic}`,
            PropertySearchQueryMap.map(searchQuery),
            {
                contentType: API_CLIENT_CONTENT_TYPE_JSON
            }
        );
    }

    searchByLenders(searchQuery: PropertySearchQuery) {
        return this.PROPERTY_API.post(
            "/property/search-by-lenders",
            SearchByLendersQueryMap.map(searchQuery),
            {
                contentType: API_CLIENT_CONTENT_TYPE_JSON
            }
        );
    }

    async getById(id: TypeString) {
        return USER_DATA_API.get(`/property-card/${id.value}`);
    }

    getStaticImageByLatLng(lat: number, lng: number) {
        const IMAGE_WIDTH = 380;
        const IMAGE_HEIGHT = 190;
        return fetch(
            `https://maps.googleapis.com/maps/api/streetview?size=${IMAGE_WIDTH}x${IMAGE_HEIGHT}&return_error_code=true&source=outdoor&location=${lat},${lng}&fov=80&heading=70&pitch=0&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
        )
            .then((response) => {
                if (response.ok) return response.blob();
                return response.json();
            })

            .catch((_err) => {
                // Report to Logger (e.g. Sentry)
            });
    }

    getTotalCount() {
        return PROPERTY_DATA_API.get("/property/get-totals");
    }

    getByGeoData(
        street: TypeString,
        state: TypeString,
        county: TypeString,
        zip: TypeString,
        city: TypeString,
        latitude: TypeFloat,
        longitude: TypeFloat
    ) {
        const usePublic = this.isLoggedIn ? "" : "/public";
        return this.PROPERTY_API.get(
            `/property/find${usePublic}`,
            new Collection({
                street,
                state,
                county,
                zip,
                city,
                latitude,
                longitude
            })
        );
    }
}
