import { Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PartCheckboxGroup from "./PartCheckboxGroup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHouseBuilding,
    faPersonDollyEmpty,
    faChevronDown
} from "@fortawesome/pro-regular-svg-icons";
import { hotjar } from "react-hotjar";
import { useSelector } from "react-redux";
import indexSliceState from "../../store";

const PartDropdownProperties = ({
    parent,
    placeholder,
    defaultValue,
    options,
    name,
    onChange,
    disabled,
    value,
    dropdownClassName,
    showSearch = true,
    getPopupContainer,
    hotjarEventName,
    noContentMessage,
    isMobile
}: {
    parent: string;
    placeholder?: string;
    defaultValue?: string;
    options: any;
    name: string;
    value?: any;
    onChange: any;
    disabled?: boolean;
    dropdownClassName?: string;
    showSearch?: boolean;
    getPopupContainer?: any;
    hotjarEventName?: string;
    noContentMessage: string;
    isMobile?: boolean;
}) => {
    const {
        control,
        watch,
        formState,
        setValue,
        formState: { errors }
    } = useForm({
        mode: "onChange",
        shouldFocusError: false,
        defaultValues: {
            [name]: []
        }
    });

    const [focus, setFocus] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [showingAll, setShowingAll] = useState(true);
    const dropdownInstance: any = watch(name);

    const indexSlice = useSelector(indexSliceState);

    useEffect(() => {
        if (value && value.length === 0) setValue(name, []);
        if (value && value.length > 0 && !value.includes("All")) {
            setValue(name, value);
        }
        if (value && value.includes("All")) {
            const newValues: any = [];
            options.forEach((type: any) => newValues.push(type.value));
            setValue(name, newValues);
        }
    }, [value]);

    // This function doesn't trigger at all
    const handleOnDeselect = (value: string) => {
        const newValues = dropdownInstance.filter(
            (type: any) => type !== getValue(value)
        );
        setValue(name, newValues);
        onChange(newValues, false);
    };

    const getValue = (label: string) => {
        return options.find((type: any) => type.label === label)?.value;
    };
    const onSearch = (value: any) => {
        setSearchValue(value);
    };

    const checkIfEmpty = () => {
        return options.filter((option: any) =>
            option.label.toLowerCase().includes(searchValue.toLowerCase())
        ).length;
    };

    const renderDropdown = () => (
        <div className="checkbox-dropdown-header">
            <div className="checkbox-dropdown__switch">
                <Switch
                    defaultChecked={true}
                    checked={showingAll && dropdownInstance.length === 0}
                    onChange={(checked: boolean) => {
                        hotjar.event(
                            `Property Type Showing All - ${
                                checked ? "On" : "Off"
                            }`
                        );
                        setShowingAll(checked);
                        if (checked) {
                            onChange([]);
                            setValue(name, []);
                        } else {
                            onChange(dropdownInstance);
                        }
                        const checkBox = document.querySelector(
                            ".property-types-dropdown"
                        );
                        checkBox?.classList.toggle("not-disabled");
                    }}
                />
                Showing All
            </div>

            <span className="mobile-small-title">OR SELECT PROPERTY TYPES</span>

            <PartCheckboxGroup
                name={name}
                control={control}
                errors={errors}
                formState={formState}
                options={options.filter((option: any) =>
                    option.label
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                )}
                onChange={(selectedValues: any) => {
                    const latestSelectedValue = selectedValues.filter(
                        (x: any) => !dropdownInstance.includes(x)
                    );

                    if (latestSelectedValue.length > 0) {
                        const value = latestSelectedValue[0];
                        const propertyTypeName = indexSlice.propertyTypes.find(
                            (type: any) => type.value === value
                        )?.label;
                        hotjar.event(
                            `Property Type Selected - ${propertyTypeName}`
                        );
                    }
                    onChange(selectedValues);
                }}
                hotjarEventName={hotjarEventName}
                showingAll={
                    // showingAll && dropdownInstance.length === 0
                    false
                }
            />
            {checkIfEmpty() === 0 ? (
                <span className="empty-dropdown">
                    <FontAwesomeIcon icon={faPersonDollyEmpty} />
                    <span className="empty-dropdown__text">
                        {noContentMessage}
                    </span>
                </span>
            ) : null}
        </div>
    );

    return isMobile ? (
        renderDropdown()
    ) : (
        <div
            className={`property-types ${
                focus ? "property-types--focused" : ""
            } `}
            onFocus={() => {
                setFocus(true);
            }}
            onBlur={() => {
                setFocus(false);
            }}
        >
            {parent === "header" ? (
                <div className="property-types__icon">
                    <FontAwesomeIcon icon={faHouseBuilding} />
                </div>
            ) : null}
            <Select
                mode="multiple"
                placement="bottomRight"
                placeholder={placeholder}
                showSearch={showSearch}
                defaultValue={defaultValue}
                dropdownClassName={`checkbox-dropdown ${dropdownClassName}`}
                disabled={disabled}
                getPopupContainer={getPopupContainer}
                onBlur={(value: any) => {
                    if (value.target.value === "") {
                        setSearchValue("");
                    }
                }}
                onDropdownVisibleChange={(isOpen: boolean) => {
                    if (hotjarEventName)
                        hotjar.event(
                            `${hotjarEventName} - ${isOpen ? "Open" : "Closed"}`
                        );
                    if (!isOpen && dropdownInstance.length === 0)
                        setShowingAll(true);
                }}
                value={
                    dropdownInstance.length
                        ? options.find(
                              (type: any) => type.value === dropdownInstance[0]
                          )?.label
                        : undefined
                }
                dropdownRender={renderDropdown}
                onSearch={onSearch}
                onDeselect={handleOnDeselect}
            />
            {dropdownInstance.length > 1 && dropdownInstance[0] !== "All" && (
                <span
                    className={
                        parent !== "filters"
                            ? "property-types__count"
                            : "filters__count"
                    }
                >
                    +{dropdownInstance.length - 1}
                </span>
            )}
            {parent === "filters" ? (
                <span className="filters-dropdown__icon">
                    <FontAwesomeIcon icon={faChevronDown} />
                </span>
            ) : null}
        </div>
    );
};

export default PartDropdownProperties;
