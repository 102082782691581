import { faNotes, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import PartButton from "../../parts/PartButton";
import { Input, Spin } from "antd";
import NoteService from "../../../domain/note/NoteService";
import Collection from "../../../domain/valueObject/Collection";
import TypeString from "../../../domain/valueObject/TypeString";
import NoteViewMapper from "../../../domain/note/NoteViewMapper";
import {
    setPropertyCardMarkAsFavorite,
    setPropertyFavoriteActionTriggered
} from "../../../store/mapTriggers";
import indexSliceState from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { hotjar } from "react-hotjar";
import PropertyService from "../../../domain/property/PropertyService";

const { TextArea } = Input;
const loaderIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

const BlockTabNotes = ({ propertyCardData }: any) => {
    const [viewType, setViewType] = useState("list");
    const [inputValue, setInputValue] = useState("");
    const [selectedNote, setSelectedNote] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [notes, setNotes] = useState<any>([]);
    const [favoriteAdded, setFavoriteAdded] = useState<boolean>(
        propertyCardData.isFavorite
    );
    const dispatch = useDispatch();
    const query = PropertyService.getSearchQuery();
    const sliceState = useSelector(indexSliceState);

    useEffect(() => {
        if (sliceState.isLoggedIn) {
            getNotes();
        }
    }, [sliceState.isLoggedIn]);

    const getNotes = async () => {
        try {
            const notesResult = await NoteService.getNotes(
                new TypeString(propertyCardData.id)
            );
            const notes = NoteViewMapper.map(notesResult);
            setNotes(notes);
        } catch (error) {
            console.log(error, "Error while trying to get notes.");
        } finally {
            setIsLoading(false);
        }
    };

    const renderNotesList = () => {
        return (
            <div className="property-card__notes-wrap">
                <PartButton
                    className="property-card__link"
                    type="secondary"
                    onClick={() => {
                        hotjar.event(
                            `Add Note Button Clicked - ${propertyCardData.id}`
                        );
                        setViewType("add");
                    }}
                >
                    <>
                        <FontAwesomeIcon icon={faPlus} />{" "}
                        {isLoading ? "Adding note..." : "Add note"}
                    </>
                </PartButton>
                <div className="property-card__notes">
                    {notes.map((note: any) => (
                        <div className="property-card__note" key={note.id}>
                            <p>{note.value}</p>
                            <div className="property-card__note-wrap">
                                <span className="property-card__note-date">
                                    {note.date}
                                </span>
                                <span
                                    className="property-card__note-edit"
                                    role="button"
                                    onClick={() => {
                                        hotjar.event(
                                            `Edit Note Button Clicked - ${propertyCardData.id}`
                                        );
                                        setViewType("edit");
                                        setSelectedNote(note);
                                        setInputValue(note.value);
                                    }}
                                >
                                    Edit
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderAddNotes = () => {
        return (
            <div className="property-card__add-note">
                <h3>{viewType === "add" ? "ADD" : "EDIT"} NOTE</h3>
                <TextArea
                    ref={(ref) => ref && ref.focus()}
                    onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length
                        )
                    }
                    rows={4}
                    placeholder="Type your notes here."
                    maxLength={2000}
                    defaultValue={
                        viewType === "edit" ? selectedNote?.value : ""
                    }
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onClick={() =>
                        hotjar.event(
                            `Note Text Box Clicked - ${propertyCardData.id}`
                        )
                    }
                />
                <p>Characters Remaining: {2000 - inputValue.length}/2000</p>
                <div className="property-card__note-btns">
                    <PartButton
                        className="property-card__link"
                        type="secondary"
                        onClick={() => {
                            hotjar.event(
                                `Note Cancel Button Clicked - ${propertyCardData.id}`
                            );
                            setViewType("list");
                            setInputValue("");
                            setSelectedNote(undefined);
                        }}
                    >
                        Cancel
                    </PartButton>
                    <PartButton
                        className="property-card__link"
                        type="primary"
                        disabled={inputValue === ""}
                        onClick={() => {
                            hotjar.event(
                                `Note Save Button Clicked - ${propertyCardData.id}`
                            );
                            handleOnSave();
                        }}
                    >
                        {isLoading ? "Saving..." : "Save"}
                    </PartButton>
                </div>
            </div>
        );
    };

    const renderEmptyState = () => (
        <div className="property-card__no-notes">
            <FontAwesomeIcon icon={faNotes} />
            <p>There are no notes on this property.</p>
            <p>*Notes are private and only visible to you.</p>
            <PartButton
                className="property-card__link"
                type="secondary"
                onClick={() => {
                    hotjar.event(
                        `Add the first Note Button Clicked - ${propertyCardData.id}`
                    );
                    setViewType("add");
                }}
            >
                <>
                    <FontAwesomeIcon icon={faPlus} /> Add the first note
                </>
            </PartButton>
        </div>
    );

    const handleOnSave = async () => {
        if (inputValue === "") return;
        try {
            setIsLoading(true);
            if (viewType === "add") {
                const propertyID = propertyCardData.id;
                const note = inputValue;
                if (!favoriteAdded) {
                    // if there were no notes, seet property as favorite
                    setFavoriteAdded(true);
                    hotjar.event(
                        `Note Save Button Clicked - Added to Favorites - ${propertyCardData.id}`
                    );
                    //NOTE:  THIS SHOULD BE HANDLED BY THE API
                    // await FavoritePropertyService.addToFavorite(
                    //     new Collection({
                    //         propertyID,
                    //         starReasons: ["MADE_OFFER"]
                    //     })
                    // );
                    query.apply();
                }
                await NoteService.addNote(
                    new Collection({
                        propertyID,
                        note
                    })
                );
                dispatch(setPropertyFavoriteActionTriggered(true));
                dispatch(setPropertyCardMarkAsFavorite(true));
            } else {
                await NoteService.updateNote(
                    new Collection({
                        sortKey: selectedNote.id,
                        note: inputValue
                    })
                );
            }
            await getNotes();
        } catch (error) {
            console.log(error, "Error while trying to save note.");
        } finally {
            setIsLoading(false);
            setSelectedNote(undefined);
            setViewType("list");
            setInputValue("");
            dispatch(setPropertyFavoriteActionTriggered(false));
        }
    };

    return (
        <div className="property-card__tab property-card__tab--notes">
            {isLoading ? (
                <Spin indicator={loaderIcon} />
            ) : viewType !== "list" ? (
                renderAddNotes()
            ) : notes && notes.length > 0 ? (
                renderNotesList()
            ) : (
                renderEmptyState()
            )}
        </div>
    );
};

export default BlockTabNotes;
