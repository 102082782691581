import Collection from "../../valueObject/Collection";
import IFrameMessage from "../IFrameMessage";

export default class OpenFlyoutMessage extends IFrameMessage {
    public static ACTION_NAME = "open-flyout";
    constructor() {
        super(OpenFlyoutMessage.ACTION_NAME);
    }

    public send() {
        super._send(new Collection({}));
    }

    public recieve(callback: Function) {
        callback();
    }
}
