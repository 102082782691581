import Collection from "../valueObject/Collection";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import StatsEntity from "./StatsEntity";

export default class StatsFactory {
    reconstitute(item: Collection): StatsEntity {
        return new StatsEntity()
            .setListingsCurrentCount(
                new TypePositiveInteger(item.get("listingsCurrentCount"))
            )
            .setFinanceComparablesCurrentCount(
                new TypePositiveInteger(
                    item.get("financeComparablesCurrentCount")
                )
            )
            .setSalesComparablesCurrentCount(
                new TypePositiveInteger(
                    item.get("salesComparablesCurrentCount")
                )
            );
    }
}
