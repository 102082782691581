import { USER_DATA_API } from "../../apiList";
import { API_CLIENT_CONTENT_TYPE_JSON } from "../../utils/http/ApiClient";
import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";

export default class NoteGateway {
    getNotes(propertyId: TypeString) {
        return USER_DATA_API.get(
            `/property-notes/?propertyID=${propertyId.value}`
        );
    }
    addNote(data: Collection) {
        return USER_DATA_API.post("/property-notes", data, {
            contentType: API_CLIENT_CONTENT_TYPE_JSON
        });
    }

    updateNote(data: Collection) {
        return USER_DATA_API.post("/property-notes", data, {
            contentType: API_CLIENT_CONTENT_TYPE_JSON
        });
    }
}
