import Collection from "../valueObject/Collection";

export default class LenderPortfolioViewMapper {
    static mapStats(lenderPortfolioStats: Collection) {
        const stats = {
            firstLenderSelection: 0,
            secondLenderSelection: 0,
            thirdLenderSelection: 0
        };

        if (lenderPortfolioStats.has("firstLenderName"))
            stats["firstLenderSelection"] =
                lenderPortfolioStats.get("firstLenderName");

        if (lenderPortfolioStats.has("secondLenderName"))
            stats["secondLenderSelection"] =
                lenderPortfolioStats.get("secondLenderName");

        if (lenderPortfolioStats.has("thirdLenderName"))
            stats["thirdLenderSelection"] =
                lenderPortfolioStats.get("thirdLenderName");

        return stats;
    }
}
