import EntityType, {
    ENTITY_TYPE_LISTING,
    ENTITY_TYPE_SALES_COMP,
    ENTITY_TYPE_REFINANCE_COMP
} from "../map/valueObject/EntityType";

export default class LenderHelperClass {
    static calculatePrice(propertyCardData: any): number | undefined {
        let calculatedPrice = undefined;

        const propertyCardType = new EntityType(propertyCardData.type);
        switch (propertyCardType.value) {
            case ENTITY_TYPE_SALES_COMP:
                calculatedPrice =
                    this._calculateSalesCompLoanAmount(propertyCardData);
                break;
            case ENTITY_TYPE_REFINANCE_COMP:
                calculatedPrice =
                    this._calculateRefinanceCompLoanAmount(propertyCardData);
                break;
            case ENTITY_TYPE_LISTING:
                calculatedPrice =
                    this._calculateListingLoanAmount(propertyCardData);
                break;
            default:
                break;
        }

        return calculatedPrice;
    }

    private static _calculateListingLoanAmount(propertyCardData: any) {
        if (
            propertyCardData.listing &&
            propertyCardData.listing.price &&
            propertyCardData.listing.price !== "$0.00"
        ) {
            const price = this._convertCurrencyToNumber(
                propertyCardData.listing.price
            );
            return price ? price * 0.75 : undefined;
        } else if (
            propertyCardData.saleHistory &&
            propertyCardData.saleHistory.price &&
            propertyCardData.saleHistory.price !== "$0.00"
        ) {
            return (
                this._convertCurrencyToNumber(
                    propertyCardData.saleHistory.price
                ) * 0.75
            );
        } else if (
            propertyCardData.financeHistory &&
            propertyCardData.financeHistory.acquisitionLoanAmount &&
            propertyCardData.financeHistory.acquisitionLoanAmount !== "$0.00"
        ) {
            return this._convertCurrencyToNumber(
                propertyCardData.financeHistory.acquisitionLoanAmount
            );
        } else {
            return undefined;
        }
    }

    private static _calculateSalesCompLoanAmount(propertyCardData: any) {
        if (propertyCardData.saleHistory && propertyCardData.saleHistory.price)
            return (
                this._convertCurrencyToNumber(
                    propertyCardData.saleHistory.price
                ) * 0.75
            );
    }

    private static _calculateRefinanceCompLoanAmount(propertyCardData: any) {
        if (
            propertyCardData.financeHistory &&
            propertyCardData.financeHistory.acquisitionLoanAmount
        )
            return this._convertCurrencyToNumber(
                propertyCardData.financeHistory.acquisitionLoanAmount
            );
    }

    private static _convertCurrencyToNumber(currency: string) {
        return Number(currency.replace(/[^0-9.-]+/g, ""));
    }
}
