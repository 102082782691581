import Collection from "../valueObject/Collection";
import VendorRoleFactory from "./VendorRoleFactory";

export default class VendorRoleRepository {
    _gateway: any;
    _factory: any;

    constructor(gateway: any, factory: any) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async getVendorRoles() {
        const data = new Collection(await this._gateway.getVendorRoles());

        let items = new Collection([]);

        const responseData = data.slice("responseData");

        items = responseData.slice("vendorRoles").map((itemData: any) => {
            return new VendorRoleFactory().reconstitute(
                new Collection(itemData)
            );
        });

        return items;
    }
}
