import Collection from "../valueObject/Collection";

export default class FavoriteAdditionalDataEntity {
    _pipelineCapacity: Collection = new Collection({});
    get pipelineCapacity() {
        return this._pipelineCapacity;
    }

    setPipelineCapacity(value: Collection) {
        this._pipelineCapacity = value;
        return this;
    }

    hasPipelineCapacity(): boolean {
        return this._pipelineCapacity instanceof Collection;
    }
}
