import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { indexSlice } from ".";
import { mapTriggers } from "./mapTriggers";
import { responsiveSlice } from "./responsive";
import { favoritePropertySlice } from "./favoriteProperty";
import { lenderPortfolio } from "./lenderPortfolio";

const reducer = combineReducers({
    index: indexSlice.reducer,
    responsive: responsiveSlice.reducer,
    mapTriggers: mapTriggers.reducer,
    favoriteProperty: favoritePropertySlice.reducer,
    lenderPortfolio: lenderPortfolio.reducer
});

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware: any) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
