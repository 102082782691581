import { useDispatch, useSelector } from "react-redux";
import PlaceholderImg from "../../assets/images/placeholder-img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHouseBuilding,
    faImageSlash,
    faStreetView,
    faXmark,
    faLocationDot,
    faStar,
    faCalculator,
    faBellSlash,
    faCircleNotch
} from "@fortawesome/pro-regular-svg-icons";
import favoritePropertySliceState, {
    setFavoritesPopup,
    setUserPropertyStar
} from "../../store/favoriteProperty";
import indexSliceState, {
    setCalculatorSlideout,
    setLenderFlyout,
    setMapViewPortCenterCoords,
    setShowLpInvestorPopup
} from "../../store";
import { hotjar } from "react-hotjar";
import { capitalizeFirstLetter, insertLineBreak } from "../../utils/utils";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useSearchParams } from "react-router-dom";
import mapTriggersSliceState, {
    setTriggerDocTab,
    setPropertyCardHasAlerts,
    setPropertyCardMarkAsFavorite,
    setPropertyFavoriteActionTriggered,
    setTriggerNotesTab
} from "../../store/mapTriggers";
import { useEffect, useState } from "react";
import PropertyIdMessage from "../../domain/iFrameMessage/calculator/PropertyIdMessage";
import TypeString from "../../domain/valueObject/TypeString";
import PropertyAddressMessage from "../../domain/iFrameMessage/calculator/PropertyAddressMessage";
import FavoritePropertyService from "../../domain/favoriteProperty/FavoritePropertyService";
import PropertyService from "../../domain/property/PropertyService";
import Collection from "../../domain/valueObject/Collection";
import LPInvestmentService from "../../domain/lpInvestment/LPInvestmentService";

interface PartPropertyCardTopProps {
    setPropertyCardItem: Function;
    setPropertyCardClassName: Function;
    hasProperty: boolean;
    openStreetView: Function;
    propertyCardImage: any;
    propertyTypeName: string | undefined;
    propertyCardData: any;
    propertyCardPublicData: any;
    pipelineOverLimit: any | undefined;
}
export function PartPropertyCardTop({
    setPropertyCardItem,
    setPropertyCardClassName,
    hasProperty,
    propertyCardImage,
    openStreetView,
    propertyTypeName,
    propertyCardData,
    propertyCardPublicData,
    pipelineOverLimit
}: PartPropertyCardTopProps) {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const sliceState = useSelector(indexSliceState);

    const mapTriggerState = useSelector(mapTriggersSliceState);
    const favoritePropertiesSlice: any = useSelector(
        favoritePropertySliceState
    );
    const [hasAlert, setHasAlert] = useState(false);
    const [isFavorite, setIsFavorite] = useState<boolean>(
        propertyCardData.isFavorite
    );
    const [isAlertLoading, setIsAlertLoading] = useState(false);

    const [propertyCardAddress, setPropertyCardAddress] = useState(
        propertyCardPublicData.address ?? propertyCardData.address ?? ""
    );
    const [isPropertyTransactionLoading, setIsPropertyTransactionLoading] =
        useState(false);

    const query = PropertyService.getSearchQuery();

    const handleStreetViewClick = () => {
        if (propertyCardImage) {
            openStreetView();
            hotjar.event("Propery Card Street View Open");
        }
    };

    const iconsDisabled = pipelineOverLimit || !sliceState.isLoggedIn;
    const isStreetViewAvailable = () => propertyCardImage !== undefined;

    useEffect(() => {
        if (propertyCardPublicData?.address) {
            setPropertyCardAddress(propertyCardPublicData.address);
        }
        return () => {
            setPropertyCardAddress("");
        };
    }, [propertyCardPublicData]);

    useEffect(() => {
        if (
            mapTriggerState.hidePropertyCard ||
            mapTriggerState.showFavoritePopup
        ) {
            dispatch(setFavoritesPopup(propertyCardData));
        }
    }, [mapTriggerState.hidePropertyCard, mapTriggerState.showFavoritePopup]);

    useEffect(() => {
        if (
            !favoritePropertiesSlice.favoritesProperties ||
            favoritePropertiesSlice.favoritesProperties?.length === 0
        ) {
            setHasAlert(false);
            setIsFavorite(false);
        } else {
            const property = favoritePropertiesSlice.favoritesProperties.find(
                (prop: any) => prop.propertyId === propertyCardData.id
            );
            setHasAlert(property?.hasAlert);
            setIsFavorite(property);
        }

        if (isAlertLoading) setIsAlertLoading(false);
        return () => {
            setHasAlert(false);
            setIsFavorite(false);
        };
    }, [favoritePropertiesSlice.favoritesProperties, propertyCardData]);

    const handleCalcIconClicked = async (propertyCardData: any) => {
        hotjar.event(
            `Acquisition Calculator - Property Card Calculator Button - ${propertyCardData.id}`
        );
        const propertyID = propertyCardData.id;
        const propertyAddress = propertyCardData.address;
        setIsFavorite(true);
        new PropertyIdMessage().send(
            sliceState.calculatorIframe,
            new TypeString(propertyID)
        );
        new PropertyAddressMessage().send(
            sliceState.calculatorIframe,
            new TypeString(propertyAddress)
        );
        // Opens a slideout
        dispatch(
            setCalculatorSlideout({
                isOpen: true,
                id: propertyID,
                overlay: false,
                address: propertyAddress
            })
        );
    };
    return (
        <div className="property-card__top">
            <div className="property-card__heading">
                <div className="property-card__address">
                    <span className="property-card__address-icon">
                        <FontAwesomeIcon icon={faLocationDot} />
                    </span>
                    <span className="property-card__address-text">
                        {hasProperty
                            ? insertLineBreak(
                                  capitalizeFirstLetter(
                                      propertyCardAddress,
                                      true
                                  )
                              ) ||
                              insertLineBreak(
                                  capitalizeFirstLetter(
                                      sliceState.autoCompleteResult
                                          ?.formatted_address,
                                      true
                                  )
                              )
                            : "No Property Found"}
                    </span>
                </div>
                <div
                    className="property-card__close"
                    onClick={() => {
                        dispatch(
                            setPropertyCardItem({
                                data: null,
                                isVisible: false
                            })
                        );
                        dispatch(setMapViewPortCenterCoords(undefined));
                        dispatch(setPropertyCardClassName(""));
                        dispatch(setTriggerNotesTab(false));
                        dispatch(setTriggerDocTab(false));
                        // query.setPropertyId(undefined);
                        searchParams.delete("propertyId");
                        searchParams.delete("placeId");
                        setSearchParams(searchParams);
                    }}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </div>
            </div>
            <div
                className={`property-card__image ${
                    isStreetViewAvailable() ? "" : "no-img"
                }`}
                onClick={handleStreetViewClick}
            >
                <div className="property-card__type">
                    <FontAwesomeIcon icon={faHouseBuilding} />
                    <span>
                        {propertyTypeName &&
                            capitalizeFirstLetter(propertyTypeName)}
                    </span>
                </div>
                <img
                    src={
                        isStreetViewAvailable()
                            ? propertyCardImage
                            : PlaceholderImg
                    }
                    alt="No Property Found"
                />

                {!isStreetViewAvailable() ? (
                    <div className="property-card__image-placeholder">
                        <FontAwesomeIcon icon={faImageSlash} />
                    </div>
                ) : null}
            </div>
            {hasProperty && (
                <>
                    <div
                        onClick={() => {
                            if (iconsDisabled) return false;
                            hotjar.event(
                                `Favorite Star ${
                                    isFavorite ? `Popup Opened` : `Checked`
                                } - ${propertyCardData.id}`
                            );
                            dispatch(setFavoritesPopup(propertyCardData));
                        }}
                        className={`property-card__top-icon property-card__icon-opacity favorite-property ${
                            isFavorite ||
                            mapTriggerState.setPropertyCardMarkAsFavorite
                                ? "favorited"
                                : ""
                        }${iconsDisabled ? " icon-disabled" : ""}`}
                    >
                        <FontAwesomeIcon
                            icon={
                                isFavorite ||
                                mapTriggerState.setPropertyCardMarkAsFavorite
                                    ? solid("star")
                                    : faStar
                            }
                        />
                        <span className="favorite-property__tooltip">
                            Add Tags
                        </span>
                    </div>

                    <div
                        onClick={async () => {
                            if (iconsDisabled) return false;
                            hotjar.event("ZIP Code Alerts Button Clicked");
                            setIsAlertLoading(true);
                            try {
                                if (!isAlertLoading) {
                                    await FavoritePropertyService.toggleAlerts(
                                        new Collection({
                                            propertyID: propertyCardData.id,
                                            alert: !hasAlert
                                        })
                                    );
                                    if (
                                        !(
                                            isFavorite ||
                                            mapTriggerState.setPropertyCardMarkAsFavorite
                                        )
                                    ) {
                                        dispatch(
                                            setPropertyCardMarkAsFavorite(true)
                                        );
                                    }
                                    dispatch(
                                        setPropertyFavoriteActionTriggered(true)
                                    );
                                    dispatch(
                                        setPropertyCardHasAlerts(!hasAlert)
                                    );
                                    query.apply();
                                }
                            } catch (error) {
                                console.log(
                                    error,
                                    "Error while trying to toggle alerts."
                                );
                            } finally {
                                dispatch(
                                    setPropertyFavoriteActionTriggered(false)
                                );
                            }
                        }}
                        className={`property-card__top-icon property-card__icon-opacity alerts ${
                            isAlertLoading ? "loading" : ""
                        } ${
                            hasAlert || mapTriggerState.setPropertyCardHasAlerts
                                ? "alerts--on"
                                : "alerts--of"
                        }${iconsDisabled ? " icon-disabled" : ""}`}
                    >
                        <FontAwesomeIcon
                            icon={
                                isAlertLoading
                                    ? faCircleNotch
                                    : hasAlert ||
                                      mapTriggerState.setPropertyCardHasAlerts
                                    ? solid("bell")
                                    : faBellSlash
                            }
                        />
                    </div>

                    <div className="favorite-property__zip-alert-tooltip">
                        <h6 className="favorite-property__zip-alert-title">
                            Alerts
                        </h6>
                        <p className="favorite-property__zip-alert-text">
                            Receive weekly email alerts about activity in this
                            zip code, including:
                        </p>
                        <ul className="favorite-property__zip-alert-ul">
                            <li className="favorite-property__zip-alert-li">
                                Commercial listings added
                            </li>
                            <li className="favorite-property__zip-alert-li">
                                New financing originations
                            </li>
                            <li className="favorite-property__zip-alert-li">
                                Recently sold properties
                            </li>
                            <li className="favorite-property__zip-alert-li">
                                New service providers who claim to be associated
                                with this property
                            </li>
                        </ul>
                    </div>
                    <div
                        onClick={() => {
                            if (iconsDisabled) return false;
                            handleCalcIconClicked(propertyCardData);
                        }}
                        className={`property-card__top-icon property-card__icon-opacity calculator-property ${
                            sliceState.calculatorSlideout.id ===
                            propertyCardData.id
                                ? "calc-open"
                                : ""
                        }${iconsDisabled ? " icon-disabled" : ""}`}
                    >
                        <FontAwesomeIcon icon={faCalculator} />
                        <span className="favorite-property__tooltip">
                            Calculator
                        </span>
                    </div>

                    <div
                        onClick={async () => {
                            if (iconsDisabled) return false;
                            // TODO - If property is already in LP portfolio, do not send request to create new portfolio
                            setIsPropertyTransactionLoading(true);
                            // This closes additional flyout for find a lender (view details)
                            dispatch(
                                setLenderFlyout({
                                    isOpen: false,
                                    data: undefined,
                                    type: undefined
                                })
                            );
                            dispatch(
                                setCalculatorSlideout({
                                    isOpen: false,
                                    id: null,
                                    address: ""
                                })
                            );
                            try {
                                if (
                                    !favoritePropertiesSlice.userPropertyStar
                                        .hasPortfolio
                                )
                                    await LPInvestmentService.createPropertyPortfolio(
                                        new Collection({
                                            propertyID: propertyCardData.id
                                        })
                                    );
                                dispatch(
                                    setShowLpInvestorPopup({
                                        isOpen: true,
                                        propertyId: propertyCardData.id,
                                        propertyAddress: propertyCardAddress,
                                        propertyTypeName: propertyTypeName
                                    })
                                );
                            } catch (error) {
                                console.log(
                                    error,
                                    "Error while trying to create property portfolio."
                                );
                            } finally {
                                setIsPropertyTransactionLoading(false);
                                dispatch(
                                    setUserPropertyStar({
                                        ...favoritePropertiesSlice.userPropertyStar,
                                        hasPortfolio: true
                                    })
                                );
                            }
                        }}
                        className={`
                            ${
                                isPropertyTransactionLoading
                                    ? "property-card__top-icon property-card__icon-opacity lp loading"
                                    : favoritePropertiesSlice.userPropertyStar &&
                                      favoritePropertiesSlice.userPropertyStar
                                          .hasPortfolio
                                    ? "property-card__top-icon property-card__icon-opacity lp has-portfolio"
                                    : "property-card__top-icon property-card__icon-opacity lp"
                            }
                                ${iconsDisabled ? " icon-disabled" : ""}
                        `}
                    >
                        {isPropertyTransactionLoading ? (
                            <FontAwesomeIcon icon={faCircleNotch} />
                        ) : (
                            "LP"
                        )}
                        <span className="favorite-property__tooltip lp-tooltip">
                            Track your investment in this property using our LP
                            Investment Tracker.
                        </span>
                    </div>
                </>
            )}

            {isStreetViewAvailable() ? (
                <div
                    className="property-card__top-icon property-card__icon-opacity street-view"
                    onClick={() => openStreetView()}
                >
                    <FontAwesomeIcon icon={faStreetView} />
                </div>
            ) : null}
        </div>
    );
}
