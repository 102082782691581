import StateEntity from "./StateEntity";

export default class StateViewMapper {
    static map(states: StateEntity[]) {
        return states.map((state: StateEntity) => {
            return {
                id: state.id.value,
                state: state.state.value,
                abbreviation: state.abbreviation.value
            };
        });
    }
}
