import Collection from "../../valueObject/Collection";
import TypeString from "../../valueObject/TypeString";
import VendorEntity from "./VendorEntity";

export default class VendorFactory {
    reconstitute(item: Collection): VendorEntity {
        const vendor = new VendorEntity();

        if (item.has("userId"))
            vendor.setUserId(new TypeString(item.get("userId")));
        if (item.has("firstName"))
            vendor.setFirstName(new TypeString(item.get("firstName")));
        if (item.has("lastName"))
            vendor.setLastName(new TypeString(item.get("lastName")));
        if (item.has("email"))
            vendor.setEmail(new TypeString(item.get("email")));
        if (item.has("phone"))
            vendor.setPhone(new TypeString(item.get("phone")));

        return vendor;
    }
}
