import Collection from "../../valueObject/Collection";
import TypePositiveInteger from "../../valueObject/TypePositiveInteger";
import TypeString from "../../valueObject/TypeString";
import AgentEntity from "./AgentEntity";

export default class AgentFactory {
    reconstitute(item: Collection): AgentEntity {
        const agent = new AgentEntity();
        if (item.has("id"))
            agent.setId(new TypePositiveInteger(item.get("id")));
        if (item.has("name")) agent.setName(new TypeString(item.get("name")));
        if (item.has("email"))
            agent.setEmail(new TypeString(item.get("email")));
        if (item.has("landline"))
            agent.setLandline(new TypeString(item.get("landline")));
        if (item.has("mobile"))
            agent.setPhone(new TypeString(item.get("mobile")));

        return agent;
    }
}
