import AgentFactory from "../representer/agent/AgentFactory";
import Collection from "../valueObject/Collection";
import TypeFloat from "../valueObject/TypeFloat";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import TypeTimestamp from "../valueObject/TypeTimestamp";
import PropertyListingEntity from "./PropertyListingEntity";

export default class PropertyListingFactory {
    reconstitute(item: Collection): PropertyListingEntity {
        const propertyListing = new PropertyListingEntity();
        if (item.has("id"))
            propertyListing.setId(new TypeString(item.get("id")));
        if (item.has("price")) {
            if (item.get("price") === null) {
                propertyListing.setPrice(null);
            } else {
                propertyListing.setPrice(new TypeFloat(item.get("price")));
            }
        }
        if (item.has("pricePerSqFt"))
            propertyListing.setPricePerSqFt(
                new TypeFloat(item.get("pricePerSqFt"))
            );
        if (item.has("pricePerUnit"))
            propertyListing.setPricePerUnit(
                new TypeFloat(item.get("pricePerUnit"))
            );
        if (item.has("units"))
            propertyListing.setUnits(
                new TypePositiveInteger(item.get("units"))
            );
        if (item.has("agent")) {
            if (item.get("agent") === null) {
                propertyListing.setAgent(null);
            } else {
                propertyListing.setAgent(
                    new AgentFactory().reconstitute(
                        new Collection(item.get("agent"))
                    )
                );
            }
        }
        if (item.has("company")) {
            if (item.get("company") === null) {
                propertyListing.setCompany(null);
            } else {
                propertyListing.setCompany(new TypeString(item.get("company")));
            }
        }

        if (item.has("publicListingExpiration"))
            propertyListing.setPublicListingExpiration(
                new TypeTimestamp(item.get("publicListingExpiration"))
            );

        return propertyListing;
    }
}
