import { URLSearchParams } from "url";
import BaseModel from "../BaseModel";
import LenderFilterQuery from "../lender/queries/LenderFilterQuery";
import PropertySearchQuery from "../property/queries/PropertySearchQuery";
import Collection from "../valueObject/Collection";
import TypeBoolean from "../valueObject/TypeBoolean";
import TypeFloat from "../valueObject/TypeFloat";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import URLQueryParameters from "./URLQueryParameters";

export default class URLObjectEntity extends BaseModel {
    private _searchQuery: PropertySearchQuery;
    private _lenderFilterQuery: LenderFilterQuery;
    private _searchParams: URLSearchParams;
    private _setSearchParams: Function;
    constructor(searchParams: URLSearchParams, setSearchParams: Function) {
        super();
        this._searchParams = searchParams;
        this._setSearchParams = setSearchParams;
    }

    public setPropertySearchQuery(value: PropertySearchQuery) {
        const isInitial = !(this._searchQuery instanceof PropertySearchQuery);
        this._searchQuery = value;
        this._assignPropertySearchTrigger();
        isInitial && this.fromUrlToQuery();
        return this;
    }

    public setLenderFilterQuery(value: LenderFilterQuery) {
        const isInitial = !(
            this._lenderFilterQuery instanceof LenderFilterQuery
        );
        this._lenderFilterQuery = value;
        this._assignLenderFilterSearchTrigger();
        isInitial && this.fromUrlToQuery();
        return this;
    }

    private _assignPropertySearchTrigger() {
        this._searchQuery.assignApplyTrigger("apply_url", () => {
            // FILTER PARAMETERS
            if (this._searchQuery.filters.hasListingsChecked()) {
                this._searchParams.set(
                    URLQueryParameters.listingsChecked,
                    this._searchQuery.filters?.listingsChecked?.value
                );
            }
            if (this._searchQuery.filters.hasSalesComparablesChecked()) {
                this._searchParams.set(
                    URLQueryParameters.salesComparablesChecked,
                    this._searchQuery.filters?.salesComparablesChecked?.value
                );
            }
            if (this._searchQuery.filters.hasFinanceComparablesChecked())
                this._searchParams.set(
                    URLQueryParameters.financeComparablesChecked,
                    this._searchQuery.filters.financeComparablesChecked?.value
                );
            if (this._searchQuery.filters.hasPriceMin())
                this._searchParams.set(
                    URLQueryParameters.priceMin,
                    this._searchQuery.filters.priceMin?.value
                );

            if (this._searchQuery.filters.hasPriceMax())
                this._searchParams.set(
                    URLQueryParameters.priceMax,
                    this._searchQuery.filters.priceMax?.value
                );
            if (this._searchQuery.filters.hasDateRangeListings()) {
                this._searchParams.set(
                    URLQueryParameters.listingsDateRange,
                    this._searchQuery.filters.dateRangeListings?.value
                );
            }
            if (this._searchQuery.filters.hasDateRangeFinanceComparables()) {
                this._searchParams.set(
                    URLQueryParameters.financeComparablesDateRange,
                    this._searchQuery.filters.dateRangeFinanceComparables?.value
                );
            }
            if (this._searchQuery.filters.hasDateRangeSalesComparables()) {
                this._searchParams.set(
                    URLQueryParameters.salesComparablesDateRange,
                    this._searchQuery.filters.dateRangeSalesComparables?.value
                );
            }

            // MAP PARAMETERS
            if (this._searchQuery.hasZoomLevel()) {
                this._searchParams.set(
                    URLQueryParameters.zoomLevel,
                    this._searchQuery.zoomLevel?.value
                );
            }

            if (
                this._searchQuery.hasMapCenterLatitude() &&
                this._searchQuery.hasMapCenterLongitude()
            ) {
                this._searchParams.set(
                    URLQueryParameters.mapLatitude,
                    this._searchQuery.mapCenterLatitude?.value
                );
                this._searchParams.set(
                    URLQueryParameters.mapLongitude,
                    this._searchQuery.mapCenterLongitude?.value
                );
            }

            if (this._searchQuery.hasPropertyId()) {
                this._searchParams.set(
                    URLQueryParameters.propertyId,
                    this._searchQuery.propertyId?.value
                );
            }

            // PROPERTY TYPES PARAMETERS
            const propertyTypes =
                this._searchQuery.filters.propertyType.isEmpty()
                    ? "0"
                    : this._searchQuery.filters.propertyType
                          .map((item: TypePositiveInteger) => {
                              return item.value;
                          })
                          .toArray()
                          .join(",")!;
            this._searchParams.set(
                URLQueryParameters.propertyType,
                propertyTypes
            );

            // FIND A LENDER PARAMETERS

            if (this._setSearchParams)
                this._setSearchParams(this._searchParams);
        });

        return this;
    }

    private _assignLenderFilterSearchTrigger() {
        this._lenderFilterQuery.assignApplyTrigger("apply_url", () => {
            if (this._lenderFilterQuery.hasState()) {
                this._searchParams.set(
                    URLQueryParameters.findALenderState,
                    this._lenderFilterQuery.state?.value
                );
            }

            if (this._lenderFilterQuery.hasPropertyType()) {
                this._searchParams.set(
                    URLQueryParameters.findALenderPropertyType,
                    this._lenderFilterQuery.propertyType?.value
                );
            }

            if (this._lenderFilterQuery.hasLoanAmount()) {
                this._searchParams.set(
                    URLQueryParameters.findALenderLoanAmount,
                    this._lenderFilterQuery.loanAmount?.value
                );
            }

            if (this._lenderFilterQuery.hasLoanType()) {
                this._searchParams.set(
                    URLQueryParameters.findALenderLoanType,
                    this._lenderFilterQuery.loanType?.value
                );
            }

            const executionTypes =
                this._lenderFilterQuery.executionTypes.isEmpty()
                    ? "0"
                    : this._lenderFilterQuery.executionTypes
                          .map((item: number) => {
                              return item;
                          })
                          .toArray()
                          .join(",")!;
            this._searchParams.set(
                URLQueryParameters.findALenderExecutionTypes,
                executionTypes
            );

            if (this._lenderFilterQuery.hasPublicLenders()) {
                this._searchParams.set(
                    URLQueryParameters.findALenderPublicLenders,
                    this._lenderFilterQuery._isPublicLender?.value
                );
            }

            if (this._setSearchParams)
                this._setSearchParams(this._searchParams);
        });

        return this;
    }

    public fromUrlToQuery() {
        if (this._searchQuery instanceof PropertySearchQuery) {
            // GOD WAS HERE - START
            this._searchQuery.pauseMVC();
            this._searchQuery.filters.pauseMVC();

            // PROPERTY TYPES PARAMETERS
            if (this._searchParams.has(URLQueryParameters.propertyType)) {
                const propertyTypes =
                    this._searchParams.get(URLQueryParameters.propertyType) ===
                    "0"
                        ? []
                        : this._searchParams
                              .get(URLQueryParameters.propertyType)
                              ?.split(",")
                              .map((item) => {
                                  return new TypePositiveInteger(item);
                              });

                this._searchQuery.filters.setPropertyTypes(
                    new Collection(propertyTypes)
                );
            }

            // FILTER PARAMETERS
            if (this._searchParams.has(URLQueryParameters.listingsChecked)) {
                this._searchQuery.filters.setListingsChecked(
                    new TypeBoolean(
                        this._searchParams.get(
                            URLQueryParameters.listingsChecked
                        )
                    )
                );
            }
            if (
                this._searchParams.has(
                    URLQueryParameters.salesComparablesChecked
                )
            ) {
                this._searchQuery.filters.setSalesComparablesChecked(
                    new TypeBoolean(
                        this._searchParams.get(
                            URLQueryParameters.salesComparablesChecked
                        )
                    )
                );
            }
            if (
                this._searchParams.has(
                    URLQueryParameters.financeComparablesChecked
                )
            ) {
                this._searchQuery.filters.setFinanceComparablesChecked(
                    new TypeBoolean(
                        this._searchParams.get(
                            URLQueryParameters.financeComparablesChecked
                        )
                    )
                );
            }
            if (this._searchParams.has(URLQueryParameters.priceMin)) {
                this._searchQuery.filters.setPriceMin(
                    new TypePositiveInteger(
                        this._searchParams.get(URLQueryParameters.priceMin)!
                    )
                );
            }
            if (this._searchParams.has(URLQueryParameters.priceMax)) {
                this._searchQuery.filters.setPriceMax(
                    new TypePositiveInteger(
                        this._searchParams.get(URLQueryParameters.priceMax)!
                    )
                );
            }
            if (this._searchParams.has(URLQueryParameters.listingsDateRange)) {
                this._searchQuery.filters.setDateRangeListings(
                    new TypePositiveInteger(
                        this._searchParams.get(
                            URLQueryParameters.listingsDateRange
                        )!
                    )
                );
            }
            if (
                this._searchParams.has(
                    URLQueryParameters.salesComparablesDateRange
                )
            ) {
                this._searchQuery.filters.setDateRangeSalesComparables(
                    new TypePositiveInteger(
                        this._searchParams.get(
                            URLQueryParameters.salesComparablesDateRange
                        )!
                    )
                );
            }
            if (
                this._searchParams.has(
                    URLQueryParameters.financeComparablesDateRange
                )
            ) {
                this._searchQuery.filters.setDateRangeFinanceComparables(
                    new TypePositiveInteger(
                        this._searchParams.get(
                            URLQueryParameters.financeComparablesDateRange
                        )!
                    )
                );
            }
            if (this._searchParams.has(URLQueryParameters.listingBroker)) {
                this._searchQuery.filters.setListingBroker(new Collection([]));
            }

            // MAP PARAMETERS
            if (this._searchParams.has(URLQueryParameters.zoomLevel)) {
                this._searchQuery.setZoomLevel(
                    new TypeFloat(
                        this._searchParams.get(URLQueryParameters.zoomLevel)!
                    )
                );
            }
            if (
                this._searchParams.has(URLQueryParameters.mapLatitude) &&
                this._searchParams.has(URLQueryParameters.mapLongitude)
            ) {
                this._searchQuery.setMapCenterLatitude(
                    new TypeFloat(
                        parseFloat(
                            this._searchParams.get(
                                URLQueryParameters.mapLatitude
                            )!
                        )
                    )
                );
                this._searchQuery.setMapCenterLongitude(
                    new TypeFloat(
                        parseFloat(
                            this._searchParams.get(
                                URLQueryParameters.mapLongitude
                            )!
                        )
                    )
                );
            }
            if (this._searchParams.has(URLQueryParameters.propertyId)) {
                this._searchQuery.setPropertyId(
                    new TypeString(
                        this._searchParams.get(URLQueryParameters.propertyId)!
                    )
                );
            }

            // GOD WAS HERE DO NOT THINK YOU'RE SMARTER THAN HIM
            this._searchQuery.unPauseMVC();
            this._searchQuery.filters.unPauseMVC();
            this._searchQuery.filters._executeAfterChangeTriggers();
        }

        if (this._lenderFilterQuery instanceof LenderFilterQuery) {
            // GOD WAS HERE - START
            this._lenderFilterQuery.pauseMVC();

            // FIND A LENDER PARAMETERS
            if (this._searchParams.has(URLQueryParameters.findALenderState)) {
                this._lenderFilterQuery.setState(
                    new TypePositiveInteger(
                        this._searchParams.get(
                            URLQueryParameters.findALenderState
                        )!
                    )
                );
            }
            if (
                this._searchParams.has(
                    URLQueryParameters.findALenderPropertyType
                )
            ) {
                this._lenderFilterQuery.setPropertyType(
                    new TypePositiveInteger(
                        this._searchParams.get(
                            URLQueryParameters.findALenderPropertyType
                        )!
                    )
                );
            }

            if (
                this._searchParams.has(URLQueryParameters.findALenderLoanAmount)
            ) {
                this._lenderFilterQuery.setLoanAmount(
                    new TypePositiveInteger(
                        this._searchParams.get(
                            URLQueryParameters.findALenderLoanAmount
                        )!
                    )
                );
            }

            if (
                this._searchParams.has(URLQueryParameters.findALenderLoanType)
            ) {
                this._lenderFilterQuery.setLoanType(
                    new TypePositiveInteger(
                        this._searchParams.get(
                            URLQueryParameters.findALenderLoanType
                        )!
                    )
                );
            }

            if (
                this._searchParams.has(
                    URLQueryParameters.findALenderPublicLenders
                )
            ) {
                this._lenderFilterQuery.setPublicLenders(
                    new TypeBoolean(
                        this._searchParams.get(
                            URLQueryParameters.findALenderPublicLenders
                        )!
                    )
                );
            }

            if (
                this._searchParams.has(
                    URLQueryParameters.findALenderExecutionTypes
                )
            ) {
                const executionTypes =
                    this._searchParams.get(
                        URLQueryParameters.findALenderExecutionTypes
                    ) === "0"
                        ? []
                        : this._searchParams
                              .get(URLQueryParameters.findALenderExecutionTypes)
                              ?.split(",")
                              .map((item: string) => {
                                  return parseInt(item);
                              });

                this._lenderFilterQuery.setExecutionTypes(
                    new Collection(executionTypes)
                );
            }

            // GOD WAS HERE DO NOT THINK YOU'RE SMARTER THAN HIM
            this._lenderFilterQuery.unPauseMVC();
            if (
                this._lenderFilterQuery.hasState() &&
                this._lenderFilterQuery.hasPropertyType()
            )
                this._lenderFilterQuery.apply();
        }
    }
}
