import StateRepository from "./StateRepository";
import StateGateway from "./StateGateway";
import StateFactory from "./StateFactory";

export default class StateService {
    static async get(): Promise<any> {
        return new StateRepository(
            new StateGateway(),
            new StateFactory()
        ).get();
    }
}
