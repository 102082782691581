import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Slider } from "antd";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import indexSliceState from "../../../store";
import { hotjar } from "react-hotjar";

// Components imports
import PartCheckbox from "../../parts/PartCheckbox";
import PartDropdown from "../../parts/PartDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { getBrokerageNamesFromSelectedValues } from "../../../utils/utils";
import { formatRangeNums } from "../../../utils/uiUitls";

// Domain imports
import TypePositiveInteger from "../../../domain/valueObject/TypePositiveInteger";
import TypeBoolean from "../../../domain/valueObject/TypeBoolean";
import Collection from "../../../domain/valueObject/Collection";
import PropertyService from "../../../domain/property/PropertyService";
import PropertySearchQuery from "../../../domain/property/queries/PropertySearchQuery";
import PropertyFilterEntity from "../../../domain/propertyFilter/PropertyFilterEntity";
import BrokerageService from "../../../domain/brokerage/BrokerageService";
import BrokerageViewMapper from "../../../domain/brokerage/BrokerageViewMapper";
import ListingBrokerService from "../../../domain/listingBroker/ListingBrokerService";
import ListingBrokerViewMapper from "../../../domain/listingBroker/ListingBrokerViewMapper";
import URLObjectEntity from "../../../domain/urlQuery/URLObjectEntity";
import URLObjectService from "../../../domain/urlQuery/URLObjectService";
import PartDropdownFilters from "../../parts/PartDropdownFilters";
import PartInputRangeNumber from "../../parts/PartInputRangeNumber";

export const defaultRangeValues = [0, 600000000];
const rangeStep = 10000;

interface BlockFiltersProps {
    searchIsTriggered: boolean;
    setSearchIsTriggered: Function;
}

const BlockFilters = ({
    searchIsTriggered,
    setSearchIsTriggered
}: BlockFiltersProps) => {
    const sliceState = useSelector(indexSliceState);
    const [searchParams, setSearchParams] = useSearchParams();
    const query: PropertySearchQuery = PropertyService.getSearchQuery();
    // eslint-disable-next-line no-unused-vars
    const url: URLObjectEntity = URLObjectService.getURLObject(
        searchParams,
        setSearchParams
    );

    url.setPropertySearchQuery(query);

    const [range, setRange] = useState<any>(defaultRangeValues);
    const [rangeFormat, setRangeFormat] = useState("short");
    const [listingBrokerageSelectedValues, setListingBrokerageSelectedValues] =
        useState([]);
    const [listingBrokerSelectedValues, setListingBrokerSelectedValues] =
        useState([]);
    const [brokerages, setBrokerages] = useState<any>([]);
    const [listingBrokers, setListingBrokers] = useState<any>([]);
    const [showShadow, setShowShadow] = useState<any>(false);
    const [showTopShadow, setTopShowShadow] = useState<any>(false);
    const filtersRef = useRef<any>(null);
    const filtersFormRef = useRef<any>(null);

    const {
        control,
        formState,
        register,
        setValue,
        watch,
        reset,
        formState: { errors }
    } = useForm({
        mode: "onChange",
        shouldFocusError: false,
        defaultValues: {
            listings: true,
            salesComparables: true,
            financeComparables: true,
            listingsDateRange: 9,
            salesComparablesDateRange: 6,
            financeComparablesDateRange: 6,
            listingBrokerage: [],
            listingBroker: [],
            priceRange: defaultRangeValues
        }
    });

    const listingsChecked = watch("listings");
    const financeComparablesChecked = watch("financeComparables");
    const salesComparablesChecked = watch("salesComparables");

    const onRangeChange = (range: any) => {
        if (rangeFormat === "short") {
            setRangeFormat("full");
        }

        query.filters.setPriceMin(new TypePositiveInteger(range[0]));
        query.filters.setPriceMax(new TypePositiveInteger(range[1]));
        setRange(range);
    };

    const resetFormValues = () => {
        reset();
        setRange(defaultRangeValues);
        setListingBrokerageSelectedValues([]);
        setListingBrokerSelectedValues([]);
        query.filters.reset();
        getAllBrokeragesAndBrokers();
    };

    useEffect(() => {
        if (searchIsTriggered) {
            resetFormValues();
            setSearchIsTriggered(false);
        }
    }, [searchIsTriggered]);

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        if (currentParams !== undefined) {
            // url.fromUrlToQuery();
            fromFilterToForm();
        }
    }, []);

    const fromFilterToForm = () => {
        if (!(query.filters instanceof PropertyFilterEntity)) return;

        if (query.filters.hasListingsChecked()) {
            setValue("listings", query.filters.listingsChecked?.value);
        }
        if (query.filters.hasFinanceComparablesChecked()) {
            setValue(
                "financeComparables",
                query.filters.financeComparablesChecked?.value
            );
        }
        if (query.filters.hasSalesComparablesChecked()) {
            setValue(
                "salesComparables",
                query.filters.salesComparablesChecked?.value
            );
        }

        if (query.filters.hasPriceMin()) {
            setValue(
                "priceRange",
                query.filters.priceMin?.value,
                query.filters.priceMax?.value
            );
            setRange([
                query.filters.priceMin?.value,
                query.filters.priceMax?.value
            ]);
        }

        if (query.filters.hasPriceMax()) {
            setValue("priceRange", [
                query.filters.priceMin?.value,
                query.filters?.priceMax?.value
            ]);
            setRange([
                query.filters.priceMin?.value,
                query.filters.priceMax?.value
            ]);
        }
        if (query.filters.hasDateRangeListings()) {
            setValue(
                "listingsDateRange",
                query.filters.dateRangeListings?.value
            );
        }
        if (query.filters.hasDateRangeSalesComparables()) {
            setValue(
                "salesComparablesDateRange",
                query.filters.dateRangeSalesComparables?.value
            );
        }
        if (query.filters.hasDateRangeFinanceComparables()) {
            setValue(
                "financeComparablesDateRange",
                query.filters.dateRangeFinanceComparables?.value
            );
        }
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         const filtersHeight = filtersRef.current.clientHeight;
    //         const filtersFormHeight = filtersFormRef.current.clientHeight;
    //         setShowShadow(
    //             filtersFormHeight > filtersHeight - 60 ? true : false
    //         );
    //     }, 200);
    // }, [collapseChange]);

    useEffect(() => {
        filtersRef.current.addEventListener("scroll", (e: any) => {
            setTopShowShadow(e.target.scrollTop > 0 ? true : false);
            setShowShadow(
                e.target.offsetHeight + e.target.scrollTop <
                    e.target.scrollHeight
            );
        });
    }, []);

    const getAllBrokeragesAndBrokers = async () => {
        const brokerageResponse = await BrokerageService.getAll();
        const brokerages = BrokerageViewMapper.map(brokerageResponse);

        const brokersResponse = await ListingBrokerService.get(); // no parameter means get all brokers
        const brokers = ListingBrokerViewMapper.map(brokersResponse.items);

        setBrokerages(brokerages);
        setListingBrokers(brokers);
    };

    const handleListingBrokerageChange = (selectedValues: any) => {
        setListingBrokerageSelectedValues(selectedValues);

        let brokerageNames: any = [];
        if (selectedValues.length > 0) {
            brokerageNames = getBrokerageNamesFromSelectedValues(
                selectedValues,
                brokerages
            );
        }
        ListingBrokerService.get(new Collection(brokerageNames)).then(
            (response: any) => {
                setListingBrokers(ListingBrokerViewMapper.map(response.items));
                if (response.items.length < 1) {
                    query.filters.setListingBroker(new Collection([]));
                }
            }
        );

        query.filters.setListingBrokerage(new Collection(brokerageNames));
    };

    // const checkboxLockHTML = (whereFromParameter: string) => (
    //     <div className="filter-lock__holder">
    //         <div
    //             className="filter-lock"
    //             onClick={() => {
    //                 hotjar.event("Become a Member - Unlock Comps Sidebar");
    //                 dispatch(
    //                     dispatch(
    //                         setIframe({
    //                             show: true,
    //                             street: "",
    //                             city: "",
    //                             propId: "",
    //                             mapLat: sliceState.propertyCardItem?.lat,
    //                             mapLng: sliceState.propertyCardItem?.lng,
    //                             mapZoom: sliceState.iframe.zoom,
    //                             type: "join",
    //                             authUser: "true",
    //                             whereFrom: whereFromParameter
    //                         })
    //                     )
    //                 );
    //                 // window.open(
    //                 //     `https://gparency.com/?finance-my-deal-popup=true`
    //                 // );
    //             }}
    //         >
    //             <FontAwesomeIcon icon={faLock} />
    //         </div>
    //         <p className="lock-info-popup">
    //             Click to unlock more filtering options! Search our up-to-date
    //             database of sale and finance comparables.
    //         </p>
    //     </div>
    // );

    useEffect(() => {
        getAllBrokeragesAndBrokers();
    }, []);

    return (
        <div
            className={`filters ${showShadow ? "has-shadow" : ""} ${
                showTopShadow ? "has-top-shadow" : ""
            }`}
            ref={filtersRef}
        >
            <div className="filters__head">
                <div
                    className="filters__clear-link"
                    role="button"
                    onClick={() => {
                        resetFormValues();
                        hotjar.event("Button Reset Filters");
                    }}
                >
                    reset
                </div>
            </div>
            <div className="filters__form" ref={filtersFormRef}>
                <Form>
                    <div
                        className={`filters__form-checkboxes ${
                            sliceState.isPaidUser ? "paid-user" : "free-user"
                        }`}
                    >
                        <PartCheckbox
                            name="listings"
                            control={control}
                            errors={errors}
                            label="Listings"
                            disabled={true}
                            onChange={(e: any) => {
                                query.filters.setListingsChecked(
                                    new TypeBoolean(e.target.checked)
                                );
                                hotjar.event(
                                    `Filters Checkbox Listings ${
                                        e.target.checked
                                            ? "checked"
                                            : "unchecked"
                                    }`
                                );
                            }}
                        />
                        <div
                            className={`filters__form-ch-wrap ${
                                !sliceState.isPaidUser ? "disabled" : ""
                            }`}
                        >
                            <PartCheckbox
                                name="salesComparables"
                                control={control}
                                errors={errors}
                                label="Sale Comparables"
                                onChange={(e: any) => {
                                    query.filters.setSalesComparablesChecked(
                                        new TypeBoolean(e.target.checked)
                                    );
                                    hotjar.event(
                                        `Filters Checkbox Sale Comparables ${
                                            e.target.checked
                                                ? "checked"
                                                : "unchecked"
                                        }`
                                    );
                                }}
                            />
                        </div>
                        <div className={"filters__form-ch-wrap"}>
                            <PartCheckbox
                                name="financeComparables"
                                control={control}
                                errors={errors}
                                label="Finance Comparables"
                                onChange={(e: any) => {
                                    query.filters.setFinanceComparablesChecked(
                                        new TypeBoolean(e.target.checked)
                                    );
                                    hotjar.event(
                                        `Filters Checkbox Finance Comparables ${
                                            e.target.checked
                                                ? "checked"
                                                : "unchecked"
                                        }`
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="filters__info-popup-wrapper">
                        <h3 className="filters__select-title large">PRICE</h3>
                        <span
                            className="filter-info-icon"
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <FontAwesomeIcon icon={faCircleInfo} />
                        </span>
                        <span className="filter-info-popup price">
                            Price changes will apply to Listings, Sales, and
                            Finance Comparables.
                        </span>
                    </div>
                    <Slider
                        {...register("priceRange")}
                        range
                        min={defaultRangeValues[0]}
                        max={defaultRangeValues[1]}
                        disabled={
                            !listingsChecked &&
                            !salesComparablesChecked &&
                            !financeComparablesChecked
                                ? true
                                : false
                        }
                        step={rangeStep}
                        value={range}
                        tooltipVisible={false}
                        onChange={onRangeChange}
                        onAfterChange={(vals: any) => {
                            setRangeFormat("short");
                            hotjar.event(
                                `Filters Price Range - Min: ${vals[0]}, Max: ${vals[1]}`
                            );
                        }}
                    />
                    <div className="filters__range">
                        <span className="filters__range-min">
                            $
                            {rangeFormat === "full"
                                ? range[0]?.toLocaleString()
                                : formatRangeNums(range[0])}
                        </span>
                        <span className="filters__range-msx">
                            $
                            {rangeFormat === "full"
                                ? range[1]?.toLocaleString()
                                : formatRangeNums(range[1])}
                        </span>
                    </div>
                    <div className="filters__price-inputs">
                        <PartInputRangeNumber
                            name="minPrice"
                            label="min price"
                            errors={errors}
                            value={range[0]}
                            range={range}
                            onRangeChange={onRangeChange}
                            setRangeFormat={setRangeFormat}
                        />
                        <PartInputRangeNumber
                            name="maxPrice"
                            label="max price"
                            errors={errors}
                            value={range[1]}
                            range={range}
                            onRangeChange={onRangeChange}
                            setRangeFormat={setRangeFormat}
                        />
                    </div>
                    <h3 className="filters__select-title large">DATE RANGE</h3>
                    <div
                        className="filters__dropdowns filters__date-range-dds"
                        id="filters__dropdowns-holder"
                    >
                        <div className="filters__select filters__select--icon">
                            <div
                                className={`filters__info-popup-wrapper listings ${
                                    !listingsChecked ? "disabled" : ""
                                }`}
                            >
                                <h3 className="filters__select-title">
                                    Listings
                                </h3>
                                <span
                                    className="filter-info-icon"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                </span>
                                <span className={"filter-info-popup"}>
                                    The filter date is the date GPARENCY
                                    confirms the listing is added in our
                                    database. A property may have been listed
                                    prior to this date.
                                </span>
                            </div>
                            <PartDropdown
                                name="listingsDateRange"
                                control={control}
                                errors={errors}
                                placeholder="Choose only one option"
                                getPopupContainer={() =>
                                    document.getElementById(
                                        "filters__dropdowns-holder"
                                    )
                                }
                                defaultValue={9}
                                disabled={!listingsChecked}
                                onChange={(value: any) => {
                                    query.filters.setDateRangeListings(
                                        new TypePositiveInteger(value)
                                    );
                                }}
                                formState={formState}
                                data={[
                                    {
                                        label: "Last 3 days",
                                        value: 1
                                    },
                                    {
                                        label: "Last 1 week",
                                        value: 2
                                    },
                                    {
                                        label: "Last 2 weeks",
                                        value: 3
                                    },
                                    {
                                        label: "Last 3 weeks",
                                        value: 4
                                    },
                                    {
                                        label: "Last 1 month",
                                        value: 5
                                    },
                                    {
                                        label: "Last 3 months",
                                        value: 6
                                    },
                                    {
                                        label: "Last 6 months",
                                        value: 7
                                    },
                                    {
                                        label: "Last 1 year",
                                        value: 8
                                    },
                                    {
                                        label: "All available",
                                        value: 9
                                    }
                                ]}
                                hotjarEventName="Listings Dropdown"
                            />
                        </div>
                        <div className="filters__select filters__select--icon">
                            <h3
                                className={`filters__select-title ${
                                    !salesComparablesChecked ||
                                    !sliceState.isPaidUser
                                        ? "disabled"
                                        : ""
                                }`}
                            >
                                Sale Comparables
                            </h3>
                            <PartDropdown
                                name="salesComparablesDateRange"
                                control={control}
                                errors={errors}
                                placeholder="Choose only one option"
                                defaultValue={6}
                                disabled={
                                    !salesComparablesChecked ||
                                    !sliceState.isPaidUser
                                }
                                formState={formState}
                                onChange={(value: any) => {
                                    query.filters.setDateRangeSalesComparables(
                                        new TypePositiveInteger(value)
                                    );
                                }}
                                getPopupContainer={() =>
                                    document.getElementById(
                                        "filters__dropdowns-holder"
                                    )
                                }
                                data={[
                                    {
                                        label: "Last 1 month",
                                        value: 1
                                    },
                                    {
                                        label: "Last 3 months",
                                        value: 2
                                    },
                                    {
                                        label: "Last 6 months",
                                        value: 3
                                    },
                                    {
                                        label: "Last 1 year",
                                        value: 4
                                    },
                                    {
                                        label: "Last 2 years",
                                        value: 5
                                    },
                                    {
                                        label: "Last 3 years",
                                        value: 6
                                    }
                                ]}
                                hotjarEventName="Sale Comparables Dropdown"
                            />
                        </div>
                        <div className="filters__select filters__select--icon">
                            <h3
                                className={`filters__select-title ${
                                    !financeComparablesChecked ||
                                    !sliceState.isPaidUser
                                        ? "disabled"
                                        : ""
                                }`}
                            >
                                Finance Comparables
                            </h3>
                            <PartDropdown
                                name="financeComparablesDateRange"
                                control={control}
                                errors={errors}
                                placeholder="Choose only one option"
                                defaultValue={6}
                                disabled={
                                    !financeComparablesChecked ||
                                    !sliceState.isPaidUser
                                }
                                formState={formState}
                                onChange={(value: any) => {
                                    query.filters.setDateRangeFinanceComparables(
                                        new TypePositiveInteger(value)
                                    );
                                }}
                                getPopupContainer={() =>
                                    document.getElementById(
                                        "filters__dropdowns-holder"
                                    )
                                }
                                data={[
                                    {
                                        label: "Last 1 month",
                                        value: 1
                                    },
                                    {
                                        label: "Last 3 months",
                                        value: 2
                                    },
                                    {
                                        label: "Last 6 months",
                                        value: 3
                                    },
                                    {
                                        label: "Last 1 year",
                                        value: 4
                                    },
                                    {
                                        label: "Last 2 years",
                                        value: 5
                                    },
                                    {
                                        label: "Last 3 years",
                                        value: 6
                                    }
                                ]}
                                hotjarEventName="Finance Comparables Dropdown"
                            />
                        </div>
                        {/* {!sliceState.isPaidUser ? (
                            <BlockMembershipCTA
                                content="Unlock Sales &amp; Finance Comparables"
                                ctaLinkContent="Become a member to unlock"
                                hotjarEventName="Become a Member - Unlock Comps Sidebar Date Range"
                                ctaFrom={
                                    WHERE_FROM_CTA.LEFT_NAV_SALES_FINANCE_COMP_FILTERS
                                }
                            />
                        ) : null} */}
                    </div>
                    <div className="filters__dropdowns-brokerages">
                        <div className="filters__select">
                            <h3 className="filters__select-title large brokerages">
                                LISTING BROKERAGE
                            </h3>
                            <PartDropdownFilters
                                parent="filters"
                                showSearch={true}
                                name="listingBrokerage"
                                dropdownClassName="brokers-dropdown"
                                value={listingBrokerageSelectedValues}
                                placeholder="Search Brokerage"
                                disabled={!listingsChecked}
                                noContentMessage="Nothing Matches Your Search Criteria"
                                onChange={handleListingBrokerageChange}
                                getPopupContainer={() =>
                                    document.getElementById(
                                        "filters__dropdowns-holder"
                                    )
                                }
                                options={brokerages}
                                hotjarEventName="Listing Brokerage Dropdown"
                            />
                        </div>
                        <div className="filters__select">
                            <h3 className="filters__select-title large brokerages">
                                LISTING BROKER
                            </h3>
                            <PartDropdownFilters
                                parent="filters"
                                showSearch={true}
                                dropdownClassName="brokers-dropdown"
                                name="listingBroker"
                                value={listingBrokerSelectedValues}
                                placeholder="Search Broker"
                                disabled={!listingsChecked}
                                noContentMessage="Nothing Matches Your Search Criteria"
                                getPopupContainer={() =>
                                    document.getElementById(
                                        "filters__dropdowns-holder"
                                    )
                                }
                                onChange={(values: any) => {
                                    setListingBrokerSelectedValues(values);
                                    const selectedBrokerIds = values.map(
                                        (value: any) => {
                                            const exists = listingBrokers.find(
                                                (item: any) => {
                                                    return item.label === value;
                                                }
                                            );
                                            if (exists) return exists.value;
                                        }
                                    );
                                    query.filters.setListingBroker(
                                        new Collection(selectedBrokerIds)
                                    );
                                }}
                                options={listingBrokers}
                                hotjarEventName="Listing Broker Dropdown"
                            />
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default BlockFilters;
