import TypeFloat from "../valueObject/TypeFloat";
import TypeString from "../valueObject/TypeString";
import PropertyFactory from "./PropertyFactory";
import PropertyGateway from "./PropertyGateway";
import PropertyRepository from "./PropertyRepository";
import PropertySearchQueryFactory from "./queries/PropertySearchQueryFactory";
import PropertySearchQueryRAMGateway from "./queries/PropertySearchQueryRAMGateway";

const SEARCH_DELAY_MS: number =
    process.env.REACT_APP_SEARCH_MAP_DELAY_MS !== undefined
        ? parseInt(process.env.REACT_APP_SEARCH_MAP_DELAY_MS)
        : 500;

export default class PropertyService {
    static _searchTimeout: any;
    static getSearchQuery() {
        if (new PropertySearchQueryRAMGateway().retrieve("all") === undefined) {
            const query = PropertySearchQueryFactory.make();
            query.assignApplyTrigger("ReloadSearch", () => {
                this.performSearch();
            });
            new PropertySearchQueryRAMGateway().persist("all", query);
        }
        return new PropertySearchQueryRAMGateway().retrieve("all");
    }
    static performSearch() {
        clearTimeout(this._searchTimeout);

        this._searchTimeout = setTimeout(() => {
            return new PropertyRepository(
                new PropertyGateway(),
                new PropertyFactory()
            ).search(this.getSearchQuery());
        }, SEARCH_DELAY_MS);
    }

    static getPropertyById(propertyId: TypeString) {
        return new PropertyRepository(
            new PropertyGateway(),
            new PropertyFactory()
        ).getById(propertyId);
    }

    static getStaticImage(lat: number, lng: number) {
        return new PropertyGateway().getStaticImageByLatLng(lat, lng);
    }

    static getPropertyByGeoData(
        street: TypeString,
        state: TypeString,
        county: TypeString,
        zip: TypeString,
        city: TypeString,
        latitude: TypeFloat,
        longitude: TypeFloat
    ) {
        return new PropertyRepository(
            new PropertyGateway(),
            new PropertyFactory()
        ).getByGeoData(street, state, county, zip, city, latitude, longitude);
    }

    static getMarkerTotalCount() {
        return new PropertyRepository(
            new PropertyGateway(),
            new PropertyFactory()
        ).getTotalCount();
    }
}
