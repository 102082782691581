import Collection from "../../valueObject/Collection";
import IFrameMessage from "../IFrameMessage";

export default class LenderCreditsMessage extends IFrameMessage {
    public static ACTION_NAME = "lender-credits";
    constructor() {
        super(LenderCreditsMessage.ACTION_NAME);
    }

    public send(data: Collection, iframe: HTMLIFrameElement) {
        super._send(data, iframe);
    }

    public recieve(callback: Function) {
        callback();
    }
}
