import ClosePopupsMessage from "./calculator/ClosePopupsMessage";
import NewCalculatorCreated from "./calculator/NewCalculatorCreatedMessage";
import OpenPopupMessage from "./calculator/OpenPopupMessage";
import PropertyIdMessage from "./calculator/PropertyIdMessage";
import SendMeATokenMessage from "./calculator/SendMeATokenMessage";
import ShareCalculatorMessage from "./calculator/ShareCalculatorMessage";
import BecomeMemberMessage from "./findLender/BecomeMemberMessage";
import FindLenderReadyMessage from "./findLender/FindLenderReadyMessage";
import LenderCreditsMessage from "./findLender/LenderCreditsMessage";
import OpenCTAFinancingMessage from "./findLender/OpenCTAFinancingMessage";
import OpenCTAMessage from "./findLender/OpenCTAMessage";
import OpenFlyoutMessage from "./findLender/OpenFlyoutMessage";
import SearchLendersMessage from "./findLender/SearchLendersMessage";
import SearchMapMessage from "./findLender/SearchMapMessage";

export default class IFrameMessageFactory {
    static reconstitute(messageData: any): any {
        switch (messageData.action) {
            case OpenCTAMessage.ACTION_NAME:
                return new OpenCTAMessage();
            case OpenCTAFinancingMessage.ACTION_NAME:
                return new OpenCTAFinancingMessage();
            case OpenFlyoutMessage.ACTION_NAME:
                return new OpenFlyoutMessage();
            case FindLenderReadyMessage.ACTION_NAME:
                return new FindLenderReadyMessage();
            case SearchLendersMessage.ACTION_NAME:
                return new SearchLendersMessage();
            case BecomeMemberMessage.ACTION_NAME:
                return new BecomeMemberMessage();
            case LenderCreditsMessage.ACTION_NAME:
                return new LenderCreditsMessage();
            case OpenPopupMessage.ACTION_NAME:
                return new OpenPopupMessage();
            case ClosePopupsMessage.ACTION_NAME:
                return new ClosePopupsMessage();
            case PropertyIdMessage.ACTION_NAME:
                return new PropertyIdMessage();
            case NewCalculatorCreated.ACTION_NAME:
                return new NewCalculatorCreated();
            case SendMeATokenMessage.ACTION_NAME:
                return new SendMeATokenMessage();
            case ShareCalculatorMessage.ACTION_NAME:
                return new ShareCalculatorMessage();
            case SearchMapMessage.ACTION_NAME:
                return new SearchMapMessage();
            default:
                return undefined;
        }
    }
}
