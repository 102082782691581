import Collection from "../../valueObject/Collection";
import TypeFloat from "../../valueObject/TypeFloat";
import TypeString from "../../valueObject/TypeString";
import LPInvestmentTransactionEntity from "./LPInvestmentTransactionEntity";

export default class LPInvestmentTransactionFactory {
    reconstitute(data: Collection) {
        const transaction = new LPInvestmentTransactionEntity();

        if (data.has("transactionType")) {
            transaction.setTransactionType(
                new TypeString(data.get("transactionType"))
            );
        }
        if (data.has("amount")) {
            transaction.setAmount(new TypeFloat(data.get("amount")));
        }
        if (data.has("note")) {
            transaction.setNote(new TypeString(data.get("note")));
        }
        if (data.has("sortKey")) {
            transaction.setSortKey(new TypeString(data.get("sortKey")));
        }

        if (data.has("transactionDate")) {
            transaction.setTransactionDate(
                new Date(data.get("transactionDate"))
            );
        }

        return transaction;
    }
}
