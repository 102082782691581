export default class PropertySearchQueryParameters {
    private static readonly PARAMETER_NAME_FILTER = "a";
    private static readonly PARAMETER_NAME_MAP_ZOOM_LEVEL = "b";
    private static readonly PARAMETER_NAME_MAP_BOTTOM_RIGHT_LATITUDE = "c";
    private static readonly PARAMETER_NAME_MAP_BOTTOM_RIGHT_LONGITUDE = "d";
    private static readonly PARAMETER_NAME_MAP_TOP_LEFT_LATITUDE = "e";
    private static readonly PARAMETER_NAME_MAP_TOP_LEFT_LONGITUDE = "f";
    private static readonly PARAMETER_NAME_FILTER_LISTINGS_CHECKED = "g";
    public static readonly PARAMETER_NAME_FILTER_SALES_COMPARABLES_CHECKED =
        "h";
    private static readonly PARAMETER_NAME_FILTER_FINANCE_COMPARABLES_CHECKED =
        "i";
    private static readonly PARAMETER_NAME_FILTER_PRICE_MIN = "j";
    private static readonly PARAMETER_NAME_FILTER_PRICE_MAX = "k";
    private static readonly PARAMETER_NAME_FILTER_LISTINGS_DATE_RANGE = "l";
    private static readonly PARAMETER_NAME_FILTER_SALES_COMPARABLES_DATE_RANGE =
        "m";
    private static readonly PARAMETER_NAME_FILTER_FINANCE_COMPARABLES_DATE_RANGE =
        "n";
    private static readonly PARAMETER_NAME_FILTER_LISTING_BROKERAGE = "o";
    private static readonly PARAMETER_NAME_FILTER_LISTING_BROKER = "p";
    private static readonly PARAMETER_NAME_FILTER_PROPERTY_TYPE = "q";

    private static readonly PARAMETER_NAME_LISTING_LIMIT = "r";

    private static readonly PARAMETER_NAME_SELECTABLE_ALL = "s";
    private static readonly PARAMETER_NAME_SELECTABLE_ITEMS = "t";

    private static readonly PARAMETER_NAME_MAP_LATITUDE = "u";
    private static readonly PARAMETER_NAME_MAP_LONGITUDE = "v";
    private static readonly PARAMETER_NAME_COMPS_LIMIT = "w";
    private static readonly PARAMETER_NAME_FAVORITES_ONLY = "x";

    private static readonly DEBUG_MODE = false;

    public static get filter() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "filter"
            : this.PARAMETER_NAME_FILTER;
    }
    public static get zoomLevel() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "zoomLevel"
            : this.PARAMETER_NAME_MAP_ZOOM_LEVEL;
    }
    public static get mapBottomRightLatitude() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "mapBottomRightLatitude"
            : this.PARAMETER_NAME_MAP_BOTTOM_RIGHT_LATITUDE;
    }
    public static get mapBottomRightLongitude() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "mapBottomRightLongitude"
            : this.PARAMETER_NAME_MAP_BOTTOM_RIGHT_LONGITUDE;
    }
    public static get mapTopLeftLatitude() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "mapTopLeftLatitude"
            : this.PARAMETER_NAME_MAP_TOP_LEFT_LATITUDE;
    }
    public static get mapTopLeftLongitude() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "mapTopLeftLongitude"
            : this.PARAMETER_NAME_MAP_TOP_LEFT_LONGITUDE;
    }
    public static get listingsChecked() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "listingsChecked"
            : this.PARAMETER_NAME_FILTER_LISTINGS_CHECKED;
    }
    public static get salesComparablesChecked() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "salesComparablesChecked"
            : this.PARAMETER_NAME_FILTER_SALES_COMPARABLES_CHECKED;
    }
    public static get financeComparablesChecked() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "financeComparablesChecked"
            : this.PARAMETER_NAME_FILTER_FINANCE_COMPARABLES_CHECKED;
    }
    public static get priceMin() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "priceMin"
            : this.PARAMETER_NAME_FILTER_PRICE_MIN;
    }
    public static get priceMax() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "priceMax"
            : this.PARAMETER_NAME_FILTER_PRICE_MAX;
    }
    public static get listingsDateRange() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "listingsDateRange"
            : this.PARAMETER_NAME_FILTER_LISTINGS_DATE_RANGE;
    }
    public static get salesComparablesDateRange() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "salesComparablesDateRange"
            : this.PARAMETER_NAME_FILTER_SALES_COMPARABLES_DATE_RANGE;
    }
    public static get financeComparablesDateRange() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "financeComparablesDateRange"
            : this.PARAMETER_NAME_FILTER_FINANCE_COMPARABLES_DATE_RANGE;
    }
    public static get listingBrokerage() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "listingBrokerage"
            : this.PARAMETER_NAME_FILTER_LISTING_BROKERAGE;
    }
    public static get listingBroker() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "listingBroker"
            : this.PARAMETER_NAME_FILTER_LISTING_BROKER;
    }
    public static get propertyType() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "propertyType"
            : this.PARAMETER_NAME_FILTER_PROPERTY_TYPE;
    }
    public static get listingLimit() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "listingLimit"
            : this.PARAMETER_NAME_LISTING_LIMIT;
    }

    public static get selectableAll() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "all"
            : this.PARAMETER_NAME_SELECTABLE_ALL;
    }

    public static get selectableItems() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "items"
            : this.PARAMETER_NAME_SELECTABLE_ITEMS;
    }

    public static get mapLatitude() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "lat"
            : this.PARAMETER_NAME_MAP_LATITUDE;
    }

    public static get mapLongitude() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "lng"
            : this.PARAMETER_NAME_MAP_LONGITUDE;
    }

    public static get compsLimit() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "compsLimit"
            : this.PARAMETER_NAME_COMPS_LIMIT;
    }

    public static get favoritesOnly() {
        return PropertySearchQueryParameters._isDebugMode()
            ? "favoritesOnly"
            : this.PARAMETER_NAME_FAVORITES_ONLY;
    }

    private static _isDebugMode() {
        return this.DEBUG_MODE;
    }
}
