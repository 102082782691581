import Collection from "../valueObject/Collection";
import PropertyDocumentEntity from "./PropertyDocumentEntity";

export default class PropertyDocumentViewMapper {
    static map(data: Collection) {
        if (data.isEmpty()) return [];
        return data.map((item: PropertyDocumentEntity) => {
            return {
                id: item.id.value,
                fileName: item.hasFileName() ? item.fileName.value : null,
                description: item.hasDescription()
                    ? item.description.value
                    : null,
                url: item.hasUrl() ? item.url.value : null,
                sizeKB: item.hasSizeKB() ? item.sizeKB.value : null
            };
        }).items;
    }
}
