import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";

export default class PropertyVendorEntity {
    _roleName: TypeString;
    _vendorContacts: Collection = new Collection([]);

    get roleName() {
        return this._roleName;
    }

    setRoleName(roleName: TypeString) {
        this._roleName = roleName;
        return this;
    }

    hasRoleName() {
        return this._roleName instanceof TypeString;
    }

    get vendorContacts() {
        return this._vendorContacts;
    }

    setVendorContacts(vendorContacts: Collection) {
        this._vendorContacts = vendorContacts;
        return this;
    }

    hasVendorContacts() {
        return this._vendorContacts instanceof Collection;
    }
}
