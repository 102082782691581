import { faLocationDot, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import OverwriteCalculatorMessage from "../../domain/iFrameMessage/calculator/OverwriteCalculatorMessage";
import TypeString from "../../domain/valueObject/TypeString";
import indexSliceState, { setOverwriteCalculatorPopup } from "../../store";
import PartButton from "../parts/PartButton";

const BlockOverwriteCalculations = ({ shareId }: { shareId: string }) => {
    const dispatch = useDispatch();
    const sliceState = useSelector(indexSliceState);
    const [searchParams, setSearchParams] = useSearchParams();

    const closePopup = () => {
        dispatch(
            setOverwriteCalculatorPopup({
                isOpen: false,
                calculatorShareId: undefined
            })
        );
        searchParams.delete("calculatorShareId");
        setSearchParams(searchParams);
    };

    return (
        <div
            className="calculator-mini-popup calculator-mini-popup--overwrite"
            onClick={(e) => {
                e.stopPropagation();
                closePopup();
            }}
        >
            <div
                className="calculator-mini-popup__box"
                onClick={(e) => e.stopPropagation()}
            >
                <span
                    className="calculator-mini-popup__close"
                    onClick={closePopup}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </span>
                <h2 className="calculator-mini-popup__box-title calculator-mini-popup__box-title--overwrite">
                    Overwrite Calculations
                </h2>
                <p className="calculator-mini-popup__box-text calculator-mini-popup__box-text--overwrite">
                    Warning: Importing calculations will overwrite any previous
                    calculations you have made for this property.
                </p>
                {sliceState.propertyCardItem?.data ? (
                    <span className="calculator-mini-popup__address">
                        <FontAwesomeIcon icon={faLocationDot} />
                        <span>
                            {sliceState.propertyCardItem.data.address.value}
                        </span>
                    </span>
                ) : (
                    <div className="calculator-mini-popup__skeleton"></div>
                )}
                <div className="calculator-mini-popup__footer">
                    <PartButton type="secondary" onClick={closePopup}>
                        Cancel
                    </PartButton>
                    <PartButton
                        type="primary"
                        onClick={() => {
                            new OverwriteCalculatorMessage().send(
                                sliceState.calculatorIframe,
                                new TypeString(shareId)
                            );
                            closePopup();
                        }}
                    >
                        Confirm
                    </PartButton>
                </div>
            </div>
        </div>
    );
};

export default BlockOverwriteCalculations;
