import BaseModel from "../BaseModel";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";

export default class StatsEntity extends BaseModel {
    _listingsCurrentCount: TypePositiveInteger;
    _financeComparablesCurrentCount: TypePositiveInteger;
    _salesComparablesCurrentCount: TypePositiveInteger;

    get listingsCurrentCount(): TypePositiveInteger {
        return this._listingsCurrentCount;
    }

    setListingsCurrentCount(listingsCurrentCount: TypePositiveInteger) {
        this._listingsCurrentCount = listingsCurrentCount;
        return this;
    }

    get financeComparablesCurrentCount(): TypePositiveInteger {
        return this._financeComparablesCurrentCount;
    }

    setFinanceComparablesCurrentCount(
        financeComparablesCurrentCount: TypePositiveInteger
    ) {
        this._financeComparablesCurrentCount = financeComparablesCurrentCount;
        return this;
    }

    get salesComparablesCurrentCount(): TypePositiveInteger {
        return this._salesComparablesCurrentCount;
    }

    setSalesComparablesCurrentCount(
        salesComparablesCurrentCount: TypePositiveInteger
    ) {
        this._salesComparablesCurrentCount = salesComparablesCurrentCount;
        return this;
    }
}
