export default class URLQueryParameters {
    private static readonly PARAMETER_NAME_MAP_ZOOM_LEVEL = "b";
    private static readonly PARAMETER_NAME_FILTER_LISTINGS_CHECKED = "g";
    public static readonly PARAMETER_NAME_FILTER_SALES_COMPARABLES_CHECKED =
        "h";
    private static readonly PARAMETER_NAME_FILTER_FINANCE_COMPARABLES_CHECKED =
        "i";
    private static readonly PARAMETER_NAME_FILTER_PRICE_MIN = "j";
    private static readonly PARAMETER_NAME_FILTER_PRICE_MAX = "k";
    private static readonly PARAMETER_NAME_FILTER_LISTINGS_DATE_RANGE = "l";
    private static readonly PARAMETER_NAME_FILTER_SALES_COMPARABLES_DATE_RANGE =
        "m";
    private static readonly PARAMETER_NAME_FILTER_FINANCE_COMPARABLES_DATE_RANGE =
        "n";
    private static readonly PARAMETER_NAME_FILTER_LISTING_BROKERAGE = "o";
    private static readonly PARAMETER_NAME_FILTER_LISTING_BROKER = "p";
    private static readonly PARAMETER_NAME_FILTER_PROPERTY_TYPE = "q";
    private static readonly PARAMETER_NAME_MAP_LATITUDE = "u";
    private static readonly PARAMETER_NAME_MAP_LONGITUDE = "v";
    private static readonly PARAMETER_NAME_PROPERTY_ID = "w";

    private static readonly FULL_PARAMETER_NAMES = true;

    public static get zoomLevel() {
        return URLQueryParameters._isFullParameterName()
            ? "zoomLevel"
            : this.PARAMETER_NAME_MAP_ZOOM_LEVEL;
    }

    public static get listingsChecked() {
        return URLQueryParameters._isFullParameterName()
            ? "listingsChecked"
            : this.PARAMETER_NAME_FILTER_LISTINGS_CHECKED;
    }
    public static get salesComparablesChecked() {
        return URLQueryParameters._isFullParameterName()
            ? "salesComparablesChecked"
            : this.PARAMETER_NAME_FILTER_SALES_COMPARABLES_CHECKED;
    }
    public static get financeComparablesChecked() {
        return URLQueryParameters._isFullParameterName()
            ? "financeComparablesChecked"
            : this.PARAMETER_NAME_FILTER_FINANCE_COMPARABLES_CHECKED;
    }
    public static get priceMin() {
        return URLQueryParameters._isFullParameterName()
            ? "priceMin"
            : this.PARAMETER_NAME_FILTER_PRICE_MIN;
    }
    public static get priceMax() {
        return URLQueryParameters._isFullParameterName()
            ? "priceMax"
            : this.PARAMETER_NAME_FILTER_PRICE_MAX;
    }
    public static get listingsDateRange() {
        return URLQueryParameters._isFullParameterName()
            ? "listingsDateRange"
            : this.PARAMETER_NAME_FILTER_LISTINGS_DATE_RANGE;
    }
    public static get salesComparablesDateRange() {
        return URLQueryParameters._isFullParameterName()
            ? "salesComparablesDateRange"
            : this.PARAMETER_NAME_FILTER_SALES_COMPARABLES_DATE_RANGE;
    }
    public static get financeComparablesDateRange() {
        return URLQueryParameters._isFullParameterName()
            ? "financeComparablesDateRange"
            : this.PARAMETER_NAME_FILTER_FINANCE_COMPARABLES_DATE_RANGE;
    }
    public static get listingBrokerage() {
        return URLQueryParameters._isFullParameterName()
            ? "listingBrokerage"
            : this.PARAMETER_NAME_FILTER_LISTING_BROKERAGE;
    }
    public static get listingBroker() {
        return URLQueryParameters._isFullParameterName()
            ? "listingBroker"
            : this.PARAMETER_NAME_FILTER_LISTING_BROKER;
    }
    public static get propertyType() {
        return URLQueryParameters._isFullParameterName()
            ? "propertyType"
            : this.PARAMETER_NAME_FILTER_PROPERTY_TYPE;
    }

    public static get mapLatitude() {
        return URLQueryParameters._isFullParameterName()
            ? "lat"
            : this.PARAMETER_NAME_MAP_LATITUDE;
    }

    public static get mapLongitude() {
        return URLQueryParameters._isFullParameterName()
            ? "lng"
            : this.PARAMETER_NAME_MAP_LONGITUDE;
    }

    public static get propertyId() {
        return URLQueryParameters._isFullParameterName()
            ? "propertyId"
            : this.PARAMETER_NAME_PROPERTY_ID;
    }

    public static get findALenderState() {
        return "flState";
    }
    public static get findALenderPropertyType() {
        return "flPropertyType";
    }
    public static get findALenderLoanType() {
        return "flLoanType";
    }

    public static get findALenderLoanAmount() {
        return "flLoanAmount";
    }
    public static get findALenderExecutionTypes() {
        return "flExecTypes";
    }
    public static get findALenderPublicLenders() {
        return "flPublicLenders";
    }
    private static _isFullParameterName() {
        return this.FULL_PARAMETER_NAMES;
    }
}
