import BrokerageFactory from "./BrokerageFactory";
import BrokerageGateway from "./BrokerageGateway";
import BrokerageRepository from "./BrokerageRepository";

export default class BrokerageService {
    static getAll() {
        return new BrokerageRepository(
            new BrokerageGateway(),
            new BrokerageFactory()
        ).getAll();
    }
}
