import FavoriteAdditionalDataFactory from "../favoriteAdditionalData/FavoriteAdditionalDataFactory";
import FavoriteFilterFactory from "../favoriteFilter/FavoriteFilterFactory";
import Collection from "../valueObject/Collection";
import FavoritePropertyFactory from "./FavoritePropertyFactory";

export default class FavoritePropertyRepository {
    _gateway: any;
    _factory: any;

    constructor(gateway: any, factory: any) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async getFavoriteProperties(queryData?: any) {
        const data = new Collection(
            await this._gateway.getFavoriteProperties(queryData)
        );

        let items = new Collection([]);

        items = data.slice("responseData").map((itemData: any) => {
            return new FavoritePropertyFactory().reconstitute(
                new Collection(itemData)
            );
        });

        const filters = new FavoriteFilterFactory().reconstitute(
            new Collection(data.get("filters"))
        );

        const additionalData = new FavoriteAdditionalDataFactory().reconstitute(
            new Collection(data.get("additionalData"))
        );

        const favoriteData = {
            items: items,
            filters: filters,
            additionalData: additionalData
        };

        return favoriteData;
    }
}
