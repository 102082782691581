import LoanType from "../loan/valueObject/LoanType";
import OriginatorFactory from "../originator/OriginatorFactory";
import Collection from "../valueObject/Collection";
import TypeBoolean from "../valueObject/TypeBoolean";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import LenderEntity from "./LenderEntity";
import ExecutionType from "./valueObject/ExecutionType";

export default class LenderFactory {
    reconstitute(data: Collection) {
        const lenderInstance = new LenderEntity();

        if (data.has("id"))
            lenderInstance.setId(new TypePositiveInteger(data.get("id")));
        if (data.has("name"))
            lenderInstance.setName(new TypeString(data.get("name")));
        if (data.has("paid"))
            lenderInstance.setIsPaid(new TypeBoolean(data.get("paid")));
        if (data.has("rank"))
            lenderInstance.setRank(new TypePositiveInteger(data.get("rank")));
        if (data.has("isPublicLender"))
            lenderInstance.setIsPublicLender(
                new TypeBoolean(data.get("isPublicLender"))
            );
        if (data.has("executionTypes")) {
            lenderInstance.setExecutionTypes(
                new Collection(data.get("executionTypes")).map(
                    (executionType: any) => {
                        return new ExecutionType(executionType);
                    }
                )
            );
        }
        if (data.has("states")) {
            lenderInstance.setStates(
                new Collection(data.get("states")).map((state: any) => {
                    return new TypeString(state);
                })
            );
        }
        if (data.has("propertyTypes")) {
            lenderInstance.setPropertyTypes(
                new Collection(data.get("propertyTypes")).map(
                    (propertyType: any) => {
                        return new TypeString(propertyType);
                    }
                )
            );
        }
        if (data.has("loanTypes")) {
            lenderInstance.setLoanTypes(
                new Collection(data.get("loanTypes")).map((loanType: any) => {
                    return new LoanType(loanType);
                })
            );
        }
        if (data.has("originators")) {
            lenderInstance.setOriginators(
                new Collection(data.get("originators")).map(
                    (originator: any) => {
                        return new OriginatorFactory().reconstitute(
                            new Collection(originator)
                        );
                    }
                )
            );
        }
        if (data.has("otherOriginators")) {
            lenderInstance.setOtherOriginators(
                new Collection(data.get("otherOriginators")).map(
                    (otherOriginator: any) => {
                        return new OriginatorFactory().reconstitute(
                            new Collection(otherOriginator)
                        );
                    }
                )
            );
        }

        return lenderInstance;
    }
}
