import Collection from "../../valueObject/Collection";
import IFrameMessage from "../IFrameMessage";

export default class OpenCTAFinancingMessage extends IFrameMessage {
    public static ACTION_NAME = "open-cta-financing";
    constructor() {
        super(OpenCTAFinancingMessage.ACTION_NAME);
    }

    public send() {
        super._send(new Collection({}));
    }

    public recieve(callback: Function) {
        callback();
    }
}
