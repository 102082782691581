import Collection from "../../valueObject/Collection";
import IFrameMessage from "../IFrameMessage";

export default class FindLenderReadyMessage extends IFrameMessage {
    public static ACTION_NAME = "find-lender-ready";
    constructor() {
        super(FindLenderReadyMessage.ACTION_NAME);
    }

    public send(data: Collection, iframe: HTMLIFrameElement) {
        super._send(data, iframe);
    }

    public recieve(callback: Function) {
        callback();
    }
}
