import TypeString from "../../valueObject/TypeString";
import RepresenterEntity from "../RepresenterEntity";

export default class VendorEntity extends RepresenterEntity {
    _userId: TypeString;
    _firstName: TypeString;
    _lastName: TypeString;
    _vendorRole: TypeString;
    constructor() {
        super();
    }

    get userId(): TypeString {
        return this._userId;
    }

    setUserId(userId: TypeString) {
        this._userId = userId;
        return this;
    }

    hasUserId(): boolean {
        return this._userId instanceof TypeString;
    }

    get firstName(): TypeString {
        return this._firstName;
    }

    setFirstName(firstName: TypeString) {
        this._firstName = firstName;
        return this;
    }

    hasFirstName(): boolean {
        return this._firstName instanceof TypeString;
    }

    get lastName(): TypeString {
        return this._lastName;
    }

    setLastName(lastName: TypeString) {
        this._lastName = lastName;
        return this;
    }

    hasLastName(): boolean {
        return this._lastName instanceof TypeString;
    }

    get vendorRole(): TypeString {
        return this._vendorRole;
    }

    setVendorRole(vendorRole: TypeString) {
        this._vendorRole = vendorRole;
        return this;
    }

    hasVendorRole(): boolean {
        return this._vendorRole instanceof TypeString;
    }
}
