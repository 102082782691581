import Exception from "../../../utils/exceptions/Exception";
import Collection from "../../valueObject/Collection";

export default class ExecutionType {
    static readonly TYPE_CMBS_ID = 2;
    static readonly TYPE_CMBS_NAME = "CMBS";
    static readonly TYPE_CMBS_ENUM = "CMBS";
    static readonly TYPE_HARD_MONEY_ID = 3;
    static readonly TYPE_HARD_MONEY_NAME = "Hard Money";
    static readonly TYPE_HARD_MONEY_ENUM = "HARD_MONEY";
    static readonly TYPE_AGENCY_CONVENTIONAL_ID = 6;
    static readonly TYPE_AGENCY_CONVENTIONAL_NAME = "Agency Conventional";
    static readonly TYPE_AGENCY_CONVENTIONAL_ENUM = "AGENCY_CONVENTIONAL";
    static readonly TYPE_LIFE_COMPANY_ID = 7;
    static readonly TYPE_LIFE_COMPANY_NAME = "Life Company";
    static readonly TYPE_LIFE_COMPANY_ENUM = "LIFE_COMPANY";
    static readonly TYPE_HUD_ID = 13;
    static readonly TYPE_HUD_NAME = "HUD";
    static readonly TYPE_HUD_ENUM = "HUD";
    static readonly TYPE_BALANCE_SHEET_ID = 16;
    static readonly TYPE_BALANCE_SHEET_NAME = "Balance Sheet";
    static readonly TYPE_BALANCE_SHEET_ENUM = "BALANCE_SHEET";
    static readonly TYPE_AGENCY_SBL_ID = 17;
    static readonly TYPE_AGENCY_SBL_NAME = "Agency SBL";
    static readonly TYPE_AGENCY_SBL_ENUM = "AGENCY_SBL";
    static readonly TYPE_DEBT_FUND_ID = 19;
    static readonly TYPE_DEBT_FUND_NAME = "Debt Fund";
    static readonly TYPE_DEBT_FUND_ENUM = "DEBT_FUND";
    static readonly TYPE_SBA_ID = 20;
    static readonly TYPE_SBA_NAME = "SBA";
    static readonly TYPE_SBA_ENUM = "SBA";
    static readonly TYPE_BRIDGE_TO_AGENCY_ID = 21;
    static readonly TYPE_BRIDGE_TO_AGENCY_NAME = "Bridge to Agency";
    static readonly TYPE_BRIDGE_TO_AGENCY_ENUM = "BRIDGE_TO_AGENCY";
    static readonly TYPE_BRIDGE_TO_CMBS_ID = 22;
    static readonly TYPE_BRIDGE_TO_CMBS_NAME = "Bridge to CMBS";
    static readonly TYPE_BRIDGE_TO_CMBS_ENUM = "BRIDGE_TO_CMBS";
    static readonly TYPE_BRIDGE_TO_HUD_ID = 23;
    static readonly TYPE_BRIDGE_TO_HUD_NAME = "Bridge to HUD";
    static readonly TYPE_BRIDGE_TO_HUD_ENUM = "BRIDGE_TO_HUD";
    static readonly TYPE_MEZZANINE_ID = 9;
    static readonly TYPE_MEZZANINE_NAME = "Mezzanine";
    static readonly TYPE_MEZZANINE_ENUM = "MEZZANINE";

    private _value: number | string;

    constructor(value: number | string) {
        if (Number.isNaN(+value) && typeof value === "string") {
            this._value = ExecutionType.getAvailableTypeNames().get(
                ExecutionType.getAvailableTypeEnums().findKey(
                    (item: any) => item === value
                )
            );
        } else if (!Number.isNaN(+value)) {
            this._value = +value;
        }

        this.validateValue();
    }

    get value(): number | string {
        return this._value;
    }

    get name(): string {
        return ExecutionType.getAvailableTypeNames().get(
            ExecutionType.getAvailableTypes().findKey(
                (item: any) => item === this._value
            )
        );
    }

    public static getAvailableTypes(): Collection {
        return new Collection([
            ExecutionType.TYPE_CMBS_ID,
            ExecutionType.TYPE_HARD_MONEY_ID,
            ExecutionType.TYPE_AGENCY_CONVENTIONAL_ID,
            ExecutionType.TYPE_LIFE_COMPANY_ID,
            ExecutionType.TYPE_HUD_ID,
            ExecutionType.TYPE_BALANCE_SHEET_ID,
            ExecutionType.TYPE_AGENCY_SBL_ID,
            ExecutionType.TYPE_DEBT_FUND_ID,
            ExecutionType.TYPE_SBA_ID,
            ExecutionType.TYPE_BRIDGE_TO_AGENCY_ID,
            ExecutionType.TYPE_BRIDGE_TO_CMBS_ID,
            ExecutionType.TYPE_BRIDGE_TO_HUD_ID,
            ExecutionType.TYPE_MEZZANINE_ENUM
        ]);
    }

    public static getAvailableTypeNames(): Collection {
        return new Collection([
            ExecutionType.TYPE_CMBS_NAME,
            ExecutionType.TYPE_HARD_MONEY_NAME,
            ExecutionType.TYPE_AGENCY_CONVENTIONAL_NAME,
            ExecutionType.TYPE_LIFE_COMPANY_NAME,
            ExecutionType.TYPE_HUD_NAME,
            ExecutionType.TYPE_BALANCE_SHEET_NAME,
            ExecutionType.TYPE_AGENCY_SBL_NAME,
            ExecutionType.TYPE_DEBT_FUND_NAME,
            ExecutionType.TYPE_SBA_NAME,
            ExecutionType.TYPE_BRIDGE_TO_AGENCY_NAME,
            ExecutionType.TYPE_BRIDGE_TO_CMBS_NAME,
            ExecutionType.TYPE_BRIDGE_TO_HUD_NAME,
            ExecutionType.TYPE_MEZZANINE_NAME
        ]);
    }

    public static getAvailableTypeEnums(): Collection {
        return new Collection([
            ExecutionType.TYPE_CMBS_ENUM,
            ExecutionType.TYPE_HARD_MONEY_ENUM,
            ExecutionType.TYPE_AGENCY_CONVENTIONAL_ENUM,
            ExecutionType.TYPE_LIFE_COMPANY_ENUM,
            ExecutionType.TYPE_HUD_ENUM,
            ExecutionType.TYPE_BALANCE_SHEET_ENUM,
            ExecutionType.TYPE_AGENCY_SBL_ENUM,
            ExecutionType.TYPE_DEBT_FUND_ENUM,
            ExecutionType.TYPE_SBA_ENUM,
            ExecutionType.TYPE_BRIDGE_TO_AGENCY_ENUM,
            ExecutionType.TYPE_BRIDGE_TO_CMBS_ENUM,
            ExecutionType.TYPE_BRIDGE_TO_HUD_ENUM,
            ExecutionType.TYPE_MEZZANINE_ENUM
        ]);
    }

    private validateValue(): void {
        if (!ExecutionType.getAvailableTypeNames().contains(this._value)) {
            throw new Exception("INVALID_EXECUTION_TYPE", 500);
        }
    }
}
