import TypeBoolean from "../../valueObject/TypeBoolean";
import TypeFloat from "../../valueObject/TypeFloat";
import TypePositiveInteger from "../../valueObject/TypePositiveInteger";
import TypeString from "../../valueObject/TypeString";
import EntityType from "../valueObject/EntityType";

export default class PropertyMarkerEntity {
    _id: TypeString;
    _entityType: EntityType;
    _latitude: TypeFloat;
    _longitude: TypeFloat;
    _price: TypePositiveInteger;
    _userCanSeePrice: TypeBoolean;
    _isFavorite: TypeBoolean;
    _lenderName: TypeString | undefined;
    _address: TypeString | undefined;
    _propertyType: TypeString | undefined;
    _boundaryPoints: Array<any> | undefined;

    constructor(
        id: TypeString,
        entityType: EntityType,
        latitude: TypeFloat,
        longitude: TypeFloat
    ) {
        this._id = id;
        this._entityType = entityType;
        this._latitude = latitude;
        this._longitude = longitude;
    }

    get id(): TypeString {
        return this._id;
    }

    get entityType(): EntityType {
        return this._entityType;
    }

    setEntityType(entityType: EntityType) {
        this._entityType = entityType;
        return this;
    }

    get latitude(): TypeFloat {
        return this._latitude;
    }

    setLatitude(latitude: TypeFloat) {
        this._latitude = latitude;
        return this;
    }

    get longitude(): TypeFloat {
        return this._longitude;
    }

    setLongitude(longitude: TypeFloat) {
        this._longitude = longitude;
        return this;
    }

    get price(): TypePositiveInteger {
        return this._price;
    }

    setPrice(price: TypePositiveInteger) {
        this._price = price;
        return this;
    }

    get userCanSeePrice(): TypeBoolean {
        return this._userCanSeePrice;
    }

    setUserCanSeePrice(userCanSeePrice: TypeBoolean) {
        this._userCanSeePrice = userCanSeePrice;
        return this;
    }

    get isFavorite(): TypeBoolean {
        return this._isFavorite;
    }
    setIsFavorite(isFavorite: TypeBoolean) {
        this._isFavorite = isFavorite;
        return this;
    }
    hasIsFavorite(): boolean {
        return this._isFavorite instanceof TypeBoolean;
    }

    get lenderName(): TypeString | undefined {
        return this._lenderName;
    }

    setLenderName(lenderName: TypeString | undefined) {
        this._lenderName = lenderName;
        return this;
    }

    hasLenderName(): boolean {
        return this._lenderName instanceof TypeString;
    }

    get address(): TypeString | undefined {
        return this._address;
    }

    setAddress(address: TypeString | undefined) {
        this._address = address;
        return this;
    }

    hasAddress(): boolean {
        return this._address instanceof TypeString;
    }

    get propertyType(): TypeString | undefined {
        return this._propertyType;
    }

    setPropertyType(propertyType: TypeString | undefined) {
        this._propertyType = propertyType;
        return this;
    }

    hasBoundaryPoints(): boolean {
        return this._boundaryPoints instanceof Array;
    }

    get boundaryPoints(): Array<any> | undefined {
        return this._boundaryPoints;
    }

    setBoundaryPoints(boundaryPoints: Array<any> | undefined) {
        this._boundaryPoints = boundaryPoints;
        return this;
    }
}
