import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";

export default class StateEntity {
    _id: TypePositiveInteger;
    _state: TypeString;
    _abbreviation: TypeString;

    get id(): TypePositiveInteger {
        return this._id;
    }

    setId(value: TypePositiveInteger) {
        this._id = value;
        return this;
    }

    hasId(): boolean {
        return this._id instanceof TypePositiveInteger;
    }

    get state(): TypeString {
        return this._state;
    }

    setState(value: TypeString) {
        this._state = value;
        return this;
    }

    hasState(): boolean {
        return this._state instanceof TypeString;
    }

    get abbreviation(): TypeString {
        return this._abbreviation;
    }

    setAbbreviation(value: TypeString) {
        this._abbreviation = value;
        return this;
    }

    hasAbbreviation(): boolean {
        return this._abbreviation instanceof TypeString;
    }
}
