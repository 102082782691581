/* eslint-disable prettier/prettier */
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, { setIframe } from "../../store";
import { useAuth0 } from "@auth0/auth0-react";

const BlockGetFinancingPopup = () => {
    const dispatch = useDispatch();
    const sliceState = useSelector(indexSliceState);
    let { user } = useAuth0();
    const baseUrl = process.env.REACT_APP_CRM_CTA_URL;
    user = { ...user, phone: user?.["https://auth.gparency.com/phone"] };

    return (
        <>
            <div className="overlay"></div>
            <div className="popup-financing__wrap">
                <div className={`popup popup--financing`}>
                    <span className="popup__top-line"></span>
                    <div className="popup__inner">
                        <span
                            className="popup__close"
                            onClick={() => dispatch(setIframe(false))}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </span>
                        <iframe
                            id="popupMember"
                            src={`${baseUrl}/?type=finance&token=${encodeURIComponent(
                                sliceState.token
                            )}&where-from=${encodeURIComponent(
                                sliceState.iframe.whereFrom
                            )}&family_name=${encodeURIComponent(
                                user?.family_name === undefined
                                    ? ""
                                    : user.family_name
                            )}&given_name=${encodeURIComponent(
                                user?.given_name === undefined
                                    ? ""
                                    : user.given_name
                            )}&phone=${encodeURIComponent(user?.phone)}&sub=${
                                user?.sub
                            }`}
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlockGetFinancingPopup;
