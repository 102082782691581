import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import NoteEntity from "./NoteEntity";

export default class NoteFactory {
    reconstitute(data: Collection) {
        const note = new NoteEntity();
        if (data.has("sortKey"))
            note.setId(new TypeString(data.get("sortKey")));
        if (data.has("note")) note.setContent(new TypeString(data.get("note")));
        if (data.has("createdEpoch"))
            note.setCreatedAt(new Date(data.get("createdEpoch") / 1000));

        return note;
    }
}
