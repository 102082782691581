const IconTwitter = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                d="M10.0634 29.0009C22.1389 29.0009 28.7437 18.9964 28.7437 10.3206C28.7437 10.0364 28.7437 9.75354 28.7245 9.47194C30.0094 8.54255 31.1185 7.39179 32 6.07354C30.8019 6.60474 29.5307 6.95295 28.2291 7.1065C29.5998 6.28609 30.6255 4.99555 31.1155 3.47514C29.8268 4.23995 28.4168 4.77891 26.9466 5.06874C25.9567 4.01618 24.6475 3.3192 23.2216 3.08565C21.7957 2.85211 20.3326 3.09503 19.0586 3.77681C17.7847 4.4586 16.771 5.54124 16.1743 6.85721C15.5777 8.17317 15.4314 9.64909 15.7581 11.0566C13.1479 10.9256 10.5943 10.2473 8.26327 9.06555C5.9322 7.88381 3.87569 6.22509 2.2272 4.19706C1.38764 5.64239 1.1305 7.35337 1.50813 8.98162C1.88577 10.6099 2.86979 12.033 4.25984 12.9612C3.21498 12.9306 2.19286 12.6487 1.28 12.1395V12.2227C1.28041 13.7385 1.80513 15.2075 2.76516 16.3805C3.72519 17.5535 5.06141 18.3584 6.5472 18.6585C5.58064 18.9222 4.5665 18.9607 3.58272 18.7711C4.00242 20.0756 4.81924 21.2163 5.91899 22.0337C7.01873 22.8512 8.34644 23.3046 9.71648 23.3305C8.35525 24.4005 6.79642 25.1916 5.12917 25.6585C3.46191 26.1254 1.71895 26.2591 0 26.0518C3.00244 27.9785 6.4959 29.0005 10.0634 28.9958"
                fill="#1DA1F2"
            />
        </svg>
    );
};

export default IconTwitter;
