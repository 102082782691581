import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";

export default abstract class RepresenterEntity {
    _id: TypePositiveInteger;
    _name: TypeString;
    _email: TypeString;
    _phone: TypeString;
    _landline: TypeString;
    _mobile: TypeString;

    get id(): TypePositiveInteger {
        return this._id;
    }

    setId(id: TypePositiveInteger) {
        this._id = id;
        return this;
    }

    hasId(): boolean {
        return this._id instanceof TypePositiveInteger;
    }

    get name(): TypeString {
        return this._name;
    }

    setName(name: TypeString) {
        this._name = name;
        return this;
    }

    hasName(): boolean {
        return this._name instanceof TypeString;
    }

    get email(): TypeString {
        return this._email;
    }

    setEmail(email: TypeString) {
        this._email = email;
        return this;
    }

    hasEmail(): boolean {
        return this._email instanceof TypeString;
    }
    get phone(): TypeString {
        return this._phone;
    }

    setPhone(phone: TypeString) {
        this._phone = phone;
        return this;
    }
    hasPhone(): boolean {
        return this._phone instanceof TypeString;
    }
    public get landline(): TypeString {
        return this._landline;
    }
    public setLandline(value: TypeString) {
        this._landline = value;
        return this;
    }
    public hasLandline(): boolean {
        return this._landline instanceof TypeString;
    }
    public get mobile(): TypeString {
        return this._mobile;
    }
    public setMobile(value: TypeString) {
        this._mobile = value;
        return this;
    }
    public hasMobile(): boolean {
        return this._mobile instanceof TypeString;
    }
}
