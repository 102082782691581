import { Checkbox } from "antd";
import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";

interface PartCheckboxProps {
    name: string;
    control?: any;
    errors?: any;
    formState?: any;
    onChange?: any;
    options?: any;
    hotjarEventName?: string;
    showingAll?: boolean;
    limitCount?: number;
}

const PartCheckboxGroup = ({
    name,
    control,
    errors,
    formState,
    options,
    onChange,
    showingAll = false,
    limitCount
}: PartCheckboxProps) => {
    const getClassnameIfDirty = () => {
        return formState.dirtyFields[name] ? ` input-dirty` : "";
    };

    return (
        <div
            className={
                `form-element checkbox-group ${name in errors ? "error" : ""}` +
                `${formState ? getClassnameIfDirty() : ""}
                `
            }
        >
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <Checkbox.Group
                            {...field}
                            options={options}
                            disabled={showingAll}
                            onChange={(value) => {
                                if (limitCount && value.length <= limitCount) {
                                    field.onChange(value);
                                    onChange(value);
                                } else if (!limitCount) {
                                    field.onChange(value);
                                    onChange(value);
                                }
                            }}
                        />
                    );
                }}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                    <p className="form-element__error-msg">{message}</p>
                )}
            />
        </div>
    );
};

export default PartCheckboxGroup;
