import Collection from "../../valueObject/Collection";
import TypeString from "../../valueObject/TypeString";
import IFrameMessage from "../IFrameMessage";

export default class ShareCalculatorMessage extends IFrameMessage {
    public static ACTION_NAME = "share-calculator";

    constructor() {
        super(ShareCalculatorMessage.ACTION_NAME);
    }

    public send(shareId: TypeString, propertyId: TypeString) {
        super._send(
            new Collection({
                shareId: shareId.value,
                propertyId: propertyId.value
            })
        );
    }

    public recieve(callback: Function, data: Collection) {
        callback(data.get("shareId"), data.get("propertyId"));
    }
}
