import TypeBoolean from "../valueObject/TypeBoolean";
import TypeFloat from "../valueObject/TypeFloat";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import TypeTimestamp from "../valueObject/TypeTimestamp";

export default class FinanceHistoryEntity {
    _id: TypeString;
    _mortgageDate: TypeTimestamp;
    _maturityDate: TypeTimestamp;

    _isPurchase: TypeBoolean;
    _mortgagePaidOff: TypeBoolean;
    _blanketMortgage: TypeBoolean;
    _numberOfOpenLoans: TypePositiveInteger;
    _openLoanAmount: TypeFloat;
    _lender: TypeString;
    _loanAmount: TypeFloat;

    get id(): TypeString {
        return this._id;
    }

    setId(value: TypeString) {
        this._id = value;
        return this;
    }

    hasId(): boolean {
        return this._id instanceof TypeString;
    }

    get mortgageDate(): TypeTimestamp {
        return this._mortgageDate;
    }

    setMortgageDate(mortgageDate: TypeTimestamp) {
        this._mortgageDate = mortgageDate;
        return this;
    }

    hasMortgageDate(): boolean {
        return this._mortgageDate instanceof TypeTimestamp;
    }

    get maturityDate(): TypeTimestamp {
        return this._maturityDate;
    }

    setMaturityDate(maturityDate: TypeTimestamp) {
        this._maturityDate = maturityDate;
        return this;
    }

    hasMaturityDate(): boolean {
        return this._maturityDate instanceof TypeTimestamp;
    }

    get isPurchase(): TypeBoolean {
        return this._isPurchase;
    }

    setIsPurchase(value: TypeBoolean) {
        this._isPurchase = value;
        return this;
    }

    hasIsPurchase(): boolean {
        return this._isPurchase instanceof TypeBoolean;
    }

    get mortgagePaidOff(): TypeBoolean {
        return this._mortgagePaidOff;
    }

    setMortgagePaidOff(value: TypeBoolean) {
        this._mortgagePaidOff = value;
        return this;
    }

    hasMortgagePaidOff(): boolean {
        return this._mortgagePaidOff instanceof TypeBoolean;
    }

    get blanketMortgage(): TypeBoolean {
        return this._blanketMortgage;
    }

    setBlanketMortgage(value: TypeBoolean) {
        this._blanketMortgage = value;
        return this;
    }

    hasBlanketMortgage(): boolean {
        return this._blanketMortgage instanceof TypeBoolean;
    }

    get numberOfOpenLoans(): TypePositiveInteger {
        return this._numberOfOpenLoans;
    }

    setNumberOfOpenLoans(value: TypePositiveInteger) {
        this._numberOfOpenLoans = value;
        return this;
    }

    hasNumberOfOpenLoans(): boolean {
        return this._numberOfOpenLoans instanceof TypePositiveInteger;
    }

    get openLoanAmount(): TypeFloat {
        return this._openLoanAmount;
    }

    setOpenLoanAmount(value: TypeFloat) {
        this._openLoanAmount = value;
        return this;
    }

    hasOpenLoanAmount(): boolean {
        return this._openLoanAmount instanceof TypeFloat;
    }

    get lender(): TypeString {
        return this._lender;
    }

    setLender(value: TypeString) {
        this._lender = value;
        return this;
    }

    hasLender(): boolean {
        return this._lender instanceof TypeString;
    }

    get loanAmount(): TypeFloat {
        return this._loanAmount;
    }

    setLoanAmount(value: TypeFloat) {
        this._loanAmount = value;
        return this;
    }

    hasLoanAmount(): boolean {
        return this._loanAmount instanceof TypeFloat;
    }

}
