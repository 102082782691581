import Collection from "../../valueObject/Collection";
import IFrameMessage from "../IFrameMessage";

export default class SearchMapMessage extends IFrameMessage {
    public static ACTION_NAME = "search-map";
    constructor() {
        super(SearchMapMessage.ACTION_NAME);
    }

    public send(data: Collection, iframe: HTMLIFrameElement) {
        super._send(data, iframe);
    }

    public recieve(callback: Function) {
        callback();
    }
}
