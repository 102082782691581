import LenderFactory from "./LenderFactory";
import LenderGateway from "./LenderGateway";
import LenderRepository from "./LenderRepository";
import LenderFilterQuery from "./queries/LenderFilterQuery";
import LenderFilterQueryFactory from "./queries/LenderFilterQueryFactory";
import LenderFilterQueryRAMGateway from "./queries/LenderFilterQueryRAMGateway";

export default class LenderService {
    static search() {
        return new LenderRepository(
            new LenderGateway(),
            new LenderFactory()
        ).search(this.getLenderFilterQuery());
    }

    static getLenderFilterQuery(): LenderFilterQuery {
        if (new LenderFilterQueryRAMGateway().retrieve("all") === undefined) {
            const query = LenderFilterQueryFactory.make();

            query.assignApplyTrigger("FindALenderSearch", () => {
                this.search();
            });

            new LenderFilterQueryRAMGateway().persist("all", query);
        }

        return new LenderFilterQueryRAMGateway().retrieve("all");
    }
}
