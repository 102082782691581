import moment from "moment";
import Collection from "../valueObject/Collection";
import LPInvestmentEntity from "./LPInvestmentEntity";
import LPInvestmentTransaction from "./transaction/LPInvestmentTransactionEntity";

export type LPInvestmentData = {
    finalDistributionEstimate: number;
    distributionTargetAmount: string;
    nextDistributionDate: string;
    distributionScheduleType: string;
    distributionTrackAlert: number;
    opportunitiesSubscription: boolean;
    transactions: Array<LPInvestmentTransactions>;
};

export type LPInvestmentTransactions = {
    transactionType: string;
    amount: string;
    note: string;
    date: string;
    sortKey: string;
};

export default class LPInvestmentViewMapper {
    static map(item: LPInvestmentEntity) {
        return {
            finalDistributionEstimate: item.hasFinalDistributionEstimateAmount()
                ? item.finalDistributionEstimateAmount.value
                : "0",
            distributionTargetAmount: item.hasDistributionTargetAmount()
                ? item.distributionTargetAmount.value
                : "0",
            nextDistributionDate: item.hasNextDistributionDate()
                ? moment(item.nextDistributionDate).format("MM/DD/YYYY")
                : undefined,
            distributionScheduleType: item.hasDistributionScheduleType()
                ? item.distributionScheduleType.value
                : undefined,
            distributionTrackAlert: item.hasDistributionTrackAlert()
                ? item.distributionTrackAlert.value === true
                    ? 1
                    : 0
                : 0,
            opportunitiesSubscription: item.hasOpportunitiesSubscription()
                ? item.opportunitiesSubscription.value === true
                    ? true
                    : false
                : undefined,
            transactions: this._mapTransactions(item.propertyTransactions)
        } as LPInvestmentData;
    }

    private static _mapTransactions(transactions: Collection) {
        if (transactions.isEmpty()) return [];

        return transactions.map(
            (transaction: LPInvestmentTransaction, index: number) => {
                return {
                    key: transaction.hasSortKey()
                        ? transaction.sortKey.value
                        : index,
                    transactionType: transaction.hasTransactionType()
                        ? transaction.transactionType.value
                        : "-",
                    amount: transaction.hasAmount()
                        ? transaction.amount.value
                        : "",
                    note: transaction.hasNote() ? transaction.note.value : "",
                    date: transaction.hasTransactionDate()
                        ? moment(transaction.transactionDate).format(
                              "MM/DD/YYYY"
                          )
                        : "",
                    sortKey: transaction.hasSortKey()
                        ? transaction.sortKey.value
                        : ""
                };
            }
        ).items;
    }
}
