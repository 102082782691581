import FavoriteFilterEntity from "./FavoriteFilterEntity";

export default class FavoriteFilterViewMapper {
    static map(data: FavoriteFilterEntity) {
        const NO_INFO: [] = [];
        return {
            cities: data.hasCities()
                ? data.cities.items.map((item: any) => {
                      return item.value;
                  })
                : NO_INFO,
            propertyTypes: data.hasPropertyTypes()
                ? data.propertyTypes.items.map((item: any) => {
                      if (item) return item.value;
                  })
                : NO_INFO,
            starReasons: data.hasStarReasons()
                ? data.starReasons.items.map((item: any) => {
                      return {
                          code: item.items.starType.code,
                          label: item.items.description
                      };
                  })
                : NO_INFO,
            starTypes: data.hasStarTypes()
                ? data.starTypes.items.map((item: any) => {
                      return item.value;
                  })
                : NO_INFO,
            states: data.hasStates()
                ? data.states.items.map((item: any) => {
                      return item.value;
                  })
                : NO_INFO
        };
    }
}
