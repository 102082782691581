import Collection from "../../valueObject/Collection";
import TypeFloat from "../../valueObject/TypeFloat";
import PropertySearchQuery from "./PropertySearchQuery";
import SearchByLendersQueryParameters from "./SearchByLendersQueryParameters";

export default class SearchByLendersQueryMap {
    static map(searchQuery: PropertySearchQuery) {
        const data = new Collection({});

        const LONGITUDE_WEST_LIMIT = new TypeFloat(-179.99);

        data.set(
            SearchByLendersQueryParameters.lenders,
            searchQuery.lenderPortfolioItems
        );

        if (searchQuery.hasZoomLevel())
            data.set(
                SearchByLendersQueryParameters.zoomLevel,
                searchQuery.zoomLevel
            );
        if (searchQuery.hasMapBottomRightLatitude())
            data.set(
                SearchByLendersQueryParameters.mapBottomRightLatitude,
                searchQuery.mapBottomRightLatitude
            );
        if (searchQuery.hasMapBottomRightLongitude())
            data.set(
                SearchByLendersQueryParameters.mapBottomRightLongitude,
                searchQuery.mapBottomRightLongitude
            );
        if (searchQuery.hasMapTopLeftLatitude())
            data.set(
                SearchByLendersQueryParameters.mapTopLeftLatitude,
                searchQuery.mapTopLeftLatitude
            );
        if (searchQuery.hasMapTopLeftLongitude())
            data.set(
                SearchByLendersQueryParameters.mapTopLeftLongitude,
                searchQuery.mapTopLeftLongitude.value <
                    LONGITUDE_WEST_LIMIT.value
                    ? LONGITUDE_WEST_LIMIT
                    : searchQuery.mapTopLeftLongitude
            );
        if (searchQuery.hasCompsLimit())
            data.set(
                SearchByLendersQueryParameters.markerLimit,
                searchQuery.compsLimit
            );

        return data;
    }
}
