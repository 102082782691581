import {
    faArrowLeft,
    faChevronDown,
    faPersonDollyEmpty
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Select } from "antd";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { hotjar } from "react-hotjar";
import { useDispatch, useSelector } from "react-redux";
import responsiveSliceState, {
    setCurrentView,
    setOverlays
} from "../../../store/responsive";
import PartButton from "../../parts/PartButton";
import PropertyService from "../../../domain/property/PropertyService";
import {
    setFirstLenderValue,
    setLenderPortfolioActive,
    setSecondLenderValue,
    setThirdLenderValue
} from "../../../store/lenderPortfolio";
import PropertySearchQuery from "../../../domain/property/queries/PropertySearchQuery";
import Collection from "../../../domain/valueObject/Collection";
import LenderPortfolioService from "../../../domain/lenderPortfolio/LenderPortfolioService";
import TypeString from "../../../domain/valueObject/TypeString";

const { Option } = Select;

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const BlockLenderPortfolios = () => {
    const responsiveState = useSelector(responsiveSliceState);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const query: PropertySearchQuery = PropertyService.getSearchQuery();

    query.assignApplyTrigger("setLenderSearchState", () => {
        dispatch(setLenderPortfolioActive(query.hasLenderPortfolioItems()));
    });

    const { control, watch, reset } = useForm({
        mode: "onChange",
        shouldFocusError: false
    });

    const lenderOne = watch("lenderOne");
    const lenderTwo = watch("lenderTwo");
    const lenderThree = watch("lenderThree");

    const [dropdownValue, setDropdownValue] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    const fetchLenders = async (value: string, callback: Function) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        currentValue = value;

        const getLenders = async () => {
            const response = await LenderPortfolioService.searchByKeyword(
                new TypeString(currentValue)
            );
            if (response) {
                const data = response.items.map((item: any) => ({
                    value: item.name,
                    id: item.id,
                    label: item.name
                }));
                setDropdownValue(data);
                callback(data);
            }
        };

        timeout = setTimeout(getLenders, 300);
    };

    const handleSearch = (newValue: string) => {
        setSearchValue(newValue);
        if (newValue) {
            fetchLenders(newValue, () => {
                console.log("fetching lenders");
            });
        }
    };

    const handleDropdownVisibleChange = (open: boolean) => {
        if (!open) {
            setDropdownValue([]);
        }
    };

    const notFoundContent = () => {
        return dropdownValue.length == 0 && !searchValue ? (
            <span className="empty-lender-portfolio-dropdown">
                <span className="empty-lender-portfolio-dropdown__text">
                    Begin typing to search for a lender
                </span>
            </span>
        ) : dropdownValue.length == 0 ? (
            <span className="empty-lender-portfolio-dropdown">
                <FontAwesomeIcon icon={faPersonDollyEmpty} />
                <span className="empty-lender-portfolio-dropdown__text">
                    Nothing Matches Your Search Criteria
                </span>
            </span>
        ) : null;
    };

    return (
        <div
            className={`lender-portfolios ${
                responsiveState.overlays.lenderPortfoliosOverlay
                    ? "open"
                    : "closed"
            }`}
        >
            <div className="indcs__header">
                <div className="indcs__header-wrap">
                    <PartButton
                        type="secondary"
                        onClick={() => {
                            dispatch(setCurrentView("dashboard"));
                            dispatch(
                                setOverlays({
                                    ...responsiveState.overlays,
                                    lenderPortfoliosOverlay: false
                                })
                            );
                        }}
                    >
                        <span
                            onClick={() => {
                                hotjar.event(
                                    "Lender Overlays Collapse - mobile"
                                );
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} /> tools
                        </span>
                    </PartButton>
                    <h3>LENDER OVERLAYS</h3>
                </div>
            </div>

            <p>
                Compare lender origination data and see their activity reflected
                on the map. Choose a lender below to get started.
            </p>
            <Form form={form} className="lender-portfolios__dropdowns">
                <div className="lender-portfolios__dropdown">
                    <span className="lender-portfolios__dot navy"></span>
                    <Controller
                        name="lenderOne"
                        control={control}
                        render={({ field }) => {
                            return (
                                <Select
                                    {...field}
                                    className="lender-portfolios__select"
                                    placeholder="Select a lender"
                                    value={field.value?.value}
                                    showSearch={true}
                                    disabled={false}
                                    onChange={(value: string, option: any) => {
                                        const bankId = option ? option.id : "";
                                        const valueObject = {
                                            value: value,
                                            id: bankId
                                        };
                                        field.onChange(valueObject); // Set the value using field.onChange
                                    }}
                                    suffixIcon={
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    }
                                    onSearch={handleSearch}
                                    dropdownClassName="lender-portfolios__dropdown"
                                    notFoundContent={notFoundContent()}
                                    onDropdownVisibleChange={
                                        handleDropdownVisibleChange
                                    }
                                    onSelect={(lenderName: string) => {
                                        hotjar.event(
                                            `Lender Overlays - Select a Lender 1 - Check - ${lenderName}`
                                        );
                                    }}
                                    onFocus={() => {
                                        hotjar.event(
                                            `Lender Overlays - Select a Lender 1 - Inputbox`
                                        );
                                    }}
                                >
                                    {dropdownValue
                                        .filter((item: any) => {
                                            if (
                                                lenderThree &&
                                                lenderThree.id &&
                                                lenderThree.id === item.id
                                            ) {
                                                return false;
                                            }
                                            if (
                                                lenderTwo &&
                                                lenderTwo.id &&
                                                lenderTwo.id === item.id
                                            ) {
                                                return false;
                                            }
                                            return true;
                                        })
                                        .map((item: any, i: number) => (
                                            <Option
                                                key={i}
                                                value={item.value}
                                                id={item.id}
                                            >
                                                {item.value}
                                            </Option>
                                        ))}
                                </Select>
                            );
                        }}
                    />
                </div>
                <div className="lender-portfolios__dropdown">
                    <span className="lender-portfolios__dot red"></span>
                    <Controller
                        name="lenderTwo"
                        control={control}
                        render={({ field }) => {
                            return (
                                <Select
                                    {...field}
                                    className="lender-portfolios__select"
                                    placeholder="Select a lender"
                                    value={field.value?.value}
                                    showSearch={true}
                                    disabled={false}
                                    onChange={(value: string, option: any) => {
                                        const bankId = option ? option.id : "";
                                        const valueObject = {
                                            value: value,
                                            id: bankId
                                        };
                                        field.onChange(valueObject); // Set the value using field.onChange
                                    }}
                                    suffixIcon={
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    }
                                    dropdownClassName="lender-portfolios__dropdown"
                                    notFoundContent={notFoundContent()}
                                    onSearch={handleSearch}
                                    onDropdownVisibleChange={
                                        handleDropdownVisibleChange
                                    }
                                    onSelect={(lenderName: string) => {
                                        hotjar.event(
                                            `Lender Overlays - Select a Lender 2 - Check - ${lenderName}`
                                        );
                                    }}
                                    onFocus={() => {
                                        hotjar.event(
                                            `Lender Overlays - Select a Lender 2 - Inputbox`
                                        );
                                    }}
                                >
                                    {dropdownValue
                                        .filter((item: any) => {
                                            if (
                                                lenderOne &&
                                                lenderOne.id &&
                                                lenderOne.id === item.id
                                            ) {
                                                return false;
                                            }
                                            if (
                                                lenderThree &&
                                                lenderThree.id &&
                                                lenderThree.id === item.id
                                            ) {
                                                return false;
                                            }
                                            return true;
                                        })
                                        .map((item: any, i: number) => (
                                            <Option
                                                key={i}
                                                value={item.value}
                                                id={item.id}
                                            >
                                                {item.label}
                                            </Option>
                                        ))}
                                </Select>
                            );
                        }}
                    />
                </div>
                <div className="lender-portfolios__dropdown">
                    <span className="lender-portfolios__dot yellow"></span>
                    <Controller
                        name="lenderThree"
                        control={control}
                        render={({ field }) => {
                            return (
                                <Select
                                    {...field}
                                    className="lender-portfolios__select"
                                    placeholder="Select a lender"
                                    value={field.value?.value}
                                    showSearch={true}
                                    disabled={false}
                                    onChange={(value: string, option: any) => {
                                        const bankId = option ? option.id : "";
                                        const valueObject = {
                                            value: value,
                                            id: bankId
                                        };
                                        field.onChange(valueObject); // Set the value using field.onChange
                                    }}
                                    suffixIcon={
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    }
                                    dropdownClassName="lender-portfolios__dropdown"
                                    notFoundContent={notFoundContent()}
                                    onSearch={handleSearch}
                                    onDropdownVisibleChange={
                                        handleDropdownVisibleChange
                                    }
                                    onSelect={(lenderName: string) => {
                                        hotjar.event(
                                            `Lender Overlays - Select a Lender 3 - Check - ${lenderName}`
                                        );
                                    }}
                                    onFocus={() => {
                                        hotjar.event(
                                            `Lender Overlays - Select a Lender 3 - Inputbox`
                                        );
                                    }}
                                >
                                    {dropdownValue
                                        .filter((item: any) => {
                                            if (
                                                lenderOne &&
                                                lenderOne.id &&
                                                lenderOne.id === item.id
                                            ) {
                                                return false;
                                            }
                                            if (
                                                lenderTwo &&
                                                lenderTwo.id &&
                                                lenderTwo.id === item.id
                                            ) {
                                                return false;
                                            }
                                            return true;
                                        })
                                        .map((item: any, i: number) => (
                                            <Option
                                                key={i}
                                                value={item.value}
                                                id={item.id}
                                            >
                                                {item.label}
                                            </Option>
                                        ))}
                                </Select>
                            );
                        }}
                    />
                </div>
            </Form>
            <div className="lender-portfolios__buttons">
                <span
                    className="lender-portfolios__clear"
                    role="button"
                    onClick={() => {
                        const selectedLenders = [
                            lenderOne?.value,
                            lenderTwo?.value,
                            lenderThree?.value
                        ].filter(Boolean);

                        hotjar.event(
                            `Lender Overlays - Clear Selection - ${selectedLenders.join(
                                " - "
                            )}`
                        );

                        reset();

                        dispatch(setFirstLenderValue(""));
                        dispatch(setSecondLenderValue(""));
                        dispatch(setThirdLenderValue(""));

                        query.assignApplyTrigger("setLenderSearchState", () => {
                            dispatch(
                                setLenderPortfolioActive(
                                    !query.hasLenderPortfolioItems()
                                )
                            );
                        });

                        query
                            .setLenderPortfolioStats(new Collection({}))
                            .clearLenderPortfolioItems()
                            .apply();
                    }}
                >
                    Clear Selection
                </span>
                <PartButton
                    type="primary"
                    disabled={
                        [lenderOne, lenderTwo, lenderThree].filter(Boolean)
                            .length < 1
                    }
                    onClick={() => {
                        const selectedLenders = [
                            lenderOne?.value,
                            lenderTwo?.value,
                            lenderThree?.value
                        ].filter(Boolean);

                        hotjar.event(
                            `Lender Overlays - Search - ${selectedLenders.join(
                                " - "
                            )}`
                        );
                        if (lenderOne)
                            query.setFirstLenderName(
                                new TypeString(lenderOne.value)
                            );
                        if (lenderTwo)
                            query.setSecondLenderName(
                                new TypeString(lenderTwo.value)
                            );
                        if (lenderThree)
                            query.setThirdLenderName(
                                new TypeString(lenderThree.value)
                            );
                        if (lenderOne)
                            dispatch(setFirstLenderValue(lenderOne.value));
                        if (lenderTwo)
                            dispatch(setSecondLenderValue(lenderTwo.value));
                        if (lenderThree)
                            dispatch(setThirdLenderValue(lenderThree.value));
                        query.apply();
                        dispatch(
                            setOverlays({
                                lenderPortfoliosOverlay: false
                            })
                        );
                        dispatch(setCurrentView("map"));
                    }}
                >
                    Search
                </PartButton>
            </div>
            <div id="indcs__spacer"></div>
            <p id="indcs__disclaimer">
                Beta Disclaimer: Feature only works on desktop, not mobile. Data
                pulled from public sources and missing originations, duplicates
                and misspellings are common. As we continue to improve data
                quality, tell us about errors via Feedback button on the right
                of the screen.
            </p>
            <div id="indcs__spacer"></div>
        </div>
    );
};

export default BlockLenderPortfolios;
