import TypeString from "../valueObject/TypeString";
import PropertyVendorFactory from "./PropertyVendorFactory";
import PropertyVendorGateway from "./PropertyVendorGateway";
import PropertyVendorRepository from "./PropertyVendorRepository";

export default class PropertyVendorService {
    static getPropertyVendors(id: TypeString) {
        return new PropertyVendorRepository(
            new PropertyVendorGateway(),
            new PropertyVendorFactory()
        ).get(id);
    }
}
