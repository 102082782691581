import Exception from "../../../utils/exceptions/Exception";
import Collection from "../../valueObject/Collection";

export default class FavoriteType {
    static readonly TYPE_OWN = "OWN";
    static readonly TYPE_OWN_NAME = "Own";
    static readonly TYPE_OWNED = "OWNED";
    static readonly TYPE_OWNED_NAME = "Owned";
    static readonly TYPE_INTERESTED = "INTERESTED";
    static readonly TYPE_INTERESTED_NAME = "Interested";
    static readonly TYPE_INTERACTED = "INTERACTED";
    static readonly TYPE_INTERACTED_NAME = "Interacted";
    static readonly TYPE_TOOLS = "TOOLS_USED";
    static readonly TYPE_TOOLS_NAME = "Tools Used";
    static readonly TYPE_VIEWED = "VIEWED";
    static readonly TYPE_VIEWED_NAME = "Viewed";
    static readonly TYPE_OTHER = "OTHER";
    static readonly TYPE_OTHER_NAME = "Other";
    static readonly TYPE_ASSOCIATED = "ASSOCIATED";
    static readonly TYPE_ASSOCIATED_NAME = "Associated";

    private _value: string;
    constructor(value: string) {
        if (typeof value === "string") {
            this._value = value;
            // this._value = FavoriteType.getAvailableTypes().get(
            //     FavoriteType.getAvailableTypeNames().findKey(
            //         (item: any) => item === value
            //     )
            // );
        }
        this.validateValue();
    }

    get id(): string {
        return this._value;
    }

    get name(): string {
        return FavoriteType.getAvailableTypeNames().get(
            FavoriteType.getAvailableTypes().findKey(
                (item: any) => item === this._value
            )
        );
    }

    public static getAvailableTypes(): Collection {
        return new Collection([
            FavoriteType.TYPE_OWN,
            FavoriteType.TYPE_OWNED,
            FavoriteType.TYPE_INTERESTED,
            FavoriteType.TYPE_INTERACTED,
            FavoriteType.TYPE_OTHER,
            FavoriteType.TYPE_ASSOCIATED,
            FavoriteType.TYPE_TOOLS,
            FavoriteType.TYPE_VIEWED
        ]);
    }

    public static getAvailableTypeNames(): Collection {
        return new Collection([
            FavoriteType.TYPE_OWN_NAME,
            FavoriteType.TYPE_OWNED_NAME,
            FavoriteType.TYPE_INTERESTED_NAME,
            FavoriteType.TYPE_INTERACTED_NAME,
            FavoriteType.TYPE_OTHER_NAME,
            FavoriteType.TYPE_ASSOCIATED_NAME,
            FavoriteType.TYPE_TOOLS_NAME,
            FavoriteType.TYPE_VIEWED_NAME
        ]);
    }

    private validateValue() {
        if (!FavoriteType.getAvailableTypes().contains(this._value)) {
            throw new Exception("INVALID_FAVORITE_TYPE", 500, {
                value: this._value
            });
        }
    }
}
