import PropertyTypeEntity from "./PropertyTypeEntity";

export default class PropertyTypeViewMapper {
    static map(propertyTypes: PropertyTypeEntity[]) {
        return propertyTypes.map((type: PropertyTypeEntity) => {
            return {
                value: type.id.value,
                label: type.propertyType.value
            };
        });
    }
}
