import TypeFloat from "../../valueObject/TypeFloat";
import TypePositiveInteger from "../../valueObject/TypePositiveInteger";
import PropertySearchQuery from "./PropertySearchQuery";

export default class PropertySearchQueryFactory {
    static make(): PropertySearchQuery {
        const query = new PropertySearchQuery();
        query.filters.afterChange("apply_query", () => {
            query.apply();
        });
        query.setListingsLimit(new TypePositiveInteger(200));
        query.setCompsLimit(new TypePositiveInteger(500));
        query.setZoomLevel(new TypeFloat(5));
        // query.autocompleteSearch.afterChange("apply_query", () => {
        //     query.apply();
        // });

        return query;
    }
}
