import Collection from "../../valueObject/Collection";
import IFrameMessage from "../IFrameMessage";

export default class SearchLendersMessage extends IFrameMessage {
    public static ACTION_NAME = "find-lender-submit";
    constructor() {
        super(SearchLendersMessage.ACTION_NAME);
    }

    public send(data: Collection, iframe: HTMLIFrameElement) {
        super._send(data, iframe);
    }

    public recieve(callback: Function) {
        callback();
    }
}
