import React, { MouseEventHandler, ReactElement } from "react";
import { useSelector } from "react-redux";
import indexSliceState from "../../store";
// import IconLoader from "@components/icons/IconLoader";

interface ButtonProps {
    children?: ReactElement | string;
    className?: string;
    disabled?: boolean;
    type: "primary" | "secondary";
    onClick?: MouseEventHandler<HTMLElement> | undefined;
    showLoader?: boolean;
    form?: string;
}

const PartButton = ({
    children,
    className = "",
    disabled,
    type,
    onClick,
    showLoader,
    form = ""
}: ButtonProps) => {
    const indexState = useSelector(indexSliceState);
    return (
        <button
            form={form}
            className={`btn btn--${type} ${className} ${
                disabled ||
                (indexState.isLoading.loading &&
                    indexState.isLoading.type == "button" &&
                    showLoader)
                    ? "disabled"
                    : ""
            } `}
            onClick={onClick}
            disabled={disabled}
        >
            {indexState.isLoading.loading &&
            indexState.isLoading.type == "button" &&
            showLoader ? (
                <div role="spinbutton" className="btn-loader">
                    <span className="loader">{/* <IconLoader /> */}</span>
                </div>
            ) : (
                children
            )}
        </button>
    );
};

export default PartButton;
