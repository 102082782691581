import Collection from "../valueObject/Collection";
import SaleHistoryEntity from "./SaleHistoryEntity";
import BrokerFactory from "../representer/broker/BrokerFactory";
import TypeFloat from "../valueObject/TypeFloat";
import TypeTimestamp from "../valueObject/TypeTimestamp";
import TypeString from "../valueObject/TypeString";

export default class SaleHistoryFactory {
    reconstitute(item: Collection) {
        const saleHistoryIntance = new SaleHistoryEntity();
        if (item.has("id"))
            saleHistoryIntance.setId(new TypeString(item.get("id")));
        if (item.has("price"))
            saleHistoryIntance.setPrice(new TypeFloat(item.get("price")));
        if (item.has("pricePerSqft"))
            saleHistoryIntance.setPricePerSqFt(
                new TypeFloat(item.get("pricePerSqft"))
            );
        if (item.has("pricePerUnit"))
            saleHistoryIntance.setPricePerUnit(
                new TypeFloat(item.get("pricePerUnit"))
            );
        if (item.has("saleDate"))
            saleHistoryIntance.setSaleDate(
                new TypeTimestamp(item.get("saleDate"))
            );
        if (item.has("broker"))
            saleHistoryIntance.setBroker(
                new BrokerFactory().reconstitute(
                    new Collection(item.get("broker"))
                )
            );

        if (item.has("priorSaleDate"))
            saleHistoryIntance.setPriorSaleDate(
                new TypeTimestamp(item.get("priorSaleDate"))
            );

        if (item.has("priorSalePrice"))
            saleHistoryIntance.setPriorSalePrice(
                new TypeFloat(item.get("priorSalePrice"))
            );

        if (item.has("sellerName"))
            saleHistoryIntance.setSellerName(
                new TypeString(item.get("sellerName"))
            );

        return saleHistoryIntance;
    }
}
