import Collection from "../valueObject/Collection";

export default class FavoriteFilterEntity {
    _propertyTypes: Collection = new Collection([]);
    _cities: Collection = new Collection([]);
    _starReasons: Collection = new Collection([]);
    _starTypes: Collection = new Collection([]);
    _states: Collection = new Collection([]);

    get propertyTypes() {
        return this._propertyTypes;
    }

    setPropertyTypes(value: Collection) {
        this._propertyTypes = value;
        return this;
    }

    hasPropertyTypes(): boolean {
        return this._propertyTypes instanceof Collection;
    }

    get cities() {
        return this._cities;
    }

    setCities(value: Collection) {
        this._cities = value;
        return this;
    }

    hasCities(): boolean {
        return this._cities instanceof Collection;
    }

    get starReasons() {
        return this._starReasons;
    }

    setStarReasons(value: Collection) {
        this._starReasons = value;
        return this;
    }

    hasStarReasons(): boolean {
        return this._starReasons instanceof Collection;
    }

    get starTypes() {
        return this._starTypes;
    }

    setStarTypes(value: Collection) {
        this._starTypes = value;
        return this;
    }

    hasStarTypes(): boolean {
        return this._starTypes instanceof Collection;
    }

    get states() {
        return this._states;
    }

    setStates(value: Collection) {
        this._states = value;
        return this;
    }

    hasStates(): boolean {
        return this._states instanceof Collection;
    }
}
