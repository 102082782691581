import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDotSlash } from "@fortawesome/pro-regular-svg-icons";

// eslint-disable-next-line no-unused-vars
const IconLocationDotSlash = (props: any) => {
    return (
        <div className="location-slash">
            <FontAwesomeIcon icon={faLocationDotSlash} />
        </div>
    );
};

export default IconLocationDotSlash;
