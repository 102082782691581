import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./storeIndex";

interface ILenderPorftolio {
    isActive: boolean;
    firstLenderValue: string;
    secondLenderValue: string;
    thirdLenderValue: string;
}
export const initialState: ILenderPorftolio = {
    isActive: false,
    firstLenderValue: "",
    secondLenderValue: "",
    thirdLenderValue: ""
};

export const lenderPortfolio = createSlice({
    name: "lenderPortfolio",
    initialState,
    reducers: {
        setLenderPortfolioActive: (state, action: PayloadAction<boolean>) => {
            state.isActive = action.payload;
        },
        setFirstLenderValue: (state, action: PayloadAction<string>) => {
            state.firstLenderValue = action.payload;
        },
        setSecondLenderValue: (state, action: PayloadAction<string>) => {
            state.secondLenderValue = action.payload;
        },
        setThirdLenderValue: (state, action: PayloadAction<string>) => {
            state.thirdLenderValue = action.payload;
        }
    }
});

export const {
    setLenderPortfolioActive,
    setFirstLenderValue,
    setSecondLenderValue,
    setThirdLenderValue
} = lenderPortfolio.actions;

const lenderPortfolioSliceState = (state: RootState) => state.lenderPortfolio;

export default lenderPortfolioSliceState;
