import { PROPERTY_DATA_API, PUBLIC_PROPERTY_DATA_API } from "../../apiList";
import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import store from "../../store/storeIndex";

export default class LenderPortfolioGateway {
    isLoggedIn = store.getState().index.token;
    PROPERTY_API = this.isLoggedIn
        ? PROPERTY_DATA_API
        : PUBLIC_PROPERTY_DATA_API;
    search(keyword: TypeString) {
        return this.PROPERTY_API.get(
            "/lender/search",
            new Collection({
                query: keyword
            })
        );
    }
}
