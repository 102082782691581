import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useDispatch, useSelector } from "react-redux";
import { PropertyCardData } from "../../domain/property/PropertyViewMapper";
//import indexSliceState from "../../store";
import { capitalizeFirstLetter } from "../../utils/utils";
import PartPropertyCardSingleItem from "./PartPropertyCardSingleItem";

interface PartPublicDataProps {
    propertyCardData: PropertyCardData;
}
export default function PartPublicData({
    propertyCardData
}: PartPublicDataProps) {
    // const dispatch = useDispatch();
    // const indexSlice = useSelector(indexSliceState);

    // const groupDataHasValue = (...values: Array<any>) => {
    //     return values.some((value: any) => value);
    // };

    return (
        <div className="property-card__public-data">
            <div className="property-card__public-group">
                <h4>
                    LOCATION
                    <div className="property-card__public-hover">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <span>
                            Data obtained through aggregation <br />
                            of publicly available sources
                        </span>
                    </div>
                </h4>
                <PartPropertyCardSingleItem
                    name="City"
                    value={capitalizeFirstLetter(propertyCardData.city)}
                />
                <PartPropertyCardSingleItem
                    name="County"
                    value={capitalizeFirstLetter(propertyCardData.county)}
                />
                <PartPropertyCardSingleItem
                    name="Zipcode"
                    value={propertyCardData.zip}
                />
                <PartPropertyCardSingleItem
                    name="FIPS"
                    value={propertyCardData.fips}
                />
                <PartPropertyCardSingleItem
                    name="Parcel"
                    value={propertyCardData.parcel}
                />
            </div>

            <div className="property-card__public-group">
                <h4>
                    LOT DETAILS{" "}
                    <div className="property-card__public-hover">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <span>
                            Data obtained through aggregation <br />
                            of publicly available sources
                        </span>
                    </div>
                </h4>
                <PartPropertyCardSingleItem
                    name="Zoning"
                    value={propertyCardData.zoning}
                />
                <PartPropertyCardSingleItem
                    name="Lot Size"
                    value={propertyCardData.lotSize}
                    suffix={true}
                />
                <PartPropertyCardSingleItem
                    name="Land Use"
                    value={capitalizeFirstLetter(propertyCardData.landUse)}
                />
                <PartPropertyCardSingleItem
                    name="Subclass"
                    value={capitalizeFirstLetter(propertyCardData.subclass)}
                />
            </div>

            <div className="property-card__public-group">
                <h4>
                    PROPERTY DETAILS{" "}
                    <div className="property-card__public-hover center">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <span>
                            Data obtained through aggregation <br />
                            of publicly available sources
                        </span>
                    </div>
                </h4>
                <PartPropertyCardSingleItem
                    name="Units"
                    value={propertyCardData.units}
                />
                <PartPropertyCardSingleItem
                    name="Sqft"
                    value={propertyCardData.sqFt}
                />
                <PartPropertyCardSingleItem
                    name="Year Built"
                    value={propertyCardData.yearBuilt}
                />
                <PartPropertyCardSingleItem
                    name="Stories"
                    value={propertyCardData.stories}
                />
                <PartPropertyCardSingleItem
                    name="Building Count"
                    value={propertyCardData.buildingCount}
                />
            </div>

            <div className="property-card__public-group">
                <h4>
                    PROPERTY FINANCIALS{" "}
                    <div className="property-card__public-hover center">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <span>
                            Data obtained through aggregation <br />
                            of publicly available sources
                        </span>
                    </div>
                </h4>
                <PartPropertyCardSingleItem
                    name="Property Tax"
                    value={propertyCardData.taxAssessment?.propertyTax}
                    prefix={true}
                    formatNumber={true}
                />
                <PartPropertyCardSingleItem
                    name="Tax Year"
                    value={propertyCardData.taxAssessment?.taxYear}
                />
                <PartPropertyCardSingleItem
                    name="Assessed Year"
                    value={propertyCardData.taxAssessment?.assessedYear}
                />
                <PartPropertyCardSingleItem
                    name="Assessed Value"
                    value={propertyCardData.taxAssessment?.assessedValue}
                    prefix={true}
                    formatNumber={true}
                />
            </div>
        </div>
    );
}
