import React from "react";

interface PartPropertyCardSingleItemProps {
    name: string;
    value?: string | number;
    prefix?: boolean;
    suffix?: boolean;
    formatNumber?: boolean;
}
const PartPropertyCardSingleItem = ({
    name,
    value,
    prefix,
    suffix,
    formatNumber
}: PartPropertyCardSingleItemProps) => {
    return value ? (
        <div className="property-card__public-row">
            <span className="property-card__public-name">{name}</span>
            <span className="property-card__public-value">
                {prefix && "$"}
                {formatNumber ? value.toLocaleString() : value}
                {suffix && " sq.ft"}
            </span>
        </div>
    ) : null;
};

export default PartPropertyCardSingleItem;
