import TypePositiveInteger from "../../valueObject/TypePositiveInteger";

export default class TotalMarkerCountEntity {
    _listingsTotalCount: TypePositiveInteger;
    _financeComparablesTotalCount: TypePositiveInteger;
    _salesComparablesTotalCount: TypePositiveInteger;

    get listingsTotalCount(): TypePositiveInteger {
        return this._listingsTotalCount;
    }

    setListingsTotalCount(listingsTotalCount: TypePositiveInteger) {
        this._listingsTotalCount = listingsTotalCount;
        return this;
    }

    hasListingsTotalCount(): boolean {
        return this._listingsTotalCount instanceof TypePositiveInteger;
    }

    get financeComparablesTotalCount(): TypePositiveInteger {
        return this._financeComparablesTotalCount;
    }

    setFinanceComparablesTotalCount(
        financeComparablesTotalCount: TypePositiveInteger
    ) {
        this._financeComparablesTotalCount = financeComparablesTotalCount;
        return this;
    }

    hasFinanceComparablesTotalCount(): boolean {
        return (
            this._financeComparablesTotalCount instanceof TypePositiveInteger
        );
    }

    get salesComparablesTotalCount(): TypePositiveInteger {
        return this._salesComparablesTotalCount;
    }

    setSalesComparablesTotalCount(
        salesComparablesTotalCount: TypePositiveInteger
    ) {
        this._salesComparablesTotalCount = salesComparablesTotalCount;
        return this;
    }

    hasSalesComparablesTotalCount(): boolean {
        return this._salesComparablesTotalCount instanceof TypePositiveInteger;
    }
}
