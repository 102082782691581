import Collection from "../valueObject/Collection";
import TypeBoolean from "../valueObject/TypeBoolean";
import TypeFloat from "../valueObject/TypeFloat";
import TypeString from "../valueObject/TypeString";
import LPInvestmentEntity from "./LPInvestmentEntity";

export default class LPInvestmentFactory {
    reconstitute(data: Collection, portfolioTransactions: Collection) {
        const investment = new LPInvestmentEntity();

        if (data.has("finalDistributionEstimateAmount")) {
            investment.setFinalDistributionEstimateAmount(
                new TypeFloat(data.get("finalDistributionEstimateAmount"))
            );
        }
        if (data.has("distributionTargetAmount")) {
            investment.setDistributionTargetAmount(
                new TypeFloat(data.get("distributionTargetAmount"))
            );
        }
        if (data.has("distributionScheduleType")) {
            investment.setDistributionScheduleType(
                new TypeString(data.get("distributionScheduleType"))
            );
        }
        if (data.has("distributionTrackAlert")) {
            investment.setDistributionTrackAlert(
                new TypeBoolean(data.get("distributionTrackAlert"))
            );
        }
        if (data.has("opportunitiesSubscription")) {
            investment.setOpportunitiesSubscription(
                new TypeBoolean(data.get("opportunitiesSubscription"))
            );
        }
        if (data.has("sortKey")) {
            investment.setSortKey(new TypeString(data.get("sortKey")));
        }
        if (data.has("nextDistributionDate")) {
            investment.setNextDistributionDate(
                new Date(data.get("nextDistributionDate"))
            );
        }

        if (portfolioTransactions instanceof Collection) {
            investment.setPropertyTransactions(portfolioTransactions);
        }

        return investment;
    }
}
