import TypeString from "../valueObject/TypeString";
import PropertyListingEntity from "../propertyListing/PropertyListingEntity";
import SaleHistoryEntity from "../propertySaleHistory/SaleHistoryEntity";
import FinanceHistoryEntity from "../propertyFinanceHistory/FinanceHistoryEntity";
import PropertyType from "./valueObject/PropertyType";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeFloat from "../valueObject/TypeFloat";
import OwnershipEntity from "./ownership/OwnershipEntity";
import TypeBoolean from "../valueObject/TypeBoolean";
import PropertyTaxAssessmentEntity from "./tax/PropertyTaxAssessment";

export default class PropertyEntity {
    _id: TypeString;
    _type: TypePositiveInteger;
    _image: TypeString; // replace with ImageEntity if necessary
    _address: TypeString; //  AddressEntity;
    _listing: PropertyListingEntity;
    _saleHistory: SaleHistoryEntity; // TODO research if possible to have a collection of SaleHistoryEntity
    _financeHistory: FinanceHistoryEntity;
    _ownership: OwnershipEntity;
    _stateAbbreviation: TypeString;
    _propertyType: PropertyType;
    _propertyTypeName: TypeString;
    _sqFt: TypeFloat;
    _latitude: TypeFloat;
    _longitude: TypeFloat;
    // Public property data properties
    _city: TypeString;
    _county: TypeString;
    _zip: TypeString;
    _fips: TypePositiveInteger;
    _parcel: TypeString;
    _zoning: TypeString;
    _lotSize: TypeFloat;
    _landUse: TypeString;
    _subClass: TypeString;
    _units: TypePositiveInteger;
    _yearBuilt: TypePositiveInteger;
    _stories: TypeFloat;
    _buildingCount: TypePositiveInteger;
    _ownerOccupied: TypeBoolean;
    _owner: OwnershipEntity;
    _taxAssessment: PropertyTaxAssessmentEntity;
    _isFavorite: TypeBoolean;

    get id(): TypeString {
        return this._id;
    }
    setId(id: TypeString) {
        this._id = id;
        return this;
    }

    hasId(): boolean {
        return this._id instanceof TypeString;
    }

    get type(): TypePositiveInteger {
        return this._type;
    }

    setType(type: TypePositiveInteger) {
        this._type = type;
        return this;
    }

    hasType(): boolean {
        return this._type instanceof TypePositiveInteger;
    }

    get image(): TypeString {
        return this._image;
    }

    setImage(image: TypeString) {
        this._image = image;
        return this;
    }

    hasImage(): boolean {
        return this._image instanceof TypeString;
    }

    get address(): TypeString {
        return this._address;
    }

    setAddress(address: TypeString) {
        this._address = address;
        return this;
    }

    hasAddress(): boolean {
        return this._address instanceof TypeString;
    }

    get listing(): PropertyListingEntity {
        return this._listing;
    }

    setListing(listing: PropertyListingEntity) {
        this._listing = listing;
        return this;
    }

    hasListing(): boolean {
        return this._listing !== undefined;
    }

    get saleHistory(): SaleHistoryEntity {
        return this._saleHistory;
    }

    setSaleHistory(saleHistory: SaleHistoryEntity) {
        this._saleHistory = saleHistory;
        return this;
    }

    hasSaleHistory(): boolean {
        return this._saleHistory instanceof SaleHistoryEntity;
    }

    get financeHistory(): FinanceHistoryEntity {
        return this._financeHistory;
    }

    setFinanceHistory(financeHistory: FinanceHistoryEntity) {
        this._financeHistory = financeHistory;
        return this;
    }

    hasFinanceHistory(): boolean {
        return this._financeHistory !== undefined;
    }

    get stateAbbreviation(): TypeString {
        return this._stateAbbreviation;
    }

    setStateAbbreviation(value: TypeString) {
        this._stateAbbreviation = value;
        return this;
    }

    hasStateAbbreviation(): boolean {
        return this._stateAbbreviation instanceof TypeString;
    }

    get propertyType(): PropertyType {
        return this._propertyType;
    }
    setPropertyType(value: PropertyType) {
        this._propertyType = value;
        return this;
    }
    hasPropertyType(): boolean {
        return this._propertyType instanceof PropertyType;
    }

    get propertyTypeName(): TypeString {
        return this._propertyTypeName;
    }
    setPropertyTypeName(value: TypeString) {
        this._propertyTypeName = value;
    }
    hasPropertyTypeName(): boolean {
        return this._propertyTypeName instanceof TypeString;
    }

    get sqFt(): TypeFloat {
        return this._sqFt;
    }

    setSqFt(sqFt: TypeFloat) {
        this._sqFt = sqFt;
        return this;
    }

    hasSqFt(): boolean {
        return this._sqFt instanceof TypeFloat;
    }

    get latitude(): TypeFloat {
        return this._latitude;
    }

    setLatitude(latitude: TypeFloat) {
        this._latitude = latitude;
        return this;
    }

    hasLatitude(): boolean {
        return this._latitude instanceof TypeFloat;
    }

    get longitude(): TypeFloat {
        return this._longitude;
    }

    setLongitude(longitude: TypeFloat) {
        this._longitude = longitude;
        return this;
    }

    hasLongitude(): boolean {
        return this._longitude instanceof TypeFloat;
    }

    get ownership(): OwnershipEntity {
        return this._ownership;
    }

    setOwnership(ownership: OwnershipEntity) {
        this._ownership = ownership;
        return this;
    }

    hasOwnership(): boolean {
        return this._ownership instanceof OwnershipEntity;
    }

    get city(): TypeString {
        return this._city;
    }

    setCity(city: TypeString) {
        this._city = city;
        return this;
    }

    hasCity(): boolean {
        return this._city instanceof TypeString;
    }

    get county(): TypeString {
        return this._county;
    }

    setCounty(county: TypeString) {
        this._county = county;
        return this;
    }

    hasCounty(): boolean {
        return this._county instanceof TypeString;
    }

    get zip(): TypeString {
        return this._zip;
    }

    setZip(zip: TypeString) {
        this._zip = zip;
        return this;
    }

    hasZip(): boolean {
        return this._zip instanceof TypeString;
    }

    get FIPS(): TypePositiveInteger {
        return this._fips;
    }

    setFIPS(fips: TypePositiveInteger) {
        this._fips = fips;
        return this;
    }

    hasFIPS(): boolean {
        return this._fips instanceof TypePositiveInteger;
    }

    get parcel(): TypeString {
        return this._parcel;
    }

    setParcel(parcel: TypeString) {
        this._parcel = parcel;
        return this;
    }

    hasParcel(): boolean {
        return this._parcel ? true : false;
    }

    get zoning(): TypeString {
        return this._zoning;
    }

    setZoning(zoning: TypeString) {
        this._zoning = zoning;
        return this;
    }

    hasZoning(): boolean {
        return this._zoning instanceof TypeString;
    }

    get lotSize(): TypeFloat {
        return this._lotSize;
    }

    setLotSize(lotSize: TypeFloat) {
        this._lotSize = lotSize;
        return this;
    }

    hasLotSize(): boolean {
        return this._lotSize instanceof TypeFloat;
    }

    get landUse(): TypeString {
        return this._landUse;
    }

    setLandUse(landUse: TypeString) {
        this._landUse = landUse;
        return this;
    }

    hasLandUse(): boolean {
        return this._landUse instanceof TypeString;
    }

    get subClass(): TypeString {
        return this._subClass;
    }

    setSubClass(subClass: TypeString) {
        this._subClass = subClass;
        return this;
    }

    hasSubClass(): boolean {
        return this._subClass instanceof TypeString;
    }

    get units(): TypePositiveInteger {
        return this._units;
    }

    setUnits(units: TypePositiveInteger) {
        this._units = units;
        return this;
    }

    hasUnits(): boolean {
        return this._units instanceof TypePositiveInteger;
    }

    get yearBuilt(): TypePositiveInteger {
        return this._yearBuilt;
    }

    setYearBuilt(yearBuilt: TypePositiveInteger) {
        this._yearBuilt = yearBuilt;
        return this;
    }

    hasYearBuilt(): boolean {
        return this._yearBuilt instanceof TypePositiveInteger;
    }

    get stories(): TypeFloat {
        return this._stories;
    }

    setStories(stories: TypeFloat) {
        this._stories = stories;
        return this;
    }

    hasStories(): boolean {
        return this._stories instanceof TypeFloat;
    }

    get buildingCount(): TypePositiveInteger {
        return this._buildingCount;
    }

    setBuildingCount(buildingCount: TypePositiveInteger) {
        this._buildingCount = buildingCount;
        return this;
    }

    hasBuildingCount(): boolean {
        return this._buildingCount instanceof TypePositiveInteger;
    }

    get ownerOccupied(): TypeBoolean {
        return this._ownerOccupied;
    }

    setOwnerOccupied(ownerOccupied: TypeBoolean) {
        this._ownerOccupied = ownerOccupied;
        return this;
    }

    hasOwnerOccupied(): boolean {
        return this._ownerOccupied instanceof TypeBoolean;
    }

    get owner(): OwnershipEntity {
        return this._owner;
    }

    setOwner(owner: OwnershipEntity) {
        this._owner = owner;
        return this;
    }

    hasOwner(): boolean {
        return this._owner instanceof OwnershipEntity;
    }

    get taxAssessment(): PropertyTaxAssessmentEntity {
        return this._taxAssessment;
    }

    hasTaxAssesments(): boolean {
        return !this._taxAssessment;
    }

    setTaxAssessment(value: PropertyTaxAssessmentEntity) {
        this._taxAssessment = value;
        return this;
    }

    get isFavorite(): TypeBoolean {
        return this._isFavorite;
    }

    setIsFavorite(isFavorite: TypeBoolean) {
        this._isFavorite = isFavorite;
        return this;
    }

    hasIsFavorite(): boolean {
        return this._isFavorite instanceof TypeBoolean;
    }
}
