import { useEffect, useState } from "react";
import BlockAutoCompleteSearch from "./BlockAutoCompleteSearch";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState from "../../store";
import PropertySearchQuery from "../../domain/property/queries/PropertySearchQuery";
import PropertyService from "../../domain/property/PropertyService";
import Collection from "../../domain/valueObject/Collection";
import TypePositiveInteger from "../../domain/valueObject/TypePositiveInteger";
import URLObjectService from "../../domain/urlQuery/URLObjectService";
import URLObjectEntity from "../../domain/urlQuery/URLObjectEntity";
import { useSearchParams } from "react-router-dom";
import PartDropdownProperties from "../parts/PartDropdownProperties";
import PartButton from "../parts/PartButton";
import responsiveSliceState, {
    setCurrentView,
    setOverlays
} from "../../store/responsive";
import { hotjar } from "react-hotjar";

const BlockMobilePropertySearch = ({
    setCoordinates,
    setSearchIsTriggered
}: any) => {
    const [selectedPropertyTypeValue, setSelectedPropertyTypeValue] =
        useState<any>([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch();
    const indexSlice = useSelector(indexSliceState);
    const responsiveSlice = useSelector(responsiveSliceState);
    const query: PropertySearchQuery = PropertyService.getSearchQuery();
    // eslint-disable-next-line no-unused-vars
    const url: URLObjectEntity = URLObjectService.getURLObject(
        searchParams,
        setSearchParams
    );

    url.setPropertySearchQuery(query);

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        if (currentParams !== undefined) {
            // url.fromUrlToQuery();
            fromQueryObjectToDropdown();
        }
    }, []);

    const fromQueryObjectToDropdown = () => {
        const propertyTypeValue = query.filters.propertyType
            .map((item: TypePositiveInteger) => item.value)
            .toArray();
        setSelectedPropertyTypeValue(propertyTypeValue);
    };

    return (
        <div
            className={`mobile-property-search ${
                responsiveSlice.overlays.propertySearch === true
                    ? "visible"
                    : "hidden"
            }`}
        >
            <div className="mobile-property-search__search-wrapper">
                <h2 className="mobile-property-search__title">
                    PROPERTY SEARCH
                </h2>
                <div>
                    <BlockAutoCompleteSearch
                        setCoordinates={setCoordinates}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        setSearchIsTriggered={setSearchIsTriggered}
                    />
                </div>
            </div>
            {/* <div className="mobile-property-search__filters-wrapper">
                <h2>LISTING TYPES</h2>
                <BlockPropertyTypes
                    setSearchIsTriggered={setSearchIsTriggered}
                    searchIsTriggered={searchIsTriggered}
                />
            </div> */}

            <div className="mobile-property-search__property-types-wrapper">
                <h2>PROPERTY TYPES</h2>
                <div>
                    <PartDropdownProperties
                        parent="header"
                        placeholder={`All Property Types (${
                            indexSlice.propertyTypes.length || ""
                        })`}
                        dropdownClassName="property-types-dropdown"
                        name="propertyTypes"
                        value={selectedPropertyTypeValue}
                        noContentMessage="No property types match your search criteria."
                        onChange={(values: any) => {
                            query.filters.setPropertyTypes(
                                new Collection(values).map(
                                    (propertyType: any) => {
                                        return new TypePositiveInteger(
                                            propertyType
                                        );
                                    }
                                )
                            );
                        }}
                        options={indexSlice.propertyTypes}
                        hotjarEventName="Header Property Types Dropdown"
                        isMobile={true}
                    />
                </div>
            </div>

            <div className="mobile-property-search__button-wrapper">
                <PartButton
                    className="property-search-button"
                    type="primary"
                    onClick={() => {
                        hotjar.event(
                            "Mobile - Property Search Dropdown - Search Button Clicked"
                        );
                        dispatch(setCurrentView("map"));
                        dispatch(
                            setOverlays({
                                ...responsiveSlice.overlays,
                                propertySearch: false
                            })
                        );
                    }}
                >
                    Search
                </PartButton>
            </div>
        </div>
    );
};

export default BlockMobilePropertySearch;
