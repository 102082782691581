import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import PartPropertyCardSingleItem from "../../parts/PartPropertyCardSingleItem";
import PartPublicData from "../../parts/PartPublicData";

interface BlockTabOwnerProps {
    propertyCardData: any;
}

const BlockTabOwner = ({ propertyCardData }: BlockTabOwnerProps) => {
    return (
        <div className="property-card__tab">
            <>
                {propertyCardData?.owner !== undefined ? (
                    <div className="property-card__public-group">
                        <h4>
                            OWNER{" "}
                            <div className="property-card__public-hover left">
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <span>
                                    Data obtained through aggregation <br />
                                    of publicly available sources
                                </span>
                            </div>
                        </h4>
                        <PartPropertyCardSingleItem
                            name="Name"
                            value={propertyCardData.owner.name}
                        />
                        <PartPropertyCardSingleItem
                            name="Mailing Address"
                            value={propertyCardData.owner.mailingAddress}
                        />
                        <PartPropertyCardSingleItem
                            name="Owner Occupied"
                            value={propertyCardData.ownerOccupied}
                        />
                    </div>
                ) : null}
                <PartPublicData propertyCardData={propertyCardData} />
            </>
        </div>
    );
};

export default BlockTabOwner;
