import { faFolderArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dragger from "antd/lib/upload/Dragger";
import PartButton from "../../parts/PartButton";

const BlockUploadDragger = ({
    draggerOptions,
    files
}: {
    draggerOptions: Object;
    files: any;
}) => {
    const fileSizeExceeded = files.length >= 10;
    return (
        <div
            className={
                fileSizeExceeded
                    ? "upload-documents__dragger error"
                    : "upload-documents__dragger"
            }
        >
            <p
                className={`upload-documents__error ${
                    fileSizeExceeded ? "visible" : "hidden"
                }`}
            >
                You have uploaded the maximum number of files. Please remove
                files to upload more.
            </p>
            <Dragger
                {...draggerOptions}
                className={`${fileSizeExceeded ? "hidden" : "visible"}`}
                style={{
                    display: fileSizeExceeded ? "none" : "block"
                }}
            >
                <p className="ant-upload-drag-icon">
                    <FontAwesomeIcon icon={faFolderArrowUp} />
                </p>
                <p className="ant-upload-text">
                    Drag & Drop or{" "}
                    <PartButton className="ant-upload-button" type="primary">
                        Choose file
                    </PartButton>{" "}
                    to upload
                </p>
                <span className="ant-upload-text-limit">
                    File size limit 10MB
                    <br />
                    Documents and photos are private and only visible to you.
                </span>
            </Dragger>
        </div>
    );
};

export default BlockUploadDragger;
