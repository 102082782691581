import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import OriginatorEntity from "./OriginatorEntity";
import TypeBoolean from "../valueObject/TypeBoolean";

export default class OriginatorFactory {
    reconstitute(data: Collection) {
        const originatorInstance = new OriginatorEntity();
        if (data.has("id"))
            originatorInstance.setId(new TypeString(data.get("id")));
        if (data.has("name"))
            originatorInstance.setName(new TypeString(data.get("name")));
        if (data.has("email"))
            originatorInstance.setEmail(new TypeString(data.get("email")));
        if (data.has("phone"))
            originatorInstance.setPhone(new TypeString(data.get("phone")));
        if (data.has("company"))
            originatorInstance.setCompany(new TypeString(data.get("company")));
        if (data.has("state"))
            originatorInstance.setState(new TypeString(data.get("state")));
        if (data.has("slug"))
            originatorInstance.setSlug(new TypeString(data.get("slug")));
        if (data.has("primary"))
            originatorInstance.setPrimary(new TypeBoolean(data.get("primary")));

        return originatorInstance;
    }
}
