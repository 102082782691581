import Collection from "../valueObject/Collection";
import StateFactory from "./StateFactory";
import StateGateway from "./StateGateway";

export default class StateRepository {
    _gateway: StateGateway;
    _factory: StateFactory;

    constructor(gateway: StateGateway, factory: StateFactory) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async get() {
        const data = new Collection(await this._gateway.get());
        let items = new Collection([]);
        items = data.slice("data").map((itemData: any) => {
            const item = new Collection(itemData);
            return this._factory.reconstitute(item);
        });

        return items;
    }
}
