import { FIND_LENDER_API } from "../../apiList";
import Collection from "../valueObject/Collection";
import LenderFilterQuery from "./queries/LenderFilterQuery";

export default class LenderGateway {
    async search(query: LenderFilterQuery) {
        const result = await FIND_LENDER_API.get(
            `/find-lender`,
            this._mapRequestData(query)
        );

        result.lenders = new Collection(result.lenders).map((lender: any) => {
            lender.originators = new Collection(lender.originators).filter(
                (originator: any) => !(!originator.email && !originator.phone)
            ).items;

            lender.otherOriginators = new Collection(
                lender.otherOriginators
            ).filter(
                (originator: any) => !(!originator.email && !originator.phone)
            ).items;

            return lender;
        }).items;

        return result;
    }

    private _mapRequestData(query: LenderFilterQuery) {
        const data = new Collection({});

        query.hasState() && data.set("state", query.state);
        query.hasPropertyType() && data.set("propertyType", query.propertyType);
        query.hasLoanType() && data.set("loanType", query.loanType);
        if (query.hasLoanAmount()) {
            if (query.loanAmount?.value !== 0)
                data.set("loanAmount", query.loanAmount);
        }
        query.hasExecutionTypes() &&
            data.set("executionType", query._executionTypes);

        query.hasPublicLenders() &&
            data.set("includePublicData", query.publicLenders);

        return data;
    }
}
