import FavoriteAdditionalDataEntity from "./FavoriteAdditionalDataEntity";

export default class FavoriteAdditionalDataViewMapper {
    static map(data: FavoriteAdditionalDataEntity) {
        const NO_INFO: [] = [];
        return {
            pipelineCapacity: data.hasPipelineCapacity()
                ? data.pipelineCapacity.items
                : NO_INFO
        };
    }
}
