export default class SearchByLendersQueryParameters {
    private static readonly PARAMETER_NAME_LENDERS = "a";
    private static readonly PARAMETER_NAME_MAP_ZOOM_LEVEL = "b";
    private static readonly PARAMETER_NAME_MAP_BOTTOM_RIGHT_LATITUDE = "c";
    private static readonly PARAMETER_NAME_MAP_BOTTOM_RIGHT_LONGITUDE = "d";
    private static readonly PARAMETER_NAME_MAP_TOP_LEFT_LATITUDE = "e";
    private static readonly PARAMETER_NAME_MAP_TOP_LEFT_LONGITUDE = "f";
    private static readonly PARAMETER_NAME_MARKER_LIMIT = "g";

    private static readonly DEBUG_MODE = false;

    public static get lenders() {
        return SearchByLendersQueryParameters._isDebugMode()
            ? "lenders"
            : this.PARAMETER_NAME_LENDERS;
    }
    public static get zoomLevel() {
        return SearchByLendersQueryParameters._isDebugMode()
            ? "zoomLevel"
            : this.PARAMETER_NAME_MAP_ZOOM_LEVEL;
    }
    public static get mapBottomRightLatitude() {
        return SearchByLendersQueryParameters._isDebugMode()
            ? "mapBottomRightLatitude"
            : this.PARAMETER_NAME_MAP_BOTTOM_RIGHT_LATITUDE;
    }
    public static get mapBottomRightLongitude() {
        return SearchByLendersQueryParameters._isDebugMode()
            ? "mapBottomRightLongitude"
            : this.PARAMETER_NAME_MAP_BOTTOM_RIGHT_LONGITUDE;
    }
    public static get mapTopLeftLatitude() {
        return SearchByLendersQueryParameters._isDebugMode()
            ? "mapTopLeftLatitude"
            : this.PARAMETER_NAME_MAP_TOP_LEFT_LATITUDE;
    }
    public static get mapTopLeftLongitude() {
        return SearchByLendersQueryParameters._isDebugMode()
            ? "mapTopLeftLongitude"
            : this.PARAMETER_NAME_MAP_TOP_LEFT_LONGITUDE;
    }
    public static get markerLimit() {
        return SearchByLendersQueryParameters._isDebugMode()
            ? "markerLimit"
            : this.PARAMETER_NAME_MARKER_LIMIT;
    }

    private static _isDebugMode() {
        return this.DEBUG_MODE;
    }
}
