import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, { setAffilliatedPopup } from "../../../store";
import BlockTabListing from "./BlockTabListing";
import BlockTabSaleHistory from "./BlockTabSaleHistory";
import BlockTabFinanceHistory from "./BlockTabFinanceHistory";
import { PropertyCardData } from "../../../domain/property/PropertyViewMapper";
import BlockTabOwner from "./BlockTabOwner";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { useEffect, useState } from "react";
import BlockTabNotes from "./BlockTabNotes";
import mapTriggersSliceState from "../../../store/mapTriggers";
import PartPropertyServiceProvidersList from "../../parts/PartPropertyServiceProvidersList";
import lenderPortfolioSliceState from "../../../store/lenderPortfolio";
import BlockUploadDocuments from "./BlockUploadDocuments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PartNotLoggedInMessage from "../../parts/PartNotLoggedInMessage";
interface BlockPropertyCardTabsProps {
    hasProperty: boolean;
    propertyCardData: PropertyCardData;
    propertyVendors: Array<any>;
    pipelineOverLimit: any | undefined;
    propertyCardPublicData: any | undefined;
}
export function BlockPropertyCardTabs({
    hasProperty,
    propertyCardData,
    propertyVendors,
    pipelineOverLimit,
    propertyCardPublicData
}: BlockPropertyCardTabsProps) {
    const { TabPane } = Tabs;

    const dispatch = useDispatch();

    const indexSlice = useSelector(indexSliceState);
    const mapTriggersSlice = useSelector(mapTriggersSliceState);
    const lenderPortfolioState = useSelector(lenderPortfolioSliceState);

    const [activeTab, setActiveTab] = useState("5");
    const [propertyCardAddress, setPropertyCardAddress] = useState(
        propertyCardPublicData.address ?? propertyCardData.address ?? ""
    );

    useEffect(() => {
        if (propertyCardPublicData?.address) {
            setPropertyCardAddress(propertyCardPublicData.address);
        }
        // return () => {
        //     setPropertyCardAddress("");
        // };
    }, [propertyCardPublicData]);

    const splitAddress =
        propertyCardAddress && propertyCardAddress !== "undefined"
            ? capitalizeFirstLetter(propertyCardAddress, true).split(",")
            : capitalizeFirstLetter(
                  indexSlice.autoCompleteResult?.formatted_address,
                  true
              ).split(",");
    const streetLine: any = splitAddress ? splitAddress[0] : "";
    const cityLine: any = splitAddress
        ? `${splitAddress[1]}, ${splitAddress[2]}`
        : "";

    return (
        <>
            {pipelineOverLimit ? (
                <div className="property-card_main">
                    <div className="icon-section">
                        <span>
                            <FontAwesomeIcon icon={solid("rocket")} />
                        </span>
                    </div>
                    <div className="header-section">
                        <h2>
                            See More, Do More - Upgrade for Full Property & CRE
                            Toolkit Access
                        </h2>
                    </div>
                    <div className="membership-section">
                        <span className="header">Limited Membership</span>
                        <span className="list-item">
                            View listing, sales, financing, and public data on
                            up to <b>100 properties</b>
                        </span>
                        <span className="list-item">
                            Use all CRE tools including the Underwriting
                            Calculator, Alerts, Tags, Documents, Notes, etc on
                            up to <b>100 properties</b>
                        </span>
                        <span className="list-item">
                            Match with top 5 lender recommendations using Find A
                            Lender
                        </span>
                        <span className="learn-more">
                            <a
                                href="https://gparency.com/membership-pricing-page/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Learn More
                            </a>
                        </span>
                    </div>
                    <div className="membership-section">
                        <span className="header">Unlimited Membership</span>
                        <span className="list-item">
                            View listing, sales, financing, and public data on
                            unlimited properties
                        </span>
                        <span className="list-item">
                            Use CRE tools including the Underwriting Calculator,
                            Alerts, Tags, Documents, Notes, etc on up to{" "}
                            <b>Unlimited</b> properties
                        </span>
                        <span className="list-item">
                            Match with unlimited lender recommendations using
                            Find A Lender
                        </span>
                        <span className="learn-more">
                            <a
                                href="https://gparency.com/membership-pricing-page/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Learn More
                            </a>
                        </span>
                    </div>
                </div>
            ) : (
                <div
                    className={`property-card__main ${
                        !hasProperty ? "no-property" : ""
                    } ${indexSlice.isPaidUser ? "paid-user" : "free-user"}`}
                >
                    {hasProperty ? (
                        <div
                            className={`property-card__tabs ${
                                indexSlice.isLoggedIn
                                    ? "property-card__tabs--"
                                    : ""
                            }${
                                activeTab === "1"
                                    ? "listing"
                                    : activeTab === "2"
                                    ? "transactionHistory"
                                    : activeTab === "3"
                                    ? "documents"
                                    : activeTab === "5"
                                    ? "notes"
                                    : "publicdata"
                            } ${
                                propertyCardData.saleHistory === undefined &&
                                propertyCardData.financeHistory === undefined
                                    ? "transaction-history-disabled"
                                    : ""
                            }`}
                        >
                            <Tabs
                                defaultActiveKey={
                                    mapTriggersSlice.triggerDocTab
                                        ? "3"
                                        : mapTriggersSlice.triggerNotesTab
                                        ? "5"
                                        : propertyCardData.isActiveListing
                                        ? "1"
                                        : lenderPortfolioState.isActive
                                        ? "2"
                                        : "4"
                                }
                                onChange={(e) => setActiveTab(e)}
                            >
                                <TabPane
                                    tab={
                                        <span className="property-card__tab-with-icon">
                                            Key Players
                                        </span>
                                    }
                                    key="1"
                                    className="listings-tab"
                                >
                                    {indexSlice.isLoggedIn ? (
                                        propertyCardData.isActiveListing ? (
                                            <BlockTabListing
                                                propertyCardData={
                                                    propertyCardData
                                                }
                                                propertyVendors={
                                                    propertyVendors
                                                }
                                            />
                                        ) : (
                                            <div
                                                className={`listings-tab--no-listing ${
                                                    propertyVendors &&
                                                    propertyVendors.length > 0
                                                        ? "has-vendors"
                                                        : "no-vendors"
                                                }`}
                                            >
                                                {propertyVendors &&
                                                propertyVendors.length > 0 ? (
                                                    <PartPropertyServiceProvidersList
                                                        propertyVendors={
                                                            propertyVendors
                                                        }
                                                    />
                                                ) : (
                                                    <span
                                                        className="property-card__listing-credits"
                                                        role="button"
                                                        onClick={() => {
                                                            dispatch(
                                                                setAffilliatedPopup(
                                                                    true
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        Claim your professional
                                                        association to this
                                                        property.
                                                        <br /> Get marketed to
                                                        owners.
                                                    </span>
                                                )}
                                            </div>
                                        )
                                    ) : (
                                        <PartNotLoggedInMessage />
                                    )}
                                </TabPane>
                                <TabPane
                                    tab={
                                        <span className="property-card__tab-with-icon">
                                            Public Data
                                        </span>
                                    }
                                    key="4"
                                    className="card-visible ownership-tab"
                                >
                                    {indexSlice.isLoggedIn ? (
                                        <BlockTabOwner
                                            propertyCardData={propertyCardData}
                                        />
                                    ) : (
                                        <PartNotLoggedInMessage />
                                    )}
                                </TabPane>
                                <TabPane tab="Transactions" key={"2"}>
                                    {indexSlice.isLoggedIn ? (
                                        propertyCardData.saleHistory !==
                                            undefined ||
                                        propertyCardData.financeHistory !==
                                            undefined ? (
                                            <>
                                                <BlockTabSaleHistory
                                                    propertyCardData={
                                                        propertyCardData
                                                    }
                                                />
                                                <BlockTabFinanceHistory
                                                    propertyCardData={
                                                        propertyCardData
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <span className="property-card__history-unavailable">
                                                Transaction history data not
                                                available for this property.{" "}
                                            </span>
                                        )
                                    ) : (
                                        <PartNotLoggedInMessage />
                                    )}
                                </TabPane>
                                <TabPane tab="My Docs & Photos" key={"3"}>
                                    {indexSlice.isLoggedIn ? (
                                        <BlockUploadDocuments
                                            propertyId={propertyCardData.id}
                                        />
                                    ) : (
                                        <PartNotLoggedInMessage />
                                    )}
                                </TabPane>
                                <TabPane tab="My Notes" key={"5"}>
                                    {indexSlice.isLoggedIn ? (
                                        <BlockTabNotes
                                            propertyCardData={propertyCardData}
                                        />
                                    ) : (
                                        <PartNotLoggedInMessage />
                                    )}
                                </TabPane>
                            </Tabs>
                        </div>
                    ) : (
                        <div className="property-card__no-data property-card__list-item">
                            <span className="property-card__no-data-title">
                                We did not locate a property for:
                            </span>
                            <span className="property-card__no-data-property">
                                {streetLine},<br /> {cityLine}
                            </span>
                            <span className="property-card__no-data-info">
                                Try searching for another address or explore
                                properties nearby this address.
                            </span>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
