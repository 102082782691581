import { useEffect, useState } from "react";
import BlockAutoCompleteSearch from "./BlockAutoCompleteSearch";
import LoginButton from "../Auth0/LoginButton";
import LogoutButton from "../Auth0/LogoutButton";
import Logo from "../../assets/images/logo.svg";
import LogoS from "../../assets/images/logo-mobile.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleUser,
    faBars,
    faMagnifyingGlass,
    faClose,
    faMap,
    faChartTreeMap,
    faArrowRightToBracket
} from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, {
    setFiltersPopupOpen,
    setLenderFlyout,
    setTrialPopupOpen,
    setIframe
} from "../../store";
import PropertySearchQuery from "../../domain/property/queries/PropertySearchQuery";
import PropertyService from "../../domain/property/PropertyService";
import Collection from "../../domain/valueObject/Collection";
import TypePositiveInteger from "../../domain/valueObject/TypePositiveInteger";
import URLObjectService from "../../domain/urlQuery/URLObjectService";
import URLObjectEntity from "../../domain/urlQuery/URLObjectEntity";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { hotjar } from "react-hotjar";
import PartDropdownProperties from "../parts/PartDropdownProperties";
import PartButton from "../parts/PartButton";
import responsiveSliceState, {
    setCurrentView,
    setOverlays,
    setShowMapBtn
} from "../../store/responsive";
import { WHERE_FROM_CTA } from "../../utils/constants";

const BlockHeader = ({
    setCoordinates,
    setSearchIsTriggered,
    setIsMenuCollapsed,
    isMenuCollapsed
}: any) => {
    const [isActiveSearch, setActiveSearch] = useState(false);
    const [headerState, setHeaderState] = useState("");
    const [isDropdown, setDropdown] = useState(false);
    const [hasFocus, setFocus] = useState(false);
    const [selectedPropertyTypeValue, setSelectedPropertyTypeValue] =
        useState<any>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [bannerVisible, setBannerVisible] = useState(false);

    const { isAuthenticated, user } = useAuth0();

    const dispatch = useDispatch();
    const indexSlice = useSelector(indexSliceState);
    const responsiveSlice = useSelector(responsiveSliceState);
    const query: PropertySearchQuery = PropertyService.getSearchQuery();
    // eslint-disable-next-line no-unused-vars
    const url: URLObjectEntity = URLObjectService.getURLObject(
        searchParams,
        setSearchParams
    );

    url.setPropertySearchQuery(query);

    const hideMenu = () => {
        dispatch(
            setOverlays({
                hamburgerMenu: false,
                propertySearch: false,
                lenderDefault: false,
                lenderOverlay: false,
                indexesOverlay: false,
                ratesOverlay: false,
                myPropertiesOverlay: false
            })
        );
        setActiveSearch(false);
        setFocus(false);
    };

    // useEffect(() => {
    //     if (!window.localStorage.getItem("video-banner-closed"))
    //         setTimeout(() => setBannerVisible(true), 4000);
    // }, []);

    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.scrollY;
        let ticking = false;

        const updateHeaderState = () => {
            const scrollY = window.scrollY;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setHeaderState(
                scrollY > lastScrollY && scrollY > 80
                    ? "header--sticky-down"
                    : scrollY < 80
                    ? ""
                    : "header--sticky-up"
            );
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateHeaderState);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        if (currentParams !== undefined) {
            // url.fromUrlToQuery();
            fromQueryObjectToDropdown();
        }
    }, []);

    const onFocus = () => {
        setDropdown(true);
    };

    const onBlur = () => {
        setDropdown(false);
    };

    const fromQueryObjectToDropdown = () => {
        const propertyTypeValue = query.filters.propertyType
            .map((item: TypePositiveInteger) => item.value)
            .toArray();
        setSelectedPropertyTypeValue(propertyTypeValue);
    };

    return (
        <>
            <div
                className={`site-header__banner ${bannerVisible && "visible"}`}
            >
                <div className="site-header__banner__text">
                    {/* We&apos;re improving our system to give you an even better
                    experience. Some features on our marketplace may be
                    temporarily unavailable, but we&apos;re working hard to get
                    them back up and running soon. We apologize for any
                    inconvenience, and appreciate your patience. */}
                    Watch a{" "}
                    <a
                        href="https://youtu.be/fIjWvPSxp6o?si=V1rCCro7fWZsJUzM"
                        target="_blank"
                        rel="noreferrer"
                    >
                        3 min
                    </a>{" "}
                    or{" "}
                    <a
                        href="https://youtu.be/rvt_glrWrS8?si=oA7UdAD3i2UVCl65"
                        target="_blank"
                        rel="noreferrer"
                    >
                        30 min
                    </a>{" "}
                    video tutorial to learn how to use the marketplace to save
                    time and money acquiring, underwriting and/or financing CRE
                </div>
                <div className="site-header__banner-close">
                    <FontAwesomeIcon
                        icon={faClose}
                        onClick={() => {
                            window.localStorage.setItem(
                                "video-banner-closed",
                                "1"
                            );
                            setBannerVisible(false);
                        }}
                    />
                </div>
            </div>
            ){/* <BlockMessageForMobile /> */}
            <header className={`site-header__container ${headerState} `}>
                <a
                    className="site-sidebar__logo"
                    href="https://gparency.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => hotjar.event("Link Marketing Site")}
                >
                    <img
                        className="site-sidebar__logo--L"
                        src={Logo}
                        alt="Logo"
                    />
                    <img
                        className="site-sidebar__logo--S"
                        src={LogoS}
                        alt="Logo"
                    />
                </a>
                <div
                    onClick={hideMenu}
                    className={`page-overlay ${
                        responsiveSlice.overlays.hamburgerMenu ||
                        isActiveSearch ||
                        hasFocus
                            ? "active"
                            : ""
                    }`}
                ></div>

                <div
                    className={`header__search ${
                        isActiveSearch ? "active" : ""
                    } ${isDropdown ? "header__search--focused" : ""}`}
                >
                    <button
                        className={`main-menu-collapser ${
                            isMenuCollapsed ? "collapsed" : ""
                        }`}
                        role="button"
                        onClick={() => {
                            dispatch(
                                setLenderFlyout({
                                    isOpen: false,
                                    data: undefined,
                                    type: undefined
                                })
                            );
                            setIsMenuCollapsed(!isMenuCollapsed);
                            hotjar.event(
                                `Left Nav ${
                                    isMenuCollapsed ? "Opened" : "Collapsed"
                                }`
                            );
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowRightToBracket} />
                        <FontAwesomeIcon icon={faArrowRightToBracket} />
                    </button>

                    <div onFocus={onFocus} onBlur={onBlur}>
                        <BlockAutoCompleteSearch
                            setCoordinates={setCoordinates}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            setSearchIsTriggered={setSearchIsTriggered}
                        />
                    </div>

                    <div onFocus={onFocus} onBlur={onBlur}>
                        <PartDropdownProperties
                            parent="header"
                            placeholder={`All Property Types (${
                                indexSlice.propertyTypes.length || ""
                            })`}
                            dropdownClassName="property-types-dropdown"
                            name="propertyTypes"
                            value={selectedPropertyTypeValue}
                            noContentMessage="No property types match your search criteria."
                            onChange={(values: any) => {
                                query.filters.setPropertyTypes(
                                    new Collection(values).map(
                                        (propertyType: any) => {
                                            return new TypePositiveInteger(
                                                propertyType
                                            );
                                        }
                                    )
                                );
                            }}
                            options={indexSlice.propertyTypes}
                            hotjarEventName="Header Property Types Dropdown"
                        />
                    </div>
                </div>

                {/* <BlockPropertyTypes
                    setSearchIsTriggered={setSearchIsTriggered}
                    searchIsTriggered={searchIsTriggered}
                /> */}

                <PartButton
                    type="secondary"
                    className={`header__filters-btn ${
                        indexSlice.filtersPopupOpen ? "active" : ""
                    }`}
                    onClick={() => dispatch(setFiltersPopupOpen(true))}
                    disabled={query.hasLenderPortfolioItems()}
                >
                    All Filters
                </PartButton>

                <span
                    className={`site-header__show-property-search ${
                        responsiveSlice.overlays.propertySearch ? "open" : ""
                    }`}
                    onClick={() => {
                        dispatch(
                            setOverlays({
                                hamburgerMenu: false,
                                propertySearch: responsiveSlice.overlays
                                    .propertySearch
                                    ? false
                                    : true,
                                userMenu: false,
                                lenderDefault: false,
                                lenderOverlay: false,
                                indexesOverlay: false,
                                ratesOverlay: false,
                                myPropertiesOverlay: false
                            })
                        );
                        dispatch(
                            setShowMapBtn(
                                responsiveSlice.overlays.propertySearch
                                    ? true
                                    : false
                            )
                        );
                        hotjar.event(
                            `Mobile Header Property Search ${
                                responsiveSlice.overlays.propertySearch
                                    ? "Closed"
                                    : "Opened"
                            }`
                        );
                    }}
                >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </span>

                <div className="site-header__nav-wrap">
                    {!indexSlice.isPaidUser ? (
                        <div className="site-header__upgrade">
                            <span
                                onClick={() => {
                                    dispatch(
                                        setIframe({
                                            show: true,
                                            street: "",
                                            city: "",
                                            propId: "",
                                            mapLat: "",
                                            mapLng: "",
                                            mapZoom: "",
                                            type: "demo",
                                            authUser: "true",
                                            whereFrom:
                                                WHERE_FROM_CTA.NON_PAID_USER_NAV_BAR_DEMO
                                        })
                                    );
                                }}
                            >
                                Schedule a Free Tour
                            </span>
                            {/* <div className="site-header__popups-popup">
                                <h3>
                                    Upgrade Membership. Lock in competitive
                                    edge.
                                </h3>
                                <ul>
                                    <li>
                                        Enjoy more of your favorite tools to
                                        find, finance, and underwrite deals
                                    </li>
                                    <li>
                                        Add unlimited deals to your pipeline
                                    </li>
                                    <li>
                                        Access the top lenders for your deal
                                    </li>
                                </ul>
                                <div className="site-header__popup_bttns">
                                    <a
                                        className="site-header__details-hover"
                                        href="https://gparency.com/membership/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        More Details
                                    </a>
                                    <button
                                        className="site-header__member-hover"
                                        onClick={() => {
                                            dispatch(
                                                setIframe({
                                                    show: true,
                                                    street: "",
                                                    city: "",
                                                    propId: "",
                                                    mapLat: "",
                                                    mapLng: "",
                                                    mapZoom: "",
                                                    type: "demo",
                                                    authUser: "true",
                                                    whereFrom:
                                                        WHERE_FROM_CTA.NON_PAID_USER_NAV_BAR_DEMO
                                                })
                                            );
                                        }}
                                    >
                                        Become a Member
                                    </button>
                                </div>
                            </div> */}
                        </div>
                    ) : null}
                    <div
                        className={`site-header__hamburger ${
                            responsiveSlice.overlays.hamburgerMenu ? "open" : ""
                        }`}
                    >
                        <FontAwesomeIcon
                            icon={faBars}
                            onClick={() => {
                                dispatch(
                                    setOverlays({
                                        ...responsiveSlice.overlays,
                                        hamburgerMenu: true,
                                        propertySearch: false
                                    })
                                );

                                dispatch(setShowMapBtn(false));
                                hotjar.event("Main Nav Menu Opened");
                            }}
                        />
                        <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                                dispatch(
                                    setOverlays({
                                        ...responsiveSlice.overlays,
                                        hamburgerMenu: false
                                    })
                                );
                                dispatch(setShowMapBtn(true));
                                hotjar.event("Main Nav Menu Closed");
                            }}
                        />
                        <nav
                            className={`site-header__user-dropdown-wrap main-menu`}
                        >
                            <ul className="site-nav__list">
                                <li className="menu-item menu-item--back">
                                    <a
                                        onClick={() => {
                                            hotjar.event("Back to Tools CTA");
                                            dispatch(
                                                setCurrentView("dashboard")
                                            );
                                            dispatch(setShowMapBtn(true));
                                            hideMenu();
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faChartTreeMap}
                                        />
                                        Back to Tools
                                    </a>
                                </li>
                                <li className="menu-item menu-item--back">
                                    <a
                                        onClick={() => {
                                            hotjar.event("Back to Map CTA");
                                            dispatch(setCurrentView("map"));
                                            dispatch(setShowMapBtn(false));
                                            hideMenu();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faMap} />
                                        Map
                                    </a>
                                </li>
                                <li className="menu-item">
                                    <a
                                        href="https://gparency.com/pricing/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() =>
                                            hotjar.event(
                                                "Link Header Pricing Click"
                                            )
                                        }
                                    >
                                        Pricing
                                    </a>
                                </li>{" "}
                                <li className="menu-item">
                                    <a
                                        href="https://gparency.com/financing/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() =>
                                            hotjar.event(
                                                "Link Header Financing Click"
                                            )
                                        }
                                    >
                                        Financing
                                    </a>
                                </li>{" "}
                                <li className="menu-item">
                                    <a
                                        href="https://gparency.com/live-listings/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() =>
                                            hotjar.event(
                                                "Link Header Listings Click"
                                            )
                                        }
                                    >
                                        Listings
                                    </a>
                                </li>{" "}
                                <li className="menu-item">
                                    <a
                                        href="https://gparency.com/company/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() =>
                                            hotjar.event(
                                                "Link Header Company Click"
                                            )
                                        }
                                    >
                                        Company
                                    </a>
                                </li>
                                <li className="menu-item">
                                    <a
                                        href="https://gparency.com/lenders/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() =>
                                            hotjar.event(
                                                "Link Header Lenders Click"
                                            )
                                        }
                                    >
                                        Lenders
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div
                        className="site-header__navigation-dropdown"
                        onClick={() =>
                            dispatch(
                                setOverlays({
                                    ...responsiveSlice.overlays,
                                    userMenu: true
                                })
                            )
                        }
                    >
                        <div className="site-header__user">
                            {isAuthenticated ? (
                                <img
                                    src={user?.picture}
                                    alt="profile pic"
                                    referrerPolicy="no-referrer"
                                />
                            ) : (
                                <FontAwesomeIcon icon={faCircleUser} />
                            )}
                        </div>
                        <div
                            className={`site-header__user-dropdown-wrap ${
                                responsiveSlice.overlays.userMenu ? "open" : ""
                            }`}
                        >
                            {isAuthenticated && (
                                <div className="site-header__user-dropdown-info">
                                    <h5 className="site-header__user-dropdown__name">
                                        {user?.name}
                                    </h5>
                                    <p className="site-header__user-dropdown__email">
                                        {user?.email}
                                    </p>
                                    <span
                                        className={`site-header__user-dropdown__status ${
                                            indexSlice.isPaidUser
                                                ? "member"
                                                : "free"
                                        }`}
                                    >
                                        {indexSlice.isPaidUser &&
                                        indexSlice.isLimitedUser
                                            ? "Limited Member"
                                            : indexSlice.isPaidUser
                                            ? "Member"
                                            : "Free User"}
                                    </span>
                                </div>
                            )}
                            {!indexSlice.isPaidUser && isAuthenticated ? (
                                <div className="site-header__member-cta">
                                    <button
                                        onClick={() => {
                                            dispatch(setTrialPopupOpen(false));
                                            dispatch(
                                                setIframe({
                                                    show: true,
                                                    street: "",
                                                    city: "",
                                                    propId: "",
                                                    mapLat: "",
                                                    mapLng: "",
                                                    mapZoom: "",
                                                    type: "join",
                                                    authUser: "true",
                                                    whereFrom:
                                                        WHERE_FROM_CTA.NON_PAID_USER_PROFILE_ICON_MEMBERSHIP_UPGRADE
                                                })
                                            );
                                        }}
                                    >
                                        Become a Member
                                    </button>
                                </div>
                            ) : null}
                            {isAuthenticated ? (
                                <LogoutButton />
                            ) : (
                                <LoginButton />
                            )}
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default BlockHeader;
