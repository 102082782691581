import Exception from "../../utils/exceptions/Exception";
import BaseValueObject from "./BaseValueObject";

export default class TypeFloat extends BaseValueObject {
    /**
     * @param {number | string} value
     */
    constructor(value: any) {
        if (isNaN(value)) {
            throw new Exception(`INVALID_FLOAT_VALUE`, 400);
        }
        super(parseFloat(value));
    }
}
