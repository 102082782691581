import * as yup from "yup";
export const findALenderSelectionSchema = yup.object().shape({
    loanType: yup.number().required("Loan Type is required"),
    state: yup.number().required("State is required"),
    propertyType: yup.number().required("Property Type is required")
});

export const addTransactionSchema = yup.object().shape({
    transactionType: yup.string().required("Transaction Type is required")
    // amount: yup.string().required("Amount is required")
});
