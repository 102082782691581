import BubbleFactory from "../map/bubble/BubbleFactory";
import PropertyMarkerFactory from "../map/propertyMarker/PropertyMarkerFactory";
import StatsFactory from "../stats/StatsFactory";
import Collection from "../valueObject/Collection";
import PropertySearchQuery from "./queries/PropertySearchQuery";
import TypeFloat from "../valueObject/TypeFloat";
import TypeString from "../valueObject/TypeString";
import FavoritePropertyFactory from "../favoriteProperty/FavoritePropertyFactory";

export default class PropertyRepository {
    _gateway: any;
    _factory: any;

    constructor(gateway: any, factory: any) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async search(searchQuery: PropertySearchQuery) {
        const isLenderSearch = searchQuery.hasLenderPortfolioItems();
        let data = new Collection({});
        if (isLenderSearch) {
            data = new Collection(
                await this._gateway.searchByLenders(searchQuery)
            );
            const zoomLevel = searchQuery.zoomLevel?.value;
            if (zoomLevel >= 8) {
                //only get listings if zoom level is 8 or greater
                const listings = new Collection(
                    await this._gateway.search(searchQuery)
                );
                const listingItems = listings
                    .get("items")
                    .filter((item: any) => {
                        return (
                            item.markerType === 1 || item.isFavorite === true
                        ); // get all listings and all favorites (even comps)
                    });
                const dataItems = data.get("items");
                const items = dataItems.concat(listingItems);

                data.set("items", items);
            }
        } else {
            data = new Collection(await this._gateway.search(searchQuery));
        }
        let items = new Collection([]);
        let currentTotals = new Collection([]);

        let currentListingsCount = 0;
        let currentFinanceComparablesCount = 0;
        let currentSalesComparablesCount = 0;

        if (!data.slice("items").isEmpty()) {
            items = data.slice("items").map((itemData: any) => {
                const item = new Collection(itemData);
                if (item.has("markerType")) {
                    return new PropertyMarkerFactory().reconstitute(item);
                } else {
                    return isLenderSearch
                        ? new BubbleFactory().reconstituteLenders(item)
                        : new BubbleFactory().reconstitute(item);
                }
            });
        }
        // Extract current totals from response
        currentTotals = data.slice("totals");

        if (isLenderSearch) {
            const lenderStats = new Collection({});
            if (searchQuery.hasFirstLenderName())
                lenderStats.set(
                    "firstLenderName",
                    currentTotals.get(searchQuery.firstLenderName?.value)
                );

            if (searchQuery.hasSecondLenderName())
                lenderStats.set(
                    "secondLenderName",
                    currentTotals.get(searchQuery.secondLenderName?.value)
                );

            if (searchQuery.hasThirdLenderName())
                lenderStats.set(
                    "thirdLenderName",
                    currentTotals.get(searchQuery.thirdLenderName?.value)
                );

            searchQuery._lenderPortfolioStats = lenderStats;
        } else {
            currentListingsCount = currentTotals.get("listings");
            currentFinanceComparablesCount = currentTotals.get("financeComps");
            currentSalesComparablesCount = currentTotals.get("saleComps");

            searchQuery._stats = new StatsFactory().reconstitute(
                new Collection({
                    listingsCurrentCount: currentListingsCount,
                    financeComparablesCurrentCount:
                        currentFinanceComparablesCount,
                    salesComparablesCurrentCount: currentSalesComparablesCount
                })
            );
        }

        searchQuery._items = items;
        searchQuery._executeAfterChangeTriggers();
    }

    async getById(propertyId: TypeString) {
        const data = new Collection(await this._gateway.getById(propertyId));

        const propertyDetail = this._factory.reconstituteProperty(
            data.slice("responseData").slice("propertyData")
        );

        const userPropertyStar = new FavoritePropertyFactory().reconstitute(
            data.slice("responseData").slice("userPropertyStar")
        );

        const propertyData = {
            propertyDetail,
            userPropertyStar
        };

        return propertyData;
    }

    async getTotalCount() {
        const data = new Collection(await this._gateway.getTotalCount());
        return this._factory.reconstituteTotalCount(data);
    }

    async getByGeoData(
        street: TypeString,
        state: TypeString,
        county: TypeString,
        zip: TypeString,
        city: TypeString,
        latitude: TypeFloat,
        longitude: TypeFloat
    ) {
        const data = new Collection(
            await this._gateway.getByGeoData(
                street,
                state,
                county,
                zip,
                city,
                latitude,
                longitude
            )
        );
        return this._factory.reconstituteProperty(data);
    }
}
