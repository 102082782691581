import Collection from "../../valueObject/Collection";
import TypeFloat from "../../valueObject/TypeFloat";
import PropertySearchQuery from "./PropertySearchQuery";
import PropertySearchQueryParameters from "./PropertySearchQueryParameters";

export default class PropertySearchQueryMap {
    static map(searchQuery: PropertySearchQuery) {
        const data = new Collection({});

        const LONGITUDE_WEST_LIMIT = new TypeFloat(-179.99);
        // const LONGITUDE_EAST_LIMIT = new TypeFloat(-54);

        PropertySearchQueryMap._mapFilters(searchQuery, data);

        // SearchQueryMap._mapAutocompleteSearch(searchQuery, data);

        // if (searchQuery.hasRadius()) data.set("radius", searchQuery.radius);
        // if (searchQuery.hasMapCenterLatitude())
        //     data.set("mapCenterLatitude", searchQuery.mapCenterLatitude);
        // if (searchQuery.hasMapCenterLongitude())
        //     data.set("mapCenterLongitude", searchQuery.mapCenterLongitude);
        if (searchQuery.hasZoomLevel())
            data.set(
                PropertySearchQueryParameters.zoomLevel,
                searchQuery.zoomLevel
            );
        if (searchQuery.hasMapBottomRightLatitude())
            data.set(
                PropertySearchQueryParameters.mapBottomRightLatitude,
                searchQuery.mapBottomRightLatitude
            );
        if (searchQuery.hasMapBottomRightLongitude())
            data.set(
                PropertySearchQueryParameters.mapBottomRightLongitude,
                searchQuery.mapBottomRightLongitude
            );
        if (searchQuery.hasMapTopLeftLatitude())
            data.set(
                PropertySearchQueryParameters.mapTopLeftLatitude,
                searchQuery.mapTopLeftLatitude
            );
        if (searchQuery.hasMapTopLeftLongitude())
            data.set(
                PropertySearchQueryParameters.mapTopLeftLongitude,
                searchQuery.mapTopLeftLongitude.value <
                    LONGITUDE_WEST_LIMIT.value
                    ? LONGITUDE_WEST_LIMIT
                    : searchQuery.mapTopLeftLongitude
            );
        if (searchQuery.hasListingsLimit())
            data.set(
                PropertySearchQueryParameters.listingLimit,
                searchQuery.listingsLimit
            );

        if (searchQuery.hasCompsLimit())
            data.set(
                PropertySearchQueryParameters.compsLimit,
                searchQuery.compsLimit
            );

        if (searchQuery.hasShowOnlyFavoriteProperties()) {
            data.set(
                PropertySearchQueryParameters.favoritesOnly,
                searchQuery.showOnlyFavoriteProperties
            );
        }

        return data;
    }

    private static _mapFilters(
        searchQuery: PropertySearchQuery,
        data: Collection
    ) {
        const filter = new Collection({});

        if (searchQuery.filters.hasListingsChecked())
            filter.set(
                PropertySearchQueryParameters.listingsChecked,
                searchQuery.filters.listingsChecked
            );
        if (searchQuery.filters.hasSalesComparablesChecked())
            filter.set(
                PropertySearchQueryParameters.salesComparablesChecked,
                searchQuery.filters.salesComparablesChecked
            );
        if (searchQuery.filters.hasFinanceComparablesChecked())
            filter.set(
                PropertySearchQueryParameters.financeComparablesChecked,
                searchQuery.filters.financeComparablesChecked
            );
        if (searchQuery.filters.hasPriceMin())
            filter.set(
                PropertySearchQueryParameters.priceMin,
                searchQuery.filters.priceMin
            );
        if (searchQuery.filters.hasPriceMax())
            filter.set(
                PropertySearchQueryParameters.priceMax,
                searchQuery.filters.priceMax
            );
        if (searchQuery.filters.hasDateRangeListings())
            filter.set(
                PropertySearchQueryParameters.listingsDateRange,
                searchQuery.filters.dateRangeListings
            );
        if (searchQuery.filters.hasDateRangeSalesComparables())
            filter.set(
                PropertySearchQueryParameters.salesComparablesDateRange,
                searchQuery.filters.dateRangeSalesComparables
            );
        if (searchQuery.filters.hasDateRangeFinanceComparables())
            filter.set(
                PropertySearchQueryParameters.financeComparablesDateRange,
                searchQuery.filters.dateRangeFinanceComparables
            );

        if (searchQuery.filters.hasListingBrokerage()) {
            filter.set(
                PropertySearchQueryParameters.listingBrokerage,
                searchQuery.filters.listingBrokerage
            );
        }

        if (searchQuery.filters.hasListingBroker()) {
            filter.set(
                PropertySearchQueryParameters.listingBroker,
                searchQuery.filters.listingBroker
            );
        }

        if (searchQuery.filters.hasPropertyType()) {
            filter.set(
                PropertySearchQueryParameters.propertyType,
                searchQuery.filters.propertyType
            );
        }

        if (!filter.isEmpty())
            data.set(PropertySearchQueryParameters.filter, filter);
    }

    // private static _mapAutocompleteSearch(
    //     searchQuery: SearchQuery,
    //     data: Collection
    // ) {
    //     const autocompleteSearch = new Collection({});

    //     if (searchQuery.autocompleteSearch.hasLatitude())
    //         autocompleteSearch.set(
    //             "latitude",
    //             searchQuery.autocompleteSearch.latitude
    //         );
    //     if (searchQuery.autocompleteSearch.hasLongitude())
    //         autocompleteSearch.set(
    //             "longitude",
    //             searchQuery.autocompleteSearch.longitude
    //         );

    //     if (!autocompleteSearch.isEmpty())
    //         data.set("autocompleteSearch", autocompleteSearch);
    // }
}
