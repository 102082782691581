import store from "../../../store/storeIndex";
import Collection from "../../valueObject/Collection";
import IFrameMessage from "../IFrameMessage";

export default class OpenPopupMessage extends IFrameMessage {
    public static ACTION_NAME = "open-popup";

    constructor() {
        super(OpenPopupMessage.ACTION_NAME);
    }

    public send(data: Collection, iframe: HTMLIFrameElement) {
        const token = store.getState().index.token;
        data.set("token", token);
        super._send(data, iframe);
    }

    public recieve(callback: Function) {
        callback();
    }
}
