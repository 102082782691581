import React from "react";
import LogoS from "../assets/images/logo-mobile-circular.svg";

interface SpinnerProps {
    visible: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ visible }) => {
    if (!visible) return null;
    return (
        <div className="overlay">
            <div className="spinner">
                <img src={LogoS} alt="Loading..." className="spinner-image" />
            </div>
        </div>
    );
};

export default Spinner;
