import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import BrokerageEntity from "./BrokerageEntity";

export default class BrokerageFactory {
    reconstitute(item: Collection) {
        const instance = new BrokerageEntity();
        if (item.has("name"))
            instance.setName(new TypeString(item.get("name")));

        return instance;
    }
}
