import BaseModel from "../../BaseModel";
import PropertyFilterEntity from "../../propertyFilter/PropertyFilterEntity";
import StatsEntity from "../../stats/StatsEntity";
import Collection from "../../valueObject/Collection";
import TypeFloat from "../../valueObject/TypeFloat";
import TypeString from "../../valueObject/TypeString";
import TypePositiveInteger from "../../valueObject/TypePositiveInteger";
import TypeBoolean from "../../valueObject/TypeBoolean";

export default class PropertySearchQuery extends BaseModel {
    _filters: PropertyFilterEntity = new PropertyFilterEntity();
    // _autocompleteSearch: AutoCompleteSearchEntity =
    //     new AutoCompleteSearchEntity();
    _autoCompleteSearchQuery: TypeString;
    _propertyId: TypeString | undefined;
    _zoomLevel: TypeFloat;
    _mapCenterLatitude: TypeFloat;
    _mapCenterLongitude: TypeFloat;
    _mapTopLeftLatitude: TypeFloat;
    _mapTopLeftLongitude: TypeFloat;
    _mapBottomRightLatitude: TypeFloat;
    _mapBottomRightLongitude: TypeFloat;
    // _radius: TypeFloat; // rezultat oduzimanja koordinata
    _items: Collection = new Collection([]);
    _favorites: Collection = new Collection([]);
    _stats: StatsEntity = new StatsEntity();
    _lenderPortfolioStats: Collection = new Collection({});
    _listingsLimit: TypePositiveInteger;
    _compsLimit: TypePositiveInteger;
    _showOnlyFavoriteProperties: TypeBoolean = new TypeBoolean(false);
    _lenderPortfolioItems: Collection = new Collection([]);
    _firstLenderName: TypeString | undefined;
    _secondLenderName: TypeString | undefined;
    _thirdLenderName: TypeString | undefined;

    get filters(): PropertyFilterEntity {
        return this._filters;
    }

    setFilters(filters: PropertyFilterEntity) {
        this._filters = filters;
        return this;
    }

    hasFilters(): boolean {
        return (
            this._filters instanceof PropertyFilterEntity ||
            this._filters !== undefined
        );
    }

    get autoCompleteSearchQuery(): TypeString {
        return this._autoCompleteSearchQuery;
    }
    setAutoCompleteSearchQuery(autoCompleteSearchQuery: TypeString) {
        if (!this._autoCompleteSearchQuery?.sameAs(autoCompleteSearchQuery)) {
            this._autoCompleteSearchQuery = autoCompleteSearchQuery;
            this._executeAfterChangeTriggers();
        }
        return this;
    }
    hasAutoCompleteSearchQuery(): boolean {
        return this._autoCompleteSearchQuery instanceof TypeString;
    }

    get propertyId(): TypeString | undefined {
        return this._propertyId;
    }
    setPropertyId(propertyId: TypeString | undefined) {
        if (!this._propertyId?.sameAs(propertyId)) {
            this._propertyId = propertyId;
            this._executeAfterChangeTriggers();
        }
        return this;
    }
    hasPropertyId(): boolean {
        return this._propertyId instanceof TypeString;
    }

    get mapTopLeftLatitude(): TypeFloat {
        return this._mapTopLeftLatitude;
    }
    setMapTopLeftLatitude(mapTopLeftLatitude: TypeFloat) {
        if (!this._mapTopLeftLatitude?.sameAs(mapTopLeftLatitude)) {
            this._mapTopLeftLatitude = mapTopLeftLatitude;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasMapTopLeftLatitude(): boolean {
        return this._mapTopLeftLatitude instanceof TypeFloat;
    }

    get mapTopLeftLongitude(): TypeFloat {
        return this._mapTopLeftLongitude;
    }

    setMapTopLeftLongitude(mapTopLeftLongitude: TypeFloat) {
        if (!this._mapTopLeftLongitude?.sameAs(mapTopLeftLongitude)) {
            this._mapTopLeftLongitude = mapTopLeftLongitude;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasMapTopLeftLongitude(): boolean {
        return this._mapTopLeftLongitude instanceof TypeFloat;
    }

    get mapBottomRightLatitude(): TypeFloat {
        return this._mapBottomRightLatitude;
    }

    setMapBottomRightLatitude(mapBottomRightLatitude: TypeFloat) {
        if (!this._mapBottomRightLatitude?.sameAs(mapBottomRightLatitude)) {
            this._mapBottomRightLatitude = mapBottomRightLatitude;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasMapBottomRightLatitude(): boolean {
        return this._mapBottomRightLatitude instanceof TypeFloat;
    }

    get mapBottomRightLongitude(): TypeFloat {
        return this._mapBottomRightLongitude;
    }

    setMapBottomRightLongitude(mapBottomRightLongitude: TypeFloat) {
        if (!this._mapBottomRightLongitude?.sameAs(mapBottomRightLongitude)) {
            this._mapBottomRightLongitude = mapBottomRightLongitude;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasMapBottomRightLongitude(): boolean {
        return this._mapBottomRightLongitude instanceof TypeFloat;
    }
    // get autocompleteSearch(): AutoCompleteSearchEntity {
    //     return this._autocompleteSearch;
    // }

    // setAutocompleteSearch(autocompleteSearch: AutoCompleteSearchEntity) {
    //     this._autocompleteSearch = autocompleteSearch;
    //     return this;
    // }

    // hasAutoCompleteSearch(): boolean {
    //     return this._autocompleteSearch instanceof AutoCompleteSearchEntity;
    // }

    get zoomLevel(): TypeFloat {
        return this._zoomLevel;
    }

    setZoomLevel(zoomLevel: TypeFloat) {
        if (!this._zoomLevel?.sameAs(zoomLevel)) {
            this._zoomLevel = zoomLevel;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasZoomLevel(): boolean {
        return this._zoomLevel instanceof TypeFloat;
    }

    get mapCenterLatitude(): TypeFloat {
        return this._mapCenterLatitude;
    }

    setMapCenterLatitude(mapCenterLatitude: TypeFloat) {
        if (!this._mapCenterLatitude?.sameAs(mapCenterLatitude)) {
            this._mapCenterLatitude = mapCenterLatitude;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasMapCenterLatitude(): boolean {
        return this._mapCenterLatitude instanceof TypeFloat;
    }

    get mapCenterLongitude(): TypeFloat {
        return this._mapCenterLongitude;
    }

    setMapCenterLongitude(mapCenterLongitude: TypeFloat) {
        if (!this._mapCenterLongitude?.sameAs(mapCenterLongitude)) {
            this._mapCenterLongitude = mapCenterLongitude;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasMapCenterLongitude(): boolean {
        return this._mapCenterLongitude instanceof TypeFloat;
    }

    // get radius(): TypeFloat {
    //     return this._radius;
    // }

    // setRadius(radius: TypeFloat) {
    //     this._radius = radius;
    //     return this;
    // }

    // hasRadius(): boolean {
    //     return this._radius instanceof TypeFloat;
    // }

    get items(): Collection {
        return this._items;
    }

    setItems(items: Collection) {
        if (!this._items?.isSameAs(items)) {
            this._items = items;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasItems(): boolean {
        return !this._items.isEmpty();
    }

    get favorites(): Collection {
        return this._favorites;
    }
    setFavorites(favorites: Collection) {
        if (!this._favorites?.isSameAs(favorites)) {
            this._favorites = favorites;
            this._executeAfterChangeTriggers();
        }
        return this;
    }
    hasFavorites(): boolean {
        return !this._favorites.isEmpty();
    }

    get stats(): StatsEntity {
        return this._stats;
    }

    setStats(stats: StatsEntity) {
        this._stats = stats;
        this._executeAfterChangeTriggers();
        return this;
    }

    hasStats(): boolean {
        return this._stats instanceof StatsEntity;
    }

    get listingsLimit(): TypePositiveInteger {
        return this._listingsLimit;
    }
    setListingsLimit(value: TypePositiveInteger) {
        this._listingsLimit = value;
        return this;
    }
    hasListingsLimit(): boolean {
        return this._listingsLimit instanceof TypePositiveInteger;
    }

    get compsLimit(): TypePositiveInteger {
        return this._compsLimit;
    }
    setCompsLimit(value: TypePositiveInteger) {
        this._compsLimit = value;
        return this;
    }

    hasCompsLimit(): boolean {
        return this._compsLimit instanceof TypePositiveInteger;
    }

    get showOnlyFavoriteProperties(): TypeBoolean {
        return this._showOnlyFavoriteProperties;
    }

    setShowOnlyFavoriteProperties(value: TypeBoolean) {
        this._showOnlyFavoriteProperties = value;
        return this;
    }

    hasShowOnlyFavoriteProperties(): boolean {
        return this._showOnlyFavoriteProperties instanceof TypeBoolean;
    }

    get lenderPortfolioItems(): Collection {
        const items = new Collection([]);

        if (this.hasFirstLenderName())
            items.insert(this._firstLenderName?.value);
        if (this.hasSecondLenderName())
            items.insert(this._secondLenderName?.value);
        if (this.hasThirdLenderName())
            items.insert(this._thirdLenderName?.value);
        return items;
    }

    hasLenderPortfolioItems(): boolean {
        return (
            this._firstLenderName instanceof TypeString ||
            this._secondLenderName instanceof TypeString ||
            this._thirdLenderName instanceof TypeString
        );
    }

    clearLenderPortfolioItems() {
        this._firstLenderName = undefined;
        this._secondLenderName = undefined;
        this._thirdLenderName = undefined;

        return this;
    }

    get lenderPortfolioStats(): Collection {
        return this._lenderPortfolioStats;
    }
    setLenderPortfolioStats(lenderPortfolioStats: Collection) {
        this._lenderPortfolioStats = lenderPortfolioStats;
        this._executeAfterChangeTriggers();
        return this;
    }

    hasLenderPortfolioStats(): boolean {
        return this._lenderPortfolioStats instanceof Collection;
    }

    get firstLenderName(): TypeString | undefined {
        return this._firstLenderName;
    }
    setFirstLenderName(firstLenderName: TypeString | undefined) {
        this._firstLenderName = firstLenderName;
        return this;
    }
    hasFirstLenderName(): boolean {
        return this._firstLenderName instanceof TypeString;
    }

    get secondLenderName(): TypeString | undefined {
        return this._secondLenderName;
    }
    setSecondLenderName(secondLenderName: TypeString | undefined) {
        this._secondLenderName = secondLenderName;
        return this;
    }
    hasSecondLenderName(): boolean {
        return this._secondLenderName instanceof TypeString;
    }

    get thirdLenderName(): TypeString | undefined {
        return this._thirdLenderName;
    }
    setThirdLenderName(thirdLenderName: TypeString | undefined) {
        this._thirdLenderName = thirdLenderName;
        return this;
    }
    hasThirdLenderName(): boolean {
        return this._thirdLenderName instanceof TypeString;
    }
}
