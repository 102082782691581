import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import VendorRoleEntity from "./VendorRoleEntity";

export default class VendorRoleFactory {
    reconstitute(data: Collection) {
        const vendorRole = new VendorRoleEntity();
        if (data.has("description"))
            vendorRole.setDescription(new TypeString(data.get("description")));
        if (data.has("roleName"))
            vendorRole.setRoleName(new TypeString(data.get("roleName")));
        if (data.has("code"))
            vendorRole.setCode(new TypeString(data.get("code")));

        return vendorRole;
    }
}
