import { BlockPropertyCardTabs } from "./BlockPropertyCardTabs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, {
    setPropertyCardClassName,
    setPropertyCardItem
} from "../../../store";
import PropertyService from "../../../domain/property/PropertyService";
import PropertyViewMapper from "../../../domain/property/PropertyViewMapper";
import { PartPropertyCardTop } from "../../parts/PartPropertyCardTop";
import { PartPropertyCardButtons } from "../../parts/PartPropertyCardButtons";
import { convertFromBinaryToImage } from "../../../utils/utils";
import TypeString from "../../../domain/valueObject/TypeString";
import BlockCardLoader from "../BlockCardLoader";
import PropertyVendorService from "../../../domain/propertyVendor/PropertyVendorService";
import PropertyVendorViewMapper from "../../../domain/propertyVendor/PropertyVendorViewMapper";
import favoritePropertySliceState, {
    setUserPropertyStar
} from "../../../store/favoriteProperty";
import mapTriggersSliceState, {
    setPropertyCardHasAlerts,
    setPropertyCardMarkAsFavorite,
    setPropertyFavoriteActionTriggered
} from "../../../store/mapTriggers";
import FavoritePropertyViewMapper from "../../../domain/favoriteProperty/FavoritePropertyViewMapper";
import FavoritePropertyService from "../../../domain/favoriteProperty/FavoritePropertyService";
import Collection from "../../../domain/valueObject/Collection";

const BlockPropertyCard = (props: any) => {
    const dispatch = useDispatch();
    const indexState = useSelector(indexSliceState);
    const mapTriggerState = useSelector(mapTriggersSliceState);
    const [isLoading, setLoading] = useState(true);
    const [propertyCardData, setPropertyCardData] = useState<any>({});
    const [propertyCardImage, setPropertyCardImage] = useState<any>(undefined);
    const [propertyVendors, setPropertyVendors] = useState<Array<any>>([]);
    const { propertyTypeName } = propertyCardData || undefined;
    const query = PropertyService.getSearchQuery();
    const favoritePropertiesSlice = useSelector(favoritePropertySliceState);
    const pipelineCapacity = favoritePropertiesSlice.pipelineCapacity;
    const favoriteProperties = favoritePropertiesSlice.favoritesProperties;
    const [pipelineOverLimit, setOverlimit] = useState(false);
    const sliceState = useSelector(indexSliceState);
    const isLoggedIn = sliceState.isLoggedIn;

    const getPropertyStar = async (propertyId: string) => {
        const isFavorite =
            favoriteProperties &&
            favoriteProperties.some((i: any) => i?.propertyId === props.id);
        try {
            const result = await FavoritePropertyService.getPropertyStar(
                new TypeString(propertyId)
            );
            if (result.statusCode === "NOT_FOUND" && !isFavorite) {
                try {
                    await FavoritePropertyService.addToFavorite(
                        new Collection({
                            propertyID: propertyId,
                            starReasons: ["VIEWED"]
                        })
                    );
                    dispatch(setPropertyCardMarkAsFavorite(true));
                    dispatch(setPropertyFavoriteActionTriggered(true));
                    query.apply();
                } catch (error) {
                    console.log(error);
                } finally {
                    dispatch(setPropertyFavoriteActionTriggered(false));
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getPropertyCardInfo = async (id: any) => {
        try {
            const propertyCardResult = await PropertyService.getPropertyById(
                new TypeString(id)
            );
            const propertyImageBinary = await PropertyService.getStaticImage(
                props.lat,
                props.lng
            );
            const propertyImage = convertFromBinaryToImage(propertyImageBinary);

            setPropertyCardData(
                PropertyViewMapper.mapPropertyCard(
                    propertyCardResult?.propertyDetail
                )
            );

            dispatch(
                setUserPropertyStar(
                    FavoritePropertyViewMapper.mapExtended(
                        propertyCardResult.userPropertyStar
                    )
                )
            );
            setPropertyCardImage(propertyImage);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isLoggedIn && mapTriggerState.triggerPropretyServiceProviders) {
            setTimeout(() => {
                getPropertyVendors(props.id);
            }, 2000);
        }
    }, [isLoggedIn, mapTriggerState.triggerPropretyServiceProviders]);

    const getPropertyVendors = async (id: any) => {
        try {
            const propertyVendors =
                await PropertyVendorService.getPropertyVendors(
                    new TypeString(id)
                );
            setPropertyVendors(PropertyVendorViewMapper.map(propertyVendors));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (propertyVendors && propertyVendors.length > 0) {
            setPropertyCardData((prevState: any) => {
                return {
                    ...prevState,
                    vendors: propertyVendors
                };
            });
        }
    }, [propertyVendors]);

    useEffect(() => {
        if (isLoggedIn) {
            getPropertyCardInfo(props.id);
            getPropertyVendors(props.id);
        }
        return () => {
            // if (isLoggedIn && pipelineCapacity.availableCount !== 0)
            getPropertyStar(props.id);
            setPropertyCardData({});
            setPropertyVendors([]);
            setPropertyCardImage(undefined);
            dispatch(setPropertyCardMarkAsFavorite(false));
            dispatch(setPropertyCardHasAlerts(false));
            dispatch(setUserPropertyStar(undefined));
        };
    }, [isLoggedIn]);

    const hasProperty =
        indexState.propertyCardItem.isVisible &&
        indexState.propertyCardItem.data == null
            ? false
            : true;

    useEffect(() => {
        if (
            favoriteProperties &&
            favoriteProperties.some((i: any) => i?.propertyId === props.id)
        )
            return;
        if (pipelineCapacity.availableCount === 0) {
            setOverlimit(true);
        } else {
            setOverlimit(false);
        }
    }, [pipelineCapacity]);

    return (
        <div
            className={`property-card center ${
                mapTriggerState.hidePropertyCard ? "hidden" : ""
            } ${indexState.propertyCardClassName} ${props.className} ${
                indexState.propertyCardItem?.data?.entityType?.value !== 1
                    ? "comparables"
                    : ""
            }${pipelineOverLimit ? " pipeline-overlimit" : ""}`}
            onMouseEnter={() => props.setDisableDoubleClickZoom(true)}
            onMouseDown={(e: any) => e.stopPropagation()}
            onContextMenu={(e: any) => e.stopPropagation()}
            onClick={(e: any) => e.stopPropagation()}
            onWheel={(e: any) => e.stopPropagation()}
            onMouseLeave={() => props.setDisableDoubleClickZoom(false)}
        >
            {isLoggedIn && isLoading ? (
                <BlockCardLoader />
            ) : (
                <>
                    <PartPropertyCardTop
                        setPropertyCardItem={setPropertyCardItem}
                        setPropertyCardClassName={setPropertyCardClassName}
                        hasProperty={hasProperty}
                        openStreetView={props.openStreetView}
                        propertyCardImage={propertyCardImage}
                        propertyTypeName={
                            propertyTypeName ??
                            indexState.propertyCardItem.property_type
                        }
                        propertyCardData={propertyCardData}
                        propertyCardPublicData={indexState.propertyCardItem}
                        pipelineOverLimit={pipelineOverLimit}
                    />
                    <BlockPropertyCardTabs
                        propertyCardData={propertyCardData}
                        propertyVendors={propertyVendors}
                        hasProperty={hasProperty}
                        pipelineOverLimit={pipelineOverLimit}
                        propertyCardPublicData={indexState.propertyCardItem}
                    />
                    <PartPropertyCardButtons
                        hasProperty={hasProperty}
                        propertyCardData={propertyCardData}
                        pipelineOverLimit={pipelineOverLimit}
                        isLoggedIn={isLoggedIn}
                    />
                </>
            )}
        </div>
    );
};

export default BlockPropertyCard;
