import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";

export default class PropertyTypeEntity {
    _id: TypePositiveInteger;
    _propertyType: TypeString;

    get id(): TypePositiveInteger {
        return this._id;
    }

    setId(value: TypePositiveInteger) {
        this._id = value;
        return this;
    }

    hasId(): boolean {
        return this._id instanceof TypePositiveInteger;
    }

    get propertyType(): TypeString {
        return this._propertyType;
    }

    setPropertyType(value: TypeString) {
        this._propertyType = value;
        return this;
    }
    hasPropertyType(): boolean {
        return this._propertyType instanceof TypeString;
    }
}
