import { Space } from "antd";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";

interface InputFieldProps {
    name: string;
    label?: string;
    errors: Object;
    isRequired?: boolean; //If true, adds * next to the input field
    value?: any;
    // eslint-disable-next-line no-unused-vars
    onChange: (value: any) => void;
    onBlur?: any;
    focus?: boolean;
    disabled?: boolean;
    onEnter?: () => void;
}

const PartInputRangeNumber = ({
    name,
    label,
    errors,
    isRequired,
    value,
    onChange,
    onBlur,
    disabled,
    focus,
    onEnter
}: InputFieldProps) => {
    const [inputState, setInputState] = useState(value);

    useEffect(() => {
        setInputState(value);
    }, [value, inputState]);

    return (
        <div
            className={
                !disabled
                    ? `form-element form-element--input ${
                          name in errors ? "form-element--error" : ""
                      }`
                    : `form-element form-element--input locked ${
                          name in errors ? "form-element--error" : ""
                      }`
            }
        >
            <label>
                {label}
                {isRequired && <span>{" *"}</span>}
            </label>
            <div className="form-element__inner">
                <span className="form-element__prefix">$</span>
                <NumericFormat
                    name={name}
                    allowLeadingZeros={false}
                    thousandSeparator=","
                    value={inputState}
                    allowNegative={false}
                    autoFocus={focus}
                    disabled={disabled}
                    onBlurCapture={(e: any) => onBlur(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            if (onEnter) onEnter();
                        }
                    }}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    onBlur={(e) => {
                        const val = e.target.value
                            ? parseInt(e.target.value.replace(/,/g, ""))
                            : 0;
                        setInputState(val);
                    }}
                />
            </div>
            <Space />
        </div>
    );
};

export default PartInputRangeNumber;
