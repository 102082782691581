/* eslint-disable prettier/prettier */
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, { setIframe } from "../../store";
import { useAuth0 } from "@auth0/auth0-react";
import { hotjar } from "react-hotjar";
import { WHERE_FROM_CTA } from "../../utils/constants";

const BlockMemberPopup = () => {
    let { user } = useAuth0();
    const sliceState = useSelector(indexSliceState);
    const dispatch = useDispatch();
    const baseUrl = process.env.REACT_APP_CRM_CTA_URL;
    user = { ...user, phone: user?.["https://auth.gparency.com/phone"] };
    return (
        <>
            <div className="overlay"></div>
            <div
                className={`popup popup--member ${sliceState.iframe.type}-height`}
            >
                <span className="popup__top-line"></span>
                <div
                    className={`popup__inner 
                        ${sliceState.iframe.type === "alert" && "alert"} 
                        ${sliceState.iframe.type === "join" && "join"} 
                        ${sliceState.iframe.type === "demo" && "demo"} 
                        ${sliceState.iframe.type === "finance" && "finance"} 
                        ${sliceState.iframe.type === "credits" && "credits"} 
                        ${
                            sliceState.iframe.type === "nonListingCredit" &&
                            "nonListingCredit"
                        }
                        ${
                            sliceState.iframe.type === "missingProperty" &&
                            "missingProperty"
                        }
                    `}
                >
                    <span
                        className={`popup__close  ${
                            sliceState.iframe.type === "missingProperty" &&
                            "missing-property-close"
                        }`}
                        onClick={() => {
                            dispatch(setIframe(false));
                            if (
                                sliceState.iframe.whereFrom ===
                                WHERE_FROM_CTA.LEFT_NAV_INTEREST_RATES
                            ) {
                                hotjar.event(
                                    "Interest Rates Get Financing Form Close"
                                );
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} />
                    </span>
                    <iframe
                        id="popupMember"
                        className={`${
                            sliceState.iframe.type === "alert" && "alert"
                        } ${sliceState.iframe.type === "join" && "join"} 
                        ${sliceState.iframe.type === "demo" && "demo"} ${
                            sliceState.iframe.type === "finance" && "finance"
                        } ${sliceState.iframe.type === "credits" && "credits"} 
                        ${
                            sliceState.iframe.type === "lenderCredits" &&
                            "lenderCredits"
                        } 
                        ${
                            sliceState.iframe.type === "missingProperty" &&
                            "missingProperty"
                        } 
                        ${
                            sliceState.iframe.type === "nonListingCredit" &&
                            "nonListingCredit"
                        }`}
                        src={`${baseUrl}/?street=${encodeURIComponent(
                            sliceState.iframe.street
                        )}&city=${encodeURIComponent(
                            sliceState.iframe.city
                        )}&prop-id=${encodeURIComponent(
                            sliceState.iframe.propId
                        )}&map-lat=${encodeURIComponent(
                            sliceState.iframe.mapLat
                        )}&map-lng=${encodeURIComponent(
                            sliceState.iframe.mapLng
                        )}&map-zoom=${encodeURIComponent(
                            sliceState.iframe.mapZoom
                        )}&type=${encodeURIComponent(
                            sliceState.iframe.type
                        )}&token=${encodeURIComponent(
                            sliceState.token
                        )}&where-from=${encodeURIComponent(
                            sliceState.iframe.whereFrom
                        )}&family_name=${encodeURIComponent(
                            user?.family_name === undefined
                                ? ""
                                : user.family_name
                        )}&given_name=${encodeURIComponent(
                            user?.given_name === undefined
                                ? ""
                                : user.given_name
                        )}&phone=${encodeURIComponent(user?.phone)}&sub=${
                            user?.sub
                        }`}
                    ></iframe>
                </div>
            </div>
        </>
    );
};

export default BlockMemberPopup;
