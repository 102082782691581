import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import PropertyVendorEntity from "./PropertyVendorEntity";

export default class PropertyVendorFactory {
    reconstitute(data: Collection) {
        const vendorInstance = new PropertyVendorEntity();
        const vendorRole = new Collection(data.get("vendorRole"));

        if (vendorRole.has("roleName"))
            vendorInstance.setRoleName(
                new TypeString(vendorRole.get("roleName"))
            );

        return vendorInstance;
    }
}
