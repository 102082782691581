import { USER_DATA_API } from "../../apiList";
import { API_CLIENT_CONTENT_TYPE_JSON } from "../../utils/http/ApiClient";
import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";

export default class LPInvestmentGateway {
    getAll(propertyId: TypeString) {
        return USER_DATA_API.get(`/property-portfolio/${propertyId.value}`);
    }

    addTransaction(propertyId: TypeString, data: Collection) {
        return USER_DATA_API.post(
            "/property-portfolio-transactions",
            new Collection({
                propertyId: propertyId.value,
                ...this._mapTransactionData(data)
            }),
            {
                contentType: API_CLIENT_CONTENT_TYPE_JSON
            }
        );
    }

    updateTransaction(sortKey: TypeString, data: Collection) {
        return USER_DATA_API.post(
            "/property-portfolio-transactions",
            new Collection({
                sortKey: sortKey.value,
                ...this._mapTransactionData(data)
            }),
            {
                contentType: API_CLIENT_CONTENT_TYPE_JSON
            }
        );
    }

    createPropertyPortfolio(data: Collection) {
        return USER_DATA_API.post("/property-portfolio", data, {
            contentType: API_CLIENT_CONTENT_TYPE_JSON
        });
    }

    updatePropertyPortfolio(data: Collection) {
        return USER_DATA_API.post("/property-portfolio", data, {
            contentType: API_CLIENT_CONTENT_TYPE_JSON
        });
    }

    removeTransaction(sortKey: TypeString) {
        return USER_DATA_API.delete(
            `/property-portfolio-transactions/${sortKey.value}`
        );
    }

    private _mapTransactionData(data: Collection) {
        if (data.isEmpty()) return;
        const mappedData = {};
        if (data.has("propertyID"))
            mappedData["propertyID"] = data.get("propertyID");
        if (data.has("amount")) mappedData["amount"] = data.get("amount");
        if (data.has("transactionType"))
            mappedData["transactionType"] = data.get("transactionType");
        if (data.has("note")) mappedData["note"] = data.get("note");
        if (data.has("transactionDate"))
            mappedData["transactionDate"] = data.get("transactionDate");

        return mappedData;
    }
}
