import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import PropertyDocumentFactory from "./PropertyDocumentFactory";
import PropertyDocumentGateway from "./PropertyDocumentGateway";

export default class PropertyDocumentRepository {
    _gateway: PropertyDocumentGateway;
    _factory: PropertyDocumentFactory;

    constructor(
        gateway: PropertyDocumentGateway,
        factory: PropertyDocumentFactory
    ) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async getAll(propertyId: TypeString) {
        const data = new Collection(await this._gateway.getAll(propertyId));
        let items = new Collection([]);

        items = data.slice("responseData").map((itemData: any) => {
            const item = new Collection(itemData);
            return this._factory.reconstitute(item);
        });

        return items;
    }
}
