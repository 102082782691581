import {
    faCalendarRange,
    faPencil,
    faTrashCan
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, DatePicker, Popover, Skeleton, Table } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import PartInputNumber from "../parts/PartInputNumber";
import BlockAddTransactionPopup from "./BlockAddTransactionPopup";
import LPInvestmentService from "../../domain/lpInvestment/LPInvestmentService";
import TypeString from "../../domain/valueObject/TypeString";
import Collection from "../../domain/valueObject/Collection";
import { parseToNumber } from "../../utils/uiUitls";
import moment from "moment";
import PartDropdown from "../parts/PartDropdown";
import { LPInvestmentTransactions } from "../../domain/lpInvestment/LPInvestmentViewMapper";

interface BlockLedgerProps {
    transactions: LPInvestmentTransactions[] | undefined;
    getTransactions: any;
    skeletonLoader: boolean;
    setSkeletonLoader: Function;
}

const BlockLedgerTable = ({
    transactions,
    getTransactions,
    skeletonLoader,
    setSkeletonLoader
}: BlockLedgerProps) => {
    const [editableCell, setEditableCell] = useState<string | null>(null);
    const [transactionPopupOpen, setTransactionPopupOpen] = useState<any>(null);
    const [transactionDate, setTransactionDate] = useState<any>(undefined);
    const [deleteButtonLoading, setDeleteButtonLoading] =
        useState<boolean>(false);

    const {
        control,
        formState: { errors }
    } = useForm({
        mode: "onChange"
    });

    const updateTransaction = async (
        updatedPayload: Collection,
        sortKey: string
    ) => {
        try {
            setSkeletonLoader(true);
            await LPInvestmentService.updateTransaction(
                new TypeString(sortKey),
                updatedPayload
            );
            getTransactions();
        } catch (error) {
            console.error("Error updating transaction:", error);
        }
    };

    const getContent = (sortKey: any) => (
        <div>
            <Button
                className="delete-ledger-row-button btn btn--primary"
                loading={deleteButtonLoading}
                onClick={() => {
                    handleDeleteTransaction(sortKey.sortKey);
                }}
            >
                Delete
            </Button>
        </div>
    );
    const columns = [
        {
            title: "Transaction",
            dataIndex: "transactionType",
            key: "transactionType",
            width: "210px",
            render: (record: any, records: any, key: number) => {
                return skeletonLoader ? (
                    <Skeleton.Input />
                ) : (
                    <PartDropdown
                        name={`ledgerTransaction${key}`}
                        control={control}
                        errors={errors}
                        placeholder="Choose one option"
                        getPopupContainer={() =>
                            document.getElementById("filters__dropdowns-holder")
                        }
                        defaultValue={record}
                        onChange={(transactionType: any) => {
                            const sortKey = records.sortKey;
                            const updatedTransactionPayload = new Collection({
                                propertyId: records.propertyId,
                                transactionType: transactionType,
                                amount: parseToNumber(records.amount),
                                transactionDate: moment(records.date).format(
                                    "YYYY-MM-DD"
                                ),
                                note: records.note
                            });
                            updateTransaction(
                                updatedTransactionPayload,
                                sortKey
                            );
                        }}
                        data={[
                            {
                                label: "Invested",
                                value: "INVESTMENT"
                            },
                            {
                                label: "Distribution Received",
                                value: "DISTRIBUTION"
                            }
                        ]}
                        // hotjarEventName=""
                    />
                );
            }
        },
        {
            title: "Amount In/Out",
            dataIndex: "amount",
            key: "amount",
            width: "140px",
            render: (record: any, records: any, key: number) => {
                const cellId = `${records.id}-${key}-amount`;
                return skeletonLoader ? (
                    <Skeleton.Input />
                ) : (
                    <div>
                        {cellId === editableCell ? (
                            <PartInputNumber
                                name={`ledgerAmount${key}`}
                                errors={errors}
                                focus={true}
                                onChange={function (): void {
                                    console.log("onChange");
                                }}
                                value={record}
                                onBlur={(amount: any) => {
                                    if (
                                        records.amount !== parseToNumber(amount)
                                    ) {
                                        const sortKey = records.sortKey;
                                        const updatedTransactionPayload =
                                            new Collection({
                                                propertyId: records.propertyId,
                                                transactionType:
                                                    records.transactionType,
                                                amount: parseToNumber(amount),
                                                transactionDate: moment(
                                                    records.date
                                                ).format("YYYY-MM-DD"),
                                                note: records.note
                                            });
                                        updateTransaction(
                                            updatedTransactionPayload,
                                            sortKey
                                        );
                                    }

                                    setEditableCell(null);
                                }}
                            />
                        ) : (
                            <div>
                                <span className="ledger-table-amount-value">
                                    {records.transactionType === "INVESTMENT"
                                        ? `-$${record.toLocaleString()}`
                                        : `$${record.toLocaleString()}`}
                                </span>
                                <FontAwesomeIcon
                                    onClick={() => setEditableCell(cellId)}
                                    icon={faPencil}
                                />
                            </div>
                        )}
                    </div>
                );
            }
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            width: "150px",
            render: (record: any, records: any, key: number) => {
                const cellId = `${records.id}-${key}-date`;
                return skeletonLoader ? (
                    <Skeleton.Input />
                ) : (
                    <div>
                        {cellId === editableCell ? (
                            <DatePicker
                                className="datepicker table-datepicker"
                                format="MM/DD/YYYY"
                                allowClear={false}
                                autoFocus
                                defaultOpen
                                onChange={(e) => {
                                    setTransactionDate(e);
                                }}
                                defaultValue={moment(record)}
                                onBlur={(e) => {
                                    const previousDate = moment(records.date);
                                    const selectedDate = moment(e.target.value);
                                    if (!previousDate.isSame(selectedDate)) {
                                        const sortKey = records.sortKey;
                                        const updatedTransactionPayload =
                                            new Collection({
                                                propertyId: records.propertyId,
                                                transactionType:
                                                    records.transactionType,
                                                amount: parseToNumber(
                                                    records.amount
                                                ),
                                                transactionDate:
                                                    moment(
                                                        transactionDate
                                                    ).format("YYYY-MM-DD"),
                                                note: records.note
                                            });

                                        updateTransaction(
                                            updatedTransactionPayload,
                                            sortKey
                                        );
                                    }
                                    setEditableCell(null);
                                    setTransactionDate(undefined);
                                }}
                                value={transactionDate}
                                suffixIcon={
                                    <FontAwesomeIcon icon={faCalendarRange} />
                                }
                            />
                        ) : (
                            <div>
                                <span className="ledger-table-date-text">
                                    {record}
                                </span>
                                <FontAwesomeIcon
                                    icon={faPencil}
                                    onClick={() => {
                                        setTransactionDate(moment(record));
                                        setEditableCell(cellId);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                );
            }
        },
        {
            title: "Notes",
            dataIndex: "note",
            key: "note",
            render: (record: any, records: any) =>
                skeletonLoader ? (
                    <Skeleton.Input />
                ) : (
                    <div className={`${record ? "has-note" : "no-note"}`}>
                        <span
                            className="ledger-table-notes-text"
                            title={record}
                        >
                            {record
                                ? record.length > 24
                                    ? record.substring(0, 20) + "..."
                                    : record
                                : "Enter Note"}
                            <FontAwesomeIcon
                                onClick={() => setTransactionPopupOpen(records)}
                                icon={faPencil}
                            />
                        </span>
                    </div>
                )
        },
        {
            key: "sortKey",
            dataIndex: "sortKey",
            hidden: true,
            width: "5px",
            render: () => null
        },
        {
            title: null,
            dataIndex: "delete",
            key: "delete",
            width: "45px",
            render: (record: any, sortKey: any) =>
                skeletonLoader ? (
                    <Skeleton.Input />
                ) : (
                    <span role="button" className="delete-ledger-btn">
                        <Popover
                            content={getContent(sortKey)}
                            title="Are you sure you want to delete this row?"
                            trigger="click"
                        >
                            <FontAwesomeIcon icon={faTrashCan} />
                        </Popover>
                    </span>
                )
        }
    ];

    const handleDeleteTransaction = async (sortKey: any) => {
        try {
            setDeleteButtonLoading(true);
            await LPInvestmentService.removeTransaction(
                new TypeString(sortKey)
            );
            getTransactions();
            // const updatedTransactions = transactions.filter(
            //     (transaction: any) => transaction.id !== transactionId
            // );
            // calculateTotalAmount(updatedTransactions);
        } catch (error) {
            console.error("Error deleting transaction:", error);
        } finally {
            setDeleteButtonLoading(false);
        }
    };

    const scrollProps = transactions
        ? transactions.length > 3
            ? { y: 250, x: "750px" }
            : { x: "750px" }
        : {};

    return (
        <div className="ledger-table-wrapper">
            <Table
                dataSource={transactions ? transactions : []}
                columns={columns}
                pagination={false}
                scroll={scrollProps}
            />
            {transactionPopupOpen && (
                <BlockAddTransactionPopup
                    transactionPopupOpen={transactionPopupOpen}
                    setTransactionPopupOpen={setTransactionPopupOpen}
                    getTransactions={getTransactions}
                />
            )}
        </div>
    );
};

export default BlockLedgerTable;
