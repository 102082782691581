import Collection from "../../valueObject/Collection";
import TypeFloat from "../../valueObject/TypeFloat";
import TypePositiveInteger from "../../valueObject/TypePositiveInteger";
import TypeString from "../../valueObject/TypeString";

export default class BubbleEntity {
    _latitude: TypeFloat;
    _longitude: TypeFloat;
    _totalCount: TypePositiveInteger;
    _listingCount: TypePositiveInteger;
    _saleCount: TypePositiveInteger;
    _financeCount: TypePositiveInteger;
    private _lenders: Collection = new Collection({});

    get latitude(): TypeFloat {
        return this._latitude;
    }

    setLatitude(latitude: TypeFloat) {
        this._latitude = latitude;
        return this;
    }

    get longitude(): TypeFloat {
        return this._longitude;
    }

    setLongitude(longitude: TypeFloat) {
        this._longitude = longitude;
        return this;
    }

    get totalCount(): number {
        if (this.hasLenders()) {
            let total = 0;
            this._lenders.forEach((lenderTotal: number) => {
                total += lenderTotal;
            });
            return total;
        } else {
            return this._listingCount.value;
        }
    }

    get listingCount(): TypePositiveInteger {
        return this._listingCount;
    }

    setListingCount(listingCount: TypePositiveInteger) {
        this._listingCount = listingCount;
        return this;
    }

    get saleCount(): TypePositiveInteger {
        return this._saleCount;
    }

    setSaleCount(saleCount: TypePositiveInteger) {
        this._saleCount = saleCount;
        return this;
    }

    get financeCount(): TypePositiveInteger {
        return this._financeCount;
    }

    setFinanceCount(financeCount: TypePositiveInteger) {
        this._financeCount = financeCount;
        return this;
    }

    get lenders(): Collection {
        return this._lenders;
    }

    addLender(lender: TypeString, count: TypePositiveInteger): BubbleEntity {
        this._lenders.set(lender.value, count.value);
        return this;
    }

    hasLenders(): boolean {
        return !this._lenders.isEmpty();
    }
}
