import Collection from "./valueObject/Collection";

export default class BaseModel {
    private _afterChangeTriggers: Collection = new Collection({});
    private _pauseChangeTriggers: boolean = false;
    private _afterAddTriggers: Collection = new Collection({});
    private _pauseAddTriggers: boolean = false;
    private _applyTriggers: any = {};
    private _loadStartTriggers: any = new Collection({});
    private _loadEndTriggers: any = new Collection({});
    private _pauseApplyTriggers: boolean = false;

    public pauseMVC() {
        this._pauseChangeTriggers = true;
        this._pauseAddTriggers = true;
        this._pauseApplyTriggers = true;
    }
    public unPauseMVC() {
        this._pauseChangeTriggers = false;
        this._pauseAddTriggers = false;
        this._pauseApplyTriggers = false;
    }
    afterChange(key: string, callback: any) {
        this._afterChangeTriggers.set(key, callback);
        return this;
    }

    _executeAfterChangeTriggers() {
        if (this._pauseChangeTriggers) return;
        // eslint-disable-next-line no-unused-vars
        this._afterChangeTriggers.forEach((callback: any, key: string) => {
            callback();
        });
    }

    _executeLoadStartTriggers() {
        // eslint-disable-next-line no-unused-vars
        this._loadStartTriggers.forEach((callback: any, key: string) => {
            callback();
        });
    }

    _executeLoadEndTriggers() {
        // eslint-disable-next-line no-unused-vars
        this._loadEndTriggers.forEach((callback: any, key: string) => {
            callback();
        });
    }

    afterAdd(key: string, callback: any) {
        this._afterAddTriggers.set(key, callback);
        return this;
    }

    _executeAfterAddTriggers() {
        if (this._pauseAddTriggers) return;
        // eslint-disable-next-line no-unused-vars
        this._afterAddTriggers.forEach((callback: any, key: string) => {
            callback();
        });
    }

    public assignApplyTrigger(key: string, handler: Function) {
        this._applyTriggers[key] = handler;
    }

    public onLoadStart(key: string, handler: Function) {
        this._loadStartTriggers.set(key, handler);
        return this;
    }
    public onLoadEnd(key: string, handler: Function) {
        this._loadEndTriggers.set(key, handler);
        return this;
    }

    public apply() {
        if (this._pauseApplyTriggers) return;
        for (const key in this._applyTriggers) this._applyTriggers[key]();
    }
}
