import VendorRoleFactory from "./VendorRoleFactory";
import VendorRoleGateway from "./VendorRoleGateway";
import VendorRoleRepository from "./VendorRoleRepository";

export default class VendorRoleService {
    static getVendorRoles() {
        return new VendorRoleRepository(
            new VendorRoleGateway(),
            new VendorRoleFactory()
        ).getVendorRoles();
    }
}
