import { TotalMarkersInterface } from "../../store";
import FinanceHistoryEntity from "../propertyFinanceHistory/FinanceHistoryEntity";
import PropertyListingEntity from "../propertyListing/PropertyListingEntity";
import SaleHistoryEntity from "../propertySaleHistory/SaleHistoryEntity";
import RepresenterEntity from "../representer/RepresenterEntity";
import OwnershipEntity from "./ownership/OwnershipEntity";
import PropertyEntity from "./PropertyEntity";
import PropertyTaxAssessmentEntity from "./tax/PropertyTaxAssessment";
import TotalMarkerCountEntity from "./totalMarkerCount/TotalMarkerCountEntity";

export interface PropertyCardData {
    id?: string;
    type?: number;
    image: any;
    address: string;
    listing: ListingType;
    isActiveListing?: boolean;
    saleHistory: SaleHistoryType;
    financeHistory: any;
    numberOfTabs: number;
    stateAbbreviation?: string;
    propertyType?: number;
    propertyTypeName?: string;
    sqFt?: number;
    city?: string;
    county?: string;
    zip?: number;
    fips?: number;
    parcel?: string;
    zoning?: string;
    lotSize?: number;
    landUse?: string;
    subclass?: string;
    units?: number;
    yearBuilt?: number;
    stories?: number;
    buildingCount?: number;
    owner?: OwnerType;
    taxAssessment?: TaxAssessmentType;
    ownerOccupied?: boolean;
    isFavorite?: boolean;
}

// eslint-disable-next-line no-unused-vars
type ListingType = {
    id?: number;
    price?: string;
    pricePerSqFt?: string;
    pricePerUnit?: string;
    sqFt?: number;
    units?: number;
    agent?: RepresenterType;
    company?: string;
    publicListingExpiration?: boolean;
};

type SaleHistoryType = {
    id: number;
    price?: number;
    pricePerSqFt?: number;
    pricePerUnit?: number;
    saleDate?: string;
    broker?: RepresenterType;
    priorSaleDate?: string;
    priorSalePrice?: number;
    sellerName?: string;
    salePrice?: number;
};

type RepresenterType = {
    company?: string;
    name?: string;
    email?: string;
    phone?: string;
};

type OwnerType = {
    name?: string;
    mailingAddress?: string;
};

type TaxAssessmentType = {
    taxYear?: number;
    propertyTax?: number;
    assessedYear?: number;
    assessedValue?: number;
};

export const REFINANCE_TYPE = "REFINANCE";
export const ACQUISITION_TYPE = "ACQUISITION";

export default class PropertyViewMapper {
    static DATE_FORMAT = "n/j/Y";
    static loanAmountCurrencyFormatter = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    });

    static mapPropertyCard(propertyCard: PropertyEntity): PropertyCardData {
        let numberOfTabs = 0;
        if (propertyCard.hasSaleHistory()) numberOfTabs++;
        if (propertyCard.hasFinanceHistory()) numberOfTabs++;

        const propertyCardData = {
            id: propertyCard.hasId() ? propertyCard.id.value : undefined,
            type: propertyCard.hasType() ? propertyCard.type.value : undefined,
            propertyType: propertyCard.hasPropertyType()
                ? propertyCard.propertyType.id
                : undefined,
            propertyTypeName: propertyCard.hasPropertyType()
                ? propertyCard.propertyType.name
                : undefined,
            image: propertyCard.hasImage()
                ? propertyCard.image.value
                : undefined,
            address: propertyCard.address.value,
            listing: propertyCard.hasListing()
                ? this._mapListing(propertyCard.listing)
                : undefined,
            isActiveListing: propertyCard.hasListing() ? true : false,
            saleHistory: propertyCard.hasSaleHistory()
                ? this._mapSaleHistory(propertyCard.saleHistory)
                : undefined,
            financeHistory: propertyCard.hasFinanceHistory()
                ? this._mapFinanceHistory(propertyCard.financeHistory)
                : undefined,
            numberOfTabs: numberOfTabs,
            stateAbbreviation: propertyCard.hasStateAbbreviation()
                ? propertyCard.stateAbbreviation.value
                : undefined,
            sqFt: propertyCard.hasSqFt()
                ? propertyCard.sqFt.value.toLocaleString()
                : undefined,
            city: propertyCard.hasCity() ? propertyCard.city.value : undefined,
            county: propertyCard.hasCounty()
                ? propertyCard.county.value
                : undefined,
            zip: propertyCard.hasZip() ? propertyCard.zip.value : undefined,
            fips: propertyCard.hasFIPS() ? propertyCard.FIPS.value : undefined,
            parcel: propertyCard.hasParcel() ? propertyCard.parcel : undefined,
            zoning: propertyCard.hasZoning()
                ? propertyCard.zoning.value
                : undefined,
            lotSize: propertyCard.hasLotSize()
                ? propertyCard.lotSize.value.toLocaleString()
                : undefined,
            landUse: propertyCard.hasLandUse()
                ? propertyCard.landUse.value
                : undefined,
            subclass: propertyCard.hasSubClass()
                ? propertyCard.subClass.value
                : undefined,
            units: propertyCard.hasUnits()
                ? propertyCard.units.value.toLocaleString()
                : undefined,
            yearBuilt: propertyCard.hasYearBuilt()
                ? propertyCard.yearBuilt.value
                : undefined,
            stories: propertyCard.hasStories()
                ? propertyCard.stories.value
                : undefined,
            buildingCount: propertyCard.hasBuildingCount()
                ? propertyCard.buildingCount.value
                : undefined,
            taxAssessment: this._mapTaxAssessment(propertyCard.taxAssessment),
            owner: this._mapOwner(propertyCard.ownership),
            ownerOccupied: propertyCard.hasOwnerOccupied()
                ? propertyCard.ownerOccupied.value === "true"
                    ? "Y"
                    : "N"
                : undefined,
            isFavorite: propertyCard.hasIsFavorite()
                ? propertyCard.isFavorite.value
                : undefined
        } as PropertyCardData;

        return propertyCardData;
    }

    static mapTotalMarkers(
        markerTotals: TotalMarkerCountEntity
    ): TotalMarkersInterface | undefined {
        if (!(markerTotals instanceof TotalMarkerCountEntity)) return undefined;

        return {
            listingsCount: markerTotals.hasListingsTotalCount()
                ? markerTotals.listingsTotalCount.value
                : undefined,
            salesComparablesCount: markerTotals.hasSalesComparablesTotalCount()
                ? markerTotals.salesComparablesTotalCount.value
                : undefined,
            financeComparablesCount:
                markerTotals.hasFinanceComparablesTotalCount()
                    ? markerTotals.financeComparablesTotalCount.value
                    : undefined
        };
    }

    private static _mapListing(listing: PropertyListingEntity) {
        if (!listing) return undefined;

        return {
            id: listing.hasId() ? listing.id.value : undefined,
            price: listing.hasPrice()
                ? listing.price === null
                    ? null
                    : this.loanAmountCurrencyFormatter.format(
                          listing.price.value
                      )
                : undefined,
            pricePerSqFt: listing.hasPricePerSqFt()
                ? this.loanAmountCurrencyFormatter.format(
                      listing.pricePerSqFt.value
                  )
                : undefined,
            pricePerUnit: listing.hasPricePerUnit()
                ? this.loanAmountCurrencyFormatter.format(
                      listing.pricePerUnit.value
                  )
                : undefined,
            units: listing.hasUnits() ? listing.units.value : undefined,
            company: listing.hasCompany()
                ? listing.company === null
                    ? null
                    : listing.company.value
                : undefined,
            agent: listing.hasAgent()
                ? listing.agent === null
                    ? null
                    : this._mapRepresenter(listing.agent)
                : undefined,
            publicListingExpiration: listing.hasPublicListingExpiration()
                ? true
                : false
        } as ListingType;
    }

    private static _mapSaleHistory(
        saleHistory: SaleHistoryEntity
    ): SaleHistoryType | undefined {
        if (!saleHistory) return undefined;

        return {
            id: saleHistory.hasId() ? saleHistory.id.value : undefined,
            saleDate: saleHistory.hasSaleDate()
                ? saleHistory.saleDate.toDateFormat(this.DATE_FORMAT)
                : undefined,
            price: saleHistory.hasPrice()
                ? this.loanAmountCurrencyFormatter.format(
                      saleHistory.price.value
                  )
                : undefined,
            pricePerSqFt: saleHistory.hasPricePerSqFt()
                ? this.loanAmountCurrencyFormatter.format(
                      saleHistory.pricePerSqFt.value
                  )
                : undefined,
            pricePerUnit: saleHistory.hasPricePerUnit()
                ? this.loanAmountCurrencyFormatter.format(
                      saleHistory.pricePerUnit.value
                  )
                : undefined,
            broker: saleHistory.hasBroker()
                ? this._mapRepresenter(saleHistory.broker)
                : undefined,
            priorSaleDate: saleHistory.hasPriorSaleDate()
                ? saleHistory.priorSaleDate.toDateFormat(this.DATE_FORMAT)
                : undefined,
            priorSalePrice: saleHistory.hasPriorSalePrice()
                ? this.loanAmountCurrencyFormatter.format(
                      saleHistory.priorSalePrice.value
                  )
                : undefined,
            sellerName: saleHistory.hasSellerName()
                ? saleHistory.sellerName.value
                : undefined
        } as SaleHistoryType;
    }

    private static _mapFinanceHistory(financeHistory: FinanceHistoryEntity) {
        if (!financeHistory) return undefined;

        return {
            id: financeHistory.hasId() ? financeHistory.id.value : undefined,

            maturityDate: financeHistory.hasMaturityDate()
                ? financeHistory.maturityDate.toDateFormat(this.DATE_FORMAT)
                : undefined,

            mortgageDate: financeHistory.hasMortgageDate()
                ? financeHistory.mortgageDate.toDateFormat(this.DATE_FORMAT)
                : undefined,
            isPurchase: financeHistory.hasIsPurchase()
                ? financeHistory.isPurchase.toString()
                : undefined,
            mortgagePaidOff: financeHistory.hasMortgagePaidOff()
                ? financeHistory.mortgagePaidOff.toString()
                : undefined,
            blanketMortgage: financeHistory.hasBlanketMortgage()
                ? financeHistory.blanketMortgage.toString()
                : undefined,
            numberOfOpenLoans: financeHistory.hasNumberOfOpenLoans()
                ? financeHistory.numberOfOpenLoans.value
                : undefined,
            openLoanAmount: financeHistory.hasOpenLoanAmount()
                ? this.loanAmountCurrencyFormatter.format(
                      financeHistory.openLoanAmount.value
                  )
                : undefined,
            lender: financeHistory.hasLender()
                ? financeHistory.lender.value
                : undefined,
            loanAmount: financeHistory.hasLoanAmount()
                ? this.loanAmountCurrencyFormatter.format(
                      financeHistory.loanAmount.value
                  )
                : undefined
        };
    }

    private static _mapRepresenter(representer: RepresenterEntity | null) {
        if (representer === undefined) return undefined;
        if (representer === null) return null;

        return {
            name: representer.hasName() ? representer.name.value : undefined,
            email: representer.hasEmail() ? representer.email.value : undefined,
            phone: representer.hasLandline()
                ? this._getFirstLandlineNumber(representer.landline.value)
                : undefined,
            mobile: representer.hasMobile()
                ? representer.mobile.value
                : undefined
        };
    }

    private static _mapTaxAssessment(
        taxAssessment: PropertyTaxAssessmentEntity
    ) {
        if (!taxAssessment) return undefined;

        return {
            taxYear: taxAssessment.hasTaxYear()
                ? taxAssessment.taxYear.value
                : undefined,
            propertyTax: taxAssessment.hasPropertyTax()
                ? taxAssessment.propertyTax.value
                : undefined,
            assessedYear: taxAssessment.hasAssessedYear()
                ? taxAssessment.assessedYear.value
                : undefined,
            assessedValue: taxAssessment.hasAssessedValue()
                ? taxAssessment.assessedValue.value
                : undefined
        } as TaxAssessmentType;
    }

    private static _mapOwner(owner: OwnershipEntity): OwnerType | undefined {
        if (!owner) return undefined;

        return {
            name: owner.hasName() ? owner.name.value : undefined,
            mailingAddress: owner.hasMailingAddress()
                ? owner.mailingAddress.value
                : undefined
        } as OwnerType;
    }

    private static _getFirstLandlineNumber(landlineNumber: string) {
        if (!landlineNumber) return undefined;
        if (!landlineNumber.includes(",")) return landlineNumber;
        return landlineNumber.split(",")[0];
    }
}
