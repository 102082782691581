import PropertyTypeEntity from "./PropertyTypeEntity";
import PropertyTypeFactory from "./PropertyTypeFactory";
import PropertyTypeGateway from "./PropertyTypeGateway";
import PropertyTypeRepository from "./PropertyTypeRepository";

export default class PropertyTypeService {
    private static _propertyTypes: PropertyTypeEntity[] = [];

    static async get(): Promise<any> {
        if (this._propertyTypes.length > 0) {
            return this._propertyTypes;
        }

        return await new PropertyTypeRepository(
            new PropertyTypeGateway(),
            new PropertyTypeFactory()
        ).get();
    }
}
