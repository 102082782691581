import URLObjectEntity from "./URLObjectEntity";
import { RAM_PERSISTENCE } from "../../";

export default class SearchQueryRAMGateway {
    _entityKey = "URLObjectEntity";

    persist(persistenceKey: any, object: URLObjectEntity) {
        RAM_PERSISTENCE.persist(persistenceKey, object, this._entityKey);
    }

    retrieve(persistenceKey: any) {
        return RAM_PERSISTENCE.retrieve(persistenceKey, this._entityKey);
    }
}
