import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import responsiveSliceState, {
    setCurrentView,
    setOverlays
} from "../../../store/responsive";
import PartButton from "../../parts/PartButton";
import { hotjar } from "react-hotjar";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

const BlockVideoWalkthrough = () => {
    const responsiveState = useSelector(responsiveSliceState);
    const dispatch = useDispatch();

    const links = [
        {
            text: "A Quick Intro to GPARENCY",
            href: "https://youtu.be/fIjWvPSxp6o?si=V1rCCro7fWZsJUzM"
        },
        {
            text: "Overview of the marketplace",
            href: "https://youtu.be/rvt_glrWrS8?si=oA7UdAD3i2UVCl65"
        }
    ];

    return (
        <div
            id="video_walkthrough"
            className={
                responsiveState.overlays.videoWalkthroughOverlay
                    ? "open"
                    : "closed"
            }
        >
            <div className="sidebar__videos">
                <div className="sidebar__videos__header">
                    <div className="sidebar__videos__header-wrap">
                        <PartButton
                            type="secondary"
                            onClick={() => {
                                dispatch(setCurrentView("dashboard"));
                                dispatch(
                                    setOverlays({
                                        ...responsiveState.overlays,
                                        videoWalkthroughOverlay: false
                                    })
                                );
                            }}
                        >
                            <span
                                onClick={() => {
                                    hotjar.event(
                                        "Video Walkthrough Collapse - mobile"
                                    );
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} /> tools
                            </span>
                        </PartButton>
                        <h3>VIDEO WALKTHROUGH</h3>
                    </div>
                </div>
                <h1>
                    Learn about all the marketplace tools <br />
                    by clicking on the videos below
                </h1>

                <div className="sidebar__videos__links">
                    {links.map((link) => (
                        <div key={link.href}>
                            <a
                                href={link.href}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={solid("play")} />
                                {link.text}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BlockVideoWalkthrough;
