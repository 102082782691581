import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import LPInvestmentTransactionFactory from "./transaction/LPInvestmentTransactionFactory";

export default class LPInvestmentRepository {
    _gateway: any;
    _factory: any;

    constructor(gateway: any, factory: any) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async getAll(propertyId: TypeString) {
        const data = new Collection(
            await this._gateway.getAll(propertyId)
        ).extract("responseData");

        const userPropertyPortfolio = new Collection(
            data.get("userPropertyPortfolio")
        );
        // Merge opportunitiesSubscription parameter into userPropertyPortfolio
        if (data.has("opportunitiesSubscription")) {
            userPropertyPortfolio.set(
                "opportunitiesSubscription",
                data.get("opportunitiesSubscription")
            );
        }

        let portfolioTransactions = [];

        if (data.has("userPropertyPortfolioTransactions")) {
            portfolioTransactions = data
                .get("userPropertyPortfolioTransactions")
                .map((item: any) => {
                    return new LPInvestmentTransactionFactory().reconstitute(
                        new Collection(item)
                    );
                });
        }

        return this._factory.reconstitute(
            userPropertyPortfolio,
            new Collection(portfolioTransactions)
        );
    }
}
