import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { hotjar } from "react-hotjar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/pro-regular-svg-icons";

const LogoutButton = () => {
    const { logout } = useAuth0();
    return (
        <div
            className="site-header__user-dropdown__btn"
            onClick={() => {
                hotjar.event("Button Logout Click");
                logout({
                    returnTo: window.location.origin
                });
            }}
        >
            <span className="site-header__user-dropdown__btn-icon">
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
            </span>
            <div>Log Out</div>
        </div>
    );
};

export default LogoutButton;
