import Collection from "../../valueObject/Collection";
import TypePositiveInteger from "../../valueObject/TypePositiveInteger";
import PropertyTaxAssessmentEntity from "./PropertyTaxAssessment";

export default class PropertyTaxAssessmentFactory {
    reconstitute(data: Collection): PropertyTaxAssessmentEntity {
        const taxAssesment = new PropertyTaxAssessmentEntity();
        if (data.has("taxAmount"))
            taxAssesment.setPropertyTax(
                new TypePositiveInteger(data.get("taxAmount"))
            );
        if (data.has("taxYear"))
            taxAssesment.setTaxYear(
                new TypePositiveInteger(data.get("taxYear"))
            );
        if (data.has("assessmentYear"))
            taxAssesment.setAssessedYear(
                new TypePositiveInteger(data.get("assessmentYear"))
            );
        if (data.has("assessmentValue"))
            taxAssesment.setAssessedValue(
                new TypePositiveInteger(data.get("assessmentValue"))
            );
        return taxAssesment;
    }
}
