import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import NoteFactory from "./NoteFactory";
import NoteGateway from "./NoteGateway";
import NoteRepository from "./NoteRepository";

export default class NoteService {
    static getNotes(propertyId: TypeString) {
        return new NoteRepository(
            new NoteGateway(),
            new NoteFactory()
        ).getNotes(propertyId);
    }
    static addNote(data: Collection) {
        return new NoteGateway().addNote(data);
    }
    static updateNote(data: Collection) {
        return new NoteGateway().updateNote(data);
    }
}
