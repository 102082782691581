import { useEffect, useState } from "react";
import { faArrowLeft, faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Loader from "./../../../assets/images/loader.gif";
import { useDispatch, useSelector } from "react-redux";
import responsiveSliceState, {
    setCurrentView,
    setOverlays
} from "../../../store/responsive";
import PartButton from "../../parts/PartButton";
import { hotjar } from "react-hotjar";

export default function BlockIndexes() {
    const dispatch = useDispatch();

    const [indices, setIndices] = useState<any>([]);
    const [stockIndexes, setStockIndexes] = useState<any>([]);

    const responsiveState = useSelector(responsiveSliceState);

    const fifteenMinsInMilliSecs: number = 900000;

    useEffect(() => {
        loadIndexes();
        loadStockIndexes();
        const interval = setInterval(() => {
            loadIndexes();
            loadStockIndexes();
        }, fifteenMinsInMilliSecs);
        return () => clearInterval(interval);
    }, []);

    async function loadIndexes() {
        try {
            const r = await fetch(
                `${process.env.REACT_APP_LOOKUP_MS}/simple-rate-sheet`
            );
            const j = await r.json();
            const strings: Array<any> = [];
            const days: Array<any> = [];
            const years: Array<any> = [];
            j.data.forEach((item: any, index: any, object: any) => {
                if (item.name === "30 YEAR TREASURY") {
                    item.name = "30 YEAR US TREASURY";
                }
                object[index].name = item.name.replace(/-/g, " ");
                if (isNaN(item.name[0])) {
                    strings.push(item);
                } else if (item.name.includes("DAY")) {
                    days.push(item);
                } else if (item.name.includes("YEAR")) {
                    years.push(item);
                }
            });
            strings.sort((a, b) =>
                a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            );
            days.sort(
                (a, b) =>
                    Number(a.name.slice(0, 2)) - Number(b.name.slice(0, 2))
            );
            years.sort(
                (a, b) =>
                    Number(a.name.slice(0, 2)) - Number(b.name.slice(0, 2))
            );
            const res: Array<any> = [...strings, ...days, ...years];
            setIndices(res);
        } catch (e) {
            console.log(e);
        }
    }

    async function loadStockIndexes() {
        try {
            const r = await fetch(
                `${process.env.REACT_APP_LOOKUP_MS}/stockmarket-rate-sheet`
            );
            const j = await r.json();
            const arr = [];
            arr[0] = j.data.find(
                (item: { name: string }) => item.name === "DOW"
            );
            arr[1] = j.data.find(
                (item: { name: string }) => item.name === "S&P 500"
            );
            arr[2] = j.data.find(
                (item: { name: string }) => item.name === "NASDAQ"
            );
            setStockIndexes(arr);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div
            id="indcs"
            className={`${
                responsiveState.overlays.indexesOverlay ? "open" : "closed"
            }`}
        >
            <div className="indcs__header">
                <div className="indcs__header-wrap">
                    <PartButton
                        type="secondary"
                        onClick={() => {
                            dispatch(setCurrentView("dashboard"));
                            dispatch(
                                setOverlays({
                                    ...responsiveState.overlays,
                                    indexesOverlay: false
                                })
                            );
                        }}
                    >
                        <span
                            onClick={() => {
                                hotjar.event("Indexes Collapse - mobile");
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} /> tools
                        </span>
                    </PartButton>
                    <h3>INDEXES</h3>
                </div>
            </div>
            <table style={{ position: "relative" }}>
                <thead>
                    <tr>
                        <th className="indcs__header indcs__clmn-1">
                            Index Name
                        </th>
                        <th className="indcs__header indcs__clmn-2">Yield</th>
                        <th id="indcs___hdr-3" className="indcs__header">
                            <span id="indcs__c-i-r">Change in Yield</span>
                            <span id="indcs__info">
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    style={{
                                        width: "14px",
                                        height: "14px"
                                    }}
                                />
                                <div>
                                    <p>
                                        Change from current listed rate compared
                                        to the value at the end of the previous
                                        day.
                                    </p>
                                </div>
                            </span>
                        </th>
                    </tr>
                </thead>

                {indices && indices.length > 0 ? (
                    <tbody id="indcs__tbody">
                        {indices.map((index: any, count: any) => {
                            return (
                                <tr className="indcs__rows" key={count}>
                                    <td className="indcs__td indcs__clmn-1 indcs__td-name-and-rate">
                                        {index.name}
                                    </td>
                                    <td className="indcs__td indcs__clmn-2 indcs__td-name-and-rate">
                                        {index.rate.toString().slice(0, 5)}%
                                    </td>
                                    <td
                                        className={`indcs__td ${
                                            String(index.change).startsWith("-")
                                                ? "indcs__pstv-clr"
                                                : String(
                                                      index.change
                                                  ).toString() === "0%"
                                                ? "indcs__unchanged-clr"
                                                : "indcs__ngtv-clr"
                                        } indcs__clmn-3`}
                                    >
                                        {String(index.change).startsWith(
                                            "-"
                                        ) ? (
                                            <>
                                                {String(index.change).slice(
                                                    0,
                                                    -1
                                                )}
                                                <span className="indcs__change-postion">
                                                    <FontAwesomeIcon
                                                        icon={solid(
                                                            "caret-down"
                                                        )}
                                                    />
                                                </span>
                                            </>
                                        ) : String(index.change).toString() ===
                                          "0%" ? (
                                            <>
                                                {String(index.change).slice(
                                                    0,
                                                    -1
                                                )}
                                                <span className="indcs__change-postion"></span>
                                            </>
                                        ) : (
                                            <>
                                                {"+"}
                                                {String(index.change).slice(
                                                    0,
                                                    -1
                                                )}
                                                <span className="indcs__change-postion">
                                                    <FontAwesomeIcon
                                                        icon={solid("caret-up")}
                                                    />
                                                </span>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                ) : null}
            </table>
            {indices && indices.length === 0 ? (
                <div
                    style={{
                        width: "540px",
                        textAlign: "center"
                    }}
                >
                    <img
                        style={{
                            width: "128px",
                            transition: "350ms ease"
                        }}
                        src={Loader}
                        alt="Loader"
                    />
                </div>
            ) : null}

            <table style={{ position: "relative", marginTop: "20px" }}>
                <thead>
                    <tr>
                        <th className="indcs__header indcs__clmn-1">
                            Index Name
                        </th>
                        <th className="indcs__header indcs__clmn-2">Price</th>
                        <th id="indcs___hdr-3" className="indcs__header">
                            <span id="indcs__c-i-r">Change in Price</span>
                            <span id="indcs__info-stocks">
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    style={{ width: "14px", height: "14px" }}
                                />
                                <div>
                                    <p>
                                        Change from current listed price
                                        compared to the value at the end of the
                                        previous day.
                                    </p>
                                </div>
                            </span>
                        </th>
                    </tr>
                </thead>

                {stockIndexes && stockIndexes.length > 0 ? (
                    <tbody id="indcs__tbody">
                        {stockIndexes.map((index: any, count: any) => {
                            return (
                                <tr className="indcs__rows" key={count}>
                                    <td className="indcs__td indcs__clmn-1 indcs__td-name-and-rate">
                                        {index.name}
                                    </td>
                                    <td className="indcs__td indcs__clmn-2 indcs__td-name-and-rate">
                                        {Number(
                                            index.rate.toFixed(2)
                                        ).toLocaleString("en-US")}
                                    </td>
                                    <td
                                        className={`indcs__td ${
                                            String(index.change).startsWith("-")
                                                ? "indcs__ngtv-clr"
                                                : String(
                                                      index.change
                                                  ).toString() === "0%"
                                                ? "indcs__unchanged-clr"
                                                : "indcs__pstv-clr"
                                        } indcs__clmn-3`}
                                        style={{
                                            paddingRight: "48px"
                                        }}
                                    >
                                        {String(index.change).startsWith(
                                            "-"
                                        ) ? (
                                            <>{index.change}</>
                                        ) : index.change === 0 ? (
                                            <>
                                                {index.change}
                                                <span className="indcs__change-postion"></span>
                                            </>
                                        ) : (
                                            <>
                                                {"+"}
                                                {index.change}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                ) : null}
            </table>
            {stockIndexes && stockIndexes.length === 0 ? (
                <div
                    style={{
                        width: "540px",
                        textAlign: "center"
                    }}
                >
                    <img
                        style={{
                            width: "128px",
                            transition: "350ms ease"
                        }}
                        src={Loader}
                        alt="Loader"
                    />
                </div>
            ) : null}
            <div id="indcs__spacer"></div>

            <p id="indcs__disclaimer">
                Disclaimer: Data is provided for information purposes only, and
                is not intended for trading purposes. GPARENCY shall not be
                liable for any errors or delays in the content, or for any
                actions taken in reliance thereon. All data is delayed at least
                15 minutes.
            </p>
            <div id="indcs__spacer"></div>
        </div>
    );
}
