import store from "../../../store/storeIndex";
import Collection from "../../valueObject/Collection";
import TypeString from "../../valueObject/TypeString";
import IFrameMessage from "../IFrameMessage";

export default class PropertyIdMessage extends IFrameMessage {
    public static ACTION_NAME = "property-id";

    constructor() {
        super(PropertyIdMessage.ACTION_NAME);
    }

    public send(
        iframe: HTMLIFrameElement,
        propertyId?: TypeString | undefined
    ) {
        const token = store.getState().index.token;
        super._send(
            new Collection({
                propertyId: propertyId ? propertyId.value : undefined,
                token: token
            }),
            iframe
        );
    }

    public recieve(callback: Function, data: Collection) {
        callback(data.get("propertyId"));
    }
}
