import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import FavoritePropertyGateway from "./FavoritePropertyGateway";
import FavoritePropertyRepository from "./FavoritePropertyRepository";

export default class FavoritePropertyService {
    static async addToFavorite(data: Collection): Promise<any> {
        return new FavoritePropertyGateway().addToFavorite(data);
    }

    static async removeFromFavorite(propertyId: TypeString): Promise<any> {
        return new FavoritePropertyGateway().removeFromFavorite(propertyId);
    }
    static async getPropertyStar(propertyId: TypeString): Promise<any> {
        return new FavoritePropertyGateway().getPropertyStar(propertyId);
    }
    static async getFavoriteProperties(queryData?: any): Promise<any> {
        return new FavoritePropertyRepository(
            new FavoritePropertyGateway(),
            new FavoritePropertyGateway()
        ).getFavoriteProperties(queryData);
    }

    static async toggleAlerts(data: Collection): Promise<any> {
        return new FavoritePropertyGateway().toggleAlerts(data);
    }
}
