import React, { useEffect, useState } from "react";
import {
    faCircleInfo,
    faBuildingColumns,
    faArrowLeft
} from "@fortawesome/pro-regular-svg-icons";
import { hotjar } from "react-hotjar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import responsiveSliceState, {
    setCurrentView,
    setOverlays
} from "../../../store/responsive";
import PartButton from "../../parts/PartButton";
import { setIframe } from "../../../store";
import { WHERE_FROM_CTA } from "../../../utils/constants";

interface Rates {
    term: string;
    loanProgram: string;
    max_value: string;
    min_value: string;
    propertyType: string;
}

const ratesArray = [
    "Industrial",
    "Retail",
    "Land",
    "Warehouse",
    "Office",
    "Hotels",
    "Mixed Use",
    "Self Storage",
    "Manufactured Housing",
    "RV Campground",
    "Healthcare",
    "Single Family Rental (SFR)",
    "Senior Living",
    "Short Term Rental",
    "Single Tenant",
    "SRO"
];

export default function BlockInterest() {
    const [interestRates, setInterestRates] = useState([]);
    const dispatch = useDispatch();
    const responsiveState = useSelector(responsiveSliceState);

    const fifteenMinsInMilliSecs: number = 900000;

    useEffect(() => {
        loadData();
        const interval = setInterval(() => {
            loadData();
        }, fifteenMinsInMilliSecs);
        return () => clearInterval(interval);
    }, []);

    async function loadData() {
        try {
            const r = await fetch(
                `${process.env.REACT_APP_LOOKUP_MS}/marketplace-rate-sheet`
            );
            const j = await r.json();
            setInterestRates(j.data);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div
            id="interest"
            className={`${
                responsiveState.overlays.ratesOverlay
                    ? "ratesOpen"
                    : "ratesClosed"
            }`}
        >
            <div className="indcs__header">
                <div className="indcs__header-wrap">
                    <PartButton
                        type="secondary"
                        onClick={() => {
                            dispatch(setCurrentView("dashboard"));
                            dispatch(
                                setOverlays({
                                    ...responsiveState.overlays,
                                    ratesOverlay: false
                                })
                            );
                        }}
                    >
                        <span
                            onClick={() => {
                                hotjar.event(
                                    "Interest Rates Collapse - mobile"
                                );
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} /> tools
                        </span>
                    </PartButton>
                    <h3>INTEREST RATES</h3>
                </div>
            </div>
            <div className={"interest__contact"}>
                <p>
                    <span
                        onClick={() => {
                            hotjar.event("Interest Rates Get Financing Form");
                            dispatch(
                                setIframe({
                                    show: true,
                                    street: "",
                                    city: "",
                                    propId: "",
                                    mapLat: null,
                                    mapLng: null,
                                    mapZoom: null,
                                    type: "finance",
                                    authUser: "true",
                                    whereFrom:
                                        WHERE_FROM_CTA.LEFT_NAV_INTEREST_RATES
                                })
                            );
                        }}
                    >
                        Contact us
                    </span>{" "}
                    to speak with one of our <b>top brokers</b> or use our{" "}
                    <FontAwesomeIcon icon={faBuildingColumns} />
                    <b> Find a Lender </b>
                    feature to get the best loan terms.
                </p>
            </div>

            <div className="interest__scrollable-table">
                <table className="interest__table">
                    <thead>
                        <tr className="interest__display-table">
                            <th className="interest__term-column interest__rate-headers">
                                Term
                            </th>
                            <th className="interest__loan-program-column interest__rate-headers">
                                Loan Program
                            </th>
                            <th className="interest__rate-min-column interest__rate-headers">
                                Rate Min
                                <span className="interest__info">
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                    <div
                                        className={
                                            !responsiveState.isMobile
                                                ? "interest__rate-min-info-all interest__rate-min-info"
                                                : "interest__rate-min-info-all interest__rate-min-info-mobile"
                                        }
                                    >
                                        <p>
                                            Commercial mortgage rates are more
                                            subjective than residential mortgage
                                            rates, so exact quotes can only be
                                            given after underwriting and
                                            speaking to lenders. Lower and
                                            higher rates may be present in the
                                            market for loan types and structures
                                            not covered here.
                                        </p>
                                        <p className="interest__perfect-deal">
                                            *There is always that
                                            &quot;perfect&quot; deal that will
                                            get a lower rate.*
                                        </p>
                                    </div>
                                </span>
                            </th>
                            <th className="interest__rate-max-column interest__rate-headers">
                                Rate Max
                            </th>
                        </tr>
                    </thead>
                    <tbody className="interest__tbody">
                        <tr className="interest__gray-row">
                            <td colSpan={1} className="interest__term-column">
                                Multifamily
                            </td>
                            <td colSpan={3} className="interest__disclaimer">
                                *all rates shown are for{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    permanent
                                </span>{" "}
                                loans
                            </td>
                        </tr>
                        <tr className="interest__white-row">
                            <td className="interest__term-column">5 Year</td>
                            <td className="interest__loan-program-column">
                                Bank
                            </td>
                            <td className="interest__rate-min-column">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "5 Year" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.min_value !== null &&
                                        !isNaN(+x?.min_value) &&
                                        x?.min_value > 0
                                            ? String(x?.min_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>

                            <td className="interest__rate-max-column">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "5 Year" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.max_value !== null &&
                                        !isNaN(+x?.max_value) &&
                                        x?.max_value > 0
                                            ? String(x?.max_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                        </tr>
                        <tr className="interest__white-row">
                            <td className="interest__term-column">7 Year</td>
                            <td className="interest__loan-program-column">
                                Bank
                            </td>
                            <td className="interest__rate-min-column">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "7 Year" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.min_value !== null &&
                                        !isNaN(+x?.min_value) &&
                                        x?.min_value > 0
                                            ? String(x?.min_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                            <td className="interest__rate-max-column">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "7 Year" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.max_value !== null &&
                                        !isNaN(+x?.max_value) &&
                                        x?.max_value > 0
                                            ? String(x?.max_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                        </tr>
                        <tr className="interest__white-row">
                            <td className="interest__term-column">10 Year</td>
                            <td className="interest__loan-program-column">
                                Bank
                            </td>
                            <td className="interest__rate-min-column">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram ===
                                                    "Bank" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.min_value !== null &&
                                        !isNaN(+x?.min_value) &&
                                        x?.min_value > 0
                                            ? String(x?.min_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                            <td className="interest__rate-max-column">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram ===
                                                    "Bank" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.max_value !== null &&
                                        !isNaN(+x?.max_value) &&
                                        x?.max_value > 0
                                            ? String(x?.max_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                        </tr>

                        <tr className="interest__white-row">
                            <td
                                className="interest__term-column interest__spanned-first interest__spanned-last"
                                style={{ padding: "1" }}
                                rowSpan={5}
                            >
                                <div className="interest__line"></div>
                            </td>
                            <td className="interest__loan-program-column interest__spanned interest__spanned-first">
                                Fannie Mae (Conventional){" "}
                                <span className="interest__info-fannie-mae">
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        size="lg"
                                        className="interest__info-loan"
                                    />

                                    <div>
                                        <p>
                                            The Coupon rate is based on the 10
                                            Year Treasury + the Spread with a
                                            Floor of 0.9%.
                                        </p>
                                    </div>
                                </span>
                            </td>
                            <td className="interest__rate-min-column interest__spanned interest__spanned-first">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram ===
                                                    "Fannie Mae (Conventional)" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.min_value !== null &&
                                        !isNaN(+x?.min_value) &&
                                        x?.min_value > 0
                                            ? String(x?.min_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                            <td className="interest__rate-max-column interest__spanned interest__spanned-first">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram ===
                                                    "Fannie Mae (Conventional)" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.max_value !== null &&
                                        !isNaN(+x?.max_value) &&
                                        x?.max_value > 0
                                            ? String(x?.max_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                        </tr>
                        <tr className="interest__white-row">
                            <td className="interest__loan-program-column interest__spanned interest__spanned-info">
                                Freddie Mac (Conventional){" "}
                                <span className="interest__info-freddie-mac">
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        size="lg"
                                        className="interest__info-loan"
                                    />
                                    <div>
                                        <p>
                                            The Coupon rate is based on the 10
                                            Year Treasury + the Spread with a
                                            Floor of 0.5%.
                                        </p>
                                    </div>
                                </span>
                            </td>
                            <td className="interest__rate-min-column interest__spanned">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram ===
                                                    "Freddie Mac (Conventional)" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.min_value !== null &&
                                        !isNaN(+x?.min_value) &&
                                        x?.min_value > 0
                                            ? String(x?.min_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                            <td className="interest__rate-max-column interest__spanned">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram ===
                                                    "Freddie Mac (Conventional)" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.max_value !== null &&
                                        !isNaN(+x?.max_value) &&
                                        x?.max_value > 0
                                            ? String(x?.max_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                        </tr>
                        <tr className="interest__white-row">
                            <td className="interest__loan-program-column interest__spanned interest__spanned-info">
                                Freddie Mac (SBL)
                            </td>
                            <td className="interest__rate-min-column interest__spanned">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram ===
                                                    "Freddie Mac (SBL)" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.min_value !== null &&
                                        !isNaN(+x?.min_value) &&
                                        x?.min_value > 0
                                            ? String(x?.min_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                            <td className="interest__rate-max-column interest__spanned">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram ===
                                                    "Freddie Mac (SBL)" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.max_value !== null &&
                                        !isNaN(+x?.max_value) &&
                                        x?.max_value > 0
                                            ? String(x?.max_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                        </tr>
                        <tr className="interest__white-row">
                            <td className="interest__loan-program-column interest__spanned interest__spanned-CMBS">
                                CMBS{" "}
                                <span className="interest__info-CMBS">
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        size="lg"
                                        className="interest__info-loan"
                                    />
                                    <div>
                                        <p>
                                            The Coupon rate is based on the 10
                                            Year Swap + the Spread with a Floor
                                            of 1%.
                                        </p>
                                    </div>
                                </span>
                            </td>
                            <td className="interest__rate-min-column interest__spanned">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram ===
                                                    "CMBS" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.min_value !== null &&
                                        !isNaN(+x?.min_value) &&
                                        x?.min_value > 0
                                            ? String(x?.min_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                            <td className="interest__rate-max-column interest__spanned">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram ===
                                                    "CMBS" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.max_value !== null &&
                                        !isNaN(+x?.max_value) &&
                                        x?.max_value > 0
                                            ? String(x?.max_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                        </tr>
                        <tr className="interest__white-row">
                            <td className="interest__loan-program-column interest__spanned interest__spanned-last-row interest__spanned-info">
                                FHA/HUD
                            </td>
                            <td className="interest__rate-min-column interest__spanned interest__spanned-last-row">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram === "FHA" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.min_value !== null &&
                                        !isNaN(+x?.min_value) &&
                                        x?.min_value > 0
                                            ? String(x?.min_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                            <td className="interest__rate-max-column interest__spanned interest__spanned-last-row">
                                {(() => {
                                    const x: any = interestRates?.find(
                                        function (element: Rates) {
                                            return (
                                                element.term === "10 Year" &&
                                                element.loanProgram === "FHA" &&
                                                element.propertyType ===
                                                    "Multifamily"
                                            );
                                        }
                                    );
                                    return `${
                                        x?.max_value !== null &&
                                        !isNaN(+x?.max_value) &&
                                        x?.max_value > 0
                                            ? String(x?.max_value) + "%"
                                            : "-"
                                    }`;
                                })()}
                            </td>
                        </tr>
                        {ratesArray.map((rate, index) => {
                            const x: any = interestRates?.find(
                                (e: Rates) => e.propertyType === rate
                            );
                            if (
                                x !== undefined &&
                                x.propertyType.length > 0 &&
                                (+x.min_value > 0 || +x.max_value > 0)
                            ) {
                                return (
                                    <React.Fragment key={index}>
                                        <tr className="interest__gray-row">
                                            <td
                                                className="interest__term-column"
                                                colSpan={4}
                                            >
                                                {x.propertyType}
                                            </td>
                                        </tr>
                                        <tr className="interest__white-row">
                                            <td className="interest__term-column">
                                                5 Year
                                            </td>
                                            <td>All</td>
                                            <td>
                                                {x?.min_value !== null &&
                                                !isNaN(+x?.min_value) &&
                                                x?.min_value > 0
                                                    ? String(x?.min_value) + "%"
                                                    : "-"}
                                            </td>
                                            <td>
                                                {x?.max_value !== null &&
                                                !isNaN(+x?.max_value) &&
                                                x?.max_value > 0
                                                    ? String(x?.max_value) + "%"
                                                    : "-"}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            }
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
