import Collection from "../valueObject/Collection";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import LenderFilterQuery from "./queries/LenderFilterQuery";

export default class LenderRepository {
    // eslint-disable-next-line no-unused-vars, no-empty-function
    constructor(private _gateway: any, private _factory: any) {}

    async search(query: LenderFilterQuery) {
        try {
            query._executeLoadStartTriggers();
            const data = new Collection(await this._gateway.search(query));
            query

                .setTotal(new TypePositiveInteger(data.get("total")))
                .setTotalInFilter(
                    new TypePositiveInteger(data.get("totalInFilter"))
                )
                .setItems(
                    data.slice("lenders").map((itemData: any) => {
                        return this._factory.reconstitute(
                            new Collection(itemData)
                        );
                    })
                )
                ._executeLoadEndTriggers();
        } catch (error) {
            query._executeLoadEndTriggers();
            console.log("Something went wrong while searching lenders", error);
            throw error;
        }
    }
}
