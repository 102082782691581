import Exception from "../../../utils/exceptions/Exception";
import Collection from "../../valueObject/Collection";

export default class LoanType {
    static readonly TYPE_PERMANENT = 1;
    static readonly TYPE_PERMANENT_NAME = "PERMANENT_DEBT";

    static readonly TYPE_BRIDGE = 5;
    static readonly TYPE_BRIDGE_NAME = "BRIDGE";

    static readonly TYPE_CONSTRUCTION = 6;
    static readonly TYPE_CONSTRUCTION_NAME = "CONSTRUCTION";

    private _value: number;
    private _mappedValues: Collection = new Collection({});

    constructor(value: number | string) {
        if (Number.isNaN(+value) && typeof value === "string") {
            this._value = LoanType.getAvailableLoanTypes().get(
                LoanType.getAvailableLoanTypeNames().findKey(
                    (item: any) => item === value
                )
            );
        } else if (!Number.isNaN(+value)) {
            this._value = +value;
        }

        this.validateValue();
    }

    get id(): number {
        return this._value;
    }

    get name(): string {
        return LoanType.getAvailableLoanTypeNames().get(
            LoanType.getAvailableLoanTypes().findKey(
                (item: any) => item === this._value
            )
        );
    }

    public static getAvailableLoanTypes(): Collection {
        return new Collection([
            LoanType.TYPE_PERMANENT,
            LoanType.TYPE_BRIDGE,
            LoanType.TYPE_CONSTRUCTION
        ]);
    }

    public static getAvailableLoanTypeNames(): Collection {
        return new Collection([
            LoanType.TYPE_PERMANENT_NAME,
            LoanType.TYPE_BRIDGE_NAME,
            LoanType.TYPE_CONSTRUCTION_NAME
        ]);
    }
    public mapValue(value: number, name: string) {
        this._mappedValues.set(value, name);
        return this;
    }

    public mappedValue(): string {
        return this._mappedValues.has(this._value)
            ? this._mappedValues.get(this._value)
            : this.name;
    }

    sameAs(valueObject: LoanType) {
        return (
            valueObject instanceof LoanType && valueObject.id === this._value
        );
    }

    private validateValue(): void {
        if (!LoanType.getAvailableLoanTypes().contains(this._value)) {
            throw new Exception("INVALID_LOAN_TYPE", 406);
        }
    }
}
