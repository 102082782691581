import { LoadingOutlined } from "@ant-design/icons";
import {
    faCheck,
    faClose,
    faFile,
    faFileImage,
    faFilePdf,
    faFileSpreadsheet
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Progress, Spin } from "antd";
import { useEffect, useState } from "react";
import PartButton from "../../parts/PartButton";

interface SingleUploadItemProps {
    file: any;
    filesInDeleteProcess: any;
    filesInUploadProcess: any;
    filePercentage: any;
    deleteHandler: any;
    showDeleteBtn: string | null;
    setShowDeleteBtn: any;
    uploadMessage: any;
}

const loaderIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

const SingleUploadItem = ({
    file,
    filesInDeleteProcess,
    filesInUploadProcess,
    uploadMessage,
    filePercentage,
    deleteHandler,
    showDeleteBtn,
    setShowDeleteBtn
}: SingleUploadItemProps) => {
    const [fontAwesomeIcon, setFontAwesomeIcon] = useState<any>(faFile);
    const [hasUploadError, setHasUploadError] = useState<boolean>(false);
    const [fSize, setFsize] = useState<any>(0);

    useEffect(() => {
        if (file && (file.fileName || file.name)) {
            const name = file.fileName ?? file.name;
            const extension = name.substr(name.lastIndexOf(".") + 1);

            switch (extension) {
                case "jpg":
                case "jpeg":
                case "png":
                    setFontAwesomeIcon(faFileImage);
                    break;
                case "xls":
                case "xlsx":
                    setFontAwesomeIcon(faFileSpreadsheet);
                    break;
                case "pdf":
                    setFontAwesomeIcon(faFilePdf);
                    break;
                default:
                    setFontAwesomeIcon(faFile);
            }
        }
    }, [file]);

    useEffect(() => {
        const error =
            uploadMessage.includes(file.id) || uploadMessage.includes(file.uid);
        setHasUploadError(error);
    }, [uploadMessage]);

    // Use internal file size if file is still not uploaded
    useEffect(() => {
        const fSize = file.sizeKB ?? file.size;
        const fileSize = file.sizeKB
            ? `${(fSize / 1024).toFixed(2)}MB`
            : `${(fSize / (1024 * 1024)).toFixed(2)}MB`;
        setFsize(fileSize);
    }, [file]);

    const isInDeleteProcess = filesInDeleteProcess.find(
        (el: any) => el === file?.id
    );

    const isInUploadProcess = filesInUploadProcess.find(
        (el: any) => el === file?.uid
    );

    return (
        <div
            className={`upload-documents__item ${
                showDeleteBtn === file.id ? "delete-opened" : ""
            } ${isInDeleteProcess ? "is-deleting" : ""}`}
            onMouseLeave={() => setShowDeleteBtn(null)}
            onClick={() => {
                setShowDeleteBtn(null);
            }}
        >
            <a
                className="upload-documents__link"
                href={`${file.url}`}
                download
                target="_blank"
                rel="noreferrer"
            ></a>
            <Spin indicator={loaderIcon} />
            <div className="upload-documents__item-main">
                {isInUploadProcess && filePercentage.uid !== file.uid ? (
                    <div className="upload-documents__loader">
                        <Spin indicator={loaderIcon} />
                    </div>
                ) : (
                    <div className="upload-documents__item-icon">
                        <FontAwesomeIcon icon={fontAwesomeIcon} />
                    </div>
                )}
                <div className="upload-documents__file-info-wrapper">
                    <span className="upload-documents__item-name">
                        {file.fileName ?? file.name}{" "}
                        {filePercentage.uid === file.uid &&
                        isInUploadProcess ? (
                            <span>({fSize})</span>
                        ) : null}
                        {hasUploadError && (
                            <>
                                {/* <span>({file.sizeKB})</span> */}
                                <span className="upload-documents__error">
                                    Upload Failed
                                </span>
                            </>
                        )}
                    </span>
                    {!isInUploadProcess ? (
                        <span className="upload-documents__item-file-size">
                            {fSize}
                        </span>
                    ) : null}
                    {isInUploadProcess ? (
                        <>
                            <Progress
                                percent={
                                    filePercentage.uid === file.uid
                                        ? filePercentage?.percent
                                        : 0
                                }
                                format={(percent) => `${percent}%`}
                            />
                            <span className="ant-progress-text">
                                {filePercentage.uid === file.uid
                                    ? filePercentage?.percent
                                    : 0}
                                %
                            </span>
                        </>
                    ) : null}
                </div>
            </div>
            {!isInUploadProcess ? (
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={`upload-documents__buttons ${
                        showDeleteBtn === file.id ? "show-delete" : ""
                    }`}
                >
                    <span
                        role="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowDeleteBtn(file.id);
                            // Stop propagation to child elements
                        }}
                        className={
                            hasUploadError
                                ? "upload-documents__remove-btn has-error"
                                : "upload-documents__remove-btn"
                        }
                    >
                        <FontAwesomeIcon icon={faClose} />
                    </span>
                    <PartButton
                        type="primary"
                        onClick={() => deleteHandler(file.id)}
                        className="upload-documents__remove-item"
                    >
                        <>
                            <FontAwesomeIcon icon={faCheck} />
                            <span>Delete </span>
                        </>
                    </PartButton>
                </div>
            ) : null}
        </div>
    );
};

export default SingleUploadItem;
