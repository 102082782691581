import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <div className="site-header__not_logged_in">
            <button
                onClick={() => {
                    loginWithRedirect({ redirectUri: window.location.href });
                }}
            >
                Free Sign Up / Log In
            </button>
        </div>
    );
};

export default LoginButton;
