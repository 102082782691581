import Collection from "../../valueObject/Collection";
import TypeString from "../../valueObject/TypeString";
import OwnershipEntity from "./OwnershipEntity";

export default class OwnershipFactory {
    reconstitute(item: Collection) {
        const owner = new OwnershipEntity();

        if (item.has("name")) owner.setName(new TypeString(item.get("name")));
        if (item.has("mailingAddress"))
            owner.setMailingAddress(new TypeString(item.get("mailingAddress")));

        return owner;
    }
}
