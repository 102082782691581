const BlockCardLoader = () => {
    return (
        <div className="property-card__loader">
            <span className="property-card__loader-header"></span>
            <div className="property-card__loader-body">
                <span className="property-card__loader-body-line"></span>
                <span className="property-card__loader-body-line property-card__loader-body-line--cut"></span>
                <span className="property-card__loader-body-line property-card__loader-body-line--cut property-card__loader-body-line--big"></span>
                <div className="property-card__loader-body-lines">
                    <span className="property-card__loader-body-line"></span>
                    <span className="property-card__loader-body-line"></span>
                </div>
                <span className="property-card__loader-body-line property-card__loader-body-line--cut property-card__loader-body-line--large"></span>
            </div>
            <span className="property-card__loader-footer"></span>
        </div>
    );
};

export default BlockCardLoader;
