import { useEffect, useRef } from "react";
import IconClose from "../icons/IconClose";

const BlockStreetView = ({ closeStreetView }: any) => {
    const streetViewRef = useRef<any>(null);

    const handleClickOutside = (event: any) => {
        if (
            streetViewRef.current &&
            !streetViewRef.current.contains(event.target)
        ) {
            closeStreetView();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        return () => {
            document.getElementById("street-view")?.remove();
        };
    }, []);
    return (
        <div ref={streetViewRef} className="street-view__wrapper">
            <span className="street-view__close" onClick={closeStreetView}>
                <IconClose />
            </span>
            <div className="street-view" id="street-view"></div>
        </div>
    );
};

export default BlockStreetView;
