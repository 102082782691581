import { hotjar } from "react-hotjar";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, { setIframe } from "../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faTag, faUserTag } from "@fortawesome/pro-regular-svg-icons";
import { PropertyCardData } from "../../../domain/property/PropertyViewMapper";
import { useState } from "react";
import PartPropertyServiceProvidersList from "../../parts/PartPropertyServiceProvidersList";
import forSaleSign from "../../../assets/images/for-sale-sign.png";

interface BlockTabListingProps {
    propertyCardData: PropertyCardData;
    propertyVendors: Array<any>;
}

const BlockTabListing = ({
    propertyCardData,
    propertyVendors
}: BlockTabListingProps) => {
    const dispatch = useDispatch();
    const indexSlice = useSelector(indexSliceState);
    const [listingPriceExpanded, setListingPriceExpanded] = useState(false);

    return (
        <div className="property-card__listing">
            <div className="property-card__listing-top">
                <h3>
                    <FontAwesomeIcon icon={faUserTag} />
                    Investment Sales Broker
                </h3>
            </div>

            {propertyCardData.listing?.agent ? (
                <div className={"property-card__group-item agent open"}>
                    {/* <div className="property-card__group-icon">
            <FontAwesomeIcon icon={faAddressBook} />
        </div> */}
                    <div>
                        <div className="property-card__group-item-content">
                            <p className="property-card__group-item-info">
                                {propertyCardData.listing?.agent?.name}
                            </p>
                        </div>
                        <div className="property-card__list">
                            <a
                                className="property-card__list-content property-card__list-content--email property-card__list-link"
                                href={
                                    "mailto:" +
                                    `${propertyCardData.listing.agent?.email}`
                                }
                                onClick={() => {
                                    hotjar.event(`Link Mail Agent`);
                                    hotjar.event(
                                        `Link Mail Agent - ${propertyCardData.listing?.agent?.name}`
                                    );
                                    hotjar.event(
                                        `Link Mail Agent for Address - ${propertyCardData.address}`
                                    );
                                }}
                            >
                                {propertyCardData.listing.agent?.email}
                            </a>
                            <a
                                className="property-card__list-content property-card__list-link"
                                href={
                                    "tel:" +
                                    `${propertyCardData.listing.agent?.phone}`
                                }
                                onClick={() => {
                                    hotjar.event(`Link Phone Agent`);
                                    hotjar.event(
                                        `Link Phone Agent - ${propertyCardData.listing?.agent?.name}`
                                    );
                                    hotjar.event(
                                        `Link Phone Agent for Address - ${propertyCardData.address}`
                                    );
                                }}
                            >
                                {propertyCardData.listing.agent?.phone}
                            </a>

                            <span className="property-card__list-content property-card__list-content--name">
                                {propertyCardData.listing?.company}
                            </span>
                        </div>
                    </div>
                    <div className="property-card__group-image">
                        <img src={forSaleSign} alt="" width="75" />
                    </div>
                </div>
            ) : null}

            {propertyCardData.listing.price &&
            propertyCardData.listing?.price !== "$0.00" ? (
                listingPriceExpanded ? (
                    <div className="property-card__group-item price">
                        {propertyCardData.listing?.price !== undefined &&
                        !propertyCardData.listing?.price.startsWith("$0") ? (
                            <div className="property-card__group-icon price">
                                <FontAwesomeIcon icon={faTag} />
                            </div>
                        ) : null}
                        <div className="property-card__group-item-content">
                            {!propertyCardData.listing?.price?.startsWith(
                                "$0"
                            ) ? (
                                <div className="property-card__group-item-info bell">
                                    {
                                        propertyCardData.listing.price
                                            ?.toLocaleString()
                                            .split(".")[0]
                                    }
                                    <div
                                        className="property-card__bell"
                                        onClick={() => {
                                            hotjar.event(
                                                "Icon Property Card - Price Change Click"
                                            );
                                            const splitAddress =
                                                propertyCardData.address.split(
                                                    ","
                                                );
                                            const streetLine: any =
                                                splitAddress[0];
                                            const cityLine: any = `${splitAddress[1]}, ${splitAddress[2]}`;
                                            dispatch(
                                                setIframe({
                                                    show: true,
                                                    street: streetLine || "",
                                                    city: cityLine || "",
                                                    propId: propertyCardData.id,
                                                    mapLat: indexSlice
                                                        .propertyCardItem?.lat,
                                                    mapLng: indexSlice
                                                        .propertyCardItem?.lng,
                                                    mapZoom:
                                                        indexSlice.iframe.zoom,
                                                    type: "alert",
                                                    authUser: "true"
                                                })
                                            );
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faBell} />
                                        <div>
                                            <span>Price Alert</span>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div className="property-card__group-item-details">
                                {propertyCardData.listing?.pricePerSqFt &&
                                !propertyCardData.listing?.pricePerSqFt.startsWith(
                                    "$0"
                                ) ? (
                                    <p>
                                        {
                                            propertyCardData.listing?.pricePerSqFt
                                                ?.toLocaleString()
                                                .split(".")[0]
                                        }
                                        /sq. ft.
                                    </p>
                                ) : null}

                                {propertyCardData.listing?.pricePerUnit &&
                                !propertyCardData.listing?.pricePerUnit.startsWith(
                                    "$0"
                                ) ? (
                                    <p>
                                        {
                                            propertyCardData.listing?.pricePerUnit
                                                ?.toLocaleString()
                                                .split(".")[0]
                                        }
                                        /unit
                                    </p>
                                ) : null}
                            </div>
                            <p className="property-card__disclosure">
                                GPARENCY does not guarantee listing data
                                accuracy. For the most up-to-date and detailed
                                information, please contact the listing broker.
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="property-card__group-item price-cta">
                        <div className="property-card__group-icon price">
                            <FontAwesomeIcon icon={faTag} />
                        </div>
                        <span onClick={() => setListingPriceExpanded(true)}>
                            See Active Listing Price
                        </span>
                    </div>
                )
            ) : null}
            <PartPropertyServiceProvidersList
                propertyVendors={propertyVendors}
            />
        </div>
    );
};

export default BlockTabListing;
