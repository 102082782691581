import Collection from "../valueObject/Collection";
import NoteEntity from "./NoteEntity";

export default class NoteViewMapper {
    static map(notes: Collection) {
        return notes.map((note: NoteEntity) => {
            let formattedDate = "N/A";
            if (note.hasCreatedAt()) {
                formattedDate = NoteViewMapper.formatDate(note.createdAt);
            }

            return {
                id: note.id.value,
                value: note.hasContent() ? note.content.value : "-",
                date: note.hasCreatedAt() ? formattedDate : "-"
            };
        });
    }

    private static formatDate(date: Date) {
        const formattedDate =
            (date.getMonth() > 8
                ? date.getMonth() + 1
                : "0" + (date.getMonth() + 1)) +
            "/" +
            (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
            "/" +
            date.getFullYear() +
            " @ " +
            date.getHours() +
            ":" +
            date.getMinutes() +
            " " +
            (date.getHours() > 12 ? "PM" : "AM");

        return formattedDate;
    }
}
