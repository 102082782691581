import TypePositiveInteger from "../../valueObject/TypePositiveInteger";

export default class PropertyTaxAssessmentEntity {
    private _propertyTax: TypePositiveInteger;
    private _taxYear: TypePositiveInteger;
    private _assessedYear: TypePositiveInteger;
    private _assessedValue: TypePositiveInteger;

    get propertyTax(): TypePositiveInteger {
        return this._propertyTax;
    }

    hasPropertyTax(): boolean {
        return this._propertyTax instanceof TypePositiveInteger;
    }

    setPropertyTax(value: TypePositiveInteger) {
        this._propertyTax = value;
        return this;
    }

    get taxYear(): TypePositiveInteger {
        return this._taxYear;
    }

    hasTaxYear(): boolean {
        return this._taxYear instanceof TypePositiveInteger;
    }

    setTaxYear(value: TypePositiveInteger) {
        this._taxYear = value;
        return this;
    }

    get assessedYear(): TypePositiveInteger {
        return this._assessedYear;
    }

    hasAssessedYear(): boolean {
        return this._assessedYear instanceof TypePositiveInteger;
    }

    setAssessedYear(value: TypePositiveInteger) {
        this._assessedYear = value;
        return this;
    }

    get assessedValue(): TypePositiveInteger {
        return this._assessedValue;
    }

    hasAssessedValue(): boolean {
        return this._assessedValue instanceof TypePositiveInteger;
    }

    setAssessedValue(value: TypePositiveInteger) {
        this._assessedValue = value;
        return this;
    }
}
