import { RAM_PERSISTENCE } from "../../..";
import PropertySearchQuery from "./PropertySearchQuery";

export default class PropertySearchQueryRAMGateway {
    _entityKey = "SearchQueryEntity";

    persist(persistenceKey: any, searchQuery: PropertySearchQuery) {
        RAM_PERSISTENCE.persist(persistenceKey, searchQuery, this._entityKey);
    }

    retrieve(persistenceKey: any) {
        return RAM_PERSISTENCE.retrieve(persistenceKey, this._entityKey);
    }
}
