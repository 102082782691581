import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";

export default class ListingBrokerEntity {
    _id: TypePositiveInteger;
    _name: TypeString;

    get id(): TypePositiveInteger {
        return this._id;
    }

    setId(value: TypePositiveInteger) {
        this._id = value;
        return this;
    }
    hasId(): boolean {
        return this._id instanceof TypePositiveInteger;
    }

    get name(): TypeString {
        return this._name;
    }

    setName(value: TypeString): ListingBrokerEntity {
        this._name = value;
        return this;
    }

    hasName(): boolean {
        return this._name instanceof TypeString;
    }
}
