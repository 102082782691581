import AgentEntity from "../representer/agent/AgentEntity";
import TypeFloat from "../valueObject/TypeFloat";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import TypeTimestamp from "../valueObject/TypeTimestamp";

export default class PropertyListingEntity {
    _id: TypeString;
    _price: TypeFloat | null;
    _pricePerSqFt: TypeFloat;
    _pricePerUnit: TypeFloat;
    _units: TypePositiveInteger;
    _company: TypeString | null;
    _agent: AgentEntity | null;
    _publicListingExpiration: TypeTimestamp;

    get id(): TypeString {
        return this._id;
    }

    setId(id: TypeString) {
        this._id = id;
        return this;
    }

    hasId(): boolean {
        return this._id instanceof TypeString;
    }

    get price(): TypeFloat | null {
        return this._price;
    }

    setPrice(price: TypeFloat | null) {
        this._price = price;
        return this;
    }

    hasPrice(): boolean {
        return this._price instanceof TypeFloat || this._price === null;
    }

    get pricePerSqFt(): TypeFloat {
        return this._pricePerSqFt;
    }

    setPricePerSqFt(pricePerSqFt: TypeFloat) {
        this._pricePerSqFt = pricePerSqFt;
        return this;
    }

    hasPricePerSqFt(): boolean {
        return this._pricePerSqFt instanceof TypeFloat;
    }

    get pricePerUnit(): TypeFloat {
        return this._pricePerUnit;
    }

    setPricePerUnit(pricePerUnit: TypeFloat) {
        this._pricePerUnit = pricePerUnit;
        return this;
    }

    hasPricePerUnit(): boolean {
        return this._pricePerUnit instanceof TypeFloat;
    }

    get units(): TypePositiveInteger {
        return this._units;
    }

    setUnits(units: TypePositiveInteger) {
        this._units = units;
        return this;
    }

    hasUnits(): boolean {
        return this._units instanceof TypePositiveInteger;
    }

    get agent(): AgentEntity | null {
        return this._agent;
    }

    setAgent(agent: AgentEntity | null) {
        this._agent = agent;
        return this;
    }

    hasAgent(): boolean {
        return this._agent instanceof AgentEntity || this._agent === null;
    }

    get company(): TypeString | null {
        return this._company;
    }
    setCompany(value: TypeString | null) {
        this._company = value;
        return this;
    }

    hasCompany(): boolean {
        return this._company instanceof TypeString || this._company === null;
    }

    get publicListingExpiration(): TypeTimestamp {
        return this._publicListingExpiration;
    }

    setPublicListingExpiration(publicListingExpiration: TypeTimestamp) {
        this._publicListingExpiration = publicListingExpiration;
        return this;
    }

    hasPublicListingExpiration(): boolean {
        return this._publicListingExpiration instanceof TypeTimestamp;
    }
}
