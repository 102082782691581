import Collection from "../valueObject/Collection";
import ListingBrokerFactory from "./ListingBrokerFactory";
import ListingBrokerGateway from "./ListingBrokerGateway";

export default class ListingBrokerRepository {
    _gateway: ListingBrokerGateway;
    _factory: ListingBrokerFactory;

    constructor(gateway: ListingBrokerGateway, factory: ListingBrokerFactory) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async get(names?: Collection) {
        const data = new Collection(await this._gateway.get(names));
        let items = new Collection([]);
        items = data.map((itemData: any) => {
            return new ListingBrokerFactory().reconstitute(
                new Collection(itemData)
            );
        });
        return items;
    }
}
