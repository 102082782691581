import VendorRoleEntity from "./VendorRoleEntity";

export default class VendorRoleViewMapper {
    static map(data: VendorRoleEntity[]) {
        return data.map((item: VendorRoleEntity) => {
            return {
                value: item.code.value,
                label: item.description.value
            };
        });
    }
}
