import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import TypeBoolean from "../valueObject/TypeBoolean";

export default class OriginatorEntity {
    private _id: TypeString;
    private _name: TypeString;
    private _email: TypeString;
    private _phone: TypeString;
    private _company: TypeString;
    private _state: TypeString;
    private _slug: TypeString;
    private _primary: TypeBoolean;
    private _lenderProfiles: Collection = new Collection([]);

    get id() {
        return this._id;
    }

    setId(value: TypeString) {
        this._id = value;
        return this;
    }

    get name() {
        return this._name;
    }

    setName(value: TypeString) {
        this._name = value;
        return this;
    }

    get phone() {
        return this._phone;
    }

    setPhone(value: TypeString) {
        this._phone = value;
        return this;
    }

    hasPhone() {
        return this._phone instanceof TypeString && this._phone.value !== "0";
    }

    get email() {
        return this._email;
    }

    setEmail(value: TypeString) {
        this._email = value;
        return this;
    }

    hasEmail() {
        return this._email instanceof TypeString;
    }

    get slug() {
        return this._slug;
    }

    setSlug(value: TypeString) {
        this._slug = value;
        return this;
    }

    hasSlug() {
        return this._slug instanceof TypeString;
    }

    get state(): TypeString {
        return this._state;
    }

    setState(value: TypeString) {
        this._state = value;
        return this;
    }

    hasState(): boolean {
        return this._state instanceof TypeString;
    }

    get company(): TypeString {
        return this._company;
    }

    setCompany(value: TypeString) {
        this._company = value;
        return this;
    }

    hasCompany(): boolean {
        return this._company instanceof TypeString;
    }

    get lenderProfiles(): Collection {
        return this._lenderProfiles;
    }

    setLenderProfiles(value: Collection) {
        this._lenderProfiles = value;
        return this;
    }

    get primary() {
        return this._primary;
    }

    setPrimary(value: TypeBoolean) {
        this._primary = value;
        return this;
    }

    hasPrimary() {
        return this._primary instanceof TypeBoolean;
    }
}
