import Collection from "../../valueObject/Collection";
import TypeFloat from "../../valueObject/TypeFloat";
import TypePositiveInteger from "../../valueObject/TypePositiveInteger";
import TypeString from "../../valueObject/TypeString";
import BubbleEntity from "./BubbleEntity";

export default class BubbleFactory {
    reconstitute(item: Collection) {
        return new BubbleEntity()
            .setLatitude(new TypeFloat(item.get("latitude")))
            .setLongitude(new TypeFloat(item.get("longitude")))
            .setListingCount(new TypePositiveInteger(item.get("listingCount")))
            .setFinanceCount(
                new TypePositiveInteger(item.get("refinanceCount"))
            )
            .setSaleCount(new TypePositiveInteger(item.get("salesCount")));
    }

    reconstituteLenders(item: Collection) {
        const bubble = new BubbleEntity()
            .setLatitude(new TypeFloat(item.get("latitude")))
            .setLongitude(new TypeFloat(item.get("longitude")));

        item.filter((count: number, lenderName: string) => {
            return lenderName !== "latitude" && lenderName !== "longitude";
        }).forEach((count: number, lenderName: string) =>
            bubble.addLender(
                new TypeString(lenderName),
                new TypePositiveInteger(count)
            )
        );
        return bubble;
    }
}
