import BrokerEntity from "../representer/broker/BrokerEntity";
import TypeFloat from "../valueObject/TypeFloat";
import TypeTimestamp from "../valueObject/TypeTimestamp";
import TypeString from "../valueObject/TypeString";

export default class SaleHistoryEntity {
    _id: TypeString;
    _saleDate: TypeTimestamp;
    _price: TypeFloat;
    _pricePerSqFt: TypeFloat;
    _pricePerUnit: TypeFloat;
    _broker: BrokerEntity;
    _priorSaleDate: TypeTimestamp;
    _priorSalePrice: TypeFloat;
    _sellerName: TypeString;

    get id(): TypeString {
        return this._id;
    }

    setId(id: TypeString) {
        this._id = id;
        return this;
    }

    hasId(): boolean {
        return this._id instanceof TypeString;
    }

    get saleDate(): TypeTimestamp {
        return this._saleDate;
    }

    setSaleDate(saleDate: TypeTimestamp) {
        this._saleDate = saleDate;
        return this;
    }

    hasSaleDate(): boolean {
        return this._saleDate instanceof TypeTimestamp;
    }

    get price(): TypeFloat {
        return this._price;
    }

    setPrice(price: TypeFloat) {
        this._price = price;
        return this;
    }

    hasPrice(): boolean {
        return this._price instanceof TypeFloat;
    }

    get pricePerSqFt(): TypeFloat {
        return this._pricePerSqFt;
    }

    setPricePerSqFt(pricePerSqFt: TypeFloat) {
        this._pricePerSqFt = pricePerSqFt;
        return this;
    }

    hasPricePerSqFt(): boolean {
        return this._pricePerSqFt instanceof TypeFloat;
    }

    get pricePerUnit(): TypeFloat {
        return this._pricePerUnit;
    }

    setPricePerUnit(pricePerUnit: TypeFloat) {
        this._pricePerUnit = pricePerUnit;
        return this;
    }

    hasPricePerUnit(): boolean {
        return this._pricePerUnit instanceof TypeFloat;
    }

    get broker(): BrokerEntity {
        return this._broker;
    }

    setBroker(broker: BrokerEntity) {
        this._broker = broker;
        return this;
    }

    hasBroker(): boolean {
        return this._broker instanceof BrokerEntity;
    }

    get priorSaleDate(): TypeTimestamp {
        return this._priorSaleDate;
    }

    setPriorSaleDate(priorSaleDate: TypeTimestamp) {
        this._priorSaleDate = priorSaleDate;
        return this;
    }

    hasPriorSaleDate(): boolean {
        return this._priorSaleDate instanceof TypeTimestamp;
    }

    get priorSalePrice(): TypeFloat {
        return this._priorSalePrice;
    }

    setPriorSalePrice(priorSalePrice: TypeFloat) {
        this._priorSalePrice = priorSalePrice;
        return this;
    }

    hasPriorSalePrice(): boolean {
        return this._priorSalePrice instanceof TypeFloat;
    }

    get sellerName(): TypeString {
        return this._sellerName;
    }

    setSellerName(sellerName: TypeString) {
        this._sellerName = sellerName;
        return this;
    }

    hasSellerName(): boolean {
        return this._sellerName instanceof TypeString;
    }
}
