export default class RAMPersistence {
    /**
     * @type {
     *      [class name] : {
     *          [persistence key] : [instance]
     *      },
     *      ...
     * }
     */
    _persistence: any;
    /**
     * @type {
     *      [class name] : {
     *          [persistence key] : [promise]
     *      },
     *      ...
     * }
     */
    _promisePersistence: any;

    constructor() {
        this._persistence = {};
        this._promisePersistence = {};
    }

    /**
     * @param {string} persistenceKey
     * @param {string} className
     * @return {undefined|Promise<any>}
     */
    retrieve(persistenceKey: string, className: string) {
        let instance =
            className in this._persistence &&
            persistenceKey in this._persistence[className]
                ? this._persistence[className][persistenceKey]
                : undefined;

        if (instance === undefined) {
            instance =
                className in this._promisePersistence &&
                persistenceKey in this._promisePersistence[className]
                    ? this._promisePersistence[className][persistenceKey]
                    : undefined;
        }

        return instance;
    }

    /**
     * @param {string} persistenceHash
     * @param {object/promise} instance
     * @param {string} className
     */
    persist(persistenceKey: string, instance: any, className: string) {
        if (instance instanceof Promise) {
            if (!(className in this._promisePersistence)) {
                this._promisePersistence[className] = {};
            }

            if (
                // Make sure we're not overwriting an existing instance in promise persistence and in instance persistence
                !(persistenceKey in this._promisePersistence[className]) &&
                (!(className in this._persistence) ||
                    !(persistenceKey in this._persistence[className]))
            ) {
                this._promisePersistence[className][persistenceKey] =
                    this._resolvePromiseInstance(
                        persistenceKey,
                        instance,
                        className
                    );
            }
        } else {
            if (!(className in this._persistence)) {
                this._persistence[className] = {};
            }

            if (!(persistenceKey in this._persistence[className])) {
                this._persistence[className][persistenceKey] = instance;
            }
        }
    }

    /**
     * @param {string} persistenceKey
     * @param {object/promise} instance
     * @param {string} className
     * @returns {Promise<any>}
     * @private
     */
    async _resolvePromiseInstance(
        persistenceKey: string,
        instance: any,
        className: string
    ) {
        const resolvedInstance = await instance;

        if (!(className in this._persistence)) {
            this._persistence[className] = {};
        }

        this._persistence[className][persistenceKey] = resolvedInstance;
        delete this._promisePersistence[className][persistenceKey];
        return this._persistence[className][persistenceKey];
    }
}
