import { Space } from "antd";
import { defaultRangeValues } from "../blocks/sidebar/BlockFilters";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";

interface InputFieldProps {
    name: string;
    label: string;
    errors: Object;
    isRequired?: boolean; //If true, adds * next to the input field
    value?: any;
    range: any;
    onRangeChange?: any;
    setRangeFormat?: any;
}

const PartInputRangeNumber = ({
    name,
    label,
    errors,
    isRequired,
    value,
    range,
    setRangeFormat,
    onRangeChange
}: InputFieldProps) => {
    const [inputState, setInputState] = useState(value);

    useEffect(() => {
        setInputState(value);
        if (range[0] > range[1]) {
            if (name === "maxPrice") {
                setInputState(defaultRangeValues[1]);
                onRangeChange([range[0], defaultRangeValues[1]]);
            }
        }
    }, [value, range, inputState]);

    return (
        <div
            className={`form-element form-element--input ${
                name in errors ? "form-element--error" : ""
            }`}
        >
            <label>
                {label}
                {isRequired && <span>{" *"}</span>}
            </label>
            <div className="form-element__inner">
                <span className="form-element__prefix">$</span>
                <NumericFormat
                    name={name}
                    allowLeadingZeros={false}
                    thousandSeparator=","
                    value={inputState}
                    allowNegative={false}
                    isAllowed={(value: any) => {
                        if (value.floatValue > defaultRangeValues[1]) {
                            return false;
                        }
                        return true;
                    }}
                    onBlur={(e) => {
                        const val = e.target.value
                            ? parseInt(e.target.value.replace(/,/g, ""))
                            : 0;
                        const values =
                            name === "minPrice"
                                ? [val, range[1]]
                                : [range[0], val];
                        onRangeChange(values);
                        setRangeFormat("short");
                        setInputState(val);
                    }}
                />
            </div>
            <Space />
        </div>
    );
};

export default PartInputRangeNumber;
