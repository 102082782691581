import TypeFloat from "../../valueObject/TypeFloat";
import TypeString from "../../valueObject/TypeString";

export default class LPInvestmentTransaction {
    _transactionType: TypeString;
    _amount: TypeFloat;
    _note: TypeString;
    _sortKey: TypeString;
    _transactionDate: Date;

    get transactionType() {
        return this._transactionType;
    }

    setTransactionType(value: TypeString) {
        this._transactionType = value;
        return this;
    }

    hasTransactionType() {
        return this._transactionType instanceof TypeString;
    }

    get amount() {
        return this._amount;
    }

    setAmount(value: TypeFloat) {
        this._amount = value;
        return this;
    }

    hasAmount() {
        return this._amount instanceof TypeFloat;
    }

    get note() {
        return this._note;
    }

    setNote(value: TypeString) {
        this._note = value;
        return this;
    }

    hasNote() {
        return this._note instanceof TypeString;
    }

    get sortKey() {
        return this._sortKey;
    }

    setSortKey(value: TypeString) {
        this._sortKey = value;
        return this;
    }

    hasSortKey() {
        return this._sortKey instanceof TypeString;
    }

    get transactionDate() {
        return this._transactionDate;
    }

    setTransactionDate(value: Date) {
        this._transactionDate = value;
        return this;
    }

    hasTransactionDate() {
        return this._transactionDate instanceof Date;
    }
}
