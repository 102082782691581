import BaseValueObject from "../valueObject/BaseValueObject";
import Collection from "../valueObject/Collection";

export default abstract class IFrameMessage {
    // private _iframe: HTMLIFrameElement;
    // eslint-disable-next-line no-unused-vars, no-empty-function
    constructor(private action: string, private target: string = "*") {}

    protected _send(
        data: Collection,
        iframe: HTMLIFrameElement | undefined = undefined
    ) {
        data = data.map((item: any) =>
            item instanceof BaseValueObject ? item.value : item
        ).items;
        const target = iframe ? iframe.contentWindow : window.parent;

        target?.postMessage(
            {
                action: this.action,
                ...data
            },
            this.target
        );
    }
}
