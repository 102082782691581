import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";

export default class PropertyDocumentEntity {
    _id: TypeString;
    _fileName: TypeString;
    _description: TypeString;
    _url: TypeString;
    _sizeKB: TypePositiveInteger;

    get id(): TypeString {
        return this._id;
    }

    setId(id: TypeString): PropertyDocumentEntity {
        this._id = id;
        return this;
    }

    hasId(): boolean {
        return this._id instanceof TypeString;
    }

    get fileName(): TypeString {
        return this._fileName;
    }

    setFileName(fileName: TypeString): PropertyDocumentEntity {
        this._fileName = fileName;
        return this;
    }

    hasFileName(): boolean {
        return this._fileName instanceof TypeString;
    }

    get description(): TypeString {
        return this._description;
    }

    setDescription(description: TypeString): PropertyDocumentEntity {
        this._description = description;
        return this;
    }

    hasDescription(): boolean {
        return this._description instanceof TypeString;
    }

    get url(): TypeString {
        return this._url;
    }

    setUrl(url: TypeString): PropertyDocumentEntity {
        this._url = url;
        return this;
    }

    hasUrl(): boolean {
        return this._url instanceof TypeString;
    }

    get sizeKB(): TypePositiveInteger {
        return this._sizeKB;
    }

    setSizeKB(sizeKB: TypePositiveInteger): PropertyDocumentEntity {
        this._sizeKB = sizeKB;
        return this;
    }

    hasSizeKB(): boolean {
        return this._sizeKB instanceof TypePositiveInteger;
    }
}
