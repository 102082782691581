import Collection from "../valueObject/Collection";
import FinanceHistoryEntity from "./FinanceHistoryEntity";
import TypeFloat from "../valueObject/TypeFloat";
import TypeTimestamp from "../valueObject/TypeTimestamp";
import TypeString from "../valueObject/TypeString";
import TypeBoolean from "../valueObject/TypeBoolean";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";

export default class FinanceHistoryFactory {
    reconstitute(item: Collection) {
        const financeHistory = new FinanceHistoryEntity();

        if (item.has("id"))
            financeHistory.setId(new TypeString(item.get("id")));

        if (item.has("lender"))
            financeHistory.setLender(new TypeString(item.get("lender")));
        if (item.has("loanAmount"))
            financeHistory.setLoanAmount(new TypeFloat(item.get("loanAmount")));
        if (item.has("maturityDate"))
            financeHistory.setMaturityDate(
                new TypeTimestamp(item.get("maturityDate"))
            );
        if (item.has("mortgageDate"))
            financeHistory.setMortgageDate(
                new TypeTimestamp(item.get("mortgageDate"))
            );

        if (item.has("isPurchase"))
            financeHistory.setIsPurchase(
                new TypeBoolean(item.get("isPurchase"))
            );

        if (item.has("isPaidOff"))
            financeHistory.setMortgagePaidOff(
                new TypeBoolean(item.get("isPaidOff"))
            );

        if (item.has("blanket"))
            financeHistory.setBlanketMortgage(
                new TypeBoolean(item.get("blanket"))
            );

        if (item.has("openLoans"))
            financeHistory.setNumberOfOpenLoans(
                new TypePositiveInteger(item.get("openLoans"))
            );

        if (item.has("openAmount"))
            financeHistory.setOpenLoanAmount(
                new TypeFloat(item.get("openAmount"))
            );

        return financeHistory;
    }
}
