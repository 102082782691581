import { Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faArrowRotateRight
} from "@fortawesome/pro-regular-svg-icons";
import PartDropdown from "./PartDropdown";
import { useForm } from "react-hook-form";
import PartButton from "./PartButton";
import { getStateAbbById } from "../../utils/mappers";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState from "../../store";
import favoritePropertySliceState, {
    setFavoritesProperties
} from "../../store/favoriteProperty";
import FavoritePropertyService from "../../domain/favoriteProperty/FavoritePropertyService";
import FavoritePropertyViewMapper from "../../domain/favoriteProperty/FavoritePropertyViewMapper";
import { useState } from "react";
import Collection from "../../domain/valueObject/Collection";
import PartDropdownTagTypes from "./PartDropdownTagTypes";

interface PartFilterFavoritesProps {
    updateStateOnSubmit: Function;
    setIsLoading: Function;
}

const PartFilterFavorites = ({
    updateStateOnSubmit,
    setIsLoading
}: PartFilterFavoritesProps) => {
    const indexState = useSelector(indexSliceState);
    const favoriteState = useSelector(favoritePropertySliceState);
    const dispatch = useDispatch();
    const [notesChecked, setNotesChecked] = useState(false);
    const [selectedTagTypes, setSelectedTagTypes] = useState<any>([]);
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
        reset
    } = useForm({
        mode: "onChange",
        shouldFocusError: false
    });

    const statesOptions = indexState.states.map((state) => ({
        value: state.id,
        label: state.state,
        abb: state.abbreviation
    }));

    // const hasAssociatedFavoriteType = favoriteState.favoriteStarTypes.some(
    //     (obj: any) => obj === "ASSOCIATED"
    // );

    const getFavoriteProperties = async (
        formData: any,
        reset: boolean = false
    ) => {
        updateStateOnSubmit();
        setIsLoading(true);
        const formQueryData = new Collection({});
        if (!reset) {
            if (formData.state)
                formQueryData.set(
                    "state",
                    getStateAbbById(formData.state, indexState.states)
                );
            if (formData.city) formQueryData.set("city", formData.city);
            if (formData.propertyType)
                formQueryData.set("propertyType", formData.propertyType);
            if (formData.favoriteType) {
                const code = formData.favoriteType.split("-");
                code &&
                    code.length > 0 &&
                    formQueryData.set("starType", code[1]);
            }
            if (notesChecked) formQueryData.set("hasNotes", notesChecked);
            if (selectedTagTypes.length > 0 && !selectedTagTypes.includes(0))
                formQueryData.set("starTypes", selectedTagTypes);
        }
        try {
            const favoriteProperties =
                await FavoritePropertyService.getFavoriteProperties(
                    formQueryData
                );
            dispatch(
                setFavoritesProperties(
                    FavoritePropertyViewMapper.map(favoriteProperties.items)
                )
            );
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const isSearchButtonDisabled = () => {
        const selectedCity = watch("city");
        const selectedstate = watch("state");
        const selectedPropertyType = watch("propertyType");
        const selectedFavoriteType = watch("favoriteType");
        const hasSelectedTagTypes = selectedTagTypes.length > 0;
        if (
            selectedCity ||
            selectedstate ||
            selectedPropertyType ||
            selectedFavoriteType ||
            notesChecked ||
            hasSelectedTagTypes
        ) {
            return false;
        }
        return true;
    };

    const resetFilters = () => {
        reset();
        setSelectedTagTypes([]);
        getFavoriteProperties({}, true);
    };

    return (
        <div className="filters-favorite__container">
            <div className="filters-favorite__wrapper">
                <div className="filters-favorite__filters-wrapper">
                    <PartDropdown
                        name="city"
                        label="City"
                        control={control}
                        errors={errors}
                        isRequired={false}
                        placeholder="Select City"
                        data={favoriteState.favoriteCities.map(
                            (city: string) => {
                                return { label: city, value: city };
                            }
                        )}
                    />

                    <PartDropdown
                        name="state"
                        label="State"
                        control={control}
                        errors={errors}
                        isRequired={false}
                        placeholder="Select State"
                        data={statesOptions.filter((option) =>
                            favoriteState.favoriteStates.includes(option.abb)
                        )}
                    />

                    <PartDropdown
                        name="propertyType"
                        label="Property Type"
                        control={control}
                        errors={errors}
                        isRequired={false}
                        placeholder="Select Property Type"
                        data={indexState.propertyTypes.filter((option) =>
                            favoriteState.favoritePropTypes.includes(
                                option.value.toString()
                            )
                        )}
                    />
                    <PartDropdownTagTypes
                        name="favoriteType"
                        label="Tags"
                        disabled={false}
                        values={selectedTagTypes}
                        setValues={setSelectedTagTypes}
                        options={[
                            {
                                label: "All Tags",
                                value: 0
                            },
                            ...favoriteState.favoriteStarTypes.map(
                                (starType: any) => {
                                    const label =
                                        starType === "ASSOCIATED"
                                            ? "Key Player"
                                            : starType.charAt(0) +
                                              starType
                                                  .substring(1)
                                                  .toLowerCase()
                                                  .replace("_", " ");
                                    return {
                                        label,
                                        value: `${starType}`
                                    };
                                }
                            )
                        ]}
                    />

                    <Checkbox
                        name="notes"
                        onChange={() => {
                            setNotesChecked(!notesChecked);
                        }}
                    >
                        Show only properties with notes
                    </Checkbox>
                </div>
                <div className="favorite_filters__buttons">
                    <span
                        className="favorite_filters__reset"
                        role="button"
                        onClick={resetFilters}
                    >
                        Reset Filters{" "}
                        <FontAwesomeIcon icon={faArrowRotateRight} />
                    </span>
                    <PartButton
                        className="lender__submit-btn"
                        type="primary"
                        disabled={isSearchButtonDisabled()}
                        onClick={() => {
                            handleSubmit((formData) =>
                                getFavoriteProperties(formData)
                            )();
                        }}
                    >
                        <>
                            <FontAwesomeIcon icon={faSearch} /> Search
                        </>
                    </PartButton>
                </div>
            </div>
        </div>
    );
};
export default PartFilterFavorites;
