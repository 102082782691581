import { Checkbox, Select } from "antd";
import { useEffect, useState, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPersonDollyEmpty,
    faChevronDown,
    faClose
} from "@fortawesome/pro-regular-svg-icons";
import { hotjar } from "react-hotjar";

const FILTER_CHECKBOX_LIMIT_COUNT = 15;

const { Option } = Select;

const PartDropdownCheckbox = ({
    parent,
    placeholder,
    defaultValue,
    options,
    name,
    onChange,
    disabled,
    value,
    dropdownClassName,
    showSearch = true,
    getPopupContainer,
    hotjarEventName,
    noContentMessage
}: {
    parent: string;
    placeholder?: string;
    defaultValue?: string;
    options: any;
    name: string;
    value?: any;
    onChange: any;
    disabled?: boolean;
    dropdownClassName?: string;
    showSearch?: boolean;
    getPopupContainer?: any;
    hotjarEventName?: string;
    noContentMessage: string;
}) => {
    const [searchValue, setSearchValue] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [dropdownFocused, setDropdownFocused] = useState<boolean>(false);

    const input = useRef<any>(null);

    // used for manually deselecting checkboxes and when clicking on the 'X' icon to remove specific items
    const handleOnDeselect = (selectedValue: string) => {
        const newValues = value.filter((type: any) => type !== selectedValue);
        onChange(newValues);
    };

    const onSearch = (value: any) => {
        setSearchValue(value);
    };

    const notFoundContent = () => (
        <span className="empty-dropdown">
            <FontAwesomeIcon icon={faPersonDollyEmpty} />
            <span className="empty-dropdown__text">{noContentMessage}</span>
        </span>
    );

    useEffect(() => {
        const inputs: any = document.querySelectorAll(
            ".filters-dropdowns .ant-select-selection-search-input"
        );
        if (inputs) {
            inputs[0].placeholder = "Search Brokerage";
            inputs[1].placeholder = "Search Broker";
        }
    }, []);

    return (
        <>
            <div className="property-types filters-dropdowns">
                <Select
                    ref={input}
                    mode="multiple"
                    placement="bottomRight"
                    placeholder={placeholder}
                    open={dropdownOpen && searchValue.length > 0}
                    value={value}
                    showSearch={showSearch}
                    searchValue={searchValue}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    getPopupContainer={getPopupContainer}
                    onFocus={() => {
                        setSearchValue("");
                        setDropdownFocused(true);
                    }}
                    onChange={(values) => {
                        if (values.length <= FILTER_CHECKBOX_LIMIT_COUNT) {
                            onChange(values);
                        }
                    }}
                    onBlur={(value: any) => {
                        if (value.target.value === "") {
                            setSearchValue("");
                            setDropdownFocused(false);
                        }
                    }}
                    onDropdownVisibleChange={(isOpen: boolean) => {
                        setDropdownOpen(isOpen);
                        if (hotjarEventName)
                            hotjar.event(
                                `${hotjarEventName} - ${
                                    isOpen ? "Open" : "Closed"
                                }`
                            );
                    }}
                    dropdownClassName={`checkbox-dropdown filter-dropdown ${dropdownClassName} ${
                        value.length === FILTER_CHECKBOX_LIMIT_COUNT
                            ? "disabled-unchecked"
                            : ""
                    }`}
                    notFoundContent={notFoundContent()}
                    onSearch={onSearch}
                    onDeselect={handleOnDeselect}
                >
                    {options.map((item: any) => (
                        <Option
                            key={item.value}
                            value={item.label}
                            disabled={
                                value.length === FILTER_CHECKBOX_LIMIT_COUNT &&
                                !value.includes(
                                    name === "listingBrokerage"
                                        ? item.value
                                        : item.label
                                )
                            }
                        >
                            <Checkbox
                                id={item.value}
                                checked={value.includes(
                                    name === "listingBrokerage"
                                        ? item.value
                                        : item.label
                                )}
                            />
                            {item.label}
                        </Option>
                    ))}
                </Select>
                {parent === "filters" ? (
                    <span className="filters-dropdown__icon">
                        <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                ) : null}
            </div>
            <div
                className={`dropdown-tooltip ${
                    dropdownOpen && searchValue.length == 0 && dropdownFocused
                        ? "visible"
                        : ""
                }`}
            >
                Begin typing to search for a{" "}
                {name === "listingBrokerage" ? "brokerage" : "broker"}.
            </div>
            <div className="filters-dropdown__selections">
                {value.map((opt: any, i: number) => (
                    <div className="filters-dropdown__selection" key={i}>
                        <div className="filters-dropdown__selection-inner">
                            <span>{opt}</span>
                            <div
                                className="filters-dropdown__selection-icon"
                                onClick={() => {
                                    handleOnDeselect(opt);
                                }}
                            >
                                <FontAwesomeIcon icon={faClose} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default PartDropdownCheckbox;
