import BubbleFactory from "../map/bubble/BubbleFactory";
import PropertyMarkerFactory from "../map/propertyMarker/PropertyMarkerFactory";
import PropertyListingFactory from "../propertyListing/PropertyListingFactory";
import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import PropertyEntity from "./PropertyEntity";
import SaleHistoryFactory from "../propertySaleHistory/SaleHistoryFactory";
import FinanceHistoryFactory from "../propertyFinanceHistory/FinanceHistoryFactory";
import TotalMarkerCountEntity from "./totalMarkerCount/TotalMarkerCountEntity";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import PropertyType from "./valueObject/PropertyType";
import TypeFloat from "../valueObject/TypeFloat";
import OwnershipFactory from "./ownership/OwnershipFactory";
import TypeBoolean from "../valueObject/TypeBoolean";
import PropertyTaxAssessmentFactory from "./tax/PropertyTaxAssessmentFactory";

// eslint-disable-next-line no-unused-vars
interface PropertyResponseData {
    latitude: number;
    longitude: number;
    entityType: number;
    propertyId: number;
    price: number;
}

export default class PropertyFactory {
    reconstitute(item: Collection) {
        if (item.has("markerType")) {
            return new PropertyMarkerFactory().reconstitute(item);
        } else {
            return item.map((bubbleItemData: any) => {
                return new BubbleFactory().reconstitute(
                    new Collection(bubbleItemData)
                );
            });
        }
    }

    reconstituteProperty(item: Collection) {
        const propertyEntity = new PropertyEntity();
        if (item.has("id"))
            propertyEntity.setId(new TypeString(item.get("id").toString()));
        if (item.has("address"))
            propertyEntity.setAddress(new TypeString(item.get("address")));
        if (item.has("image"))
            propertyEntity.setImage(new TypeString(item.get("image")));
        if (item.has("type"))
            propertyEntity.setType(new TypePositiveInteger(item.get("type")));
        if (item.has("listing"))
            propertyEntity.setListing(
                new PropertyListingFactory().reconstitute(
                    new Collection(item.get("listing"))
                )
            );
        if (item.has("financeHistory"))
            propertyEntity.setFinanceHistory(
                new FinanceHistoryFactory().reconstitute(
                    new Collection(item.get("financeHistory"))
                )
            );
        if (item.has("saleHistory"))
            propertyEntity.setSaleHistory(
                new SaleHistoryFactory().reconstitute(
                    new Collection(item.get("saleHistory"))
                )
            );
        if (item.has("owner"))
            propertyEntity.setOwnership(
                new OwnershipFactory().reconstitute(
                    new Collection(item.get("owner"))
                )
            );
        if (item.has("state"))
            propertyEntity.setStateAbbreviation(
                new TypeString(item.get("state"))
            );
        if (item.has("propertyType"))
            propertyEntity.setPropertyType(
                new PropertyType(item.get("propertyType"))
            );
        if (item.has("sqFt"))
            propertyEntity.setSqFt(new TypeFloat(item.get("sqFt")));

        if (item.has("latitude"))
            propertyEntity.setLatitude(new TypeFloat(item.get("latitude")));

        if (item.has("longitude"))
            propertyEntity.setLongitude(new TypeFloat(item.get("longitude")));

        if (item.has("city"))
            propertyEntity.setCity(new TypeString(item.get("city")));
        if (item.has("county"))
            propertyEntity.setCounty(new TypeString(item.get("county")));
        if (item.has("zip"))
            propertyEntity.setZip(new TypeString(item.get("zip")));
        if (item.has("fips"))
            propertyEntity.setFIPS(new TypePositiveInteger(item.get("fips")));
        if (item.has("parcel")) propertyEntity.setParcel(item.get("parcel"));
        if (item.has("zoning"))
            propertyEntity.setZoning(new TypeString(item.get("zoning")));
        if (item.has("lotSqft"))
            propertyEntity.setLotSize(new TypeFloat(item.get("lotSqft")));
        if (item.has("landUse"))
            propertyEntity.setLandUse(new TypeString(item.get("landUse")));
        if (item.has("subClass"))
            propertyEntity.setSubClass(new TypeString(item.get("subClass")));
        if (item.has("units"))
            propertyEntity.setUnits(new TypePositiveInteger(item.get("units")));
        if (item.has("yearBuilt"))
            propertyEntity.setYearBuilt(
                new TypePositiveInteger(item.get("yearBuilt"))
            );
        if (item.has("stories"))
            propertyEntity.setStories(new TypeFloat(item.get("stories")));
        if (item.has("numBuildings"))
            propertyEntity.setBuildingCount(
                new TypePositiveInteger(item.get("numBuildings"))
            );
        if (item.has("ownerOccupied"))
            propertyEntity.setOwnerOccupied(
                new TypeBoolean(item.get("ownerOccupied"))
            );

        if (item.has("taxAssessment")) {
            propertyEntity.setTaxAssessment(
                new PropertyTaxAssessmentFactory().reconstitute(
                    new Collection(item.get("taxAssessment"))
                )
            );
        }

        if (item.has("isFavorite"))
            propertyEntity.setIsFavorite(
                new TypeBoolean(item.get("isFavorite"))
            );

        return propertyEntity;
    }

    reconstituteTotalCount(item: Collection) {
        const totalsInstance = new TotalMarkerCountEntity();

        if (item.has("listingsTotal"))
            totalsInstance.setListingsTotalCount(
                new TypePositiveInteger(item.get("listingsTotal"))
            );
        if (item.has("salesTotal"))
            totalsInstance.setSalesComparablesTotalCount(
                new TypePositiveInteger(item.get("salesTotal"))
            );
        if (item.has("refinanceTotal"))
            totalsInstance.setFinanceComparablesTotalCount(
                new TypePositiveInteger(item.get("refinanceTotal"))
            );

        return totalsInstance;
    }
}
