import VendorEntity from "../representer/vendor/VendorEntity";
import PropertyVendorEntity from "./PropertyVendorEntity";

export default class PropertyVendorViewMapper {
    static map(propertyVendors: any) {
        const NO_INFO = "-";
        return propertyVendors.map((propertyVendor: PropertyVendorEntity) => {
            return {
                vendorRole: propertyVendor.hasRoleName()
                    ? propertyVendor.roleName.value
                    : NO_INFO,
                vendorContacts: propertyVendor.hasVendorContacts()
                    ? PropertyVendorViewMapper.mapVendorContacts(
                          propertyVendor.vendorContacts
                      )
                    : []
            };
        }).items;
    }

    static mapVendorContacts(vendorContacts: any) {
        const NO_INFO = "-";
        return vendorContacts.map((vendorContact: VendorEntity) => {
            return {
                id: vendorContact.hasUserId()
                    ? vendorContact.userId.value
                    : NO_INFO,
                firstName: vendorContact.hasFirstName()
                    ? vendorContact.firstName.value
                    : NO_INFO,
                lastName: vendorContact.hasLastName()
                    ? vendorContact.lastName.value
                    : NO_INFO,
                email: vendorContact.hasEmail()
                    ? vendorContact.email.value
                    : NO_INFO,
                phone: vendorContact.hasPhone()
                    ? vendorContact.phone.value
                    : NO_INFO
            };
        }).items;
    }
}
