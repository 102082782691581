export const favoriteOptions = [
    { label: "I own as a GP", value: "GP" },
    {
        label: "I’m interested in investing as an LP in a passive role",
        value: "INVESTING"
    },
    { label: "Tool Used (Calc, Notes, etc.)", value: "USED_TOOLS" },
    { label: "I own as an LP", value: "LP" },
    {
        label: "I’m interested in acquiring as a GP or managing member",
        value: "ACQUIRING"
    },
    { label: "Viewed Only", value: "VIEWED" },
    { label: "I previously owned", value: "PREVIOUS" },
    { label: "Other", value: "OTHER" }
];
