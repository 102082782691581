import { faCalendarRange, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import PartButton from "../parts/PartButton";
import PartDropdown from "../parts/PartDropdown";
import PartInputRangeNumber from "../parts/PartInputNumber";
import PartTextAreaInput from "../parts/PartTextAreaInput";
import { useEffect, useState } from "react";
import LPInvestmentService from "../../domain/lpInvestment/LPInvestmentService";
import TypeString from "../../domain/valueObject/TypeString";
import { useSelector } from "react-redux";
import indexSliceState from "../../store";
import Collection from "../../domain/valueObject/Collection";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { addTransactionSchema } from "../../utils/formValidationSchemas";
import { DatePicker, Space } from "antd";

const BlockAddTransactionPopup = ({
    transactionPopupOpen,
    setTransactionPopupOpen,
    getTransactions
}: any) => {
    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        setValue
    } = useForm({
        mode: "onChange",
        shouldFocusError: false,
        resolver: yupResolver(addTransactionSchema)
    });

    const indexSlice = useSelector(indexSliceState);

    const [amount, setAmount] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [popupLoading, setPopupLoading] = useState(false);
    const [popupDate, setPopupDate] = useState<any>(moment());

    useEffect(() => {
        // Prefill popup with data if in edit mode
        if (transactionPopupOpen && transactionPopupOpen.transactionType) {
            const d = new Date(transactionPopupOpen.date);
            const date = moment(d).format("MM/DD/YYYY");
            setIsEditMode(true);
            setValue("transactionType", transactionPopupOpen.transactionType);
            setPopupDate(moment(date));
            setAmount(transactionPopupOpen.amount.toString());
            setValue("note", transactionPopupOpen.note);
        } else {
            setPopupDate(moment());
        }
    }, [transactionPopupOpen]);

    const onSubmit = async (data: any) => {
        if (!amount) return;
        // convert string with commas to number
        const amountNumber = Number(amount.replace(/,/g, ""));
        const transactionDateTimestamp = moment(popupDate).format("YYYY-MM-DD");

        try {
            setPopupLoading(true);
            if (indexSlice.showLpInvestmentPopup.propertyId) {
                setButtonDisabled(true);
                const requestPayload = new Collection({
                    transactionType: data.transactionType,
                    amount: amountNumber,
                    transactionDate: transactionDateTimestamp,
                    note: data.note
                });
                if (isEditMode) {
                    await LPInvestmentService.updateTransaction(
                        new TypeString(transactionPopupOpen.sortKey),
                        new Collection({
                            propertyID:
                                indexSlice.showLpInvestmentPopup.propertyId,
                            ...requestPayload.items
                        })
                    );
                } else {
                    await LPInvestmentService.addTransaction(
                        new TypeString(
                            indexSlice.showLpInvestmentPopup.propertyId
                        ),
                        requestPayload
                    );
                }

                getTransactions();
                setTransactionPopupOpen(false);
            }
        } catch (error) {
            console.log(error, "Error adding transaction");
            setPopupLoading(false);
        } finally {
            setButtonDisabled(false);
            setPopupLoading(false);
        }
    };

    const transactionType = watch("transactionType");

    return (
        <>
            <div
                className="overlay transaction-popup-overlay"
                onClick={() => setTransactionPopupOpen(false)}
            ></div>
            {popupLoading ? (
                <div className="transaction-popup__loader-overlay loading">
                    <span className="loader"></span>
                </div>
            ) : (
                <div className="popup transaction-popup">
                    <h3 className="transaction-popup__title">
                        {isEditMode ? "Edit" : "Add"} Transaction
                    </h3>
                    <span
                        className="popup__close"
                        onClick={() => setTransactionPopupOpen(false)}
                    >
                        <FontAwesomeIcon icon={faXmark} />
                    </span>
                    <div className="popup-scrollable">
                        <div className="transaction-popup__inputs-wrapper">
                            <PartDropdown
                                name="transactionType"
                                control={control}
                                errors={errors}
                                placeholder="Choose one option"
                                label="Transaction Type"
                                defaultValue={transactionPopupOpen.transaction}
                                // onChange={(value: any) => {}}
                                // formState={formState}
                                data={[
                                    {
                                        label: "Invested",
                                        value: "INVESTMENT"
                                    },
                                    {
                                        label: "Distribution Received",
                                        value: "DISTRIBUTION"
                                    }
                                ]}
                            />
                            <Space direction="vertical">
                                <label className="datepicker-label">Date</label>
                                <DatePicker
                                    className="datepicker transaction-popup__datepicker"
                                    format="MM/DD/YYYY"
                                    allowClear={false}
                                    onChange={(e) => {
                                        setPopupDate(e);
                                    }}
                                    value={popupDate}
                                    defaultValue={moment()}
                                    suffixIcon={
                                        <FontAwesomeIcon
                                            icon={faCalendarRange}
                                        />
                                    }
                                />
                            </Space>
                        </div>
                        <div className="transaction-popup__inputs-wrapper add-transaction-amount">
                            <PartInputRangeNumber
                                label="Amount"
                                name="amount"
                                errors={errors}
                                disabled={transactionType === undefined}
                                onChange={function (value: any): void {
                                    console.log(value);
                                    setAmount(value);
                                }}
                                value={amount}
                            />
                        </div>

                        <div className="transaction-popup__inputs-wrapper">
                            <PartTextAreaInput
                                label="Notes"
                                name="note"
                                errors={errors}
                                control={control}
                                rows={5}
                                placeholder="Enter notes here"
                                maxLength={2000}
                            />
                        </div>
                    </div>
                    <div className="popup-favorites__footer">
                        <PartButton
                            type="primary"
                            disabled={buttonDisabled}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Save
                        </PartButton>
                    </div>
                </div>
            )}
        </>
    );
};

export default BlockAddTransactionPopup;
