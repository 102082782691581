const intlFormat = (num: number) => {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
};

export function displayNum(price: number) {
    let modifier = "";
    if (price >= 1.0e9) {
        price = price / 1.0e9;
        modifier = "B";
    } else if (price >= 1.0e6) {
        price = price / 1.0e6;
        modifier = "M";
    } else if (price >= 1.0e3) {
        price = price / 1.0e3;
        modifier = "K";
    }
    let numDecimals = 3 - Math.floor(price).toString().length;
    numDecimals = numDecimals < 0 ? 0 : numDecimals;
    let x = price.toFixed(numDecimals);
    if (x === "1000") {
        x = "1";
        if (modifier === "B") {
            modifier = "T";
        } else if (modifier === "M") {
            modifier = "B";
        } else if (modifier === "K") {
            modifier = "M";
        }
    }
    return formatCommaNums(+x) + modifier;
}

export const formatRangeNums = (num: number) => {
    if (num >= 1000000) {
        const res = num.toLocaleString().split(",")[0];
        return res + "M";
    }
    if (num >= 1000) return intlFormat(num / 1000) + "K";
    return intlFormat(num);
};

export const formatCommaNums = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const featureLayerSupported = (featureLayer: string) => {
    if (featureLayer === undefined) return false;

    const SUPPORTED_FEATURE_LAYERS = [
        "LOCALITY",
        "ADMINISTRATIVE_AREA_LEVEL_1",
        "ADMINISTRATIVE_AREA_LEVEL_2 ",
        "POSTAL_CODE"
    ];
    return SUPPORTED_FEATURE_LAYERS.some((layer) =>
        featureLayer.includes(layer)
    );
};

export function parseToNumber(value: string | number) {
    if (value === "0" || value === 0) return 0;
    if (!value) return undefined;
    const onlyNumbers = (value + "").replace(/[^0-9.+-]/g, "");
    return parseFloat(onlyNumbers);
}
