import TypeString from "../valueObject/TypeString";

export default class BrokerageEntity {
    _name: TypeString;

    get name(): TypeString {
        return this._name;
    }

    setName(value: TypeString) {
        this._name = value;
        return this;
    }

    hasName(): boolean {
        return this._name instanceof TypeString;
    }
}
