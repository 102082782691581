import BaseValueObject from "./BaseValueObject";

export default class TypeBoolean extends BaseValueObject {
    /**
     * @param {boolean} value
     */
    constructor(value: any) {
        super(value);
        this._value = this._sanitizeValue(value);
    }

    copy() {
        return new TypeBoolean(this._value);
    }

    // IMPORTANT:
    // IF YOU OVERRIDE ONE MAGIC METHOD OF A PROPERTY, YOU MUST OVERRIDE THE SECOND ONE AS WELL
    // OVERRIDING JUST THE GETTER OR JUST THE SETTER OF A SPECIFIC PARAMETER WILL RENDER THE OTHER METHOD USELESS

    set value(value) {
        this._value = this._sanitizeValue(value);
    }

    get value() {
        return this._value;
    }

    toString() {
        return this._value ? "Yes" : "No";
    }

    toInt() {
        return this._value ? 1 : 0;
    }

    _sanitizeValue(value: any) {
        switch (true) {
            case typeof value === "boolean":
                return value;
            case value == 1:
            case value === "true":
                return true;
            case value == 0:
            case value === "false":
                return false;
            default:
                throw new TypeError(`Could not create a boolean from ${value}`);
        }
    }
}
