import React from "react";

export default function BlockCookiePopup() {
    return (
        <div id="cookie__bckgrnd">
            <div id="cookie__mdl">
                <img id="cookie__img" src="./cookie.jpg" />
                <h1 id="cookie__hdr">Allow Cookies</h1>
                <div id="cookie__wrpr">
                    <div id="cookie__prgrph">
                        <p>
                            This website uses cookies. Please{" "}
                            <b className="cookies__brwn-txt">
                                allow cookies and refresh the page
                            </b>{" "}
                            or re-open this website in a{" "}
                            <b className="cookies__brwn-txt">
                                cookie-enabled browser
                            </b>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
