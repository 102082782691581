import Collection from "../../valueObject/Collection";
import IFrameMessage from "../IFrameMessage";

export default class NewCalculatorCreated extends IFrameMessage {
    public static ACTION_NAME = "new-calculator-created";

    constructor() {
        super(NewCalculatorCreated.ACTION_NAME);
    }

    public send(data: Collection | undefined, iframe?: HTMLIFrameElement) {
        super._send(new Collection({}), iframe);
    }

    public recieve(callback: Function) {
        callback();
    }
}
