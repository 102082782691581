import BaseModel from "../../BaseModel";
import Collection from "../../valueObject/Collection";
import TypeBoolean from "../../valueObject/TypeBoolean";
import TypePositiveInteger from "../../valueObject/TypePositiveInteger";

export default class LenderFilterQuery extends BaseModel {
    _state: TypePositiveInteger;
    _propertyType: TypePositiveInteger;
    _loanType: TypePositiveInteger;
    _loanAmount: TypePositiveInteger | undefined;
    _executionTypes: Collection = new Collection([]);
    _isPublicLender: TypeBoolean;
    _items: Collection = new Collection([]);
    _total: TypePositiveInteger = new TypePositiveInteger(0);
    _totalInFilter: TypePositiveInteger = new TypePositiveInteger(0);

    get state() {
        return this._state;
    }
    setState(value: TypePositiveInteger) {
        this._state = value;
        return this;
    }

    hasState(): boolean {
        return this._state instanceof TypePositiveInteger;
    }

    get propertyType() {
        return this._propertyType;
    }
    setPropertyType(value: TypePositiveInteger) {
        this._propertyType = value;
        return this;
    }

    hasPropertyType(): boolean {
        return this._propertyType instanceof TypePositiveInteger;
    }

    get loanType() {
        return this._loanType;
    }
    setLoanType(value: TypePositiveInteger) {
        this._loanType = value;
        return this;
    }

    hasLoanType(): boolean {
        return this._loanType instanceof TypePositiveInteger;
    }

    get loanAmount() {
        return this._loanAmount;
    }
    setLoanAmount(value: TypePositiveInteger | undefined) {
        this._loanAmount = value;
        return this;
    }

    hasLoanAmount(): boolean {
        return this._loanAmount instanceof TypePositiveInteger;
    }

    get executionTypes(): Collection {
        return this._executionTypes;
    }

    setExecutionTypes(executionTypes: Collection) {
        this._executionTypes = executionTypes;
        return this;
    }

    hasExecutionTypes(): boolean {
        return !this._executionTypes.isEmpty();
    }

    get publicLenders() {
        return this._isPublicLender;
    }

    setPublicLenders(value: TypeBoolean) {
        this._isPublicLender = value;
        return this;
    }

    hasPublicLenders(): boolean {
        return this._isPublicLender instanceof TypeBoolean;
    }

    get items(): Collection {
        return this._items;
    }

    setItems(items: Collection) {
        // if (!this._items?.isSameAs(items)) {
        this._items = items;
        this._executeAfterChangeTriggers();
        // }
        return this;
    }

    hasItems(): boolean {
        return !this._items.isEmpty();
    }

    get total() {
        return this._total;
    }

    setTotal(value: TypePositiveInteger) {
        this._total = value;
        return this;
    }

    get totalInFilter() {
        return this._totalInFilter;
    }

    setTotalInFilter(value: TypePositiveInteger) {
        this._totalInFilter = value;
        return this;
    }
}
