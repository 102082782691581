import Collection from "../valueObject/Collection";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import ListingBrokerEntity from "./ListingBrokerEntity";

export default class ListingBrokerFactory {
    reconstitute(item: Collection) {
        const instance = new ListingBrokerEntity();
        if (item.has("id"))
            instance.setId(new TypePositiveInteger(item.get("id")));
        if (item.has("name"))
            instance.setName(new TypeString(item.get("name")));

        return instance;
    }
}
