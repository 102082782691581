import Collection from "../valueObject/Collection";
import TypeBoolean from "../valueObject/TypeBoolean";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";

export default class LenderEntity {
    private _id: TypePositiveInteger;
    private _name: TypeString;
    private _isPaid: TypeBoolean;
    private _rank: TypePositiveInteger;
    private _executionTypes: Collection = new Collection([]);
    private _states: Collection = new Collection([]);
    private _propertyTypes: Collection = new Collection([]);
    private _loanTypes: Collection = new Collection([]);
    private _originators: Collection = new Collection([]);
    private _otherOriginators: Collection = new Collection([]);
    private _isPublicLender: TypeBoolean;

    get id() {
        return this._id;
    }

    setId(value: TypePositiveInteger) {
        this._id = value;
        return this;
    }

    get name() {
        return this._name;
    }

    setName(value: TypeString) {
        this._name = value;
        return this;
    }

    get isPaid() {
        return this._isPaid;
    }

    setIsPaid(value: TypeBoolean) {
        this._isPaid = value;
        return this;
    }

    hasIsPaid() {
        return this._isPaid instanceof TypeBoolean;
    }

    get rank() {
        return this._rank;
    }

    setRank(value: TypePositiveInteger) {
        this._rank = value;
        return this;
    }

    hasRank(): boolean {
        return this._rank instanceof TypePositiveInteger;
    }

    get executionTypes() {
        return this._executionTypes;
    }

    setExecutionTypes(value: Collection) {
        this._executionTypes = value;
        return this;
    }

    get states() {
        return this._states;
    }

    setStates(value: Collection) {
        this._states = value;
        return this;
    }

    get propertyTypes() {
        return this._propertyTypes;
    }

    setPropertyTypes(value: Collection) {
        this._propertyTypes = value;
        return this;
    }

    get originators() {
        return this._originators;
    }

    setOriginators(value: Collection) {
        this._originators = value;
        return this;
    }

    get otherOriginators() {
        return this._otherOriginators;
    }

    setOtherOriginators(value: Collection) {
        this._otherOriginators = value;
        return this;
    }

    hasOtherOriginators() {
        return this._otherOriginators instanceof Collection;
    }

    get loanTypes() {
        return this._loanTypes;
    }

    setLoanTypes(value: Collection) {
        this._loanTypes = value;
        return this;
    }

    hasLoanTypes() {
        return this._loanTypes instanceof Collection;
    }

    get isPublicLender() {
        return this._isPublicLender;
    }

    setIsPublicLender(value: TypeBoolean) {
        this._isPublicLender = value;
        return this;
    }

    hasIsPublicLender() {
        return this._isPublicLender instanceof TypeBoolean;
    }
}
