const PartFavoritePopupSkeleton = (length: any) => {
    return (
        <div className="popup-favorites__skeleton">
            <div className="popup-favorites__skeleton-row">
                {Array.from({ length: length.length }).map((item, index) => (
                    <span
                        className="popup-favorites__skeleton-item"
                        key={index}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default PartFavoritePopupSkeleton;
