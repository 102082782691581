import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEarthAmericas,
    faUserTag,
    faXmarkCircle
} from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import StatsEntity from "../../../domain/stats/StatsEntity";
import PropertyService from "../../../domain/property/PropertyService";
import PropertySearchQuery from "../../../domain/property/queries/PropertySearchQuery";

import { useDispatch, useSelector } from "react-redux";
import lenderPortfolioSliceState, {
    setFirstLenderValue,
    setLenderPortfolioActive,
    setSecondLenderValue,
    setThirdLenderValue
} from "../../../store/lenderPortfolio";
import LenderPortfolioViewMapper from "../../../domain/lenderPortfolio/LenderPortfolioViewMapper";
import Collection from "../../../domain/valueObject/Collection";
import { hotjar } from "react-hotjar";

const BlockLegend = () => {
    const lenderPortfolioState = useSelector(lenderPortfolioSliceState);
    const [stats, setStats] = useState<StatsEntity | undefined>(undefined);
    const [lenderPortfolioStats, setLenderPortfolioStats] = useState<any>({
        firstLenderSelection: 0,
        secondLenderSelection: 0,
        thirdLenderSelection: 0
    });

    const dispatch = useDispatch();

    const query: PropertySearchQuery = PropertyService.getSearchQuery();

    useEffect(() => {
        query.afterChange("updating_stats", () => {
            if (query.hasLenderPortfolioItems()) {
                setLenderPortfolioStats(
                    LenderPortfolioViewMapper.mapStats(
                        query.lenderPortfolioStats
                    )
                );
            } else {
                setStats(query.stats);
            }
        });
    }, [query, query.lenderPortfolioStats, query.stats]);

    return (
        <div className="legend">
            {query.hasLenderPortfolioItems() && (
                <span
                    className="legend__remove"
                    onClick={() => {
                        const selectedLenders = [
                            lenderPortfolioState.firstLenderValue,
                            lenderPortfolioState.secondLenderValue,
                            lenderPortfolioState.thirdLenderValue
                        ].filter(Boolean);

                        hotjar.event(
                            `Lender Overlays - Remove Lender Selection - ${selectedLenders.join(
                                " - "
                            )}`
                        );

                        dispatch(setFirstLenderValue(""));
                        dispatch(setSecondLenderValue(""));
                        dispatch(setThirdLenderValue(""));

                        query.assignApplyTrigger("setLenderSearchState", () => {
                            dispatch(
                                setLenderPortfolioActive(
                                    !query.hasLenderPortfolioItems()
                                )
                            );
                        });

                        query
                            .setLenderPortfolioStats(new Collection({}))
                            .clearLenderPortfolioItems()
                            .apply();
                    }}
                >
                    <FontAwesomeIcon icon={faXmarkCircle} /> Remove Lender
                    Overlays
                </span>
            )}
            <span className="legend__title">
                <FontAwesomeIcon icon={faEarthAmericas} />
                <span>Map Key</span>
            </span>
            <div className="legend__item-left">
                {!query.hasLenderPortfolioItems() ? (
                    <>
                        <div className="legend__item">
                            <div className="legend__item-first">
                                <p className="legend__item-bubble">
                                    <FontAwesomeIcon icon={faUserTag} />
                                </p>
                            </div>
                            <p className="legend__item-value">
                                {stats?.listingsCurrentCount?.value
                                    ? stats?.listingsCurrentCount?.value.toLocaleString()
                                    : 0}
                            </p>
                            <span className="legend__item-tooltip">
                                Listings
                            </span>
                        </div>
                        <div className="legend__item">
                            <div className="legend__item-first">
                                <p className="legend__item-cicle legend__item-cicle--green"></p>
                            </div>
                            <p className="legend__item-value">
                                {stats?.salesComparablesCurrentCount?.value
                                    ? stats?.salesComparablesCurrentCount?.value.toLocaleString()
                                    : 0}
                            </p>
                            <span className="legend__item-tooltip">
                                Sale Comparables
                            </span>
                        </div>
                        <div className="legend__item">
                            <div className="legend__item-first">
                                <p className="legend__item-cicle legend__item-cicle--blue"></p>
                            </div>
                            <p className="legend__item-value">
                                {stats?.financeComparablesCurrentCount?.value
                                    ? stats?.financeComparablesCurrentCount?.value.toLocaleString()
                                    : 0}
                            </p>
                            <span className="legend__item-tooltip">
                                Finance Comparables
                            </span>
                        </div>

                        <div className="legend__item">
                            <div className="legend__item-first">
                                <p className="legend__item-cicle legend__item-cicle--grey"></p>
                            </div>
                            <span className="legend__item-tooltip">
                                Commercial Properties With Limited Public Data
                            </span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="legend__item">
                            <div className="legend__item-first">
                                <p className="legend__item-cicle legend__item-cicle--navy"></p>
                            </div>
                            <p className="legend__item-value">
                                {lenderPortfolioStats.firstLenderSelection}
                            </p>
                        </div>
                        <div className="legend__item">
                            <div className="legend__item-first">
                                <p className="legend__item-cicle legend__item-cicle--red"></p>
                            </div>
                            <p className="legend__item-value">
                                {lenderPortfolioStats.secondLenderSelection}
                            </p>
                        </div>
                        <div className="legend__item">
                            <div className="legend__item-first">
                                <p className="legend__item-cicle legend__item-cicle--yellow"></p>
                            </div>
                            <p className="legend__item-value">
                                {lenderPortfolioStats.thirdLenderSelection}
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default BlockLegend;
