import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, {
    setCalculatorIframe,
    setCalculatorSlideout,
    setSearchMapFromCalc,
    setShareCalculatorPopup
} from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkLarge, faCalculator } from "@fortawesome/pro-regular-svg-icons";
import calculatorOverlayImg from "../../assets/images/calculator-img.png";
import IFrameMessageFactory from "../../domain/iFrameMessage/IFrameMessageFactory";
import OpenPopupMessage from "../../domain/iFrameMessage/calculator/OpenPopupMessage";
import { hotjar } from "react-hotjar";
import NewCalculatorCreated from "../../domain/iFrameMessage/calculator/NewCalculatorCreatedMessage";
import {
    setPropertyCardMarkAsFavorite,
    setPropertyFavoriteActionTriggered
} from "../../store/mapTriggers";
import PropertySearchQuery from "../../domain/property/queries/PropertySearchQuery";
import PropertyService from "../../domain/property/PropertyService";
import SendMeATokenMessage from "../../domain/iFrameMessage/calculator/SendMeATokenMessage";
import { useAuth0 } from "@auth0/auth0-react";
import HereIsATokenMessage from "../../domain/iFrameMessage/calculator/HereIsATokenMessage";
import ShareCalculatorMessage from "../../domain/iFrameMessage/calculator/ShareCalculatorMessage";
import Collection from "../../domain/valueObject/Collection";
import ClosePopupsMessage from "../../domain/iFrameMessage/calculator/ClosePopupsMessage";
import TypeBoolean from "../../domain/valueObject/TypeBoolean";
import { Tooltip } from "antd";

const BlockCalculatorSlideout = () => {
    const sliceState = useSelector(indexSliceState);
    const dispatch = useDispatch();
    const [slideOutWidth, setSlideOutWidth] = React.useState(false);
    const [allowAccess, setAllowAccess] = React.useState(true); //Allow access to the aCalc
    const calculatorIframeRef = React.useRef<any>(null);
    const { getAccessTokenSilently } = useAuth0();

    const query: PropertySearchQuery = PropertyService.getSearchQuery();

    useEffect(() => {
        dispatch(setCalculatorIframe(calculatorIframeRef.current));
        window.addEventListener("message", (event) => {
            setTimeout(() => {
                const message = IFrameMessageFactory.reconstitute(event.data);
                switch (true) {
                    case message instanceof OpenPopupMessage:
                        message!.recieve(() => {
                            setSlideOutWidth(event.data.width);
                            // TODO extend sidebar div to width recieved from event.data.width
                        });
                        break;
                    case message instanceof NewCalculatorCreated:
                        message!.recieve(() => {
                            dispatch(setPropertyCardMarkAsFavorite(true));
                            setTimeout(() => {
                                dispatch(
                                    setPropertyFavoriteActionTriggered(true)
                                );
                                query.apply();
                            }, 2000);
                        });
                        break;
                    case message instanceof SendMeATokenMessage:
                        message!.recieve(async () => {
                            const token = await getAccessTokenSilently();
                            console.log(
                                "Here is the token from the markeplace:",
                                token
                            );
                            new HereIsATokenMessage().send(
                                sliceState.calculatorIframe,
                                token
                            );
                        });
                        break;
                    case message instanceof ShareCalculatorMessage:
                        message!.recieve(
                            (shareId: string, propertyId: string) => {
                                // Opening the share calculator popup with the hash sent from the iframe
                                dispatch(
                                    setShareCalculatorPopup({
                                        isOpen: true,
                                        hash: shareId,
                                        propertyId: propertyId
                                    })
                                );
                            },
                            new Collection(event.data)
                        );
                }
            }, 1000);
        });
    }, [sliceState.calculatorIframe]);

    useEffect(() => {
        const viewportWidth = window.innerWidth;
        const hjFeedback: any = document.querySelector(
            "._hj_feedback_container"
        );
        if (hjFeedback && viewportWidth < 768)
            hjFeedback.style.display = sliceState.calculatorSlideout.isOpen
                ? "none"
                : "block";
        if (sliceState.calculatorSlideout.address || sliceState.isPaidUser) {
            setAllowAccess(true);
        }
    }, [sliceState.calculatorSlideout]);

    return (
        <div
            className={
                sliceState.calculatorSlideout.isOpen
                    ? "calculator-slideout calculator-slideout--open"
                    : "calculator-slideout"
            }
            style={{ maxWidth: slideOutWidth ? `${slideOutWidth}px` : "" }}
        >
            <div className="calculator-slideout__header">
                <div className="calculator-slideout__header__title">
                    <FontAwesomeIcon icon={faCalculator}></FontAwesomeIcon>
                    <span className="calculator-slideout__header__title-text">
                        Underwriting Calculator
                    </span>
                </div>
                <div
                    onClick={() => {
                        hotjar.event(`Underwriting Calculator - Collapse`);
                        new ClosePopupsMessage().send(
                            sliceState.calculatorIframe,
                            new TypeBoolean(true)
                        );
                        dispatch(
                            setCalculatorSlideout({
                                isOpen: false,
                                id: null,
                                address: ""
                            })
                        );
                    }}
                >
                    {/* <span
                        style={{
                            color: "#22aa94",
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontSize: "14px"
                        }}
                    >
                        Close the Calc{" "}
                    </span> */}
                    <Tooltip placement="left" title="Close the Calc">
                        <FontAwesomeIcon
                            icon={faXmarkLarge}
                            style={{
                                height: "13px",
                                transform: "rotateY(180deg)",
                                cursor: "pointer"
                            }}
                        ></FontAwesomeIcon>
                    </Tooltip>
                </div>
            </div>
            <div className="calculator-slideout__iframe">
                <iframe
                    ref={calculatorIframeRef}
                    className={`calculator-slideout__iframe-element ${
                        !allowAccess ? "hide" : ""
                    }`}
                    src={process.env.REACT_APP_ACQUISITION_CALCUATOR_IFRAME_URL}
                    width={"100%"}
                    height={"100%"}
                    allow="clipboard-write"
                />
            </div>

            <div
                className={
                    !allowAccess
                        ? "calculator-slideout__overlay-wrapper "
                        : "calculator-slideout__overlay-wrapper hide"
                }
            >
                <img src={calculatorOverlayImg} alt="" />
                <h3 className="calculator-slideout__overlay-title">
                    Underwriting Calculator
                </h3>
                <p className="calculator-slideout__overlay-text">
                    You must begin calculations from a specific property. <br />
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            hotjar.event(
                                `Underwriting Calculator - Collapse from 'search the map' link`
                            );
                            dispatch(
                                setCalculatorSlideout({
                                    isOpen: false,
                                    id: null,
                                    address: ""
                                })
                            );
                            dispatch(setSearchMapFromCalc(true));
                        }}
                    >
                        Search the map
                    </span>{" "}
                    to find a property and click the{" "}
                    <FontAwesomeIcon icon={faCalculator}></FontAwesomeIcon>{" "}
                    ‘Calculator’ icon to begin.
                </p>
                {/* <div className="calculator-slideout__overlay-divider">
                    <span>OR</span>
                </div>
                <Button
                    className="btn calculator-slideout__overlay-btn"
                    onClick={() => {
                        hotjar.event(
                            `Acquisition Calculator - Use Generic Calculator Button On Initial Menu`
                        );
                        new PropertyIdMessage().send(
                            sliceState.calculatorIframe,
                            undefined
                        );
                        dispatch(
                            setCalculatorSlideout({
                                isOpen: true,
                                id: null,
                                overlay: false,
                                address: ""
                            })
                        );
                    }}
                >
                    Use Generic Calculator
                </Button> */}
            </div>
        </div>
    );
};

export default BlockCalculatorSlideout;
