import BaseModel from "../BaseModel";
import Collection from "../valueObject/Collection";
import TypeBoolean from "../valueObject/TypeBoolean";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";

export default class PropertyFilterEntity extends BaseModel {
    _listingsChecked: TypeBoolean | undefined;
    _salesComparablesChecked: TypeBoolean | undefined;
    _financeComparablesChecked: TypeBoolean | undefined;
    _priceMin: TypePositiveInteger | undefined;
    _priceMax: TypePositiveInteger | undefined;
    _dateRangeListings: TypePositiveInteger | undefined;
    _dateRangeSalesComparables: TypePositiveInteger | undefined;
    _dateRangeFinanceComparables: TypePositiveInteger | undefined;
    _listingBrokerage: Collection = new Collection([]);
    _listingBroker: Collection = new Collection([]);
    _propertyType: Collection = new Collection([]);

    get listingsChecked(): TypeBoolean | undefined {
        return this._listingsChecked;
    }

    setListingsChecked(listingsChecked: TypeBoolean | undefined) {
        if (!this._listingsChecked?.sameAs(listingsChecked)) {
            this._listingsChecked = listingsChecked;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasListingsChecked(): boolean {
        return this._listingsChecked instanceof TypeBoolean;
    }

    get salesComparablesChecked(): TypeBoolean | undefined {
        return this._salesComparablesChecked;
    }

    setSalesComparablesChecked(
        salesComparablesChecked: TypeBoolean | undefined
    ) {
        if (!this._salesComparablesChecked?.sameAs(salesComparablesChecked)) {
            this._salesComparablesChecked = salesComparablesChecked;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasSalesComparablesChecked(): boolean {
        return this._salesComparablesChecked instanceof TypeBoolean;
    }

    get financeComparablesChecked(): TypeBoolean | undefined {
        return this._financeComparablesChecked;
    }

    setFinanceComparablesChecked(
        financeComparablesChecked: TypeBoolean | undefined
    ) {
        if (
            !this._financeComparablesChecked?.sameAs(financeComparablesChecked)
        ) {
            this._financeComparablesChecked = financeComparablesChecked;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasFinanceComparablesChecked(): boolean {
        return this._financeComparablesChecked instanceof TypeBoolean;
    }

    get priceMin(): TypePositiveInteger | undefined {
        return this._priceMin;
    }

    setPriceMin(priceMin: TypePositiveInteger | undefined) {
        if (!this._priceMin?.sameAs(priceMin)) {
            this._priceMin = priceMin;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasPriceMin(): boolean {
        return this._priceMin instanceof TypePositiveInteger;
    }

    get priceMax(): TypePositiveInteger | undefined {
        return this._priceMax;
    }

    setPriceMax(priceMax: TypePositiveInteger | undefined) {
        if (!this._priceMax?.sameAs(priceMax)) {
            this._priceMax = priceMax;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasPriceMax(): boolean {
        return this._priceMax instanceof TypePositiveInteger;
    }

    get dateRangeListings(): TypePositiveInteger | undefined {
        return this._dateRangeListings;
    }

    setDateRangeListings(dateRangeListings: TypePositiveInteger | undefined) {
        if (!this._dateRangeListings?.sameAs(dateRangeListings)) {
            this._dateRangeListings = dateRangeListings;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasDateRangeListings(): boolean {
        return this._dateRangeListings instanceof TypePositiveInteger;
    }

    get dateRangeSalesComparables(): TypePositiveInteger | undefined {
        return this._dateRangeSalesComparables;
    }

    setDateRangeSalesComparables(
        dateRangeSalesComparables: TypePositiveInteger | undefined
    ) {
        if (
            !this._dateRangeSalesComparables?.sameAs(dateRangeSalesComparables)
        ) {
            this._dateRangeSalesComparables = dateRangeSalesComparables;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasDateRangeSalesComparables(): boolean {
        return this._dateRangeSalesComparables instanceof TypePositiveInteger;
    }

    get dateRangeFinanceComparables(): TypePositiveInteger | undefined {
        return this._dateRangeFinanceComparables;
    }

    setDateRangeFinanceComparables(
        dateRangeFinanceComparables: TypePositiveInteger | undefined
    ) {
        if (
            !this._dateRangeFinanceComparables?.sameAs(
                dateRangeFinanceComparables
            )
        ) {
            this._dateRangeFinanceComparables = dateRangeFinanceComparables;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasDateRangeFinanceComparables(): boolean {
        return this._dateRangeFinanceComparables instanceof TypePositiveInteger;
    }

    get listingBrokerage(): Collection {
        return this._listingBrokerage;
    }

    setListingBrokerage(items: Collection) {
        if (
            !this._listingBrokerage?.isSameAs(items) &&
            items instanceof Collection
        ) {
            this._listingBrokerage = items;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasListingBrokerage(): boolean {
        return this._listingBrokerage instanceof Collection;
    }

    get listingBroker(): Collection {
        return this._listingBroker;
    }

    setListingBroker(items: Collection) {
        if (
            !this._listingBroker?.isSameAs(items) &&
            items instanceof Collection
        ) {
            this._listingBroker = items;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasListingBroker(): boolean {
        return this._listingBroker instanceof Collection;
    }

    get propertyType(): Collection {
        return this._propertyType;
    }

    setPropertyTypes(items: Collection) {
        if (
            !this._propertyType?.isSameAs(items) &&
            items instanceof Collection
        ) {
            this._propertyType = items;
            this._executeAfterChangeTriggers();
        }
        return this;
    }

    hasPropertyType(): boolean {
        return this._propertyType instanceof Collection;
    }

    reset() {
        this._listingsChecked = new TypeBoolean(true);
        this._salesComparablesChecked = new TypeBoolean(true);
        this._financeComparablesChecked = new TypeBoolean(true);
        this._priceMin = new TypePositiveInteger(0);
        this._priceMax = new TypePositiveInteger(600000000);
        this._dateRangeListings = undefined;
        this._dateRangeSalesComparables = undefined;
        this._dateRangeFinanceComparables = undefined;
        this._listingBrokerage = new Collection([]);
        this._listingBroker = new Collection([]);
        this._executeAfterChangeTriggers();
    }
}
