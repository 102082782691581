import { Radio, Space } from "antd";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";

interface RadioButtonFieldProps {
    name: string;
    label?: string;
    control: any;
    errors: Object;
    isRequired?: boolean; //If true, adds * next to the input field
    capitalizeFirstLetter?: boolean;
    validationMessage?: string;
    allowClear?: boolean;
    options: Array<{ label: string; value: number }>;
    disabled?: boolean;
    // eslint-disable-next-line no-unused-vars
    onChange: (value: any) => void;
    labelTooltip?: string;
}

const PartRadioButton = ({
    name,
    label,
    control,
    errors,
    isRequired,
    validationMessage,
    options,
    disabled,
    onChange,
    labelTooltip
}: RadioButtonFieldProps) => {
    return (
        <div
            className={
                !disabled
                    ? `form-element form-element--radio ${
                          name in errors ? "form-element--error" : ""
                      }`
                    : `form-element form-element--radio locked ${
                          name in errors ? "form-element--error" : ""
                      }`
            }
        >
            {label && (
                <label>
                    {label}
                    {isRequired && <span>{" *"}</span>}
                    {labelTooltip && (
                        <div className="label-tooltip">
                            <FontAwesomeIcon icon={faCircleInfo} />
                            <span className="label-tooltip__text">
                                {labelTooltip}
                            </span>
                        </div>
                    )}
                </label>
            )}
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Radio.Group
                        {...field}
                        disabled={disabled}
                        onChange={(e) => {
                            field.onChange(e.target.value);
                            onChange(e);
                        }}
                    >
                        {options.map((option) => (
                            <Radio key={option.value} value={option.value}>
                                {option.label}
                            </Radio>
                        ))}
                    </Radio.Group>
                )}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }: any) => (
                    <p className="form-element__error-msg">
                        <span>
                            {validationMessage ? validationMessage : message}
                        </span>
                    </p>
                )}
            />
            <Space />
        </div>
    );
};

export default PartRadioButton;
