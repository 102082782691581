import Collection from "../valueObject/Collection";
import ListingBrokerFactory from "./ListingBrokerFactory";
import ListingBrokerGateway from "./ListingBrokerGateway";
import ListingBrokerRepository from "./ListingBrokerRepository";

export default class ListingBrokerService {
    static get(names?: Collection) {
        return new ListingBrokerRepository(
            new ListingBrokerGateway(),
            new ListingBrokerFactory()
        ).get(names);
    }
}
