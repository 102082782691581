import Collection from "../../valueObject/Collection";
import TypeString from "../../valueObject/TypeString";
import IFrameMessage from "../IFrameMessage";

export default class OverwriteCalculatorMessage extends IFrameMessage {
    public static ACTION_NAME = "overwrite-calculator";

    constructor() {
        super(OverwriteCalculatorMessage.ACTION_NAME);
    }

    public send(iframe: HTMLIFrameElement, shareId: TypeString) {
        super._send(
            new Collection({
                shareId: shareId.value
            }),
            iframe
        );
    }

    public recieve(callback: Function, data: Collection) {
        callback(data.get("shareId"));
    }
}
