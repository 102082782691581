import Collection from "../valueObject/Collection";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import PropertyDocumentEntity from "./PropertyDocumentEntity";

export default class PropertyDocumentFactory {
    reconstitute(data: Collection) {
        const document = new PropertyDocumentEntity();

        if (data.has("documentId"))
            document.setId(new TypeString(data.get("documentId")));
        if (data.has("fileName"))
            document.setFileName(new TypeString(data.get("fileName")));
        if (data.has("description"))
            document.setDescription(new TypeString(data.get("description")));
        if (data.has("url")) document.setUrl(new TypeString(data.get("url")));
        if (data.has("sizeKB"))
            document.setSizeKB(new TypePositiveInteger(data.get("sizeKB")));

        return document;
    }
}
