import ListingBrokerEntity from "./ListingBrokerEntity";

type ListingBroker = {
    value: number;
    label: string;
};
export default class ListingBrokerViewMapper {
    static map(listingBrokers: ListingBrokerEntity[]): ListingBroker[] {
        return listingBrokers.map((listingBroker: ListingBrokerEntity) => {
            return {
                value: listingBroker.id.value,
                label: listingBroker.name.value
            };
        });
    }
}
