/* eslint-disable prettier/prettier */
import { faArrowLeft, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Slider } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { hotjar } from "react-hotjar";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import BrokerageService from "../../domain/brokerage/BrokerageService";
import BrokerageViewMapper from "../../domain/brokerage/BrokerageViewMapper";
import ListingBrokerService from "../../domain/listingBroker/ListingBrokerService";
import ListingBrokerViewMapper from "../../domain/listingBroker/ListingBrokerViewMapper";
import PropertyService from "../../domain/property/PropertyService";
import PropertySearchQuery from "../../domain/property/queries/PropertySearchQuery";
import PropertyFilterEntity from "../../domain/propertyFilter/PropertyFilterEntity";
import URLObjectEntity from "../../domain/urlQuery/URLObjectEntity";
import URLObjectService from "../../domain/urlQuery/URLObjectService";
import Collection from "../../domain/valueObject/Collection";
import TypePositiveInteger from "../../domain/valueObject/TypePositiveInteger";
import indexSliceState, {
    setFiltersPopupOpen,
    setPropertyTypesChecked
} from "../../store";
import responsiveSliceState, {
    setCurrentView,
    setShowMapBtn
} from "../../store/responsive";
//import { WHERE_FROM_CTA } from "../../utils/constants";
import { getBrokerageNamesFromSelectedValues } from "../../utils/utils";
import PartButton from "../parts/PartButton";
import PartDropdown from "../parts/PartDropdown";
import PartDropdownFilters from "../parts/PartDropdownFilters";
import PartInputRangeNumber from "../parts/PartInputRangeNumber";
//import BlockMembershipCTA from "./BlockMembershipCTA";

export const defaultRangeValues = [0, 600000000];
const rangeStep = 20000;

interface BlockFiltersProps {
    searchIsTriggered: boolean;
    setSearchIsTriggered: Function;
}

const BlockFiltersPopup = ({
    searchIsTriggered,
    setSearchIsTriggered
}: BlockFiltersProps) => {
    const responsiveState = useSelector(responsiveSliceState);

    const [range, setRange] = useState<any>(defaultRangeValues);
    const [brokerages, setBrokerages] = useState<any>([]);
    const [listingBrokerageSelectedValues, setListingBrokerageSelectedValues] =
        useState([]);
    const [listingBrokerSelectedValues, setListingBrokerSelectedValues] =
        useState([]);
    const [listingBrokers, setListingBrokers] = useState<any>([]);

    const sliceState = useSelector(indexSliceState);
    const [searchParams, setSearchParams] = useSearchParams();

    const [touchStart, setTouchStart] = useState(0);
    const [openFilters, setOpenFilters] = useState(false);

    const handleTouchStart = (event: any) => {
        setTouchStart(event.touches[0].clientY);
        scrollToTop();
    };

    const scrollToTop = () => {
        const element = document.querySelector(".popup.popup--filters");
        element?.scrollTo({ top: 0 });
    };

    const handleTouchMove = (event: any) => {
        hotjar.event("Mobile Swipe Filters");
        setOpenFilters(
            touchStart - event.touches[0].clientY > 35
                ? true
                : event.touches[0].clientY - touchStart > 35
                ? false
                : openFilters
        );
    };

    const dispatch = useDispatch();
    const {
        control,
        formState,
        register,
        setValue,
        reset,
        formState: { errors }
    } = useForm({
        mode: "onChange",
        shouldFocusError: false,
        defaultValues: {
            listingsDateRange: 9,
            salesComparablesDateRange: 6,
            financeComparablesDateRange: 6,
            listingBrokerage: [],
            listingBroker: [],
            priceRange: defaultRangeValues
        }
    });

    const query: PropertySearchQuery = PropertyService.getSearchQuery();
    // eslint-disable-next-line no-unused-vars
    const url: URLObjectEntity = URLObjectService.getURLObject(
        searchParams,
        setSearchParams
    );

    const resetFormValues = () => {
        reset();
        setRange(defaultRangeValues);
        setListingBrokerageSelectedValues([]);
        setListingBrokerSelectedValues([]);
        query.filters.reset();
        getAllBrokeragesAndBrokers();
        dispatch(
            setPropertyTypesChecked({
                listings: true,
                saleComps: true,
                financeComps: true
            })
        );
    };

    useEffect(() => {
        if (searchIsTriggered) {
            resetFormValues();
            setSearchIsTriggered(false);
        }
    }, [searchIsTriggered]);

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        if (currentParams !== undefined) {
            fromFilterToForm();
        }
    }, []);

    const fromFilterToForm = () => {
        if (!(query.filters instanceof PropertyFilterEntity)) return;

        dispatch(
            setPropertyTypesChecked({
                listings: true,
                saleComps: query.filters.salesComparablesChecked?.value,
                financeComps: query.filters.financeComparablesChecked?.value
            })
        );

        if (query.filters.hasPriceMin()) {
            setValue(
                "priceRange",
                query.filters.priceMin?.value,
                query.filters.priceMax?.value
            );
            setRange([
                query.filters.priceMin?.value,
                query.filters.priceMax?.value
            ]);
        }

        if (query.filters.hasPriceMax()) {
            setValue("priceRange", [
                query.filters.priceMin?.value,
                query.filters?.priceMax?.value
            ]);
            setRange([
                query.filters.priceMin?.value,
                query.filters.priceMax?.value
            ]);
        }
        if (query.filters.hasDateRangeListings()) {
            setValue(
                "listingsDateRange",
                query.filters.dateRangeListings?.value
            );
        }
        if (query.filters.hasDateRangeSalesComparables()) {
            setValue(
                "salesComparablesDateRange",
                query.filters.dateRangeSalesComparables?.value
            );
        }
        if (query.filters.hasDateRangeFinanceComparables()) {
            setValue(
                "financeComparablesDateRange",
                query.filters.dateRangeFinanceComparables?.value
            );
        }
    };

    const onRangeChange = (range: any) => {
        query.filters.setPriceMin(new TypePositiveInteger(range[0]));
        query.filters.setPriceMax(new TypePositiveInteger(range[1]));
        setRange(range);
    };

    const getAllBrokeragesAndBrokers = async () => {
        const brokerageResponse = await BrokerageService.getAll();
        const brokerages = BrokerageViewMapper.map(brokerageResponse);

        const brokersResponse = await ListingBrokerService.get(); // no parameter means get all brokers
        const brokers = ListingBrokerViewMapper.map(brokersResponse.items);

        setBrokerages(brokerages);
        setListingBrokers(brokers);
    };

    const handleListingBrokerageChange = (selectedValues: any) => {
        setListingBrokerageSelectedValues(selectedValues);

        let brokerageNames: any = [];
        if (selectedValues.length > 0) {
            brokerageNames = getBrokerageNamesFromSelectedValues(
                selectedValues,
                brokerages
            );
        }
        ListingBrokerService.get(new Collection(brokerageNames)).then(
            (response: any) => {
                setListingBrokers(ListingBrokerViewMapper.map(response.items));
                if (response.items.length < 1) {
                    query.filters.setListingBroker(new Collection([]));
                }
            }
        );

        query.filters.setListingBrokerage(new Collection(brokerageNames));
    };

    useEffect(() => {
        if(sliceState.isLoggedIn)
            getAllBrokeragesAndBrokers();
    }, []);

    return (
        <div
            className={`filters-popup ${
                sliceState.filtersPopupOpen
                    ? "filters-popup-open"
                    : "filters-popup-closed"
            }`}
        >
            <div className="overlay"></div>
            <div
                className={`popup popup--filters ${
                    sliceState.isPaidUser ? "paid-user" : "non-paid"
                } ${!openFilters ? "open" : "closed"} ${
                    responsiveState.currentView === "dashboard" ||
                    responsiveState.overlays.hamburgerMenu ||
                    responsiveState.overlays.propertySearch ||
                    sliceState.propertyCardItem.isVisible
                        ? "hidden"
                        : "visible"
                }`}
            >
                <div
                    className="popup-filters__header-wrapper"
                    onTouchMove={handleTouchMove}
                    onTouchStart={handleTouchStart}
                >
                    {openFilters ? (
                        <span
                            className="popup__back-mobile"
                            onClick={() => {
                                hotjar.event("Back CTA - Filters Popup");
                                setOpenFilters(false);
                                dispatch(setCurrentView("dashboard"));
                                dispatch(setShowMapBtn(true));
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                            back
                        </span>
                    ) : (
                        <span
                            className="popup__tools-mobile"
                            onClick={() => {
                                hotjar.event(
                                    "Back to Tools CTA - Filters Popup"
                                );
                                dispatch(setCurrentView("dashboard"));
                                dispatch(setShowMapBtn(true));
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                            tools
                        </span>
                    )}
                    <h3 className="popup__heading">Filters</h3>
                </div>
                <div className="popup-filters__inner">
                    <Form>
                        <div className="popup__wrap-top">
                            <span
                                className="popup__close"
                                onClick={() =>
                                    dispatch(setFiltersPopupOpen(false))
                                }
                            >
                                <FontAwesomeIcon icon={faXmark} />
                            </span>
                            <div className="popup__section">
                                <h3>Price Range</h3>
                                <p>
                                    Price range applies to listings, sales
                                    comparables, and finance comparables.
                                </p>
                                <div className="popup__section-price">
                                    <Slider
                                        {...register("priceRange")}
                                        range
                                        min={defaultRangeValues[0]}
                                        max={defaultRangeValues[1]}
                                        step={rangeStep}
                                        value={range}
                                        tooltipVisible={false}
                                        onChange={onRangeChange}
                                        onAfterChange={(vals: any) => {
                                            hotjar.event(
                                                `Filters Price Range - Min: ${vals[0]}, Max: ${vals[1]}`
                                            );
                                        }}
                                    />
                                    <div className="filters__price-inputs">
                                        <PartInputRangeNumber
                                            name="minPrice"
                                            label="min price"
                                            errors={errors}
                                            value={range[0]}
                                            range={range}
                                            onRangeChange={onRangeChange}
                                        />
                                        <PartInputRangeNumber
                                            name="maxPrice"
                                            label="max price"
                                            errors={errors}
                                            value={range[1]}
                                            range={range}
                                            onRangeChange={onRangeChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="popup__section">
                                <h3>Date Range</h3>
                                <p>
                                    Filter listing, sales comparable, or finance
                                    comparable data by date.{" "}
                                    <b>
                                        If you filter out one set of data based
                                        on date range, you may still see other
                                        applicable data for that property on the
                                        map.
                                    </b>
                                </p>
                                <div
                                    className="filters__dropdowns filters__date-range-dds"
                                    id="filters__dropdowns-holder"
                                >
                                    <div className="filters__select filters__select--icon">
                                        <div
                                            className={`filters__info-popup-wrapper listings ${
                                                !sliceState.propertyTypesChecked
                                                    .listings
                                                    ? "disabled"
                                                    : ""
                                            }`}
                                        >
                                            <h3 className="filters__select-title">
                                                Listings
                                                <span className="filters__select-legend filters__select-legend--listing">
                                                    $$$
                                                </span>
                                            </h3>
                                        </div>
                                        <PartDropdown
                                            name="listingsDateRange"
                                            control={control}
                                            errors={errors}
                                            placeholder="Choose only one option"
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    "filters__dropdowns-holder"
                                                )
                                            }
                                            defaultValue={9}
                                            disabled={
                                                !sliceState.propertyTypesChecked
                                                    .listings
                                            }
                                            onChange={(value: any) => {
                                                query.filters.setDateRangeListings(
                                                    new TypePositiveInteger(
                                                        value
                                                    )
                                                );
                                            }}
                                            formState={formState}
                                            data={[
                                                {
                                                    label: "Last 3 days",
                                                    value: 1
                                                },
                                                {
                                                    label: "Last 1 week",
                                                    value: 2
                                                },
                                                {
                                                    label: "Last 2 weeks",
                                                    value: 3
                                                },
                                                {
                                                    label: "Last 3 weeks",
                                                    value: 4
                                                },
                                                {
                                                    label: "Last 1 month",
                                                    value: 5
                                                },
                                                {
                                                    label: "Last 3 months",
                                                    value: 6
                                                },
                                                {
                                                    label: "Last 6 months",
                                                    value: 7
                                                },
                                                {
                                                    label: "Last 1 year",
                                                    value: 8
                                                },
                                                {
                                                    label: "All available",
                                                    value: 9
                                                }
                                            ]}
                                            hotjarEventName="Listings Dropdown"
                                        />
                                    </div>
                                    <div className="filters__select filters__select--icon">
                                        <h3
                                            className={`filters__select-title ${
                                                query.filters
                                                    .salesComparablesChecked
                                                    ?.value === false
                                                    ? true
                                                    : false
                                            }`}
                                        >
                                            Sale Comparables
                                            <span className="filters__select-legend filters__select-legend--sale"></span>
                                        </h3>
                                        <PartDropdown
                                            name="salesComparablesDateRange"
                                            control={control}
                                            errors={errors}
                                            placeholder="Choose only one option"
                                            defaultValue={6}
                                            // disabled={
                                            //     (query.filters
                                            //         .salesComparablesChecked
                                            //         ?.value === false
                                            //         ? true
                                            //         : false) ||
                                            //     !sliceState.isPaidUser
                                            // }
                                            formState={formState}
                                            onChange={(value: any) => {
                                                query.filters.setDateRangeSalesComparables(
                                                    new TypePositiveInteger(
                                                        value
                                                    )
                                                );
                                            }}
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    "filters__dropdowns-holder"
                                                )
                                            }
                                            data={[
                                                {
                                                    label: "Last 1 month",
                                                    value: 1
                                                },
                                                {
                                                    label: "Last 3 months",
                                                    value: 2
                                                },
                                                {
                                                    label: "Last 6 months",
                                                    value: 3
                                                },
                                                {
                                                    label: "Last 1 year",
                                                    value: 4
                                                },
                                                {
                                                    label: "Last 2 years",
                                                    value: 5
                                                },
                                                {
                                                    label: "Last 3 years",
                                                    value: 6
                                                }
                                            ]}
                                            hotjarEventName="Sale Comparables Dropdown"
                                        />
                                    </div>
                                    <div className="filters__select filters__select--icon">
                                        <h3
                                            className={`filters__select-title ${
                                                query.filters
                                                    .financeComparablesChecked
                                                    ?.value === false
                                                    ? true
                                                    : false
                                            }`}
                                        >
                                            Finance Comparables
                                            <span className="filters__select-legend filters__select-legend--finance"></span>
                                        </h3>
                                        <PartDropdown
                                            name="financeComparablesDateRange"
                                            control={control}
                                            errors={errors}
                                            placeholder="Choose only one option"
                                            defaultValue={6}
                                            // disabled={
                                            //     (query.filters
                                            //         .financeComparablesChecked
                                            //         ?.value === false
                                            //         ? true
                                            //         : false) ||
                                            //     !sliceState.isPaidUser
                                            // }
                                            formState={formState}
                                            onChange={(value: any) => {
                                                query.filters.setDateRangeFinanceComparables(
                                                    new TypePositiveInteger(
                                                        value
                                                    )
                                                );
                                            }}
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    "filters__dropdowns-holder"
                                                )
                                            }
                                            data={[
                                                {
                                                    label: "Last 1 month",
                                                    value: 1
                                                },
                                                {
                                                    label: "Last 3 months",
                                                    value: 2
                                                },
                                                {
                                                    label: "Last 6 months",
                                                    value: 3
                                                },
                                                {
                                                    label: "Last 1 year",
                                                    value: 4
                                                },
                                                {
                                                    label: "Last 2 years",
                                                    value: 5
                                                },
                                                {
                                                    label: "Last 3 years",
                                                    value: 6
                                                }
                                            ]}
                                            hotjarEventName="Finance Comparables Dropdown"
                                        />
                                    </div>
                                    {/* {!sliceState.isPaidUser ? (
                                        <BlockMembershipCTA
                                            content="Unlock Sales &amp; Finance Comparables"
                                            ctaLinkContent="Become a member to unlock"
                                            hotjarEventName="Become a Member - Unlock Comps Sidebar Date Range"
                                            ctaFrom={
                                                WHERE_FROM_CTA.LEFT_NAV_SALES_FINANCE_COMP_FILTERS
                                            }
                                        />
                                    ) : null} */}
                                </div>
                            </div>
                            <div className="popup__section">
                                <h3 className="popup__heading-brokerages">
                                    Brokerage Information
                                </h3>
                                <div className="filters__dropdowns-brokerages">
                                    <div className="filters__select">
                                        <h3 className="filters__select-title large brokerages">
                                            Listing Brokerage
                                        </h3>
                                        <PartDropdownFilters
                                            parent="filters"
                                            showSearch={true}
                                            name="listingBrokerage"
                                            dropdownClassName="brokers-dropdown"
                                            value={
                                                listingBrokerageSelectedValues
                                            }
                                            placeholder="Search Brokerage"
                                            disabled={
                                                !sliceState.propertyTypesChecked
                                                    .listings
                                            }
                                            noContentMessage="Nothing Matches Your Search Criteria"
                                            onChange={
                                                handleListingBrokerageChange
                                            }
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    "filters__dropdowns-holder"
                                                )
                                            }
                                            options={brokerages}
                                            hotjarEventName="Listing Brokerage Dropdown"
                                        />
                                    </div>
                                    <div className="filters__select">
                                        <h3 className="filters__select-title large brokerages">
                                            Broker Name
                                        </h3>
                                        <PartDropdownFilters
                                            parent="filters"
                                            showSearch={true}
                                            dropdownClassName="brokers-dropdown"
                                            name="listingBroker"
                                            value={listingBrokerSelectedValues}
                                            placeholder="Search Broker"
                                            disabled={
                                                !sliceState.propertyTypesChecked
                                                    .listings
                                            }
                                            noContentMessage="Nothing Matches Your Search Criteria"
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    "filters__dropdowns-holder"
                                                )
                                            }
                                            onChange={(values: any) => {
                                                setListingBrokerSelectedValues(
                                                    values
                                                );
                                                const selectedBrokerIds =
                                                    values.map((value: any) => {
                                                        const exists =
                                                            listingBrokers.find(
                                                                (item: any) => {
                                                                    return (
                                                                        item.label ===
                                                                        value
                                                                    );
                                                                }
                                                            );
                                                        if (exists)
                                                            return exists.value;
                                                    });
                                                query.filters.setListingBroker(
                                                    new Collection(
                                                        selectedBrokerIds
                                                    )
                                                );
                                            }}
                                            options={listingBrokers}
                                            hotjarEventName="Listing Broker Dropdown"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup__wrap-bottom">
                            <div
                                className="filters__clear-link"
                                role="button"
                                onClick={() => {
                                    resetFormValues();
                                    hotjar.event("Button Reset Filters");
                                }}
                            >
                                Clear all
                            </div>
                            <PartButton
                                type="primary"
                                onClick={() => {
                                    dispatch(setFiltersPopupOpen(false));
                                    setOpenFilters(false);
                                    scrollToTop();
                                }}
                            >
                                Apply Filters
                            </PartButton>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default BlockFiltersPopup;
