import { BrokerageType } from "./../domain/brokerage/BrokerageViewMapper";
export const convertFromBinaryToImage = (
    binary: Blob | MediaSource | undefined
) => {
    if (binary === undefined) return undefined;
    return URL.createObjectURL(binary);
};

export const getBrokerageNamesFromSelectedValues = (
    selectedValues: (number | string)[],
    brokerages: any
) => {
    let brokerageNames: string[] = [];
    if (selectedValues.includes("All")) {
        brokerageNames = brokerages.map(
            (broker: BrokerageType) => broker.label
        );
    } else {
        selectedValues.forEach((value: any) => {
            if (brokerages !== undefined) {
                const brokerageName = brokerages.find(
                    (broker: any) => broker.value === value
                ).label;
                if (brokerageName) brokerageNames.push(brokerageName);
            }
        });
    }
    return brokerageNames;
};

export const checkTokenExpiration = (token: string) => {
    if (token) {
        const expiry = JSON.parse(atob(token.split(".")[1])).exp;
        return Math.floor(new Date().getTime() / 1000) >= expiry;
    }
    return true;
};

export const isTrialPeriodExpired = (trialPeriodDate: Date) => {
    const today = new Date();
    return today > trialPeriodDate;
};

export const calculateDaysLeft = (trialPeriodDate: Date) => {
    const today = new Date();
    const timeDiff = Math.abs(trialPeriodDate.getTime() - today.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
};

export const capitalizeFirstLetter = (
    value: any,
    isAddress: boolean = false
) => {
    const transformValue = (val: any, char: string) => {
        return val
            .toLowerCase()
            .split(char)
            .map((word: string) => {
                if (word === '("sfr")') return word.toUpperCase();
                return word.charAt(0).toUpperCase() + word.substring(1);
            })
            .join(char);
    };

    if (value) {
        return isAddress
            ? value
                  .split(",")
                  .map((val: string, i: number) => {
                      if (i === 0 || i === 1) {
                          return transformValue(val, " ");
                      }
                      return val;
                  })
                  .join(",")
            : transformValue(value, " ");
    }
    return value;
};

export const insertLineBreak = (value: any) => {
    if (value) {
        value = value.split(",");

        for (let i = 0; i < value.length; i++) {
            if (i === 1) {
                value[i] = "\n" + value[i];
            }
        }

        value = value.join(",");
    }
    return value;
};

// This function is used to get the popup container for the antd dropdown, and make it stick to it's parent, when scrolling
export const getSelectPopupContainer = (triggerNode: any) => {
    return triggerNode.parentNode;
};

export const getLastFavoriteCreatedProperty = (properties: any) => {
    const latest = properties.reduce((r: any, a: any) => {
        return r.createdAt > a.createdAt ? r : a;
    });

    return latest;
};
