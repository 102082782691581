import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./storeIndex";

export interface Index {
    isLoading: LoadingProps;
    autoCompleteResult: AutoCompleteResultsInterface | undefined;
    mapViewPortCenterCoords: AutoCompleteResCoords | undefined;
    propertyCardItem: PropertyCardItemProps;
    propertyCardClassName: string;
    totalMarkers: TotalMarkersInterface | undefined;
    states: IState[];
    propertyTypes: IPropertyType[];
    isLoggedIn: boolean;
    isPaidUser: boolean;
    isLeasingAdmin: boolean;
    isUnpaidUser: boolean;
    isLenderUser: boolean;
    isLimitedUser: boolean;
    subscriptionExpired: boolean;
    subscriptionDaysLeft: number;
    token: string;
    iframe: any;
    lenderFlyout: LenderFlyout;
    lenderIframe: any;
    calculatorIframe: any;
    trialPopupOpen: boolean;
    trialPopupWhereFrom: string;
    filtersPopupOpen: boolean;
    findALenderData: {
        state: string | undefined;
        propertyType: string | undefined;
        loanType: number;
        loanAmount: string | undefined;
        propertyId: string | undefined;
        address: string | undefined;
        calculatedLoanAmount: number | undefined;
        lastPropertyCardClicked: any;
        publicLenders: boolean;
        executionTypes: string[];
        accessedFrom: string;
    };
    propertyTypesChecked: {
        listings: boolean;
        saleComps: boolean;
        financeComps: boolean;
    };
    calculatorSlideout: CalculatorSlideout;
    leasingAdminSlideout: LeasingAdminSlideout;
    affilliatedPopup: any;
    favoriteTypeAffiliatedPropertyId: string;
    shareCalculatorPopup: IShareCalculatorPopup;
    overwriteCalculatorPopup: OverwriteCalculatorPopup;
    uploadedDocuments: Array<any>;
    showLpInvestmentPopup: ShowLpInvestmentPopup;
    searchMapFromCalc: boolean;
}

export const USDefaultLat = 37.0902;
export const USDefaultLng = -95.7129;

export const initialState: Index = {
    isLoggedIn: false,
    isPaidUser: false,
    isLeasingAdmin: false,
    isUnpaidUser: false,
    isLenderUser: false,
    isLimitedUser: false,
    subscriptionExpired: false,
    subscriptionDaysLeft: 0,
    isLoading: {
        loading: false,
        type: "content",
        name: null
    },
    autoCompleteResult: undefined,
    mapViewPortCenterCoords: {
        lat: USDefaultLat,
        lng: USDefaultLng
    },
    propertyCardItem: {
        data: null,
        isVisible: false,
        lat: 0,
        lng: 0
    },
    propertyCardClassName: "",
    states: [],
    propertyTypes: [],
    totalMarkers: undefined,
    token: "",
    iframe: false,
    lenderFlyout: {
        isOpen: false,
        data: undefined,
        type: undefined
    },
    lenderIframe: undefined,
    calculatorIframe: undefined,
    trialPopupOpen: true,
    trialPopupWhereFrom: "",
    filtersPopupOpen: false,
    findALenderData: {
        state: undefined,
        propertyType: undefined,
        propertyId: undefined,
        loanType: 1,
        loanAmount: undefined,
        address: undefined,
        calculatedLoanAmount: undefined,
        lastPropertyCardClicked: undefined,
        accessedFrom: "",
        publicLenders: false,
        executionTypes: []
    },
    propertyTypesChecked: {
        listings: true,
        saleComps: true,
        financeComps: true
    },
    calculatorSlideout: {
        isOpen: false,
        id: null,
        overlay: true,
        address: ""
    },
    leasingAdminSlideout: {
        isOpen: false,
        id: null
    },
    affilliatedPopup: false,
    favoriteTypeAffiliatedPropertyId: "",
    shareCalculatorPopup: {
        isOpen: false,
        hash: undefined,
        propertyId: undefined
    },
    overwriteCalculatorPopup: {
        isOpen: false,
        calculatorShareId: undefined
    },
    uploadedDocuments: [],
    showLpInvestmentPopup: {
        isOpen: false,
        propertyId: undefined,
        propertyAddress: "",
        propertyTypeName: ""
    },
    searchMapFromCalc: false
};

interface IShareCalculatorPopup {
    isOpen: boolean;
    hash: string | undefined;
    propertyId: string | undefined;
}

interface OverwriteCalculatorPopup {
    isOpen: boolean;
    calculatorShareId: string | undefined;
}

interface LoadingProps {
    loading: boolean;
    type: "button" | "content" | "full";
    name?: string | null;
}

export interface AutoCompleteResultsInterface {
    address_components: any;
    formatted_address: string;
    geometry: object;
    place_id: string;
    types: string[];
}

export interface AutoCompleteResCoords {
    lat: number;
    lng: number;
}

export interface TotalMarkersInterface {
    listingsCount: number;
    financeComparablesCount: number;
    salesComparablesCount: number;
}

interface PropertyCardItemProps {
    data: any;
    isVisible: boolean;
    address?: string;
    property_type?: string;
    lat?: number;
    lng?: number;
}

interface LenderFlyout {
    isOpen: boolean;
    data: undefined | any;
    type: "originator-profiles" | "lending-details" | undefined;
}

interface CalculatorSlideout {
    isOpen: boolean;
    id: number | null;
    overlay?: boolean;
    address?: string;
}

interface LeasingAdminSlideout {
    isOpen: boolean;
    id: number | null;
}

export interface IState {
    id: number;
    state: string;
    abbreviation: string;
}

export interface IPropertyType {
    value: number;
    label: string;
}

export interface ShowLpInvestmentPopup {
    isOpen: boolean;
    propertyId: string | undefined;
    propertyAddress?: string;
    propertyTypeName?: string;
}

export const indexSlice = createSlice({
    name: "index",
    initialState,
    reducers: {
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        setIsPaidUser: (state, action: PayloadAction<boolean>) => {
            state.isPaidUser = action.payload;
        },
        setIsLeasingAdmin: (state, action: PayloadAction<boolean>) => {
            state.isLeasingAdmin = action.payload;
        },
        setIsUnpaidUser: (state, action: PayloadAction<boolean>) => {
            state.isUnpaidUser = action.payload;
        },
        setIsLenderUser: (state, action: PayloadAction<boolean>) => {
            state.isLenderUser = action.payload;
        },
        setIsLimitedUser: (state, action: PayloadAction<boolean>) => {
            state.isLimitedUser = action.payload;
        },
        // Loading true when some API request is in progress
        setIsLoading: (state, action: PayloadAction<LoadingProps>) => {
            state.isLoading = action.payload;
        },
        setAutoCompleteResult: (
            state,
            action: PayloadAction<AutoCompleteResultsInterface | undefined>
        ) => {
            state.autoCompleteResult = action.payload;
        },
        setMapViewPortCenterCoords: (
            state,
            action: PayloadAction<AutoCompleteResCoords | undefined>
        ) => {
            state.mapViewPortCenterCoords = action.payload;
        },
        setPropertyCardItem: (
            state,
            action: PayloadAction<PropertyCardItemProps>
        ) => {
            state.propertyCardItem = action.payload;
        },
        setPropertyCardClassName: (state, action: PayloadAction<string>) => {
            state.propertyCardClassName = action.payload;
        },
        setTotalMarkers: (
            state,
            action: PayloadAction<TotalMarkersInterface | undefined>
        ) => {
            state.totalMarkers = action.payload;
        },
        setStates: (state, action: PayloadAction<IState[]>) => {
            state.states = action.payload;
        },
        setPropertyTypes: (state, action: PayloadAction<IPropertyType[]>) => {
            state.propertyTypes = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        setIframe: (state, action: PayloadAction<any>) => {
            state.iframe = action.payload;
        },
        setIframeZoom: (state, action: PayloadAction<any>) => {
            state.iframe = { zoom: action.payload, ...state.iframe };
        },
        setLenderIframe: (state, action: PayloadAction<any>) => {
            state.lenderIframe = action.payload;
        },
        setLenderFlyout: (state, action: PayloadAction<LenderFlyout>) => {
            state.lenderFlyout = action.payload;
        },
        setSubscriptionExpired: (state, action: PayloadAction<boolean>) => {
            state.subscriptionExpired = action.payload;
        },
        setSubscriptionDaysLeft: (state, action: PayloadAction<number>) => {
            state.subscriptionDaysLeft = action.payload;
        },
        setTrialPopupOpen: (state, action: PayloadAction<boolean>) => {
            state.trialPopupOpen = action.payload;
        },
        setTrialPopupWhereFrom: (state, action: PayloadAction<string>) => {
            state.trialPopupWhereFrom = action.payload;
        },
        setFiltersPopupOpen: (state, action: PayloadAction<boolean>) => {
            state.filtersPopupOpen = action.payload;
        },
        setFindALenderData: (state, action: PayloadAction<any>) => {
            state.findALenderData = action.payload;
        },
        setFindALenderAccessedFrom: (state, action: PayloadAction<any>) => {
            state.findALenderData = {
                ...state.findALenderData,
                accessedFrom: action.payload
            };
        },
        setPropertyTypesChecked: (state, action: PayloadAction<any>) => {
            state.propertyTypesChecked = action.payload;
        },
        setCalculatorIframe: (state, action: PayloadAction<any>) => {
            state.calculatorIframe = action.payload;
        },
        setCalculatorSlideout: (
            state,
            action: PayloadAction<CalculatorSlideout>
        ) => {
            state.calculatorSlideout = {
                ...state.calculatorSlideout,
                ...action.payload
            };
        },
        setLeasingAdminSlideout: (
            state,
            action: PayloadAction<LeasingAdminSlideout>
        ) => {
            state.leasingAdminSlideout = {
                ...state.leasingAdminSlideout,
                ...action.payload
            };
        },
        setAffilliatedPopup: (state, action: PayloadAction<boolean>) => {
            state.affilliatedPopup = action.payload;
        },
        setFavoriteTypeAffiliatedPropertyId: (
            state,
            action: PayloadAction<string>
        ) => {
            state.favoriteTypeAffiliatedPropertyId = action.payload;
        },
        setShareCalculatorPopup: (
            state,
            action: PayloadAction<IShareCalculatorPopup>
        ) => {
            state.shareCalculatorPopup = action.payload;
        },
        setOverwriteCalculatorPopup: (
            state,
            action: PayloadAction<OverwriteCalculatorPopup>
        ) => {
            state.overwriteCalculatorPopup = action.payload;
        },
        setUploadedDocuments: (state, action: PayloadAction<Array<any>>) => {
            state.uploadedDocuments = action.payload;
        },
        setShowLpInvestorPopup: (
            state,
            action: PayloadAction<ShowLpInvestmentPopup>
        ) => {
            state.showLpInvestmentPopup = action.payload;
        },
        setSearchMapFromCalc: (state, action: PayloadAction<boolean>) => {
            state.searchMapFromCalc = action.payload;
        }
    }
});

export const {
    setIsLoggedIn,
    setIsPaidUser,
    setIsLeasingAdmin,
    setIsUnpaidUser,
    setIsLenderUser,
    setIsLimitedUser,
    setIsLoading,
    setAutoCompleteResult,
    setMapViewPortCenterCoords,
    setPropertyCardItem,
    setPropertyCardClassName,
    setTotalMarkers,
    setStates,
    setToken,
    setPropertyTypes,
    setIframe,
    setIframeZoom,
    setLenderIframe,
    setLenderFlyout,
    setSubscriptionExpired,
    setSubscriptionDaysLeft,
    setTrialPopupOpen,
    setTrialPopupWhereFrom,
    setFiltersPopupOpen,
    setFindALenderData,
    setFindALenderAccessedFrom,
    setPropertyTypesChecked,
    setCalculatorIframe,
    setCalculatorSlideout,
    setLeasingAdminSlideout,
    setAffilliatedPopup,
    setFavoriteTypeAffiliatedPropertyId,
    setShareCalculatorPopup,
    setOverwriteCalculatorPopup,
    setUploadedDocuments,
    setShowLpInvestorPopup,
    setSearchMapFromCalc
} = indexSlice.actions;

const indexSliceState = (state: RootState) => state.index;
export default indexSliceState;
