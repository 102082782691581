import Collection from "../../valueObject/Collection";

export default class PropertyType {
    static readonly TYPE_CANNABIS = 27;
    static readonly TYPE_CANNABIS_NAME = "Cannabis";

    static readonly TYPE_CO_LIVING = 26;
    static readonly TYPE_CO_LIVING_NAME = "Co-Living";

    static readonly TYPE_CONDO = 17;
    static readonly TYPE_CONDO_NAME = "Condo";

    static readonly TYPE_DUPLEX = 25;
    static readonly TYPE_DUPLEX_NAME = "2-4 Unit";

    static readonly TYPE_GAS_STATION = 28;
    static readonly TYPE_GAS_STATION_NAME = "Gas Station";

    static readonly TYPE_HEALTHCARE = 12;
    static readonly TYPE_HEALTHCARE_NAME = "Healthcare";

    static readonly TYPE_HOTELS = 6;
    static readonly TYPE_HOTELS_NAME = "Hotel";

    static readonly TYPE_INDUSTRIAL = 5;
    static readonly TYPE_INDUSTRIAL_NAME = "Industrial";

    static readonly TYPE_LAND = 14;
    static readonly TYPE_LAND_NAME = "Land";

    static readonly TYPE_MIXED_USE = 4;
    static readonly TYPE_MIXED_USE_NAME = "Mixed Use";

    static readonly TYPE_MOBILE_HOME = 19;
    static readonly TYPE_MOBILE_HOME_NAME = "Manufactured Housing";

    static readonly TYPE_MULTIFAMILY = 1;
    static readonly TYPE_MULTIFAMILY_NAME = "Multifamily";

    static readonly TYPE_OFFICE_BUILDING = 2;
    static readonly TYPE_OFFICE_BUILDING_NAME = "Office";

    static readonly TYPE_PARKING_GARAGE = 22;
    static readonly TYPE_PARKING_GARAGE_NAME = "Parking Garage";

    static readonly TYPE_RETAIL_SHOPPING = 9;
    static readonly TYPE_RETAIL_SHOPPING_NAME = "Retail";

    static readonly TYPE_SELF_STORAGE = 18;
    static readonly TYPE_SELF_STORAGE_NAME = "Self Storage";

    static readonly TYPE_SENIOR_LIVING = 20;
    static readonly TYPE_SENIOR_LIVING_NAME = "Senior Living";

    static readonly TYPE_SHORT_TERM_RENTALS = 29;
    static readonly TYPE_SHORT_TERM_RENTALS_NAME = "Short Term Rental";

    static readonly TYPE_SINGLE_FAMILY = 23;
    static readonly TYPE_SINGLE_FAMILY_NAME = "Single Family Rental (SFR)";

    static readonly TYPE_SINGLE_TNT = 10;
    static readonly TYPE_SINGLE_TNT_NAME = "Single Tenant";

    static readonly TYPE_SRO = 24;
    static readonly TYPE_SRO_NAME = "SRO";

    static readonly TYPE_STUDENT_HOUSING = 21;
    static readonly TYPE_STUDENT_HOUSING_NAME = "Student Housing";

    static readonly TYPE_WAREHOUSE = 11;
    static readonly TYPE_WAREHOUSE_NAME = "Warehouse";

    static readonly TYPE_RV_CAMPGROUND = 30;
    static readonly TYPE_RV_CAMPGROUND_NAME = "RV Campground";

    static readonly TYPE_RV_SHELTER = 31;
    static readonly TYPE_RV_SHELTER_NAME = "Shelter";

    static readonly TYPE_OTHER = 999;
    static readonly TYPE_OTHER_NAME = "Other";

    private _value: number;

    constructor(value: number | string) {
        if (Number.isNaN(+value) && typeof value === "string") {
            this._value = PropertyType.getAvailableTypes().get(
                PropertyType.getAvailableTypeNames().findKey(
                    (item: any) => item === value
                )
            );
        } else if (!Number.isNaN(+value)) {
            this._value = +value;
        }

        this.validateValue();
    }

    get id(): number {
        return this._value;
    }

    get name(): string {
        return PropertyType.getAvailableTypeNames().get(
            PropertyType.getAvailableTypes().findKey(
                (item: any) => item === this._value
            )
        );
    }

    public static getAvailableTypes(): Collection {
        return new Collection([
            PropertyType.TYPE_CANNABIS,
            PropertyType.TYPE_CO_LIVING,
            PropertyType.TYPE_CONDO,
            PropertyType.TYPE_DUPLEX,
            PropertyType.TYPE_GAS_STATION,
            PropertyType.TYPE_HEALTHCARE,
            PropertyType.TYPE_HOTELS,
            PropertyType.TYPE_INDUSTRIAL,
            PropertyType.TYPE_LAND,
            PropertyType.TYPE_MIXED_USE,
            PropertyType.TYPE_MOBILE_HOME,
            PropertyType.TYPE_MULTIFAMILY,
            PropertyType.TYPE_OFFICE_BUILDING,
            PropertyType.TYPE_PARKING_GARAGE,
            PropertyType.TYPE_RETAIL_SHOPPING,
            PropertyType.TYPE_SELF_STORAGE,
            PropertyType.TYPE_SENIOR_LIVING,
            PropertyType.TYPE_SHORT_TERM_RENTALS,
            PropertyType.TYPE_SINGLE_FAMILY,
            PropertyType.TYPE_SINGLE_TNT,
            PropertyType.TYPE_SRO,
            PropertyType.TYPE_STUDENT_HOUSING,
            PropertyType.TYPE_WAREHOUSE,
            PropertyType.TYPE_RV_CAMPGROUND,
            PropertyType.TYPE_RV_SHELTER,
            PropertyType.TYPE_OTHER
        ]);
    }

    public static getAvailableTypeNames(): Collection {
        return new Collection([
            PropertyType.TYPE_CANNABIS_NAME,
            PropertyType.TYPE_CO_LIVING_NAME,
            PropertyType.TYPE_CONDO_NAME,
            PropertyType.TYPE_DUPLEX_NAME,
            PropertyType.TYPE_GAS_STATION_NAME,
            PropertyType.TYPE_HEALTHCARE_NAME,
            PropertyType.TYPE_HOTELS_NAME,
            PropertyType.TYPE_INDUSTRIAL_NAME,
            PropertyType.TYPE_LAND_NAME,
            PropertyType.TYPE_MIXED_USE_NAME,
            PropertyType.TYPE_MOBILE_HOME_NAME,
            PropertyType.TYPE_MULTIFAMILY_NAME,
            PropertyType.TYPE_OFFICE_BUILDING_NAME,
            PropertyType.TYPE_PARKING_GARAGE_NAME,
            PropertyType.TYPE_RETAIL_SHOPPING_NAME,
            PropertyType.TYPE_SELF_STORAGE_NAME,
            PropertyType.TYPE_SENIOR_LIVING_NAME,
            PropertyType.TYPE_SHORT_TERM_RENTALS_NAME,
            PropertyType.TYPE_SINGLE_FAMILY_NAME,
            PropertyType.TYPE_SINGLE_TNT_NAME,
            PropertyType.TYPE_SRO_NAME,
            PropertyType.TYPE_STUDENT_HOUSING_NAME,
            PropertyType.TYPE_WAREHOUSE_NAME,
            PropertyType.TYPE_RV_CAMPGROUND_NAME,
            PropertyType.TYPE_RV_SHELTER_NAME,
            PropertyType.TYPE_OTHER_NAME
        ]);
    }

    private validateValue() {
        if (!PropertyType.getAvailableTypes().contains(this._value)) {
            console.error("INVALID_PROPERTY_TYPE", this._value);
            this._value = PropertyType.TYPE_OTHER;
            // throw new Exception("INVALID_PROPERTY_TYPE", 500);
        }
    }
}
