import URLObjectEntity from "./URLObjectEntity";
import URLObjectRAMGateway from "./URLObjectRAMGateway";

export default class URLObjectService {
    public static getURLObject(
        searchParams: URLSearchParams,
        setSearchParams: Function
    ) {
        if (new URLObjectRAMGateway().retrieve("all") === undefined) {
            const url = new URLObjectEntity(searchParams, setSearchParams);
            new URLObjectRAMGateway().persist("all", url);
        }
        return new URLObjectRAMGateway().retrieve("all");
    }
}
